import React from 'react';
import CalendarIcon from 'ui/Icons/calendar-today.component.svg';
import { SvgIcon } from 'ui/SvgIcon';
import { formatDateDisplayFourDigitYear, numberOfNights } from 'utils';

export const ArrivalDepartureSingleLine = ({
  arival: arrival,
  departure: departure,
  showNumberOfNights,
}: {
  arival: string;
  departure: string;
  showNumberOfNights?: boolean;
}) => {
  return (
    <span className="font-pt-sans dates flex justify-between items-center space-x-2 w-full text-15px leading-17px">
      <div className="flex items-center gap-[5px]">
        <SvgIcon width="16px" height="16px" IconComponent={CalendarIcon} defaultFill="#413E3B" />
        <span>
          {formatDateDisplayFourDigitYear(arrival)} - {formatDateDisplayFourDigitYear(departure)}
        </span>
      </div>
      {showNumberOfNights && (
        <span className="flex justify-center items-center bg-teal-40 px-[10px] h-[27px]">
          {numberOfNights(arrival, departure)}&nbsp;N
        </span>
      )}
    </span>
  );
};
