import countries from 'config/data/countries.json';
import { ECompanyType, EMarketingSource } from 'services/BackendApi';

export const countryOptions = Object.values(countries).map(country => ({
  value: country.code,
  label: country.name,
}));

export const marketingSourceOptions = [
  EMarketingSource.HOTEL_REFERRAL,
  EMarketingSource.PARTNER_REFERRAL,
  EMarketingSource.SALES_TEAM_REFERRAL,
  EMarketingSource.TRADE_SHOW,
  EMarketingSource.EVENT,
  EMarketingSource.CAMPAIGN,
  EMarketingSource.WEB_2_LEAD,
  EMarketingSource.NEWSLETTER,
  EMarketingSource.EMAIL,
  EMarketingSource.SOCIAL_MEDIA,
].map(item => (
  {
    label: item,
    value: item,
  }
));
