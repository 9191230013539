import { EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { IGuest } from './GuestInformationResponse';
import { ICip } from 'store/modules/bookingManager/subdomains/voucher/types';

export interface IVoucherFormView {
  bookingUuid: string;
  hotels: {
    uuid: string;
    name: string;
    phoneNumber?: string | null;
    address?: string | null;
    countryCode?: string | null;
    region?: string | null;
    resortConfirmationNumber?: string | null;
  }[];
  guests: IGuest[];
  accommodation: { hotelUuid?: string | null; title: string; mealPlan: { title: string; description: string }[] }[];
  transfer: string[];
  groundService: string[];
  policiesAndRestrictions?: string | null;
}

export enum EVoucherLang {
  EN = 'en',
  RU = 'ru',
}

export interface IVoucherDownloadPayload {
  selectedGuests: string[];
  selectedTransfers: string[];
  selectedKeyDetails: {
    title: string;
    accommodationIdx: number;
  }[];
  selectedMealPlans: { title: string; description: string }[];
  selectedGroundServices: string[];
  notes: string;
  resorts: {
    name: string;
    address: string;
    phone: string;
    countryCode: string;
    region: string;
    confirmationNumber: string;
  }[];
  arrivalDate: string;
  arrivalTime: string;
  flightArrivalNo: string;
  departureDate: string;
  departureTime: string;
  flightDepartureNo: string;
  policiesAndRestrictions?: string | null;
  voucherLogo?: EPdfLogo;
  lang?: EVoucherLang;
  cip: ICip;
}
