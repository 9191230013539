module.exports = {
  content: ['./src/**/*.{tsx,jsx}'],
  corePlugins: {
    preflight: false,
  },
  theme: {
    colors: {
      transparent: 'transparent',
      'black-true': '#000',
      'black-true-transparent-60': 'rgba(0, 0, 0, 0.6)',
      black: '#413E3B',
      'black-legacy': '#373C46',
      'black-transparent': 'rgba(65, 62, 59, 0.5)',
      'bright-sky-blue': '#4D98FE',
      'dark-black': '#0F172A',
      'white-true': '#fff',
      white: '#fff',
      'teal-100': '#70D6BA',
      'teal-80': '#8DDEC8',
      'teal-60': '#A9E6D6',
      'teal-40': '#C6EFE3',
      'teal-30': '#D4F3EE',
      'teal-20': '#E2F7F1',
      'teal-15': '#EFFAF5',
      'teal-10': '#F1FBF8',
      'pure-ui-teal': '#57d2bf',
      teal: '#57d2bf',
      'brown-140': '#78644A',
      'brown-135': '#87684C',
      'brown-130': '#856244',
      'brown-125': '#87684C',
      'brown-120': '#8E7157',
      'brown-100': '#A08264',
      'brown-prime': '#A08264', // figma colour name, aka "#A18",
      'brown-80': '#B39B83',
      'brown-prime-80': '#B39B83', // figma "colour n",
      'brown-60': '#C6B4A2',
      'brown-40': '#D9CDC1',
      'brown-20': '#ECE6E0',
      'brown-18': '#EEEAE9',
      'brown-15': '#F6F4F1',
      'brown-10': '#F5F3EF',
      'brown-prime-10': '#F5F3EF', // figma "colour n",
      'gray-darker': '#7b7b7b',
      'gray-120': '#747474',
      'gray-100': '#968F8B',
      'gray-90': '#949494',
      'gray-80': '#ABA5A2',
      'gray-70': '#BBB4B1',
      'gray-60': '#C0BCB9',
      'gray-50': '#D1D0D0',
      'gray-40': '#D5D2D1',
      'gray-30': '#E3DAD1',
      'gray-20': '#EAE9E8',
      'gray-17': '#E0E0E0',
      'gray-15': '#F0EFEE',
      'gray-18': '#E0E0E0',
      'gray-10': '#F4F4F3',
      'gray-8': '#F6F4F1',
      'gray-7': '#F1F1F1',
      'gray-5': '#FAFAFB',
      'gray-depth-1': '#E5E3E2',
      'gray-light': '#F8F8F8',
      'gray-3': '#FAFAFA',
      'red-100': '#B11030',
      'red-95': '#D4244E',
      'red-92': '#FD5656',
      'red-90': '#EB5757',
      'red-50': '#F37E9A',
      'red-40': '#f6e7ea',
      'red-30': '#FCECEC',
      'red-25': '#FBF3F4',
      'red-20': '#F7E7EA',
      'red-15': '#FD5656',
      'green-message': '#3B963E',
      'green-100': '#008000',
      'green-90': '#73B520',
      'green-50': '#70D6BA',
      'green-40': '#e3f7f1',
      'green-25': '#EFFBF7',
      'green-20': '#E5F2E5',
      'green-hard': '#30AF8C', // "green hard", "OWA-2",
      'green-prime': '#70D6BA', // "green prime", "OWA-2",
      'yellow-100': '#996E00',
      'yellow-60': '#DC960D',
      'yellow-50': '#EBC643',
      'yellow-40': '#FFEEB2',
      'yellow-30': '#FAD03E',
      'yellow-advert': '#FAD03E', // figma "colour n",
      'yellow-20': '#FBF5E5',
      'yellow-advert-light': '#FBF5E5', // figma "colour n",
      'light-gray': '#CECCC9', // not sure "about nami",
      'brown-hard': '#836449', // not sure "about nami",
      ivory: '#FBFBFA',
      'white-hard': '#FBFBFA', // figma "colour n",
      'ivory-10': '#FCFCFB',
      'powder-blue': '#D2E9FA',
      'blue-10': '#e9f3fd',
      'blue-5': '#F3F9FB',
      'booking-com-navy-blue': '#17447D',
      'booking-com-sky-blue': '#169EDA',
      flint: '#736A65',
      bistre: '#382A21',
      marine: '#56D1BF',
      'gold-light': '#9D9591',
      // colours for the statuses
      'status-enquiry': '#4D98FE',
      'status-requested': '#F49C20',
      'status-confirmed': '#27BE58',
      'status-cancelled': '#FD4B4C',
      'status-light-cancelled': '#F8C8CF',
      'status-completed': '#A08264',
      'status-discarded': '#CECCC9',
      gold: '#A18265',
      purple: '#9359F9',
      'purple-light': '#E4DBF4',
      // inventory
      'inv-aqua': '#14B5F3',
      'inv-teal': '#53D1BD',
      'inv-green': '#7BC75C',
      'inv-yellow': '#FAD156',
      'inv-mango': '#FE9A4A',
      'inv-rose': '#FC5F6B',
      'inv-purple': '#CC61B5',
      'inv-violet': '#9C62DA',
      'inv-light-400': '#E2E6E9',
      'inv-dark-800': '#1D2328',
      'inv-light-200': '#EDF1F2',
      'inv-red': '#FC5F6B',
    },
    // a bit of jiggery-pokery in the `container` and `screens` here
    // to get the container padding to work based on
    // our somewhat "idiosyncratic" designs
    container: {
      padding: {
        DEFAULT: '16px',
        sm: '16px',
        lg: '16px',
        xl: '16px',
        '2xl': 'inherit',
      },
    },
    screens: {
      sm: '640px',
      md: '768px',
      lg: '1024px',
      xl: '1279px',
      '2xl': '1280px',
      tablet: '1px',
      desktop: '990px',
      desktophd: '1920px',
      /*
      Due to inconsistencies in Figma designs,
      designs for different features use different breakpoints.
      This set of breakpoitns is used by TMS and CRM.
      */
      'crm-tablet': '769px',
      'crm-desktop': '1440px',
      'crm-desktop-hd': '1920px',
    },
    extend: {
      borderRadius: {
        '1px': '1px',
        xs: '2px',
        '3px': '3px',
      },
      borderWidth: {
        '1': '1px',
      },
      boxShadow: {
        pe1: '0px 4px 4px #F2F2F1',
        pe2: '0px 4px 4px rgb(242 242 241 / 35%)',
        pe3: '6px 5px 6px rgba(51, 51, 51, 0.1)',
        pe4:
          '0px 0px 1px rgba(15, 23, 42, 0.06), 0px 4px 6px -1px rgba(15, 23, 42, 0.1), 0px 2px 4px -1px rgba(15, 23, 42, 0.06)',
        pe5:
          '0px 0px 1px rgba(15, 23, 42, 0.06), 0px 10px 15px -3px rgba(15, 23, 42, 0.1), 0px 4px 6px -2px rgba(15, 23, 42, 0.05',
        pe6: '2px 2px 4px rgba(0, 0, 0, 0.25)',
        'inv-shadow-sm': '0px 5px 5px -1px #0F172A08',
        'inv-shadow-md': '0px 4px 6px -2px #0F172A0D, 0px 10px 15px -3px #0F172A1A, 0px 0px 1px 0px #0F172A0F',
        'shadow-marine': '0 0 0 2px #56D1BF',
        util1: `inset 0 0 0.5px 1px hsla(0, 0%,  
              100%, 0.075),
              /* shadow ring 👇 */
              0 0 0 1px hsla(0, 0%, 0%, 0.05),
              /* multiple soft shadows 👇 */
              0 0.3px 0.4px hsla(0, 0%, 0%, 0.02),
              0 0.9px 1.5px hsla(0, 0%, 0%, 0.045),
              0 3.5px 6px hsla(0, 0%, 0%, 0.09);`,
      },
      dropShadow: {
        pe1: '0px 5px 5px rgba(0, 0, 0, 0.05))',
      },
      fontFamily: {
        'pt-sans': "'PT Sans', sans-serif; ",
        'hurmegeometric-sans': "'HurmeGeometricSans2', sans-serif; ",
        'noe-display': "'NoeDisplay', serif; ",
      },
      fontSize: {
        // the "normal"s here define the default line height for that font size
        // if you don't provide this, TailwindCSS will automatically calculate a
        // "nice" default - line height based on the font size
        // ...but we don't want to use those defaults, so we explicitly set them to normal here
        base: ['16px', 'normal'],
        xs: ['12px', 'normal'],
        sm: ['14px', 'normal'],
        lg: ['18px', 'normal'],
        xl: ['20px', 'normal'],
        '2xl': ['24px', 'normal'],
        '3xl': ['30px', 'normal'],
        '4xl': ['36px', 'normal'],
        '5xl': ['38px', 'normal'],
        '10px': ['10px', 'normal'],
        '11px': ['11px', 'normal'],
        '12px': ['12px', 'normal'],
        '13px': ['13px', 'normal'],
        '15px': ['15px', 'normal'],
        '16px': ['16px', 'normal'],
        '17px': ['17px', 'normal'],
        '19px': ['19px', 'normal'],
        '21px': ['21px', 'normal'],
        '26px': ['26px', 'normal'],
        '48px': ['48px', 'normal'],
        '64px': ['64px', 'normal'],
      },
      flex: {
        '20': '20',
        '80': '80',
      },
      letterSpacing: {
        wider: '0.06em',
        '1px': '1px',
        '2xs': '0.04em',
        xs: '0.1em',
      },
      lineHeight: {
        '2xs': '15px',
        xs: '18px',
        sm: '22px',
        md: '24px',
        lg: '26px',
        xl: '30px',
        '12px': '12px',
        '13px': '13px',
        '14px': '14px',
        '16px': '16px',
        '17px': '17px',
        '18px': '18px',
        '19px': '19px',
        '20px': '20px',
        '21px': '21px',
        '22px': '22px',
        '23px': '23px',
        '24px': '24px',
        '25px': '25px',
        '27px': '27px',
        '28px': '28px',
        '29px': '29px',
        '31px': '31px',
        '33px': '33px',
        '35px': '35px',
        '38px': '38px',
        '42px': '42px',
        '46px': '46px',
        '54px': '54px',
      },
      maxHeight: {
        '32px': '32px',
        '35px': '35px',
        '70px': '70px',
        '80px': '80px',
        '90px': '90px',
        '242px': '242px',
        '320px': '320px',
        '350px': '350px',
        '0': '0px',
        '1': '4px',
        '2': '8px',
        '3': '12px',
        '4': '16px',
        '5': '20px',
        '6': '24px',
        '7': '28px',
        '8': '32px',
        '9': '36px',
        '10': '40px',
        '11': '44px',
        '12': '48px',
        '13': '52px',
        '14': '56px',
        '15': '60px',
        '16': '64px',
        '17': '68px',
        '20': '80px',
        '23': '92px',
        '25': '100px',
      },
      maxWidth: {
        '35px': '35px',
        '30px': '30px',
        '70px': '70px',
        '80px': '80px',
        '90px': '90px',
        '100px': '100px',
        '150px': '150px',
        '170px': '170px',
        '175px': '175px',
        '160px': '160px',
        '155px': '155px',
        '200px': '200px',
        '220px': '220px',
        '250px': '250px',
        '255px': '255px',
        '290px': '290px',
        '300px': '300px',
        '312px': '312px',
        '330px': '330px',
        '350px': '350px',
        '400px': '400px',
        '430px': '430px',
        '438px': '438px',
        '460px': '460px',
        '480px': '480px',
        '520px': '520px',
        '670px': '670px',
        '940px': '940px',
        '700px': '700px',
        '790px': '790px',
        '965px': '965px',
        '1170px': '1170px',
        '1190px': '1190px',
        '1280px': '1280px',
        '0': '0px',
        '1': '4px',
        '2': '8px',
        '3': '12px',
        '4': '16px',
        '5': '20px',
        '6': '24px',
        '7': '28px',
        '8': '32px',
        '9': '36px',
        '10': '40px',
        '11': '44px',
        '12': '48px',
        '13': '52px',
        '14': '56px',
        '15': '60px',
        '16': '64px',
        '17': '68px',
        '20': '80px',
        '23': '92px',
        '25': '100px',
      },
      minHeight: {
        '1px': '1px',
        '35px': '35px',
        '36px': '36px',
        '39px': '39px',
        '33px': '33px',
        '32px': '32px',
        '70px': '70px',
        '80px': '80px',
        '90px': '90px',
        '150px': '150px',
        '165px': '165px',
        '175px': '175px',
        '270px': '270px',
        '320px': '320px',
        '0': '0px',
        '1': '4px',
        '2': '8px',
        '3': '12px',
        '4': '16px',
        '5': '20px',
        '6': '24px',
        '7': '28px',
        '8': '32px',
        '9': '36px',
        '10': '40px',
        '11': '44px',
        '12': '48px',
        '13': '52px',
        '14': '56px',
        '15': '60px',
        '16': '64px',
        '17': '68px',
        '20': '80px',
        '23': '92px',
        '25': '100px',
      },
      minWidth: {
        '35px': '35px',
        '75px': '75px',
        '100px': '100px',
        '110px': '110px',
        '120px': '120px',
        '130px': '130px',
        '150px': '150px',
        '160px': '160px',
        '170px': '170px',
        '180px': '180px',
        '200px': '200px',
        '250px': '250px',
        '290px': '290px',
        '300px': '300px',
        '400px': '400px',
        '460px': '460px',
        '520px': '520px',
        '545px': '545px',
        '600px': '600px',
        '790px': '790px',
        '1280px': '1280px',
        '0': '0px',
        '1': '4px',
        '2': '8px',
        '3': '12px',
        '4': '16px',
        '5': '20px',
        '6': '24px',
        '7': '28px',
        '8': '32px',
        '9': '36px',
        '10': '40px',
        '11': '44px',
        '12': '48px',
        '13': '52px',
        '14': '56px',
        '15': '60px',
        '16': '64px',
        '17': '68px',
        '20': '80px',
        '23': '92px',
        '25': '100px',
      },
      spacing: {
        '1px': '1px',
        '2px': '2px',
        '3px': '3px',
        '4px': '4px',
        '5px': '5px',
        '6px': '6px',
        '7px': '7px',
        '8px': '8px',
        '9px': '9px',
        '10px': '10px',
        '11px': '11px',
        '12px': '12px',
        '14px': '14px',
        '15px': '15px',
        '17px': '17px',
        '18px': '18px',
        '19px': '19px',
        '20px': '20px',
        '22px': '22px',
        '24px': '24px',
        '25px': '25px',
        '26px': '26px',
        '28px': '28px',
        '29px': '29px',
        '30px': '30px',
        '33px': '33px',
        '34px': '34px',
        '35px': '35px',
        '37px': '37px',
        '39px': '39px',
        '40px': '40px',
        '45px': '45px',
        '48px': '48px',
        '50px': '50px',
        '55px': '55px',
        '56px': '56px',
        '58px': '58px',
        '65px': '65px',
        '70px': '70px',
        '80px': '80px',
        '85px': '85px',
        '90px': '90px',
        '100px': '100px',
        '106px': '106px',
        '110px': '110px',
        '115px': '115px',
        '116px': '116px',
        '117px': '117px',
        '120px': '120px',
        '125px': '125px',
        '129px': '129px',
        '135px': '135px',
        '150px': '150px',
        '160px': '160px',
        '170px': '170px',
        '175px': '175px',
        '180px': '180px',
        '190px': '190px',
        '200px': '200px',
        '208px': '208px',
        '220px': '220px',
        '225px': '225px',
        '230px': '230px',
        '250px': '250px',
        '280px': '280px',
        '290px': '290px',
        '300px': '300px',
        '310px': '310px',
        '330px': '330px',
        '350px': '350px',
        '360px': '360px',
        '376px': '376px',
        '400px': '400px',
        '410px': '410px',
        '418px': '418px',
        '420px': '420px',
        '425px': '425px',
        '442px': '442px',
        '464px': '464px',
        '470px': '470px',
        '500px': '500px',
        '540px': '540px',
        '570px': '570px',
        '590px': '590px',
        '600px': '600px',
        '670px': '670px',
        '700px': '700px',
        '740px': '740px',
        '768px': '768px',
        '778px': '778px',
        '790px': '790px',
        '800px': '800px',
        '940px': '940px',
        '1280px': '1280px',
        '1rem': '1rem',
        '0': '0px',
        '1': '4px',
        '2': '8px',
        '3': '12px',
        '4': '16px',
        '5': '20px',
        '6': '24px',
        '7': '28px',
        '8': '32px',
        '9': '36px',
        '10': '40px',
        '11': '44px',
        '12': '48px',
        '13': '52px',
        '14': '56px',
        '15': '60px',
        '16': '64px',
        '17': '68px',
        '18': '72px',
        '19': '76px',
        '20': '80px',
        '23': '92px',
        '25': '100px',
        '14%': '14%',
      },
      transitionProperty: {
        bg: 'background-color',
        'border-color': 'border-color',
      },
      zIndex: {
        '1': '1',
        '10': '10',
        '11': '11',
        '20': '20',
        '30': '30',
        '40': '40',
        '50': '50',
        '2000': '2000',
        auto: 'auto',
      },
    },
  },
  safelist: [
    'border-brown-prime',
    'bg-brown-prime',
    'bg-gray-40',
    'border-gray-40',
    'border-1',
    'border-2',
    '-left-1px',
    '-left-2px',
    'bg-bright-sky-blue',
    'bg-status-confirmed', 
    'bg-status-cancelled', 
    'bg-status-requested', 
    'bg-brown-100'
  ],
  plugins: [
    /**
     * This plugin (taken from the official example at https://play.tailwindcss.com/antuRg9q2Y?file=config) lets
     * use put `default:` in-front of any class. This is _extremely_ useful for situations where we want to declare
     * a bunch of classes on a component, but then also have that component be allowed to accept a `className` prop
     * that can be used to override any of the classes we've declared.
     *
     * e.g the component internally uses `className="default:text-red-500"` and will render with red text, but
     * then a user can do `<MyComponent className="text-blue-500" />`and the `text-blue-500` will override the `text-red-500` class
     * causing the final output to be blue text.
     *
     * According to https://caniuse.com/?search=where basically every browser we use supports it - the major outlier is IE11 but we haven't
     * supported that for a while anyway.
     */
    function({ addVariant }) {
      addVariant('default', ':where(&)');
    },

    function({ addVariant }) {
      addVariant('o', '&:not([z])');
    },

    function({ addVariant }) {
      addVariant('children', '& > *');
    },
  ],
};
