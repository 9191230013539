import * as React from 'react';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  specialRequestsDashboardSelector,
  topNavigationDataDashboardSelector,
  bookingInformationReservationTeamDashboardSelector,
  dashboardNetworkRequestsSelector,
  isSpecialRequestsEditedWithoutSavingSelector,
  bookingTypeSelector,
  bookingTasksSelector,
} from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import {
  getBookingCommentsLoadSelector,
  latest10CommentsSelector,
  latest10PublicCommentsSelector,
} from 'store/modules/bookingManager/subdomains/comments/selectors';
import { getBookingCommentsRequestAction } from 'store/modules/bookingManager/subdomains/comments/actions';
import {
  getSpecialRequestsRequestAction,
  updateSpecialRequestsRequestAction,
  getTopNavigationDataRequestAction,
  setIsSpecialRequestsEditedWithoutSavingAction,
  setSpecialRequestsAction,
  getBookingTypeRequestAction,
  setBookingTypeRequestAction,
  getBookingTasksRequestAction,
} from 'store/modules/bookingManager/subdomains/dashboard/actions';
import {
  guestsErrorSelector,
  guestsLoadingSelector,
  guestsSelector,
} from 'store/modules/bookingManager/subdomains/guestInformation/selectors';
import { getCompanySelector, getCurrentUserType, hideFinanceSelector } from 'store/modules/auth/selectors';
import {
  isInternalUser as isSRSelector,
  isAdmin as isAdminSelector,
  isTA as isTaSelector,
  isFinanceUser,
  isCompanyTaManager as isCompanyTaManagerSelector,
} from 'store/modules/auth';
import { getGuestInformationRequestAction } from 'store/modules/bookingManager/subdomains/guestInformation/actions';
import {
  getChecklistRequestAction,
  updateChecklistRequestAction,
} from 'store/modules/bookingManager/subdomains/checklist/actions';
import {
  checklistSelector,
  networkRequestsSelector,
} from 'store/modules/bookingManager/subdomains/checklist/selectors';
import GuestDetailsOverview from 'pureUi/GuestDetailsOverview';
import TopNavigationBar from 'pureUi/TopNavigationBar';
import { BMOverviewTextContainer } from 'pureUi/BMOverviewTextContainer';
import { CommentsOverview } from 'pureUi/CommentsOverview';
import { OverviewChecklist } from 'pureUi/OverviewChecklist';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { BookingManagerDashboardStyles } from './BookingManagerDashboardStyles';

//finance
import {
  getFinanceDocumentBalanceRequestAction,
  getFinanceDocumentRequestAction,
  setFinanceTableTypeToRenderAction,
} from 'store/modules/bookingManager/subdomains/finance/actions';
import { FinanceOverview } from 'ui/FinanceOverview';
import {
  financeDocumentBalanceSelector,
  financeDocumentSelector,
  networkRequestsSelector as financeNetworkRequestsSelector,
} from 'store/modules/bookingManager/subdomains/finance/selectors';
import { EFinanceTableTypes } from 'store/modules/bookingManager/subdomains/finance/types';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import {
  cancellationPoliciesSelector,
  headlineLineItemBreakdownSelector,
  paymentTermsSelector,
  policiesAndRestrictionsSelector,
  offerTermsSelector,
} from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { bookingCurrencySymbolSelector, bookingRefSelector } from 'store/modules/bookingManager/selectors';

import { OverviewHeadlineBreakdown } from '../../ui/OverviewHeadlineBreakdown';

import { LeaveWithoutSavingModal } from '../../ui/LeaveWithoutSavingModal';
import { Multiselect } from 'ui/Multiselect';
import { EBookingType } from 'services/BookingManagerApi/types/BookingTypeResponse';
import { bookingTypeOptions } from 'containers/helpers';
import { EUserType } from 'services/BackendApi';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import { BookingTasksOverview } from './BookingTasksOverview';

export const BookingManagerDashboard: React.FC = () => {
  const { dynamicParameters } = useDynamicParameters();
  const dispatch = useDispatch();
  const userRole = useSelector(getCurrentUserType);
  const guests = useSelector(guestsSelector);
  const guestsLoading = useSelector(guestsLoadingSelector);
  const guestsError = useSelector(guestsErrorSelector);
  const specialRequests = useSelector(specialRequestsDashboardSelector);
  const isTa = useSelector(isTaSelector);
  const isSr = useSelector(isSRSelector);
  const isCompanyTaManager = useSelector(isCompanyTaManagerSelector) as boolean;
  const isAdmin = useSelector(isAdminSelector);
  const isFinance = useSelector(isFinanceUser);
  const hideFinance = useSelector(hideFinanceSelector);
  const networkRequests = useSelector(dashboardNetworkRequestsSelector);
  const topNavigationData = useSelector(topNavigationDataDashboardSelector);
  const allComments = useSelector(latest10CommentsSelector);
  const publicComments = useSelector(latest10PublicCommentsSelector);
  const commentsStatus = useSelector(getBookingCommentsLoadSelector);
  const headlineLineItemBreakdown = useSelector(headlineLineItemBreakdownSelector);
  const bookingCurrencySymbol = useSelector(bookingCurrencySymbolSelector);
  const checklist = useSelector(checklistSelector);
  const checklistNetworkRequests = useSelector(networkRequestsSelector);
  const financeDocument = useSelector(financeDocumentSelector);
  const financeDocumentBalance = useSelector(financeDocumentBalanceSelector);
  const financeNetworkRequests = useSelector(financeNetworkRequestsSelector);
  const isSpecialRequestsEditedWithoutSaving = useSelector(isSpecialRequestsEditedWithoutSavingSelector);
  const cancellationPolicies = useSelector(cancellationPoliciesSelector);
  const offerTerms = useSelector(offerTermsSelector);
  const paymentTerms = useSelector(paymentTermsSelector);
  const bookingTasks = useSelector(bookingTasksSelector);
  const canEditSpecialRequests = isTa || isFinance || isSr || isAdmin;
  const userCanEdit = isSr || isAdmin;
  const threeColumnLayout = (isSr || isAdmin) && dynamicParameters.ENABLE_CHECKLIST;
  const twoColumnLayout = isTa || isFinance || !dynamicParameters.ENABLE_CHECKLIST;
  const tasksLayout =
    ((isSr || isAdmin || isFinance) && dynamicParameters.ENABLE_OPERATIONS_WORKSPACE_INTERNAL_USERS) ||
    (isTa && dynamicParameters.ENABLE_OPERATIONS_WORKSPACE_TA);
  const policiesAndRestrictions = useSelector(policiesAndRestrictionsSelector);
  const bookingType = useSelector(bookingTypeSelector);

  const bookingInformationReservationTeamData = useSelector(bookingInformationReservationTeamDashboardSelector);

  const handleSpecialRequestsUpdate = useCallback(
    (terms: string) => {
      dispatch(updateSpecialRequestsRequestAction(terms));
    },
    [dispatch]
  );

  const handleChecklistItemUpdate = useCallback(
    (value: string, checked: boolean) => {
      dispatch(updateChecklistRequestAction(value, checked));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(setFinanceTableTypeToRenderAction(EFinanceTableTypes.SALES));
    dispatch(getGuestInformationRequestAction());
    dispatch(BreakdownActions.getCancellationPoliciesRequestAction());
    dispatch(BreakdownActions.getPaymentTermsRequestAction());
    dispatch(BreakdownActions.getOfferTermsRequestAction());
    dispatch(getSpecialRequestsRequestAction());
    dispatch(getTopNavigationDataRequestAction());
    dispatch(getBookingCommentsRequestAction());
    dispatch(BreakdownActions.getHeadlineLineItemBreakdownRequestAction());
    dispatch(getFinanceDocumentRequestAction());
    dispatch(getFinanceDocumentBalanceRequestAction());
    dispatch(BreakdownActions.getPoliciesAndRestrictionsRequestAction());
    if (isSr || isAdmin || isFinance) {
      dispatch(getBookingTypeRequestAction());
    }

    if (threeColumnLayout) {
      dispatch(getChecklistRequestAction());
    }

    if (tasksLayout) {
      dispatch(getBookingTasksRequestAction());
    }
  }, [dispatch, threeColumnLayout]);

  const renderBookingTypeDropdown = () => {
    return (
      <div className="flex items-center mb-20px">
        <span className="booking-type-label font-pt-sans text-black text-16px leading-19px">Booking Type:</span>
        {networkRequests.bookingTypeLoad === ENetworkRequestStatus.PENDING ||
        networkRequests.bookingTypeUpdate === ENetworkRequestStatus.PENDING ? (
          <span className="w-200px ml-10px block opacity-60 text-center">
            <i className="booking-type-loading loading-placeholder text-xl fas fa-circle-notch fa-spin"></i>
          </span>
        ) : (
          <Multiselect
            className="booking-type-dropdown type-select w-200px bg-white text-15px ml-10px"
            hideCheckboxes
            itemCtaClassName="hover:bg-teal-20 text-left"
            itemsClassname="bg-white"
            labelClassName="text-ellipsis overflow-hidden whitespace-nowrap"
            onUpdate={(selectedValues: EBookingType[]) => {              
              if (selectedValues.length){
                dispatch(setBookingTypeRequestAction(selectedValues[0]));
              }
            }}
            options={bookingTypeOptions}
            selectedValues={[bookingType as string]}
            isCloseOnSelect
            isSingleSelectMode
          />
        )}
      </div>
    );
  };

  const renderLeftSection = () => {
    return (
      <section className="leftSection">
        {headlineLineItemBreakdown && topNavigationData && (
          <OverviewHeadlineBreakdown
            headlineLineItemBreakdown={headlineLineItemBreakdown}
            topNavigationData={topNavigationData}
            bookingCurrencySymbol={bookingCurrencySymbol || '$'}
            isSr={isSr}
            isTaManager={isCompanyTaManager}
            isTa={isTa}
          />
        )}
        {!tasksLayout && (
          <BMOverviewTextContainer
            className="payment-terms"
            editable={false}
            text={paymentTerms}
            label="Payment Terms"
          />
        )}
        {!hideFinance && (
          <FinanceOverview
            className="mb-10"
            financeDocument={financeDocument}
            financeDocumentBalance={financeDocumentBalance}
            currencySymbol={bookingCurrencySymbol || ''}
            isLoading={financeNetworkRequests.financeDocumentLoad === ENetworkRequestStatus.PENDING}
            error={
              financeNetworkRequests.financeDocumentLoad === ENetworkRequestStatus.ERROR
                ? 'Failed to load finance information'
                : null
            }
          />
        )}
        <GuestDetailsOverview guests={guests} isLoading={guestsLoading} error={guestsError} />
      </section>
    );
  };

  const renderCenterSection = () => {
    return (
      <section className="centerSection">
        <CommentsOverview
          comments={isTa || isFinance ? publicComments : allComments}
          isLoading={commentsStatus === ENetworkRequestStatus.PENDING}
          isError={commentsStatus === ENetworkRequestStatus.ERROR}
        />
        <BMOverviewTextContainer
          className="special-requests"
          editable={canEditSpecialRequests}
          text={specialRequests}
          label="Special Requests"
          updateHandler={handleSpecialRequestsUpdate}
          isSaving={networkRequests.specialRequestsUpdate === ENetworkRequestStatus.PENDING}
          onTextChange={(text: string) => {
            dispatch(setIsSpecialRequestsEditedWithoutSavingAction(true));
            dispatch(setSpecialRequestsAction(text));
          }}
        />
        <BMOverviewTextContainer
          className="cancellation-policies"
          editable={false}
          text={cancellationPolicies}
          label="Cancellation Policies"
        />
        <BMOverviewTextContainer
          className="policies-and-restrictions"
          editable={false}
          text={policiesAndRestrictions}
          label={`Policies & Restrictions`}
        />
        <BMOverviewTextContainer className="offer-terms" editable={false} text={offerTerms} label="Offer Terms" />
      </section>
    );
  };

  const renderRightSection = () => {
    return (
      <section className="rightSection">
        {userCanEdit && (
          <OverviewChecklist
            checklist={checklist}
            onChange={handleChecklistItemUpdate}
            isLoading={checklistNetworkRequests.checklistLoad === ENetworkRequestStatus.PENDING}
            isError={checklistNetworkRequests.checklistLoad === ENetworkRequestStatus.ERROR}
          />
        )}
      </section>
    );
  };

  const renderTasksSection = () => {
    return (
      <section className="centerSection">
        <BookingTasksOverview bookingTasks={bookingTasks} isLoading={guestsLoading} error={guestsError} />
        <BMOverviewTextContainer
          className="special-requests"
          editable={canEditSpecialRequests}
          text={specialRequests}
          label="Special Requests"
          updateHandler={handleSpecialRequestsUpdate}
          isSaving={networkRequests.specialRequestsUpdate === ENetworkRequestStatus.PENDING}
          onTextChange={(text: string) => {
            dispatch(setIsSpecialRequestsEditedWithoutSavingAction(true));
            dispatch(setSpecialRequestsAction(text));
          }}
        />
        <CommentsOverview
          comments={isTa || isFinance ? publicComments : allComments}
          isLoading={commentsStatus === ENetworkRequestStatus.PENDING}
          isError={commentsStatus === ENetworkRequestStatus.ERROR}
        />
      </section>
    );
  };

  if (networkRequests.topNavigationDataLoad === ENetworkRequestStatus.PENDING) {
    return (
      <div className="mt-5">
        <LoadingBar />
      </div>
    );
  }

  return (
    <React.Fragment>
      <LeaveWithoutSavingModal when={isSpecialRequestsEditedWithoutSaving} />
      <TopNavigationBar
        userRole={userRole as EUserType}
        data={topNavigationData}
        bookingInformationReservationTeamData={bookingInformationReservationTeamData}
        isError={networkRequests.topNavigationDataLoad === ENetworkRequestStatus.ERROR}
      />
      <div className="header flex justify-between">
        <h1 className="text-4xl font-noe-display font-normal mt-0 mb-2 self-center text-black">Booking Overview</h1>

        {(isSr || isAdmin || isFinance) && renderBookingTypeDropdown()}
      </div>
      <BookingManagerDashboardStyles
        className={classNames('overview', {
          twoColumns: twoColumnLayout,
          threeColumns: threeColumnLayout,
          tasksColumn: tasksLayout,
        })}
      >
        {renderLeftSection()}
        {!tasksLayout && renderCenterSection()}
        {!tasksLayout && threeColumnLayout && renderRightSection()}
        {tasksLayout && renderTasksSection()}
      </BookingManagerDashboardStyles>
    </React.Fragment>
  );
};

export default BookingManagerDashboard;
