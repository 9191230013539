import React, { useCallback } from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import { ImagesCarousel } from 'ui/ImagesCarousel';

interface IBasketHotelPhotoGalleryModalProps {
  photos: { displayName: string; url: string }[];
  title: string;
  onClose: () => void;
}

export const BasketHotelPhotoGalleryModal: React.FC<IBasketHotelPhotoGalleryModalProps> = React.memo(
  ({ photos, title, onClose }) => {
    const handleCloseClick = useCallback(() => {
      onClose();
    }, [onClose]);

    return (
      <GeneralModal
        modalWindowClassName="basket-photo-gallery px-[35px] text-black font-pt-sans rounded w-[587px] mx-5 md:mx-0 mb-10 p-[25px]"
        shouldCloseByClickingOutside={true}
        onClose={handleCloseClick}
      >
        <h3 className="font-noe-display font-normal text-21px my-0 leading-29px h-[37px]">{title}</h3>

        <ImagesCarousel
          images={photos}
          showStatus={true}
          showArrows={true}
          showThumbnails={true}
          carouselImageClassName="h-[500px]"
          thumbnailsCarouselClassName="h-[70px]"
        />
      </GeneralModal>
    );
  }
);
