import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as AuthSelectors from 'store/modules/auth/selectors';
import React, { useCallback, useState } from 'react';
import FluidButton from 'ui/FluidButton';
import { useSelector } from 'react-redux';
import { useNotifications } from 'hooks/useNotifications';
import { useDefaultOnErrorHandler } from 'services/CrmApi/mutations/defaultOnErrorHandler';
import { useQueryClient } from '@tanstack/react-query';
import { VerticalSpace } from 'ui/VerticalSpace';
import { AddCompany, ELinkItemSection } from 'containers/CRM/components/AddCompany/AddCompany';
import { isNil } from 'lodash-es';
import { IUpsertLink } from 'services/CrmApi/types/DirectoryEntry';
import { useLinkDirectoryEntriesMutation } from 'services/CrmApi/mutations/useLinkDirectoryEntriesMutation';
import { ILinkedCompanyFormValues, isBothSelected } from 'containers/CRM/newContact/contactCreateFormValidation';
import { addExistingCompanyValidator, addNewCompanyValidator } from '../CrmContactTabs/crmContactMainInfoForm';
import { INewCompanyRequest } from 'services/CrmApi/types/CrmCompanyTypes';
import { useCreateCompanyMutation } from 'services/CrmApi/mutations/useCreateCompanyMutation';

interface ILinkedCompaniesAddProps {
  directoryEntryId: string;
  contactCountryCode: string | null;
  onCompanyLink: () => void;
}

export const LinkedCompaniesAdd: React.FC<ILinkedCompaniesAddProps> = React.memo(({ directoryEntryId, contactCountryCode, onCompanyLink }) => {
  const queryClient = useQueryClient();
  const createCompanyMutation = useCreateCompanyMutation();
  const linkDirectoryEntriesMutation = useLinkDirectoryEntriesMutation();
  const [sectionExpanded, setSectionExpanded] = useState<ELinkItemSection | null>(null);
  const { showSuccessNotification } = useNotifications();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();
  const isLoading = queryClient.isFetching({ queryKey: ['crm-directory-entry', directoryEntryId] }) > 0;
  const queryState = queryClient.getQueryState(['crm-directory-entry', directoryEntryId]);
  const isSaving = queryState?.status === 'pending';
  const isSr = useSelector(AuthSelectors.isSr);
  const canEdit = !isSr;

  const handleLinkCompany = useCallback(async (values: ILinkedCompanyFormValues, formikHelpers: FormikHelpers<ILinkedCompanyFormValues>) => {
    // If existing: then use the /link endpoint to bind both directory_entries
    // If new: create /company, then use the /link endpoint
    let existingCompanyId = values.existingCompanyId;
    if (isNil(existingCompanyId) || existingCompanyId === '') {
      const companyCreateRequestData : INewCompanyRequest = {
        // validation won't allow nulls here, then safely casting for TS
        typeCode: values.companyType as string, 
        name: values.companyName as string,
        countryCode: contactCountryCode || undefined, // If Company is new and Contact had country, then align Country to Company
      };
      try {
        const response = await createCompanyMutation.mutateAsync(companyCreateRequestData);
        showSuccessNotification('Company created.');      
        await queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntryId] });
        existingCompanyId = response.data.id;
      } catch (error) {
        defaultOnErrorHandler(error, 'Failed to create a company');
        await queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntryId] });
      }
    }
    if (!(isNil(existingCompanyId) || existingCompanyId === '')) {
      const linkDirectoryEntriesRequestData : IUpsertLink = {
        sourceId: directoryEntryId,
        destinationId: existingCompanyId,
        linkTypeCode: 'MEMBER', // In the future this value will be selected somewhere in FE
        reference: null, // not being used yet
      };
      try {
        await linkDirectoryEntriesMutation.mutateAsync(linkDirectoryEntriesRequestData);
        showSuccessNotification('Company linked to contact.');      
        await queryClient.invalidateQueries({ queryKey: ['crm-link-directory-entries', directoryEntryId] });
        formikHelpers.resetForm();
        onCompanyLink();
      } catch (error) {
        defaultOnErrorHandler(error, 'Failed to link company to Contact');
        await queryClient.invalidateQueries({ queryKey: ['crm-link-directory-entries', directoryEntryId] });
      }
    }
  }, []);
    
  const handleOnSectionExpanded = useCallback((section: ELinkItemSection | null) => {
    setSectionExpanded(section);
  }, []);
    
  const initialAddLinkCompanyValues: ILinkedCompanyFormValues = {
    companyType: null,
    companyName: '',
    existingCompanyId: null,
  };
  
  let companyValidator;
  if (sectionExpanded === ELinkItemSection.CREATE_NEW) {
    companyValidator = addNewCompanyValidator;
  }
  if (sectionExpanded === ELinkItemSection.SELECT_EXISTING) {
    companyValidator = addExistingCompanyValidator;
  }
  
  return (
    <Formik 
      initialValues={initialAddLinkCompanyValues}
      enableReinitialize={true}
      validationSchema={companyValidator}
      onSubmit={handleLinkCompany}
    >
      {(form: FormikProps<ILinkedCompanyFormValues>) => {
        let wrappingError : string | null = null;
        if (form.submitCount > 0 && isBothSelected(form.values)) {
          wrappingError = 'Select Existing or create new, but not both options.';
        }
        const isEmpty = Object.values(form.values).every(value => isNil(value) || value === '');
        return (
          <>
            <AddCompany wrappingError={wrappingError} showPageSectionTitle={false} onSectionExpanded={handleOnSectionExpanded}/>
            <VerticalSpace height="20px" />
            <div className="buttons-container ">
              <FluidButton 
                type="secondary" 
                textClassName="flex items-center gap-[10px]" 
                isLoading={isLoading || isSaving}
                disabled={!canEdit || isEmpty || sectionExpanded === null} 
                onClick={form.submitForm}
              >
                Add
              </FluidButton>
            </div>
          </>
        );
      }}
    </Formik>
  );
})