import { TCountryCode } from 'interfaces';
import { BookingBuilderRequest, BookingBuilderResponse, Upload } from '.';
import { ICompanyMembership } from 'services/BookingManagerApi';

export interface ISharedCompanyMembership {
  uuid: string;
  name: string;
  benefitsDescription?: string;
}

export interface IGetBasketResponse {
  builds: IBasketBuild[];
  total: number;
}

export interface IGetSharedLinkListResponse extends IGetBasketResponse {
  shareType: 'client' | 'ta';
  link: string;
  companyMemberships: ISharedCompanyMembership[];
  expiryDate: string; // a datetime string like "2025-02-01T14:17:29.860Z"
  companyLogoUrl: string | null;
  travelAgentFullName: string;
}

/**
 * Returns true if the shared link list response is a string
 * (which means it's expired)
 */
export function isSharedLinkListResponseExpired(data: IGetSharedLinkListResponse | string): data is string {
  return typeof data === 'string';
}

/**
 * Returns true if the shared link list response is an object
 * (which means it's active)
 */
export function isSharedLinkListResponseActive(
  data: IGetSharedLinkListResponse | string
): data is IGetSharedLinkListResponse {
  return typeof data === 'object';
}

export interface IGetBasketUsersResponse {}

export interface IHoldSupplierResponse {
  externalBookings: {
    accommodations: { productId: string }[];
    holdDeadline: string;
    systemBookingId: string;
  }[];
}

export interface IBasketBuild {
  uuid: string;
  basketUuid: string;
  clientCountryCode: TCountryCode;
  createdAt: string;
  deletedAt: string | null;
  note: string | null;
  updatedAt: string;
  buildRequest: BookingBuilderRequest;
  initialBuildResponse: BookingBuilderResponse;
  humanReadableId: string;
  companyMembershipUuid: string | null;
  holdSupplierResponse: IHoldSupplierResponse | null;
}

// level 2 is the same as level 1 but with hotel photo url AND company membership
export interface IBasketBuildL2 extends IBasketBuild {
  hotelPhotoUrl: string;
  hotelPhotos: { displayName: string; url: string }[];
  uploads: Upload[];
  companyMembership?: ICompanyMembership;
}

// level 3 is the same as level 2 but with taMarginAmount
export interface IBasketBuildL3 extends IBasketBuildL2 {
  taMarginAmount?: string;
}

// level 4 is the same as level 3 but with latestBookingBuilderResponse
export interface IBasketBuildL4 extends IBasketBuildL3 {
  // can be undefined because maybe the basket build is old and we couldnt get a booking builder response for it
  latestBookingBuilderResponse?: BookingBuilderResponse;
}

export interface IBasketUserResponseItem {
  basketOwner: {
    firstName: string;
    lastName: string;
    uuid: string;
    type: 'ta' | 'sr' | 'admin';
    company: {
      name: string;
    };
  };
  travelAgents: {
    firstName: string;
    lastName: string;
    uuid: string;
    type: 'ta' | 'sr' | 'admin';
    company: {
      name: string;
    };
    isLatestBasket?: boolean;
  }[];
}

export interface ISharedLinkProcessResponse {
  meta: {
    type: 'genericStatus';
  };
  data: {
    uuid: string;
    ownerType: 'user';
    ownerUuid: string;
    category: 'share-link-action';
    status: 'done' | 'pending' | 'in-progress';
    parameters: any;
    updatedAt: string;
    createdAt: string;
    data: {
      success: boolean;
      booking: {
        uuid: string;
        humanReadableId: string;
      };
    } | null;
  };
}

export interface ISharedLinkCreateQuoteResponse {
  uuid: string;
  filename: string;
  displayName: string;
  url: string;
  size: string;
  tag: string; // BMS and BE set this to whatever they want, so there isn't really an enum for it
  ownerType: string; // this is based on BE DB table name, so can be anything
  ownerUuid: string;
  createdAt: string;
  updatedAt: string | null;
  deletedAt: string | null;
}
