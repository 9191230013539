import { auth, notFound, root } from 'routing/common';

export default [
  ...root,

  // Order is important here
  ...auth,

  // Page is a catch all /:path
  ...notFound,
];
