import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GeneralModal } from 'ui/GeneralModal';
import { Radio } from 'ui/Radio';
import { makeBackendApi } from 'services/BackendApi';
import { IBasketBuildL4, ISharedLinkProcessResponse } from 'services/BackendApi/types/Basket';
import FluidButton from 'ui/FluidButton';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { ButtonWithIcon } from 'containers/Basket/components/ButtonWithIcon';
import DownloadIcon from 'ui/Icons/components/Download.component';
import { guestTitles } from 'utils/guestInformation';
import Select from 'pureUi/Select';
import { IValueLabelPair } from 'interfaces';
import { TextInput } from 'ui/TextInput';
import { UITextArea } from 'ui/UITextArea';
import { isBlank } from 'utils';
import { ERoomRateType } from 'store/modules/bookingBuilder';
import { useNotifications } from 'hooks/useNotifications';
import { useCurrentWidth } from 'effects';

const quoteRecipientOptions: {
  value: 'ta' | 'client';
  label: string;
}[] = [
  { value: 'ta', label: 'Travel Partner' },
  { value: 'client', label: 'Final Client' },
];

const POLL_INTERVAL = 2000; // 2 seconds

const titles: IValueLabelPair[] = [
  {
    value: '',
    label: 'None',
  },
  ...guestTitles.slice(1).map(x => ({
    value: x.toLowerCase(),
    label: x,
  })),
];

interface ISharedLinkPageRightColumnProps {
  selectedShareItem?: IBasketBuildL4;
  sharedLinkUuid: string;
  onRequestToBookComplete: () => void;
  guestInfo: {
    guestTitle: string;
    guestFirstName: string;
    guestFirstNameError?: string;
    guestLastName: string;
    guestLastNameError?: string;
    specialRequests: string;
  };
  setGuestInfo: (
    guestInfo: Partial<{
      guestTitle: string;
      guestFirstName: string;
      guestFirstNameError?: string;
      guestLastName: string;
      guestLastNameError?: string;
      specialRequests: string;
    }>
  ) => void;
}

export const SharedLinkPageRightColumn = (props: ISharedLinkPageRightColumnProps) => {
  const { selectedShareItem, sharedLinkUuid, setGuestInfo } = props;
  const { showSuccessNotification } = useNotifications();
  const [state, setState] = useState<{
    showMakeAQuoteModal: boolean;
    showBookingRequestCompleteModal: boolean;
    quoteRecipient: 'ta' | 'client';
    pollDoneResponse: ISharedLinkProcessResponse['data'] | null;

    pollingData: {
      pollId: string | null;
      for: 'quote' | 'bookingRequest';
    } | null;
  }>({
    showMakeAQuoteModal: false,
    showBookingRequestCompleteModal: false,
    quoteRecipient: 'ta',
    pollDoneResponse: null,
    pollingData: null,
  });

  const queryClient = useQueryClient();
  const backendApi = makeBackendApi();

  // start the quote process
  const generateQuoteOrRequestMutation = useMutation({
    mutationFn: (quoteOrBookingRequest: 'quote' | 'bookingRequest') => {
      if (!selectedShareItem) {
        throw new Error('No selectedShareItem');
      }

      const attributes = {
        bookingHash: selectedShareItem.latestBookingBuilderResponse?.bookingHash!,
        bookingInformation: {
          guestFirstName: props.guestInfo.guestFirstName,
          guestLastName: props.guestInfo.guestLastName,
          guestTitle: props.guestInfo.guestTitle,
          specialRequests: props.guestInfo.specialRequests ? [props.guestInfo.specialRequests] : [],
        },
      };

      switch (quoteOrBookingRequest) {
        case 'quote':
          return backendApi.postSharedLinkInitialiseQuote({
            shareLinkUuid: sharedLinkUuid,
            basketBuildUuid: selectedShareItem.uuid,
            attributes,
          });
        case 'bookingRequest':
          return backendApi.postSharedLinkInitialiseRequestToBook({
            shareLinkUuid: sharedLinkUuid,
            basketBuildUuid: selectedShareItem.uuid,
            attributes,
          });
      }
    },
    onSuccess: (data, quoteOrBookingRequest) =>
      setState(prevState => ({
        ...prevState,
        pollingData: { pollId: data.data.data.uuid, for: quoteOrBookingRequest },
      })),
    onError: error => {
      console.error('Error generating request to book', error);
    },
  });

  // poll for process
  useQuery({
    queryKey: ['pollStatus', state.pollingData],
    queryFn: async () => {
      return backendApi.getSharedLinkProcessStatus({
        processUuid: state.pollingData?.pollId!,
      });
    },
    enabled: state.pollingData !== null,
    refetchOnMount: false,
    refetchInterval: data => {
      if (state.pollingData === null) {
        return false;
      }

      if (data.state.data?.data.data.status === 'done') {
        setState(prevState => ({
          ...prevState,
          pollDoneResponse: data.state.data?.data.data!,
        }));
        return false;
      }

      if (data.state.data?.data.data.status === 'pending' || data.state.data?.data.data.status === 'in-progress') {
        return POLL_INTERVAL;
      }

      return false;
    },
  });

  // Final GET query
  const finalQuery = useQuery({
    queryKey: ['finalResult', state.pollingData],
    refetchOnMount: false,
    refetchOnReconnect: false,
    enabled: state.pollDoneResponse !== null, // Only run when polling is complete
    queryFn: async () => {
      if (!state.pollDoneResponse) {
        return null;
      }

      if (state.pollingData?.for === 'quote') {
        // if we were waiting for a quote to finish, create the quote and open it
        const response = await backendApi.postSharedLinkCreateQuote({
          processUuid: state.pollDoneResponse.uuid,
          recipientType: state.quoteRecipient,
        });
        window.open(response.data.url, '_blank');
        setState(prevState => ({
          ...prevState,
          pollingData: null,
          pollDoneResponse: null,
          showMakeAQuoteModal: false,
        }));
        setGuestInfo({
          guestTitle: '',
          guestFirstName: '',
          guestLastName: '',
          guestFirstNameError: undefined,
          guestLastNameError: undefined,
          specialRequests: '',
        });
        return response;
      }

      if (state.pollingData?.for === 'bookingRequest') {
        setState(prevState => ({
          ...prevState,
          pollingData: null,
          pollDoneResponse: null,
          showBookingRequestCompleteModal: true,
        }));
        showSuccessNotification('Booking requested successfully');
        setGuestInfo({
          guestTitle: '',
          guestFirstName: '',
          guestLastName: '',
          guestFirstNameError: undefined,
          guestLastNameError: undefined,
          specialRequests: '',
        });
        return null;
      }
    },
  });

  const isProcessingQuoteOrBookingRequest = React.useMemo(() => {
    if (generateQuoteOrRequestMutation.isPending) return true;

    if (state.pollingData !== null) {
      return true;
    }

    if (finalQuery.isLoading) return true;

    return false;
  }, [generateQuoteOrRequestMutation.isPending, state.pollingData, finalQuery.isLoading]);

  const handleGenerateQuoteOrRequestoToBook = (quoteOrBookingRequest: 'quote' | 'bookingRequest') => {
    setState(prevState => ({
      ...prevState,
      pollingData: null,
      pollDoneResponse: null,
    }));
    queryClient.removeQueries({ queryKey: ['pollStatus'] });
    queryClient.removeQueries({ queryKey: ['finalResult'] });

    generateQuoteOrRequestMutation.mutate(quoteOrBookingRequest);
  };

  const isLoadingBookingRequest = state.pollingData?.for === 'bookingRequest' && state.pollingData?.pollId !== null;

  const isFormDisabled = !selectedShareItem;
  const isCTAsDisabled =
    isFormDisabled || isBlank(props.guestInfo.guestFirstName) || isBlank(props.guestInfo.guestLastName);

  const selectedItemRateType = selectedShareItem?.latestBookingBuilderResponse?.potentialBooking?.Accommodation[0]
    ?.isLiveRate
    ? ERoomRateType.LIVE
    : ERoomRateType.STATIC;

  const { isMobile } = useCurrentWidth();
  return (
    <>
      {/* the "lead guest info" section */}

      <span className="booking-lead-guest-heading font-hurmegeometric-sans text-[12px] leading-[14px] font-bold text-flint pb-[10px] border-b border-gray-20 block">
        LEAD GUEST INFO
      </span>

      {/* the title, first name, last name and special requests */}
      <div className="lead-guest-info flex flex-col gap-[20px] overflow-auto mt-[20px]">
        {/* the title, first name and last name */}
        <div className="flex flex-col lg:flex-row gap-y-4 lg:gap-x-4">
          {/* title */}
          <div className="booking-lead-guest-title flex-col justify-start items-start gap-[15px] inline-flex w-full lg:w-[70px]">
            <span className="self-stretch text-gray-100 text-xs font-normal font-hurmegeometric-sans uppercase">
              TITLE
            </span>
            <Select
              disabled={isFormDisabled}
              value={props.guestInfo.guestTitle || ''}
              options={titles}
              onChange={e => {
                const guestTitle = titles.find(x => x.value === e.currentTarget.value)?.value!;
                props.setGuestInfo({
                  guestTitle,
                });
              }}
              className="max-h-[36px]"
            ></Select>
          </div>

          {/* first name */}
          <div
            className={
              'booking-lead-guest-firstname flex-col justify-start items-start gap-[10px] inline-flex w-full lg:w-[125px]'
            }
          >
            <span className="self-stretch text-gray-100 text-xs font-normal font-hurmegeometric-sans uppercase">
              FIRST NAME
            </span>
            <TextInput
              disabled={isFormDisabled}
              data-lpignore="true"
              id="guest-first-name"
              value={props.guestInfo.guestFirstName ?? ''}
              onChange={e => {
                const guestFirstName = e.currentTarget.value;
                props.setGuestInfo({
                  guestFirstName,
                  guestFirstNameError: undefined,
                });
              }}
              onBlur={() => {
                if (isBlank(props.guestInfo.guestFirstName)) {
                  props.setGuestInfo({
                    guestFirstNameError: 'REQUIRED FIELD',
                  });
                }
              }}
              className="w-full"
              inputClassName="border-gray-17 bg-white-true focus:border-teal-80 focus:border-2 focus:py-[6px]"
              errorMessage={props.guestInfo.guestFirstNameError}
            />
          </div>

          {/* last name */}
          <div
            className={
              'booking-lead-guest-lastname grow shrink basis-0 flex-col justify-start items-start gap-[10px] inline-flex w-full lg:w-[125px]'
            }
          >
            <span className="self-stretch text-gray-100 text-xs font-normal font-hurmegeometric-sans uppercase">
              LAST NAME
            </span>
            <TextInput
              id="guest-last-name"
              disabled={isFormDisabled}
              value={props.guestInfo.guestLastName ?? ''}
              onChange={e => {
                const guestLastName = e.currentTarget.value;
                props.setGuestInfo({
                  guestLastName,
                  guestLastNameError: undefined,
                });
              }}
              onBlur={() => {
                if (isBlank(props.guestInfo.guestLastName)) {
                  props.setGuestInfo({
                    guestLastNameError: 'REQUIRED FIELD',
                  });
                }
              }}
              className="w-full"
              inputClassName="border-gray-17 bg-white-true focus:border-teal-80 focus:border-2 focus:py-[6px]"
              errorMessage={props.guestInfo.guestLastNameError}
            />
          </div>
        </div>

        {/* special requests */}
        <div className="shrink basis-0 flex-col justify-start items-start gap-[10px] inline-flex">
          <span className="self-stretch text-gray-100 text-xs font-normal font-hurmegeometric-sans uppercase">
            SPECIAL REQUESTS
          </span>
          <UITextArea
            rows={isMobile ? 2 : 4}
            disabled={isFormDisabled}
            value={props.guestInfo.specialRequests ?? ''}
            onChange={val => {
              props.setGuestInfo({
                specialRequests: val,
              });
            }}
            className="bg-white-true w-full"
            textAreaClassName="border-gray-17 bg-white-true focus:border-teal-80 focus:border-2 focus:py-[9px]"
          />
        </div>
      </div>

      {/* the request to book and quote buttons */}
      <div className="w-full flex flex-col gap-4 mt-4">
        {isLoadingBookingRequest && <LoadingBar />}
        {selectedShareItem && !isLoadingBookingRequest && (
          <>
            {/* request to book button */}
            {selectedItemRateType === ERoomRateType.STATIC && (
              <FluidButton
                onClick={() => {
                  handleGenerateQuoteOrRequestoToBook('bookingRequest');
                }}
                disabled={isCTAsDisabled}
                type="primary"
                className="o:!w-full flex-1 font-semibold font-hurmegeometric-sans min-h-[55px] rounded-none uppercase"
              >
                Request to Book
              </FluidButton>
            )}

            {/* quote button */}
            <ButtonWithIcon
              caption="Quote"
              Icon={DownloadIcon}
              className="booking-button-quote"
              onClick={() => {
                setState(prevState => ({
                  ...prevState,
                  showMakeAQuoteModal: true,
                }));
              }}
              disabled={isCTAsDisabled}
            />
          </>
        )}
      </div>

      {/* the "make a quote" modal */}
      {state.showMakeAQuoteModal && (
        <GeneralModal
          modalWindowClassName="p-[35px] text-black font-pt-sans overflow-y-hidden rounded w-[587px] mx-5 md:mx-0"
          shouldCloseByClickingOutside={false}
          isCloseButtonVisible={!isProcessingQuoteOrBookingRequest}
          onClose={() => {
            setState(prevState => ({
              ...prevState,
              showMakeAQuoteModal: false,
            }));
          }}
        >
          <h3 className="font-noe-display font-normal text-21px leading-29px m-0">Recipient</h3>

          <div className="mt-5 mb-5">
            {quoteRecipientOptions.map(option => {
              return (
                <label
                  key={option.value}
                  className="block cursor-pointer mb-2"
                  onClick={() => {
                    setState(prevState => ({
                      ...prevState,
                      quoteRecipient: option.value,
                    }));
                  }}
                >
                  <Radio
                    onClick={() => {
                      setState(prevState => ({
                        ...prevState,
                        quoteRecipient: option.value,
                      }));
                    }}
                    checked={state.quoteRecipient === option.value}
                  />
                  <span className="ml-2 mr-5">{option.label}</span>
                </label>
              );
            })}

            <FluidButton
              isLoading={isProcessingQuoteOrBookingRequest}
              onClick={() => {
                handleGenerateQuoteOrRequestoToBook('quote');
              }}
              type="primary"
              className="o:!w-full flex-1 font-semibold font-hurmegeometric-sans min-h-[55px] rounded-none uppercase mt-8"
            >
              Download
            </FluidButton>
          </div>
        </GeneralModal>
      )}

      {/* the "booking request complete" modal */}
      {state.showBookingRequestCompleteModal && (
        <GeneralModal
          modalWindowClassName="p-[35px] text-black font-pt-sans overflow-y-hidden rounded w-[587px] mx-5 md:mx-0"
          shouldCloseByClickingOutside={false}
          isCloseButtonVisible={true}
          onClose={() => {
            setState(prevState => ({
              ...prevState,
              showBookingRequestCompleteModal: false,
            }));
            props.onRequestToBookComplete();
          }}
        >
          <h3 className="font-noe-display font-normal text-21px leading-29px m-0">Great!</h3>

          <p className="text-center">A booking request has now been created.</p>
          <p className="text-center">Our reservations team will be in touch shortly.</p>
        </GeneralModal>
      )}
    </>
  );
};
