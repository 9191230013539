import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames';
import { HeadlineLineItemBreakdown } from '../../ui/HeadlineLineItemBreakdown';
import { ENetworkRequestStatus, EUploadTag, EUserType } from 'services/BackendApi';
import {
  EBookingStatus,
  EFinanceRowTypes,
  EPurchaseCostReviewStatus,
  ERateSource,
  IHeadlineLineItemBreakdown,
} from 'services/BookingManagerApi/types';
import { LeaveWithoutSavingModal } from '../../ui/LeaveWithoutSavingModal';
import { SaveToPerformActionModal } from './SaveToPerformActionModal';
import TopNavigationBar from 'pureUi/TopNavigationBar';
import BookingStatusCtaCollection from '../BookingStatusCtaCollection';
import { LiveCancellationModal } from 'ui/HeadlineLineItemBreakdown/LiveCancellationModal';
import { formatDateDisplay } from 'utils';
import { Select } from '../../ui/Select';
import { EInvoiceMutationMode, EInvoiceType } from '../../store/modules/bookingManager/subdomains/breakdown/model';
import InvoiceModal from './InvoiceModal';
import FluidButton from 'ui/FluidButton';
import { LoadingBar, ErrorBar } from 'ui/NetworkStatusBar';
import { BreakdownTextArea } from './BreakdownTextArea';
import { PurchaseCostCTA } from './PurchaseCostCTA';
import AddresseeModal from './AddresseeModal';
import { PurchaseCostReviewFinanceModal } from './PurchaseCostReviewFinanceModal';
import { PurchaseCostReviewDetailsModal } from './PurchaseCostReviewDetailsModal';
import { ManagedBy } from './ManagedBy';
import * as FinanceUtils from 'store/modules/bookingManager/subdomains/finance/utils';
import { BespokeNegotiation } from './BespokeNegotiation';
import BarCommission from './BarCommission';
import { TravelPartnerRef } from './TravelPartnerRef';
import { getCurrencyBySymbol } from 'utils';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { getTopNavigationDataRequestAction } from 'store/modules/bookingManager/subdomains/dashboard/actions';
import { getUploadsRequestAction } from '../../store/modules/bookingManager/subdomains/uploads/actions';
import { getBookingStatusOptionsRequestAction } from '../../store/modules/bookingManager/subdomains/bookingStatusOptions/actions';
import { getTaLogoRequestAction } from 'store/modules/bookingManager/subdomains/taLogo/actions';
import { getOwnershipRequestAction } from 'store/modules/bookingManager/subdomains/ownership/actions';
import * as PurchaseCostReviewActions from 'store/modules/bookingManager/subdomains/purchaseCostReview/actions';
import * as HotelDetailsActions from 'store/modules/bookingManager/subdomains/hotelDetails/actions';
import * as BespokeNegotiationActions from 'store/modules/bookingManager/subdomains/bespokeNegotiation/actions';
import * as ExternalIdsActions from 'store/modules/bookingManager/subdomains/externalIds/actions';
import {
  getFinanceDocumentRequestAction,
  getFinanceDocumentBalanceRequestAction,
} from '../../store/modules/bookingManager/subdomains/finance/actions';
import { getBootstrapCountriesSelector } from 'store/modules/bootstrap/selectors';
import { bookingCurrencySymbolSelector, bookingSelector } from 'store/modules/bookingManager/selectors';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as DashboardSelectors from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import * as BookingStatusOptionsSelectors from 'store/modules/bookingManager/subdomains/bookingStatusOptions/selectors';
import * as OwnershipSelectors from 'store/modules/bookingManager/subdomains/ownership/selectors';
import * as PurchaseCostReviewSelectors from 'store/modules/bookingManager/subdomains/purchaseCostReview/selectors';
import * as HotelDetailsSelectors from 'store/modules/bookingManager/subdomains/hotelDetails/selectors';
import * as FinanceSelectors from 'store/modules/bookingManager/subdomains/finance/selectors';
import * as AuthSelectors from 'store/modules/auth/selectors';
import * as GuestInformationActions from 'store/modules/bookingManager/subdomains/guestInformation/actions';
import * as GuestInformationSelectors from 'store/modules/bookingManager/subdomains/guestInformation/selectors';
import * as ExternalIdsSelectors from 'store/modules/bookingManager/subdomains/externalIds/selectors';

import RenderQuoteModal from './RenderQuoteModal';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { TCountryCode } from 'interfaces';
import ManualInvoiceModal from './ManualInvoiceModal';

import Checkbox from 'ui/Checkbox';

export enum ENotificationMessageType {
  PENDING = 'pending',
  SUCCESS = 'success',
  ERROR = 'error',
}

const isRequesting = (...s: ENetworkRequestStatus[]): boolean =>
  s.some(x => x === ENetworkRequestStatus.IDLE || x === ENetworkRequestStatus.PENDING);

export const BookingManagerBreakdown = () => {
  const dispatch = useDispatch();

  const bookingSearchItem = useSelector(bookingSelector);
  const userRole = useSelector(AuthSelectors.getCurrentUserType);

  const headlineLineItemBreakdown = useSelector(BreakdownSelectors.headlineLineItemBreakdownSelector);
  const breakdownNetworkRequests = useSelector(BreakdownSelectors.breakdownNetworkRequestsSelector);
  const bookingCurrencySymbol = useSelector(bookingCurrencySymbolSelector);
  const isHeadlineBreakdownEditedWithoutSaving = useSelector(
    BreakdownSelectors.isHeadlineBreakdownEditedWithoutSavingSelector
  );
  const topNavigationData = useSelector(DashboardSelectors.topNavigationDataDashboardSelector);
  const bookingInformationReservationTeamData = useSelector(
    DashboardSelectors.bookingInformationReservationTeamDashboardSelector
  );
  const dashboardNetworkRequests = useSelector(DashboardSelectors.dashboardNetworkRequestsSelector);
  const { topNavigationDataLoad } = dashboardNetworkRequests;
  const getBookingStatusOptions = useSelector(BookingStatusOptionsSelectors.getBookingStatusOptionsSelector);
  const bookingStatusOptionsNetworkRequests = useSelector(
    BookingStatusOptionsSelectors.bookingStatusOptionsNetworkRequestsSelector
  );
  const externalIdsNetworkRequests = useSelector(ExternalIdsSelectors.requestSelector);
  const headlineBreakdownVersionList = useSelector(BreakdownSelectors.headlineBreakdownVersionListSelector);
  const headlineBreakdownSelectedVersion = useSelector(BreakdownSelectors.headlineBreakdownSelectedVersionSelector);
  const headlineBreakdownRealLatestVersion = useSelector(BreakdownSelectors.headlineBreakdownRealLatestVersionSelector);
  const isTA = useSelector(AuthSelectors.isTA);
  const isSR = useSelector(AuthSelectors.isInternalUser);
  const isAdmin = useSelector(AuthSelectors.isAdmin);
  const isFinanceUser = useSelector(AuthSelectors.isFinanceUser);
  const createInvoice = useSelector(BreakdownSelectors.breakdownCreateInvoiceSelector);
  const createManualInvoice = useSelector(BreakdownSelectors.breakdownCreateManualInvoiceSelector);
  const isCreateQuoteModalOpen = useSelector(BreakdownSelectors.isCreateQuoteModalOpenSelector);

  const paymentTerms = useSelector(BreakdownSelectors.paymentTermsSelector);
  const cancellationPolicies = useSelector(BreakdownSelectors.cancellationPoliciesSelector);
  const policiesAndRestrictions = useSelector(BreakdownSelectors.policiesAndRestrictionsSelector);
  const offerTerms = useSelector(BreakdownSelectors.offerTermsSelector);

  const membership = useSelector(BreakdownSelectors.membershipSelector);
  const resortConfirmationNumber = useSelector(HotelDetailsSelectors.resortConfirmationNumbersSelector);
  const hotelDetailsNetworkRequests = useSelector(HotelDetailsSelectors.networkRequestsSelector);
  const managedBy = useSelector(OwnershipSelectors.managedBySelector);
  const financeDocument = useSelector(FinanceSelectors.financeDocumentSelector);
  const proformaInvoice = useSelector(FinanceSelectors.automaticProformaInvoiceToTaSelector);
  const breakdownSalesCostDiff = useSelector(BreakdownSelectors.breakdownSalesCostDiffSelector);
  const purchaseCostReviewStatus = useSelector(PurchaseCostReviewSelectors.statusSelector);
  const getPurchaseCostReviewRequestStatus = useSelector(
    PurchaseCostReviewSelectors.getPurchaseCostReviewRequestStatusSelector
  );
  const purchaseCostReviewRequestedStatus = useSelector(PurchaseCostReviewSelectors.requestedStatusSelector);
  const getBootstrapCountries = useSelector(getBootstrapCountriesSelector);
  const isPcrDetailsModalOpen = useSelector(BreakdownSelectors.isPcrDetailsModalOpenSelector);
  const isSaveToPerformActionModalOpen = useSelector(BreakdownSelectors.isSaveToPerformActionModalOpenSelector);
  const isLiveRate =
    topNavigationData?.rateSource === ERateSource.SUPPLIER_CONFIRMED ||
    topNavigationData?.rateSource === ERateSource.SUPPLIER_UNCONFIRMED;

  const isLiveCancellationWarningModalOpen = useSelector(BreakdownSelectors.isLiveCancellationWarningModalOpenSelector);
  const liveCancellationModalData = useSelector(BreakdownSelectors.liveCancellationModalDataSelector);
  const hotelDetails = useSelector(HotelDetailsSelectors.hotelDetailsSelector);

  // true if the user is both an SR, and the SR is blocked from doing actions
  // because too much time has passed since the user checked out
  const isSRAndIsBlockedViaLastCheckout = useSelector(
    GuestInformationSelectors.isSRAndIsBlockedViaLastCheckoutSelector
  );

  const canSeeBespokeNegotiation = (isSR && !isSRAndIsBlockedViaLastCheckout) || isAdmin || isFinanceUser;

  const isLatestVersion = headlineBreakdownSelectedVersion?.version === headlineBreakdownRealLatestVersion?.version;

  const isEditable = isLatestVersion && (isAdmin || isFinanceUser || (isSR && !isSRAndIsBlockedViaLastCheckout));

  const isPurchaseCostEditable = isLatestVersion && (isSR || isAdmin || isFinanceUser);

  const canChangeBookingStatus = isLatestVersion && (isTA || (isSR && !isSRAndIsBlockedViaLastCheckout) || isAdmin);

  const paymentMethods = useSelector(BreakdownSelectors.paymentMethodsSelector).list;

  const [isCancellationPoliciesConfirmationChecked, setIsCancellationPoliciesConfirmationChecked] = React.useState(
    false
  );

  const shouldShowCancellationPolicyCheckConfirmationCheckbox = useSelector(
    BreakdownSelectors.shouldUserNeedToConfirmCPsAndPTsSelector
  );

  const exchangeCurrencyRequest = useSelector(BreakdownSelectors.exchangeCurrencyRequestSelector);

  // when we determine if we should show the cancellation policy check confirmation checkbox
  // also update the bit of local state we keep to untick the checkbox
  useEffect(() => {
    if (shouldShowCancellationPolicyCheckConfirmationCheckbox === false) {
      setIsCancellationPoliciesConfirmationChecked(false);
    }
  }, [shouldShowCancellationPolicyCheckConfirmationCheckbox]);

  // on mount
  useEffect(() => {
    /*
    we DON'T manually load the headline breakdown document here, because loading
    the versions of the breakdown **does that for us.**
    e.g we get the latest version, and then load that.
    I'm leaving the line of code in here as a reminder to save the next dev some time.
    dispatch(BreakdownActions.getHeadlineLineItemBreakdownRequestAction());
    */

    // get the latest breakdown versions - remember, this also gets the "current" version
    dispatch(BreakdownActions.getHeadlineBreakdownVersionListRequestAction());

    // and always get the latest top navigation data
    dispatch(getTopNavigationDataRequestAction());
    dispatch(getUploadsRequestAction(EUploadTag.BREAKDOWN));

    // get the latest booking statuses for TA, SR, Admin
    if (isTA || isSR || isAdmin) {
      dispatch(getBookingStatusOptionsRequestAction());
    }

    dispatch(getTaLogoRequestAction());

    // and get all the fields that make up the policies and terms
    dispatch(BreakdownActions.getPaymentTermsRequestAction());
    dispatch(BreakdownActions.getCancellationPoliciesRequestAction());
    dispatch(BreakdownActions.getPoliciesAndRestrictionsRequestAction());
    dispatch(BreakdownActions.getOfferTermsRequestAction());
    dispatch(BreakdownActions.getMembershipRequestAction());

    // get the hotel details, as we use a bunch of data from there (e.g the resort confirmation number)
    dispatch(HotelDetailsActions.getHotelDetailsRequestAction());

    dispatch(getOwnershipRequestAction());
    dispatch(ExternalIdsActions.getRequestAction());

    dispatch(BreakdownActions.getPaymentMethodsRequestAction());

    // get the finance documents, as we use them to calculate the "real" outstanding total
    dispatch(getFinanceDocumentRequestAction());
    dispatch(getFinanceDocumentBalanceRequestAction());
    if (!isTA) {
      dispatch(BreakdownActions.getBookingBarCommissionRequestAction());
    }

    if (canSeeBespokeNegotiation) {
      dispatch(BespokeNegotiationActions.getRequestAction());
    }
    // get the guest details, so we know whether or not SRs can edit things
    dispatch(GuestInformationActions.getGuestInformationRequestAction());

    if (isSR || isAdmin || isFinanceUser) {
      dispatch(PurchaseCostReviewActions.getPurchaseCostReviewRequestAction());
    }

    // get the suppliers for use with the supplier dropdowns
    dispatch(BreakdownActions.getSuppliersRequestAction());
  }, []);

  const handleCreateInvoice = () => {
    const currency = bookingSearchItem?.currencySymbol ? getCurrencyBySymbol(bookingSearchItem!.currencySymbol) : null;

    if (!bookingSearchItem?.travelAgentCompanyCountryCode) {
      return;
    }

    dispatch(
      BreakdownActions.openCreateInvoiceModalAction(
        getInvoiceType() === EInvoiceType.CANCELLATION ? EInvoiceMutationMode.UPDATE : EInvoiceMutationMode.CREATE,
        currency?.code || 'USD',
        bookingSearchItem?.travelAgentCompanyCountryCode as TCountryCode,
        hotelDetails[0].countryCode as TCountryCode
      )
    );
    dispatch(BreakdownActions.getInvoiceAddresseeRequestAction());
  };

  const handleCreateManualInvoice = () => {
    const currency = bookingSearchItem?.currencySymbol ? getCurrencyBySymbol(bookingSearchItem!.currencySymbol) : null;

    if (!bookingSearchItem?.travelAgentCompanyCountryCode) {
      return;
    }
    if (isHeadlineBreakdownEditedWithoutSaving) {
      return dispatch(BreakdownActions.openSaveToPerformActionModalAction());
    }

    dispatch(
      BreakdownActions.openCreateManualInvoiceModalAction(
        EInvoiceMutationMode.CREATE,
        currency?.code || 'USD',
        bookingSearchItem?.travelAgentCompanyCountryCode as TCountryCode,
        hotelDetails[0].countryCode as TCountryCode
      )
    );
    dispatch(BreakdownActions.getManualInvoiceAddresseeRequestAction());
  };

  const handleCreateQuote = () => {
    if (isHeadlineBreakdownEditedWithoutSaving) {
      return dispatch(BreakdownActions.openSaveToPerformActionModalAction());
    }
    dispatch(BreakdownActions.setIsCreateQuoteModalOpenAction(true));
  };

  const handleSaveToPerfomActionModalClose = () => {
    dispatch(BreakdownActions.closeSaveToPerformActionModalAction());
  };

  const shouldShowCreateInvoiceButton = () => {
    if (!topNavigationData) {
      return false;
    }
    if ([EBookingStatus.ENQUIRY, EBookingStatus.DISCARDED].includes(topNavigationData.bookingStatus)) {
      return false;
    }

    return (isSR && !isSRAndIsBlockedViaLastCheckout) || isAdmin || isFinanceUser;
  };

  const shouldShowCreateManualInvoiceButton = () => {
    if (!topNavigationData) {
      return false;
    }
    if (![EBookingStatus.REQUESTED, EBookingStatus.CONFIRMED].includes(topNavigationData.bookingStatus)) {
      return false;
    }

    return (isSR && !isSRAndIsBlockedViaLastCheckout) || isAdmin || isFinanceUser;
  };

  const shouldShowCreateQuoteButton = () => !isFinanceUser && !isSRAndIsBlockedViaLastCheckout;

  const getInvoiceType = () => {
    const bookingStatus = topNavigationData?.bookingStatus;

    if (bookingStatus === EBookingStatus.CANCELLED) {
      return EInvoiceType.CANCELLATION;
    } else if (bookingStatus && EBookingStatus.COMPLETED === bookingStatus) {
      return EInvoiceType.FINAL;
    }

    return EInvoiceType.PROFORMA;
  };

  // takes into account the finance rows
  // calculated balance is
  //  the headline total
  //  MINUS all "Payments" (Credit) from finances
  //  ADD all "Invoices" (Debit) from finances
  const calculatedOutstandingBalance = () => {
    const total = (headlineLineItemBreakdown?.SubtotalCents || 0) + (breakdownSalesCostDiff || 0);

    return financeDocument.rows
      .filter(fr => !FinanceUtils.isAutomaticFinanceRow(fr))
      .reduce((acum, curr) => {
        if (FinanceUtils.isPositiveFinanceRow(curr)) {
          acum += curr.amountCents || 0;
        } else if (FinanceUtils.isNegativeFinanceRow(curr)) {
          acum -= curr.amountCents || 0;
        }

        return acum;
      }, total);
  };

  const calculateInvoiceDueDates = (outstandingBalance: number) => {
    const invoiceDueDates =
      getInvoiceType() === EInvoiceType.CANCELLATION
        ? financeDocument.rows.find(
            financeDocument => financeDocument.rowType === EFinanceRowTypes.Automatic_Cancellation_Fee
          )?.invoiceDueDates
        : proformaInvoice?.invoiceDueDates;

    const updated = (invoiceDueDates || []).map(d => ({
      ...d,
      amountCents: Math.round(((d.percentage || 0) / 100) * outstandingBalance),
    }));
    return updated;
  };

  const renderButtons = (isLatestVersion: boolean) => {
    const saveAndUpdateHeadlineBreakdownLoad = breakdownNetworkRequests.saveAndUpdateHeadlineBreakdownLoad;
    const outstandingBalance = calculatedOutstandingBalance();

    return (
      <>
        {shouldShowCancellationPolicyCheckConfirmationCheckbox && (
          <label
            className="flex items-center space-x-2 mt-2"
            onClick={() => {
              setIsCancellationPoliciesConfirmationChecked(!isCancellationPoliciesConfirmationChecked);
            }}
          >
            <Checkbox
              disabled={false}
              checked={isCancellationPoliciesConfirmationChecked}
              // onClick={} - handled by the label above
              readOnly={true}
            />
            <span className="text-sm">
              I have reviewed the Cancellation Policies and Payment Terms as a result of the changes made
            </span>
          </label>
        )}
        {breakdownNetworkRequests.headlineLineItemBreakdownLoad === ENetworkRequestStatus.SUCCESS && (
          <div className="flex justify-between">
            {isLatestVersion && (
              <FluidButton
                type="primary"
                className="update-and-save mt-4"
                onClick={() => {
                  dispatch(BreakdownActions.saveAndUpdateHeadlineBreakdownIntentAction());
                }}
                isLoading={saveAndUpdateHeadlineBreakdownLoad === ENetworkRequestStatus.PENDING}
                disabled={
                  saveAndUpdateHeadlineBreakdownLoad === ENetworkRequestStatus.PENDING ||
                  isSRAndIsBlockedViaLastCheckout ||
                  (shouldShowCancellationPolicyCheckConfirmationCheckbox &&
                    !isCancellationPoliciesConfirmationChecked) ||
                  exchangeCurrencyRequest === ENetworkRequestStatus.PENDING ||
                  headlineLineItemBreakdown?.Accommodation?.items?.length === 0
                }
              >
                Save
              </FluidButton>
            )}

            <div className="flex pt-4">
              {shouldShowCreateInvoiceButton() && (
                <FluidButton type="secondary" className="create-invoice ml-3" onClick={handleCreateInvoice}>
                  {getInvoiceType() === EInvoiceType.PROFORMA ? 'Create proforma invoice' : 'Create invoice'}
                </FluidButton>
              )}
              {shouldShowCreateManualInvoiceButton() && (
                <FluidButton type="secondary" className="create-invoice ml-3" onClick={handleCreateManualInvoice}>
                  Create Manual invoice
                </FluidButton>
              )}
              {shouldShowCreateQuoteButton() && (
                <FluidButton
                  type="secondary"
                  className="download breakdown-for-ta ml-3"
                  onClick={handleCreateQuote}
                  isLoading={
                    breakdownNetworkRequests.downloadBreakdown === ENetworkRequestStatus.PENDING ||
                    breakdownNetworkRequests.downloadBreakdownForClient === ENetworkRequestStatus.PENDING
                  }
                >
                  Create quote
                </FluidButton>
              )}
            </div>
            <InvoiceModal
              key={`invoice-modal-${createInvoice.isCreateInvoiceModalOpen}`}
              invoiceType={getInvoiceType()}
              invoiceAddresseeType={createInvoice.invoiceAddresseeType}
              invoiceAddressee={createInvoice.invoiceAddressee}
              invoiceAddresseeLoad={breakdownNetworkRequests.invoiceAddresseeLoad}
              initialOutstandingAmountCents={outstandingBalance}
              bookingCurrencySymbol={bookingCurrencySymbol || '$'}
              isOpen={createInvoice.isCreateInvoiceModalOpen && !createInvoice.isAddresseeModalOpen}
              selectedBankAccount={createInvoice.bankAccount!}
              lang={createInvoice.lang}
              paymentTerms={paymentTerms ?? ''}
              mode={createInvoice.mode}
              invoiceDueDates={
                createInvoice.mode === EInvoiceMutationMode.CREATE ? [] : calculateInvoiceDueDates(outstandingBalance)
              }
              paymentMethods={paymentMethods || []}
            />
            <ManualInvoiceModal
              key={`manual-invoice-modal-${createManualInvoice.isCreateManualInvoiceModalOpen}`}
              invoiceAddresseeType={createManualInvoice.invoiceAddresseeType}
              invoiceAddressee={createManualInvoice.invoiceAddressee}
              invoiceAddresseeLoad={breakdownNetworkRequests.invoiceAddresseeLoad}
              totalCostToClientCents={headlineLineItemBreakdown?.TotalCostToClientCents || 0}
              SubtotalCents={headlineLineItemBreakdown?.SubtotalCents || 0}
              bookingCurrencySymbol={bookingCurrencySymbol || '$'}
              isOpen={createManualInvoice.isCreateManualInvoiceModalOpen && !createManualInvoice.isAddresseeModalOpen}
              selectedBankAccount={createManualInvoice.bankAccount!}
              lang={createManualInvoice.lang}
              paymentTerms={paymentTerms ?? ''}
              cancellationPolicies={cancellationPolicies ?? ''}
              marginPercentage={headlineLineItemBreakdown?.MarginPercentage || 0}
              mode={createManualInvoice.mode}
              paymentMethods={paymentMethods || []}
            />
            <AddresseeModal
              isOpen={createInvoice.isAddresseeModalOpen}
              invoiceAddressee={createInvoice.invoiceAddressee}
              invoiceAddresseeSave={breakdownNetworkRequests.invoiceAddresseeSave}
              countries={getBootstrapCountries}
            />
            <AddresseeModal
              isOpen={createManualInvoice.isAddresseeModalOpen}
              invoiceAddressee={createManualInvoice.invoiceAddressee}
              invoiceAddresseeSave={breakdownNetworkRequests.invoiceAddresseeSave}
              countries={getBootstrapCountries}
              isManual
            />
          </div>
        )}
        {isLatestVersion &&
          breakdownNetworkRequests.headlineLineItemBreakdownLoad === ENetworkRequestStatus.SUCCESS &&
          headlineLineItemBreakdown?.Accommodation?.items?.length === 0 && (
            <p className="font-pt-sans text-[13px] italic leading-[15px] text-red-95 mt-[15px] mb-0">
              To save, there must be at least 1 accommodation
            </p>
          )}

        {/* Loading states when generating the pdf */}
        <div className="flex flex-col items-start">
          {(breakdownNetworkRequests.downloadBreakdown === ENetworkRequestStatus.PENDING ||
            breakdownNetworkRequests.downloadBreakdownForClient === ENetworkRequestStatus.PENDING) && (
            <div className="info download loading bg-gray-20 p-4 m-0 mt-4 inline-block">
              <p className="text-gray-100 p-0 m-0">Generating PDF...</p>
            </div>
          )}
        </div>
      </>
    );
  };

  const renderVersionHistory = () => {
    return (
      <div className="flex items-center">
        {headlineBreakdownRealLatestVersion?.timestamp !== undefined && (
          <span className="last-modified mb-2 self-center">
            Date last changed:{' '}
            {formatDateDisplay(new Date(parseInt(headlineBreakdownRealLatestVersion?.timestamp, 10)))}
          </span>
        )}
        <span className="ml-10px mb-2">
          <Select
            className="version-selector min-w-170px max-w-255px pl-6 pr-45px ml-4 text-ellipsis normal-case"
            value={String(headlineBreakdownSelectedVersion?.version)}
            onChange={event => {
              const selectedVersion = headlineBreakdownVersionList.find(
                v => v.version === parseInt(event.target.value)
              );
              if (selectedVersion !== undefined) {
                dispatch(BreakdownActions.setHeadlineBreakdownSelectedVersionAction(selectedVersion));
              }
            }}
            options={headlineBreakdownVersionList.map(version => {
              const v = `V${version.version}, ${formatDateDisplay(new Date(parseInt(version.timestamp, 10)))}`;
              const u = version.user?.email;
              return {
                value: String(version.version),
                label: [v, u].filter(Boolean).join(' / '),
              };
            })}
          />
        </span>
      </div>
    );
  };

  const renderTextareas = (headlineLineItemBreakdown: IHeadlineLineItemBreakdown | null, isEditable: boolean) => {
    return (
      <div className="textareas">
        <BreakdownTextArea
          className="notes"
          title="Notes"
          text={headlineLineItemBreakdown?.Notes ?? ''}
          disabled={!isEditable}
          onChange={text => dispatch(BreakdownActions.setHeadlineLineItemBreakdownNotesAction(text))}
        />
        <BreakdownTextArea
          className="cancellation-policies mt-5"
          title="Cancellation Policies"
          text={cancellationPolicies ?? ''}
          disabled={!isEditable}
          onChange={text => dispatch(BreakdownActions.setCancellationPoliciesAction(text))}
        />
        <BreakdownTextArea
          className="payment-terms mt-5"
          title="Payment Terms"
          text={paymentTerms ?? ''}
          disabled={!isEditable}
          onChange={text => dispatch(BreakdownActions.setPaymentTermsAction(text))}
        />
        <BreakdownTextArea
          className="policies-and-restrictions mt-5"
          title="Policies and Restrictions"
          text={policiesAndRestrictions ?? ''}
          disabled={!isEditable}
          onChange={text => dispatch(BreakdownActions.setPoliciesAndRestrictionsAction(text))}
        />
        <BreakdownTextArea
          className="offer-terms mt-5"
          title="Offer Terms"
          text={offerTerms ?? ''}
          disabled={!isEditable}
          onChange={text => dispatch(BreakdownActions.setOfferTermsAction(text))}
        />
        {membership?.benefitsDescription && (
          <BreakdownTextArea
            className="membership mt-5"
            title={
              <span>
                Membership Information
                <br />({membership.name})
              </span>
            }
            text={membership.benefitsDescription}
            disabled={!isEditable}
            onChange={text => dispatch(BreakdownActions.setMembershipBenefitsDescriptionAction(text))}
          />
        )}
      </div>
    );
  };

  const renderManagedBy = () => (
    <div className="contact-info flex flex-col w-full">
      <span className="block text-black font-pt-sans font-bold pb-5px">Contact info:</span>
      <ManagedBy items={(managedBy || []).map(x => x.email)} />
    </div>
  );

  const showPurchaseCostReviewFinanceModal = [
    EPurchaseCostReviewStatus.APPROVED,
    EPurchaseCostReviewStatus.REJECTED,
  ].includes(purchaseCostReviewRequestedStatus as EPurchaseCostReviewStatus);

  const showPurchaseCostCTA = (isAdmin || isFinanceUser) && !showPurchaseCostReviewFinanceModal;

  if (!bookingSearchItem) {
    return null;
  }

  let bookingStatusFromStateHistory = '';
  if (topNavigationData) {
    bookingStatusFromStateHistory =
      topNavigationData.bookingStateHistory[topNavigationData.bookingStateHistory.length - 1].status;
  }

  if (
    isRequesting(
      breakdownNetworkRequests.headlineLineItemBreakdownLoad,
      hotelDetailsNetworkRequests.hotelDetailsLoad,
      topNavigationDataLoad,
      externalIdsNetworkRequests.get,
      breakdownNetworkRequests.getPaymentMethods,
      breakdownNetworkRequests.getSuppliers
    )
  ) {
    return <LoadingBar />;
  }

  if (
    breakdownNetworkRequests.headlineLineItemBreakdownLoad === ENetworkRequestStatus.ERROR ||
    hotelDetailsNetworkRequests.hotelDetailsLoad === ENetworkRequestStatus.ERROR
  ) {
    return <ErrorBar />;
  }

  return (
    <React.Fragment>
      <TopNavigationBar
        userRole={userRole as EUserType}
        data={topNavigationData}
        bookingInformationReservationTeamData={bookingInformationReservationTeamData}
        isLoading={
          dashboardNetworkRequests.topNavigationDataLoad === ENetworkRequestStatus.PENDING ||
          dashboardNetworkRequests.topNavigationDataInlineLoad === ENetworkRequestStatus.PENDING
        }
        isError={dashboardNetworkRequests.topNavigationDataLoad === ENetworkRequestStatus.ERROR}
      />
      {showPurchaseCostCTA && (
        <PurchaseCostCTA
          status={purchaseCostReviewStatus}
          handler={status => {
            dispatch(PurchaseCostReviewActions.requestStatusChangeAction(status));
          }}
          showDetailsHandler={() => {
            dispatch(BreakdownActions.openPcrDetailsModalAction());
          }}
          disabled={isHeadlineBreakdownEditedWithoutSaving}
        />
      )}
      <div className="breakdown font-pt-sans mb-110px">
        <div className="header flex justify-between">
          <h1 className="text-4xl font-noe-display font-normal mt-0 mb-2 self-center">Breakdown</h1>

          {headlineBreakdownRealLatestVersion && headlineBreakdownVersionList && renderVersionHistory()}
        </div>

        <div
          className={classnames('breakdown-inner-wrapper', {
            'opacity-30 cursor-wait':
              breakdownNetworkRequests.headlineLineItemBreakdownViaVersionReload === ENetworkRequestStatus.PENDING,
          })}
        >
          <div
            className={classnames('breakdown-inner-inner-wrapper', {
              'pointer-events-none':
                breakdownNetworkRequests.headlineLineItemBreakdownViaVersionReload === ENetworkRequestStatus.PENDING,
            })}
          >
            <div className="lower-header mt-2 w-full flex items-start">
              <div className="flex items-center flex-1">{renderManagedBy()}</div>
              <div className="flex ml-15px justify-end">
                <TravelPartnerRef />
                {canSeeBespokeNegotiation && (
                  <div className="flex flex-col w-190px ml-15px">
                    <span className="block text-black font-pt-sans font-bold pb-5px">Bespoke Negotiation</span>
                    <BespokeNegotiation />
                  </div>
                )}
              </div>
            </div>
            {!isTA && (
              <div className="flex-1">
                <BarCommission />
              </div>
            )}

            <LeaveWithoutSavingModal when={isHeadlineBreakdownEditedWithoutSaving} />

            {isLiveCancellationWarningModalOpen && (
              <ConfirmationModal
                className="leave-without-saving"
                type={EConfirmationModalType.WARNING}
                isOpen
                title={`To cancel all products in this booking must request cancellation of Live Rates booked before and try again.`}
                message={'Do you want to cancel all Live Rates now?'}
                buttonsWrapperClassname="flex-row-reverse"
                confirmButtonLabel={'Yes'}
                cancelButtonLabel={'No'}
                onConfirm={() => {
                  dispatch(BreakdownActions.setLiveAccommodationCancellationWarningModalIsOpenAction(false));
                  dispatch(BreakdownActions.resetLiveAccommodationCancellationModalAction());
                  dispatch(BreakdownActions.addAllLiveAccommodationCancellationModalAccomsAction());
                  dispatch(BreakdownActions.setLiveAccommodationCancellationModalIsOpenAction(true));
                }}
                onCancel={() => {
                  dispatch(BreakdownActions.setLiveAccommodationCancellationWarningModalIsOpenAction(false));
                }}
              />
            )}

            {liveCancellationModalData.isLiveCancellationModalOpen && (
              <LiveCancellationModal
                requestCancellationLoad={breakdownNetworkRequests.postLiveAccommodationCancellationLoad}
                currencySymbol={bookingCurrencySymbol || '$'}
                liveCancellationModalData={liveCancellationModalData}
              />
            )}

            <HeadlineLineItemBreakdown
              bookingUuid={bookingSearchItem.bookingUuid}
              headlineLineItemBreakdownLoad={breakdownNetworkRequests.headlineLineItemBreakdownLoad}
              headlineLineItemBreakdown={headlineLineItemBreakdown}
              setHeadlineLineItemFieldAction={(path, value) => {
                return dispatch(BreakdownActions.setHeadlineLineItemFieldAction(path, value));
              }}
              addHeadlineLineItemAction={path => {
                return dispatch(BreakdownActions.addHeadlineLineItemAction(path));
              }}
              addHeadlineLineItemAccommodationAction={() => {
                return dispatch(BreakdownActions.addHeadlineLineItemAccommodationAction());
              }}
              removeHeadlineLineItemAction={(removePath, index) => {
                return dispatch(BreakdownActions.removeHeadlineLineItemAction(removePath, index));
              }}
              setHeadlineLineItemBreakdownNotesAction={newNotes => {
                return dispatch(BreakdownActions.setHeadlineLineItemBreakdownNotesAction(newNotes));
              }}
              setHeadlineLineItemBreakdownMarginPercentage={newMargin => {
                return dispatch(BreakdownActions.setHeadlineLineItemBreakdownMarginPercentageAction(newMargin));
              }}
              bookingCurrency={bookingCurrencySymbol}
              setBreakdownHold={(accommodationIndex, hold) => {
                return dispatch(BreakdownActions.setBreakdownHoldAction(accommodationIndex, hold));
              }}
              bookingStatus={bookingStatusFromStateHistory}
              purchaseCostReviewStatus={
                getPurchaseCostReviewRequestStatus === ENetworkRequestStatus.SUCCESS ? purchaseCostReviewStatus : null // seems weird, but is consistent with https://github.com/pure-escapes/webapp-frontend/blob/10b71d39371b3a290ba93e4771d25ac7a3f252ac/src/containers/BookingManagerBreakdown/index.tsx#L579 circa Dec 2021
              }
              isEditable={isEditable}
              isMarginEditable={isLatestVersion && !isFinanceUser && !isSRAndIsBlockedViaLastCheckout}
              isPurchaseCostEditable={isPurchaseCostEditable}
              isSR={isSR}
              isTA={isTA}
              isFinanceUser={isFinanceUser}
              isLiveRate={isLiveRate}
              isAdmin={isAdmin}
              isLatestVersion={isLatestVersion}
              resortConfirmationNumber={resortConfirmationNumber}
              bookingSearchItem={bookingSearchItem}
            />

            {renderTextareas(headlineLineItemBreakdown, isEditable)}
            {renderButtons(isLatestVersion)}
          </div>
        </div>

        {canChangeBookingStatus && (
          <BookingStatusCtaCollection
            options={getBookingStatusOptions}
            isLoading={bookingStatusOptionsNetworkRequests.bookingStatusOptionsLoad === ENetworkRequestStatus.PENDING}
            isError={bookingStatusOptionsNetworkRequests.bookingStatusOptionsLoad === ENetworkRequestStatus.ERROR}
          />
        )}
      </div>

      {isCreateQuoteModalOpen && <RenderQuoteModal />}
      {showPurchaseCostReviewFinanceModal && <PurchaseCostReviewFinanceModal />}
      {isPcrDetailsModalOpen && <PurchaseCostReviewDetailsModal />}
      {isSaveToPerformActionModalOpen && <SaveToPerformActionModal onClose={handleSaveToPerfomActionModalClose} />}
    </React.Fragment>
  );
};

export default BookingManagerBreakdown;
