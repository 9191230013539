import React, { useState } from "react";
import AnimateHeight from "react-animate-height";
import { renderPrice } from "utils";
import { TCurrencyCode } from "interfaces";

interface IOption {
  title: string;
  price: string;
  currencyCode: TCurrencyCode;
  isOnRequest: boolean;
}

export interface IOtherOptionsProps {
  options: IOption[]
}

export const OtherOptions: React.FC<IOtherOptionsProps> = React.memo(
  ({options}) => {
    const [areOtherOptionsOpen, setOtherOptionsOpen] = useState(false);
    return (
      <div className="other-options pb-[10px]">
        <div
          className="other-options-heading flex items-center cursor-pointer gap-[10px] select-none"
          onClick={() => setOtherOptionsOpen(value => !value)}
        >
          <span className="other-options-heading-icon font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600] uppercase">
            {areOtherOptionsOpen ? '-' : '+'}
          </span>
          <span className="other-options-heading-text font-hurmegeometric-sans text-[14px] leading-normal text-brown-100 font-[600]">
            Other Options
          </span>
        </div>
        <AnimateHeight
          duration={300}
          height={areOtherOptionsOpen ? 'auto' : 0}
          className='accordion-area pl-[17px]'
        >
          {options.map(option => (
            <div className="font-hurmegeometric-sans text-gray-80 uppercase text-[13px] mb-[3px]">{option.title} ({renderPrice(option.currencyCode, option.price ?? 0, option.isOnRequest)})</div>
          ))}
        </AnimateHeight>
      </div>
    )
  }
)