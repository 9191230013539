import React, { useCallback, useEffect, useState } from 'react'
import { AddCompanyNew } from './AddCompanyNew';
import { PageSectionTitle } from 'ui/PageSectionTitle';
import { AddCompanyExisting } from './AddCompanyExisting';
import { BorderWithLabel } from 'ui/BorderWithLabel/BorderWithLabel';

export enum ELinkItemSection {
  SELECT_EXISTING = 'SELECT_EXISTING',
  CREATE_NEW = 'CREATE_NEW',
}

interface IAddCompanyProps {
  wrappingError: string | null;
  showPageSectionTitle?: boolean;
  onSectionExpanded?: (ELinkItemSection) => void;
}

export const AddCompany: React.FC<IAddCompanyProps> = ( { wrappingError = null,  showPageSectionTitle = true, onSectionExpanded = () => {} }) => {
  const [openSection, setOpenSection] = useState<ELinkItemSection | null>(null);

  const toggleSelectExistingSection = useCallback((isOpen: boolean) => {
    const value = isOpen ? ELinkItemSection.SELECT_EXISTING : null;
    setOpenSection(value);
    onSectionExpanded(value);
  }, []);

  const toggleCreateNewSection = useCallback((isOpen: boolean) => {
    const value = isOpen ? ELinkItemSection.CREATE_NEW : null;
    setOpenSection(value);
    onSectionExpanded(value);
  }, []);

  return (
    <>
      {showPageSectionTitle && (<PageSectionTitle
        text="Company *"
      />)}
      <BorderWithLabel label="Add Company" wrappingError={wrappingError ?? undefined}>
        <AddCompanyExisting isOpen={openSection === ELinkItemSection.SELECT_EXISTING} toggleOpen={toggleSelectExistingSection} />
        <div className="w-full border-b-1 border-solid border-gray-20 my-[12px]"></div>
        <AddCompanyNew isOpen={openSection === ELinkItemSection.CREATE_NEW} toggleOpen={toggleCreateNewSection} /> 
      </BorderWithLabel>
    </>
  )
}