import React from 'react';
import { SimpleTabs } from 'ui/SimpleTabs';
import { CrmCompanyDocuments } from './CrmCompanyDocuments';
import { CrmCompanyMainInfo } from './CrmCompanyMainInfo';
import { IDirectoryEntry, ILinkedDirectoryEntryArrayItem } from 'services/CrmApi/types/DirectoryEntry';

interface ICrmCompanyTabsProps {
  directoryEntry: IDirectoryEntry;
  contacts: ILinkedDirectoryEntryArrayItem[];
  onLinkOrUnlink: () => void;
}

export const CrmCompanyTabs: React.FC<ICrmCompanyTabsProps> = ({ directoryEntry, contacts, onLinkOrUnlink }) => {
    const crmCompanyTabs = [
      {
        title: 'Overview',
        name: 'overview',
        styles: 'min-w-[50%]',
        renderContent: () => <CrmCompanyMainInfo directoryEntryId={directoryEntry.id} contacts={contacts} onLinkOrUnlink={onLinkOrUnlink} />,
      },
      {
        title: 'Documents',
        name: 'documents',
        styles: 'min-w-[50%]',
        renderContent: () => <CrmCompanyDocuments   />,
      },
    ];
  
    return (
      <SimpleTabs tabConfig={crmCompanyTabs} className="o:w-full o:p-0" tabSelectorClassName="o:m-0" />
    );
}