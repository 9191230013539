import { call, takeLatest, all, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import * as Actions from './actions';
import { BookingSearchItem, IBookingSearchResponse, makeBookingManagerApi } from 'services/BookingManagerApi';
import { IHeaderBookingSearch } from './model';
import { formatPrice, getCurrencyBySymbol } from 'utils';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { ENVIRONMENT } from 'config';

const mapSearchBookingResponseToSearchHeader = (responseBookings: BookingSearchItem[]): IHeaderBookingSearch[] =>
  responseBookings.map(rb => {
    const { code: currencyCode } = getCurrencyBySymbol(rb.currencySymbol);
    return {
      bookingRefNumber: rb.humanReadableId,
      hotelNames: rb.hotelNames,
      price: formatPrice(rb.costToTaCents / 100),
      tcUuid: rb.travelAgentCompanyUuid,
      bookingUuid: rb.bookingUuid,
      bookingCurrency: rb.currencySymbol,
      urls: {
        breakdown: `/booking-manager/${rb.bookingUuid}/breakdown`,
        finance: `/booking-manager/${rb.bookingUuid}/finance`,
        tcStatements: `/statement/${rb.travelAgentCompanyUuid}/bookings/${currencyCode}/all-transactions`,
        tcDepositAccount: `/statement/${rb.travelAgentCompanyUuid}/deposits/${currencyCode}`,
      },
    };
  });

export function* searchBookingsRequestSaga(action: Actions.SearchBookingsRequestAction) {
  const bookingManagerApi = makeBookingManagerApi();
  const query = { filter: { humanReadableIdFilter: action.bookingRefNumber } };

  try {
    const result: AxiosResponse<IBookingSearchResponse> = yield call(bookingManagerApi.bookingSearch, query);
    const mappedBookings = mapSearchBookingResponseToSearchHeader(result.data.bookings);
    yield put(Actions.searchBookingsSuccessAction(mappedBookings));
  } catch (e) {
    yield put(Actions.searchBookingsFailureAction());
  }
}

export function* watchInventoryHeaderSaga() {
  yield takeLatest([Actions.SEARCH_BOOKINGS_REQUEST], searchBookingsRequestSaga);
}
