import classNames from 'classnames';
import React from 'react';

interface ChipProps {
  children: React.ReactNode;
  'data-secondary'?: boolean; // Optional prop to indicate secondary style
}

const Chip: React.FC<ChipProps> = ({ children, 'data-secondary': secondary }) => {
  return (
    <div
      className={classNames('bg-white-true  px-[10px] text-sm uppercase my-0 mx-[5px] first:ml-0 last:mr-0', {
        'text-black py-[5px]': !secondary,
        'text-red-92 font-bold py-[6px]': secondary,
      })}
      data-secondary={secondary}
    >
      {children}
    </div>
  );
};

export default Chip;
