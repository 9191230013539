import { call, takeLatest, select, put } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import { makeBookingManagerApi, IGetHotelDetailsResponse } from 'services/BookingManagerApi';
import {
  GET_HOTEL_DETAILS_REQUEST,
  SET_RESORT_CONFIRMATION_NUMBER,
  getHotelDetailsSuccessAction,
  getHotelDetailsFailureAction,
  patchHotelDetailsSuccessAction,
  patchHotelDetailsFailureAction,
  patchHotelDetailsRequestAction,
} from './actions';
import { bookingUuidSelector } from '../../selectors';
import { IBookingManagerHotelDetails } from './types';
import { hotelDetailsSelector } from './selectors';
import { enqueueNotification } from 'store/modules/ui';
import { setIsBreakdownEditedWithoutSaving } from '../breakdown/actions';

export function* getHotelDetailsSaga() {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    const result: AxiosResponse<IGetHotelDetailsResponse> = yield call(bookingManagerApi.getHotelDetails, bookingUuid);

    yield put(getHotelDetailsSuccessAction(result.data.hotelDetails));
  } catch (e) {
    yield put(getHotelDetailsFailureAction(e));
  }
}

export function* patchHotelDetailsSaga() {
  try {
    put(patchHotelDetailsRequestAction());
    const bookingManagerApi = makeBookingManagerApi();
    const bookingUuid = yield select(bookingUuidSelector);

    // get the entire hotel details

    const hotelDetails: IBookingManagerHotelDetails[] = yield select(hotelDetailsSelector);

    const result: AxiosResponse<IGetHotelDetailsResponse> = yield call(
      bookingManagerApi.patchHotelDetails,
      bookingUuid,
      hotelDetails
    );

    yield put(patchHotelDetailsSuccessAction(result.data.hotelDetails));
    yield put(
      enqueueNotification({
        message: 'The hotel details were updated and saved',
        options: { variant: 'success' },
      })
    );
  } catch (e) {
    yield put(patchHotelDetailsFailureAction(e));
    yield put(
      enqueueNotification({
        message: 'The hotel details failed to update',
        options: { variant: 'error' },
      })
    );
  }
}

// if the user edits the resort number, we need to set the breakdown is edited
// remove this if we move the resort confirmation away from the breakdown
export function* setResortConfirmationNumberSaga() {
  yield put(setIsBreakdownEditedWithoutSaving(true));
}

export function* watchBookingManagerHotelDetails() {
  yield takeLatest([GET_HOTEL_DETAILS_REQUEST], getHotelDetailsSaga);
  yield takeLatest([SET_RESORT_CONFIRMATION_NUMBER], setResortConfirmationNumberSaga);
}
