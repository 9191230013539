import React, { useCallback, useState, useEffect } from 'react'
import { Field, FormikProps, useFormikContext } from 'formik';
import AnimateHeight from 'react-animate-height';
import classnames from 'classnames';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { TextInput } from 'ui/TextInput';
import { theme } from '../../../../../tailwind.config';
import { ArrowDownComponent } from 'ui/Icons';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';
import { useCrmTypeValues } from 'services/CrmApi/queries/useCrmTypeValues';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { useCrmCountries } from 'services/CrmApi/queries/useCrmCountries';
import Checkbox from 'ui/Checkbox';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import FluidButton from 'ui/FluidButton';
import { setIsDirtyEditContactProfileFormAction } from 'store/modules/crm/actions';
import { IContactProfileFormValues } from './contactProfileForm';
import SingleDateInput from 'pureUi/SingleDateInput';
import { formatDate, formatDateDisplay } from 'utils';
import { addYears } from 'date-fns';
import classNames from 'classnames';
import { TextInput as PureTextInput } from 'pureUi/TextInput';
import FormLabel from 'ui/FormLabel';
import { isNil } from 'lodash-es';


interface IProfileInfoProps {
  isSaving: boolean;
}

export const ContactProfileInfo: React.FC<IProfileInfoProps> = ({ isSaving }) => {
  const dispatch = useDispatch();
  const form: FormikProps<IContactProfileFormValues> = useFormikContext();
  const { isMobile, isTablet, isDesktop, isDesktopHD } = useResponsive();
  const [isProfileInfoOpen, setProfileInfoOpen] = useState(isDesktop || isDesktopHD);

  const crmTypeValuesContacts = useCrmTypeValues(ETypeValueType.CONTACT, { enabled: true });
  const typeOptions = crmTypeValuesContacts.data?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];

  const crmTypeValuesTitles = useCrmTypeValues(ETypeValueType.TITLE, { enabled: true });
  const titleTypeOptions: ISingleSelectOption[] = crmTypeValuesTitles.data?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];

  const crmTypeValuesPersonalDocuments = useCrmTypeValues(ETypeValueType.PERSONAL_DOCUMENT, { enabled: true });
  const personalDocumentTypeCodeOptions: ISingleSelectOption[] = crmTypeValuesPersonalDocuments.data?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];

  const crmTypeValuesPersonalRoles = useCrmTypeValues(ETypeValueType.ROLE, { enabled: true });
  const roleTypeCodeOptions: ISingleSelectOption[] = crmTypeValuesPersonalRoles.data?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];

  const crmCountries = useCrmCountries();
  const countryOptions: ISingleSelectOption[] = crmCountries.data?.data?.map(
    item => ({ value: item.code, label: item.name })
  ) ?? [];

  const personalDocumentCountryCodeOptions = countryOptions;
  
  const isSr = useSelector(AuthSelectors.isSr);
  const canEdit = !isSr;

  const profileInfoClassname = classnames('profile-info-container border border-solid border-gray-40 rounded-[4px]', {
    'min-w-[320px]': isDesktop || isDesktopHD,
  })
  
  const toggleProfileInfo = useCallback(() => {
    if (isMobile || isTablet) {
      setProfileInfoOpen(!isProfileInfoOpen);
    }
  }, [isMobile, isProfileInfoOpen, isTablet]);

  const handleFormSubmit = useCallback(() => {
    form.submitForm();
  }, [form]);

  useEffect(() => {
    dispatch(setIsDirtyEditContactProfileFormAction(form.dirty));
  }, [dispatch, form.dirty]);

  return (
    <div className={profileInfoClassname}>
      <div className="page-section-title font-pt-sans flex justify-between items-baseline p-[20px]" onClick={toggleProfileInfo}>
        <h3 className="section-title font-pt-sans text-[17px] leading-normal text-black font-[700] m-0 select-none">
          Profile Info
        </h3>
        {(isMobile || isTablet) && (
          <span
            className={classnames(
              'flex justify-center items-center transform-transition transition-ease-in-out duration-300 cursor-pointer',
              { 'rotate-180': isProfileInfoOpen }
            )}
          >
            <ArrowDownComponent icon={theme.colors['black']} />
          </span>
        )}
      </div>
      <AnimateHeight duration={300} height={isProfileInfoOpen ? 'auto' : 0} className="accordion-area">
        <>
          <div className="form-container flex flex-col justify-between gap-[20px] mt-[20px] px-[20px] pb-[20px]">
            <Field name="contactTypeCode">
              {({ field: { name, value }, form: { setFieldValue } }) => (
                <SingleSelect
                  fieldId="contactTypeCode"
                  label="Type *"
                  className="contactTypeCode"
                  value={value}
                  showSelectedOption
                  maxVisibleItems={isMobile ? 4 : 6}
                  scrollToSelectedItem
                  options={typeOptions ?? []}
                  onChange={value => {
                    setFieldValue(name, value);
                  }}
                  disabled={!canEdit || isSaving}
                  errorMessage={form.touched.contactTypeCode && form.errors.contactTypeCode ? form.errors.contactTypeCode : null}
                  errorClassName="contactTypeCode-error"
                />
              )}
            </Field>
            <Field name="titleTypeCode">
              {({ field: { name, value }, form: { setFieldValue } }) => (
                <SingleSelect
                  fieldId="titleTypeCode"
                  label="Title"
                  className="titleTypeCode"
                  value={value}
                  showSelectedOption
                  maxVisibleItems={isMobile ? 4 : 6}
                  scrollToSelectedItem
                  options={titleTypeOptions ?? []}
                  onChange={value => {
                    setFieldValue(name, value);
                  }}
                  disabled={!canEdit || isSaving}
                  errorMessage={form.touched.titleTypeCode && form.errors.titleTypeCode ? form.errors.titleTypeCode : null}
                  errorClassName="titleTypeCode-error"
                />
              )}
            </Field>
            <Field
              as={TextInput}
              type="text"
              name="firstName"
              id="firstName"
              className="firstName flex-1 w-full desktop:max-w-[480px]"
              label="First name *"
              disabled={!canEdit || isSaving}
              errorMessage={form.touched.firstName && form.errors.firstName ? form.errors.firstName : null}
              errorClassName="firstName-error"
            />
            <Field
              as={TextInput}
              type="text"
              name="lastName"
              id="lastName"
              className="lastName flex-1 w-full desktop:max-w-[480px]"
              label="Last name"
              disabled={!canEdit || isSaving}
              errorMessage={form.touched.lastName && form.errors.lastName ? form.errors.lastName : null}
              errorClassName="lastName-error"
            />
            <Field
              as={TextInput}
              type="text"
              name="email"
              id="email"
              className="email flex-1 w-full desktop:max-w-[480px]"
              label="Email"
              disabled={!canEdit || isSaving}
              errorMessage={form.touched.email && form.errors.email ? form.errors.email : null}
              errorClassName="email-error"
            />
            <Field name="acceptsEmail">
              {({ field: { name, value }, form: { setFieldValue } }) => (
                <label
                  className={classnames('flex justify-start items-center cursor-pointer mr-5px')}
                  onClick={() => {
                    if (!canEdit || isSaving) {
                      return;
                    }
                    setFieldValue(name, !value);
                  }}
                >
                  <Checkbox
                    checked={value}
                    // onClick={} - handled by the label above
                    className={classnames('cursor-pointer')}
                    disabled={!canEdit || isSaving}
                    />
                  <span className={classnames('checkbox-label font-pt-sans text-[16px] leading-[21px] text-black ml-2')}>
                    Accepts receive info by email
                  </span>
                </label>
              )}
            </Field>
            <Field name="countryCode">
              {({ field: { name, value }, form: { setFieldValue } }) => (
                <SingleSelect
                  fieldId="country"
                  label="Country"
                  className="country"
                  value={value ?? null}
                  maxVisibleItems={isMobile ? 4 : 6}
                  showSelectedOption
                  scrollToSelectedItem
                  disabled={!canEdit || isSaving}
                  options={countryOptions ?? []}
                  labelWhenNothingSelected="None"
                  onChange={value => {
                    setFieldValue(name, value);
                  }}
                />
              )}
            </Field>
            <Field
              as={TextInput}
              type="text"
              name="region"
              id="region"
              className="region flex-1 w-full desktop:max-w-[480px]"
              label="Region"
              disabled={!canEdit || isSaving}
              errorMessage={form.touched.region && form.errors.region ? form.errors.region : null}
              errorClassName="region-error"
            />
            <Field
              as={TextInput}
              type="text"
              name="address"
              id="address"
              className="address flex-1 w-full desktop:max-w-[480px]"
              label="Address"
              disabled={!canEdit || isSaving}
              errorMessage={form.touched.address && form.errors.address ? form.errors.address : null}
              errorClassName="address-error"
            />
            <Field
              as={TextInput}
              type="text"
              name="mobile"
              id="mobile"
              className="mobile flex-1 w-full desktop:max-w-[480px]"
              label="Phone"
              disabled={!canEdit || isSaving}
              errorMessage={form.touched.mobile && form.errors.mobile ? form.errors.mobile : null}
              errorClassName="mobile-error"
            />
            <Field
              as={TextInput}
              type="text"
              name="landline"
              id="landline"
              className="landline flex-1 w-full desktop:max-w-[480px]"
              label="Landline"
              disabled={!canEdit || isSaving}
              errorMessage={form.touched.landline && form.errors.landline ? form.errors.landline : null}
              errorClassName="landline-error"
            />
            <FormLabel className="dob" text="Date of birth">
              <Field name={'birthday'}>
                {({ field: { name, value }, form: { setFieldValue } }) =>
                  canEdit ? (
                    <SingleDateInput
                      value={value && new Date(value)}
                      onChange={value => setFieldValue(name, formatDate(value))}
                      showYearDropdown
                      enablePastDates
                      minDate={addYears(new Date(), -150)}
                      maxDate={new Date()}
                      internalClassName={classNames('dob-field', {
                        dateInputField: true,
                        dateNotSelected: value === undefined,
                      })}
                    />
                  ) : (
                    <PureTextInput
                      value={value? formatDateDisplay(value) : ''}
                      className="dob-field textInputField"
                      disabled={true}
                      readOnly
                    />
                  )
                }
              </Field>
            </FormLabel>
            <Field name="personalDocumentTypeCode">
              {({ field: { name, value }, form: { setFieldValue } }) => (
                <SingleSelect
                  fieldId="personalDocumentTypeCode"
                  label="Id type"
                  className="personalDocumentTypeCode"
                  value={value}
                  showSelectedOption
                  maxVisibleItems={isMobile ? 4 : 6}
                  scrollToSelectedItem
                  options={personalDocumentTypeCodeOptions ?? []}
                  onChange={value => {
                    setFieldValue(name, value);
                  }}
                  disabled={!canEdit || isSaving}
                  errorMessage={form.touched.personalDocumentTypeCode && form.errors.personalDocumentTypeCode ? form.errors.personalDocumentTypeCode : null}
                  errorClassName="personalDocumentTypeCode-error"
                />
              )}
            </Field>
            <Field name="personalDocumentCountryCode">
              {({ field: { name, value }, form: { setFieldValue } }) => (
                <SingleSelect
                  fieldId="personalDocumentCountryCode"
                  label="Id Country"
                  className="personalDocumentCountryCode"
                  value={value}
                  showSelectedOption
                  maxVisibleItems={isMobile ? 4 : 6}
                  scrollToSelectedItem
                  options={personalDocumentCountryCodeOptions ?? []}
                  onChange={value => {
                    setFieldValue(name, value);
                  }}
                  disabled={!canEdit || isSaving}
                  errorMessage={form.touched.personalDocumentCountryCode && form.errors.personalDocumentCountryCode ? form.errors.personalDocumentCountryCode : null}
                  errorClassName="personalDocumentCountryCode-error"
                />
              )}
            </Field>
            <Field
              as={TextInput}
              type="text"
              name="personalDocumentNumber"
              id="personalDocumentNumber"
              className="website flex-1 w-full desktop:max-w-[480px]"
              label="Id number"
              disabled={!canEdit || isSaving}
              errorMessage={form.touched.personalDocumentNumber && form.errors.personalDocumentNumber ? form.errors.personalDocumentNumber : null}
              errorClassName="personalDocumentNumber-error"
            />
            <Field name="roleTypeCode">
              {({ field: { name, value }, form: { setFieldValue } }) => (
                <SingleSelect
                  fieldId="roleTypeCode"
                  label="Role"
                  className="roleTypeCode"
                  value={value}
                  showSelectedOption
                  maxVisibleItems={isMobile ? 4 : 6}
                  scrollToSelectedItem
                  options={roleTypeCodeOptions ?? []}
                  onChange={value => {
                    setFieldValue(name, value);
                  }}
                  disabled={!canEdit || isSaving}
                  errorMessage={form.touched.roleTypeCode && form.errors.roleTypeCode ? form.errors.roleTypeCode : null}
                  errorClassName="roleTypeCode-error"
                />
              )}
            </Field>
            <FormLabel className="dob" text="Role start date">
              <Field name={'roleStart'}>
                {({ field: { name, value }, form: { setFieldValue, values } }) =>
                  canEdit ? (
                    <SingleDateInput
                      value={value && new Date(value)}
                      onChange={value => setFieldValue(name, formatDate(value))}
                      showYearDropdown
                      enablePastDates
                      minDate={new Date(0)}
                      maxDate={!isNil(values.roleEnd) ? new Date(values.roleEnd) : new Date()}
                      internalClassName={classNames('dob-field', {
                        dateInputField: true,
                        dateNotSelected: value === undefined,
                      })}
                    />
                  ) : (
                    <PureTextInput
                      value={value? formatDateDisplay(value) : ''}
                      className="dob-field textInputField"
                      disabled={true}
                      readOnly
                    />
                  )
                }
              </Field>
            </FormLabel>
            <FormLabel className="dob" text="Role end date">
              <Field name={'roleEnd'}>
                {({ field: { name, value }, form: { setFieldValue, values } }) =>
                  canEdit ? (
                    <SingleDateInput
                      value={value && new Date(value)}
                      onChange={value => setFieldValue(name, formatDate(value))}
                      showYearDropdown
                      enablePastDates
                      minDate={new Date(values.roleStart) || new Date(0)}
                      maxDate={addYears(new Date(), 150)}
                      internalClassName={classNames('dob-field', {
                        dateInputField: true,
                        dateNotSelected: value === undefined,
                      })}
                    />
                  ) : (
                    <PureTextInput
                      value={value? formatDateDisplay(value) : ''}
                      className="dob-field textInputField"
                      disabled={true}
                      readOnly
                    />
                  )
                }
              </Field>
            </FormLabel>
            <Field
              as={TextInput}
              type="text"
              name="linkedin"
              id="linkedin"
              className="linkedin flex-1 w-full desktop:max-w-[480px]"
              label="Linkedin"
              disabled={!canEdit || isSaving}
              errorMessage={form.touched.linkedin && form.errors.linkedin ? form.errors.linkedin : null}
              errorClassName="linkedin-error"
            />
            <Field
              as={TextInput}
              type="text"
              name="twitter"
              id="twitter"
              className="twitter flex-1 w-full desktop:max-w-[480px]"
              label="X"
              disabled={!canEdit || isSaving}
              errorMessage={form.touched.twitter && form.errors.twitter ? form.errors.twitter : null}
              errorClassName="twitter-error"
            />
            <Field
              as={TextInput}
              type="text"
              name="instagram"
              id="instagram"
              className="instagram flex-1 w-full desktop:max-w-[480px]"
              label="Instagram"
              disabled={!canEdit || isSaving}
              errorMessage={form.touched.instagram && form.errors.instagram ? form.errors.instagram : null}
              errorClassName="instagram-error"
            />
          </div>
          <div className="buttons-container px-[20px] pb-[20px]">
            <FluidButton submit type="primary" textClassName="flex items-center gap-[10px]" className="" isLoading={isSaving} disabled={!canEdit} onClick={handleFormSubmit}>
                Save
            </FluidButton>
          </div>
        </>
      </AnimateHeight>
    </div>
  )
}