import { call, takeLatest, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import * as BreakdownActions from '../actions';
import { IHotel, IHotelAccommodationsSearchRequest, makeBackendApi } from 'services/BackendApi';
import { IBookingCountryResponse, IOwnershipResponse, makeBookingManagerApi } from 'services/BookingManagerApi';
import {
  BAMEndDateFilterSelector,
  BAMGuestAgesFilterSelector,
  BAMHotelFilterSelector,
  BAMStartDateFilterSelector,
} from '../selectors';
import { _subDays, tDateString } from 'utils';

export function* searchHotelRoomsSaga(action: BreakdownActions.SearchBAMAccommodationsRequestAction) {
  try {
    const bookingManagerApi = makeBookingManagerApi();
    yield put(BreakdownActions.setBAMSelectedAccommodationAction(null, -1));
    const ownershipData: AxiosResponse<IOwnershipResponse> = yield call(
      bookingManagerApi.getOwnership,
      action.bookingUuid
    );
    const travelAgentUuid = ownershipData.data.ownershipData.taInfo.uuid;
    const bookingCountryData: AxiosResponse<IBookingCountryResponse> = yield call(
      bookingManagerApi.getBookingCountryCode,
      action.bookingUuid
    );
    const clientCountryCode = bookingCountryData.data.countryCode;

    const backendApi = makeBackendApi(travelAgentUuid);
    const startDate = yield select(BAMStartDateFilterSelector);
    const endDate: string = yield select(BAMEndDateFilterSelector);
    const hotelUuid = yield select(BAMHotelFilterSelector);
    const guestAges = yield select(BAMGuestAgesFilterSelector);
    const params: IHotelAccommodationsSearchRequest = {
      startDate,
      endDate: _subDays(endDate as tDateString, 1),
      numberOfAdults: guestAges.numberOfAdults,
      agesOfAllChildren: guestAges.agesOfAllChildren,
      hotelUuid,
    };
    const response: AxiosResponse<IHotel[]> = yield call(backendApi.searchHotelRooms, clientCountryCode, params);
    yield put(BreakdownActions.setBamCountryDataAction(bookingCountryData.data));
    yield put(BreakdownActions.setBamOwnershipDataAction(ownershipData.data));
    yield put(BreakdownActions.setBAMLastRequestAction(params));
    yield put(BreakdownActions.searchBAMAccommodationsSuccessAction(response.data));
  } catch (e) {
    yield put(BreakdownActions.searchBAMAccommodationsFailureAction(e));
  }
}

export function* watchSearchHotelRoomsSaga() {
  yield takeLatest([BreakdownActions.SEARCH_BAM_ACCOMMODATIONS_REQUEST], searchHotelRoomsSaga);
}
