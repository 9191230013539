import React, { useMemo } from 'react';
import classNames from 'classnames';
import FluidButton from 'ui/FluidButton';
import { GeneralModal } from 'ui/GeneralModal';
import { LoadingBar } from 'ui/NetworkStatusBar';
import { SvgIcon } from 'ui/SvgIcon';
import { formatPriceCents } from 'utils';
import Warning from 'ui/Icons/warning2.component.svg';
import { LIVE_RATES_PRICE_CHECK_TOLERANCE_CENTS } from 'config';
import CheckmarkIcon from 'ui/Icons/circle-check.component.svg';
import RestaurantIcon from 'ui/Icons/restaurant.component.svg';
import { GuestDetailsSingleLine } from 'ui/GuestDetailsSingleLine/GuestDetailsSingleLine';
import { ArrivalDepartureSingleLine } from 'ui/ArrivalDepartureSingleLine/ArrivalDepartureSingleLine';
import { iDataForPriceCheckModal } from './useBookLiveAccommodation';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  handleConfirm: (val: any) => void;
  modalData: {
    type:
      | 'booking-pre-confirm'
      | 'price-check-no-diff'
      | 'price-check-diff'
      | 'price-check-not-available'
      | 'booking-in-progress'
      | 'successful-no-proforma-invoice'
      | 'successful-with-proforma-invoice';
    dataForPriceCheckModal?: iDataForPriceCheckModal;
  } | null;
  bookingCurrencySymbol: string;
}

export const AccommodationBlockBookLiveModal = (props: Props) => {
  let isPriceCheckDifference = false;
  if (
    props.modalData?.dataForPriceCheckModal &&
    Math.abs(props.modalData.dataForPriceCheckModal.prevPrice - props.modalData.dataForPriceCheckModal.newPrice) >
      LIVE_RATES_PRICE_CHECK_TOLERANCE_CENTS
  ) {
    isPriceCheckDifference = true;
  }

  const liveRateRoomDetailsMarkup = useMemo(() => {
    return (
      <div className="w-full !text-black">
        <span className="font-bold">{props.modalData?.dataForPriceCheckModal?.title}</span>
        <span className="flex justify-between mt-[5px]">
          <span className="flex flex-col gap-[5px]">
            <GuestDetailsSingleLine
              numberOfAdults={props.modalData?.dataForPriceCheckModal?.guestAges?.numberOfAdults || 0}
              agesOfAllChildren={props.modalData?.dataForPriceCheckModal?.guestAges?.agesOfAllChildren || []}
            />
            {props.modalData?.dataForPriceCheckModal?.arrival && props.modalData.dataForPriceCheckModal?.departure && (
              <ArrivalDepartureSingleLine
                arival={props.modalData.dataForPriceCheckModal!.arrival}
                departure={props.modalData.dataForPriceCheckModal!.departure}
              />
            )}

            <span className="flex gap-2">
              <SvgIcon IconComponent={RestaurantIcon} className="w-[16px] h-[16px] fill-black" />
              <span
                className={classNames('font-pt-sans text-15px leading-17px', {
                  'text-red-92':
                    props.modalData?.dataForPriceCheckModal?.prevMealPlanCode !=
                    props.modalData?.dataForPriceCheckModal?.newMealPlanCode,
                })}
              >
                {props.modalData?.dataForPriceCheckModal?.newMealPlanName}
              </span>
            </span>
          </span>
          <span className="flex flex-col gap-2 text-right">
            <span className="text-15px leading-19px">
              {props.bookingCurrencySymbol}
              {formatPriceCents(props.modalData?.dataForPriceCheckModal?.prevPrice)}
            </span>
            {isPriceCheckDifference && (
              <span className="text-red-92 text-15px leading-19px">
                Now {props.bookingCurrencySymbol}
                {formatPriceCents(props.modalData?.dataForPriceCheckModal?.newPrice)}
              </span>
            )}
          </span>
        </span>
      </div>
    );
  }, [props.modalData?.dataForPriceCheckModal]);

  return (
    <GeneralModal
      onClose={() => {
        props.handleClose();
      }}
      isCloseButtonVisible={false}
      isOpen={props.isOpen}
      modalWindowClassName={classNames(
        'w-[500px] min-h-[450px] p-[40px] font-pt-sans flex flex-col justify-between border-[4px] border-solid rounded-[4px]',
        {
          'border-yellow-30':
            props.modalData?.type === 'price-check-diff' ||
            props.modalData?.type === 'price-check-no-diff' ||
            props.modalData?.type === 'price-check-not-available',
          'border-bright-sky-blue':
            props.modalData?.type === 'booking-pre-confirm' || props.modalData?.type === 'booking-in-progress',
          'border-green-90':
            props.modalData?.type === 'successful-no-proforma-invoice' ||
            props.modalData?.type === 'successful-with-proforma-invoice',
        }
      )}
    >
      {props.modalData?.type === 'booking-pre-confirm' && (
        <>
          <section className="text-black">
            <h2 className="m-0 font-bold text-18px leading-22px text-black flex items-center gap-[16px]">
              <SvgIcon IconComponent={Warning} className="fill-bright-sky-blue min-w-[36px] h-[36px]" />
              <span>Booking in progress</span>
            </h2>
            <p className="my-[25px]">We are checking prices with the supplier before confirming.</p>
            <p className="my-[25px]">
              Please <span className="font-bold">do not</span> close this page, navigate away, or refresh.
            </p>
            <LoadingBar label="Checking prices with the supplier..." />
          </section>
          <div className="flex items-center justify-around mt-4">
            <FluidButton
              type="secondary"
              className="w-[50%]"
              onClick={() => {
                props.handleClose();
              }}
            >
              Cancel
            </FluidButton>
          </div>
        </>
      )}

      {props.modalData?.type === 'price-check-diff' && (
        <>
          <section className="text-black">
            <h2 className="m-0 font-pt-sans font-bold text-18px leading-22px text-black flex items-center gap-[16px]">
              <SvgIcon IconComponent={Warning} className="fill-yellow-50 min-w-[36px] h-[36px]" />
              <span>By booking this accommodation, the room will be confirmed with the supplier</span>
            </h2>
            <p className="my-[25px]">
              Upon cross checking the rate and meal plan with our supplier, there are some differences to the
              accommodation and/or meal plan. You can review the changes in red below.
            </p>

            {liveRateRoomDetailsMarkup}

            <p className="my-[25px]">Do you want to continue?</p>
          </section>
          <div className="flex items-center justify-between gap-[20px]">
            <FluidButton
              type="primary"
              className="w-[50%]"
              onClick={() => {
                props.handleConfirm(true);
              }}
            >
              Yes
            </FluidButton>
            <FluidButton
              type="secondary"
              className="w-[50%]"
              onClick={() => {
                props.handleConfirm(false);
              }}
            >
              No
            </FluidButton>
          </div>
        </>
      )}

      {props.modalData?.type === 'price-check-no-diff' && (
        <>
          <h2 className="m-0 font-pt-sans font-bold text-18px leading-22px text-black flex items-center gap-[16px]">
            <SvgIcon IconComponent={Warning} className="fill-yellow-50 min-w-[36px] h-[36px]" />
            <span>By booking this accommodation, the room will be confirmed with the supplier</span>
          </h2>

          <p>
            Upon cross-checking the rate and meal plan with our supplier,{' '}
            <span className="font-bold">no differences were found.</span>
          </p>

          {liveRateRoomDetailsMarkup}

          <p>Do you want to continue?</p>
          <div className="flex items-center justify-between gap-[20px]">
            <FluidButton
              type="primary"
              className="w-[50%]"
              onClick={() => {
                props.handleConfirm(true);
              }}
            >
              Yes
            </FluidButton>
            <FluidButton
              type="secondary"
              className="w-[50%]"
              onClick={() => {
                props.handleConfirm(false);
              }}
            >
              No
            </FluidButton>
          </div>
        </>
      )}

      {props.modalData?.type === 'price-check-not-available' && (
        <>
          <section className="text-black">
            <h2 className="m-0 font-pt-sans font-bold text-18px leading-22px text-black flex items-center gap-2">
              <SvgIcon IconComponent={Warning} className="fill-yellow-50 w-[29px] h-[28px]" />
              <span>Rate Currently Unavailable</span>
            </h2>
            <p className="my-[25px]">This rate and/or meal plan is currently unavailable from the supplier.</p>
            <p className="my-[25px]">This is often due to a temporary issue with the supplier.</p>
            <p className="my-[25px]">Please try again later.</p>
          </section>

          <div className="flex items-center justify-around mt-4">
            <FluidButton
              type="secondary"
              className="w-[50%]"
              onClick={() => {
                props.handleClose();
              }}
            >
              Cancel
            </FluidButton>
          </div>
        </>
      )}

      {props.modalData?.type === 'booking-in-progress' && (
        <>
          <section className="text-black">
            <h2 className="m-0 font-bold text-18px leading-22px text-black flex items-center gap-[16px]">
              <SvgIcon IconComponent={Warning} className="fill-bright-sky-blue min-w-[36px] h-[36px]" />
              <span>Booking in progress</span>
            </h2>
            <p className="my-[25px]">We are confirming the accommodation with the supplier.</p>
            <p className="my-[25px]">
              Please <span className="font-bold">do not</span> close this page, navigate away, or refresh.
            </p>
            <LoadingBar label="Confirming with the supplier..." />
          </section>
        </>
      )}

      {props.modalData?.type === 'successful-no-proforma-invoice' && (
        <>
          <section className="text-black">
            <h2 className="m-0 font-bold text-18px leading-22px text-black flex items-center gap-[16px]">
              <SvgIcon IconComponent={CheckmarkIcon} className="fill-green-90 min-w-[36px] h-[36px]" />
              <span>Booking confirmed</span>
            </h2>
            <p className="my-[25px]">The booking has been confirmed with the supplier.</p>
            <p className="my-[25px]">The breakdown has been updated and saved with the latest information.</p>
            <p className="my-[25px]">This page will now refresh.</p>
          </section>
          <div className="flex items-center justify-around">
            <FluidButton
              type="primary"
              className="w-[50%]"
              onClick={() => {
                props.handleConfirm(true);
              }}
            >
              OK
            </FluidButton>
          </div>
        </>
      )}

      {props.modalData?.type === 'successful-with-proforma-invoice' && (
        <>
          <section className="text-black">
            <h2 className="m-0 font-bold text-18px leading-22px text-black flex items-center gap-[16px]">
              <SvgIcon IconComponent={CheckmarkIcon} className="fill-green-90 min-w-[36px] h-[36px]" />
              <span>Booking confirmed</span>
            </h2>
            <p className="my-[25px]">The booking has been confirmed with the supplier.</p>
            <p className="my-[25px]">The breakdown has been updated and saved with the latest data.</p>
            <p className="my-[25px]">A new proforma invoice has been generated.</p>
            <p className="my-[25px]">The new proforma invoice will open, and this page will refresh.</p>
          </section>
          <div className="flex items-center justify-around">
            <FluidButton
              type="primary"
              className="w-[50%]"
              onClick={() => {
                props.handleConfirm(true);
              }}
            >
              OK
            </FluidButton>
          </div>
        </>
      )}
    </GeneralModal>
  );
};
