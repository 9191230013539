import { useQuery } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";
import { ETypeValueType, ITypeValue } from "../types/types";

export const useCrmTitleValues = () => {
  const crmApi = makeCRMApi();
  
  const crmTitleValues = useQuery({
    queryKey: ['crm-title-values'],
    queryFn: () =>
      crmApi.postTitleValues(ETypeValueType.TITLE, { enabled: true }),
  });
  
  return crmTitleValues;
}
