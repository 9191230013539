import React from 'react';
import classnames from 'classnames';
import { IScrollableTableRow } from './types';

interface ITableRowProps {
  row: IScrollableTableRow;
  rowIndex: number;
  evenColor: string;
}

export const TableRow: React.FC<ITableRowProps> = React.memo(
  ({ row, rowIndex, evenColor }) => {
  const visibleCells = row.cells.filter(cell => cell.isVisible !== false);
  const isEven = rowIndex % 2 === 0;

  const className = classnames('row text-black text-15px h-30px flex items-center pr-3 min-h-[40px]', {
    [`bg-${evenColor}`]: isEven,
  })
  
  return (
    <div className={className}>
      {visibleCells.map(cell => {
        return (
          <div key={cell.id} className={classnames('flex items-center px-[10px] min-h-[40px]', cell.className)} title={cell.title}>
            {cell.value}
          </div>
        );
      })}
    </div>
  );
});
