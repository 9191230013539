import { isNil } from 'lodash-es';
import * as Yup from 'yup';

const isEmptyOrNil = (value) => isNil(value) || value === '';

export const isBothSelected = (values: IContactCreateFormValues | ILinkedCompanyFormValues) : boolean => {
  return !isEmptyOrNil(values.existingCompanyId) && !isEmptyOrNil(values.companyName) && !isEmptyOrNil(values.companyType);
}

export const contactCreateCommon = Yup.object().shape({
  type: Yup.string().required('This field is required'),
  firstName: Yup.string().required('This field is required'),
}).test({
  test: function(values) {
    // https://github.com/jaredpalmer/formik/issues/2146
    const bothSelected = isBothSelected(values);
    if (bothSelected) {
      return this.createError({
        path: 'both-not-allowed',
        message: 'Select Existing or create new, but not both options.',
      });
    }
    return true;
  }
});

export const addExistingCompanyValidator = (
  Yup.object().shape({
    existingCompanyId: Yup.string().required('This field is required'),
  })
).concat(contactCreateCommon);

export const addNewCompanyValidator = (
  Yup.object().shape({
    companyName: Yup.string().nullable(true).required('This field is required'),
    companyType: Yup.string().nullable(true).required('This field is required'),
  })
).concat(contactCreateCommon);

export interface ILinkedCompanyFormValues {
  companyType: string | null;
  companyName: string | null;
  existingCompanyId: string | null;
}

export interface IContactCreateFormValues extends ILinkedCompanyFormValues {
  type: string;
  title: string | null;
  firstName: string;
  lastName: string | null;
  country: string | null;
  email: string | null;
  mobile: string | null;
  landline: string | null;
  keyNotes: string | null;
}