import React from 'react';
import classnames from 'classnames';
import { ProductLineWithPrice } from './ProductLineWithPrice';
import { TCurrencyCode } from 'interfaces';
import { AppliedSuplement, Fine, GroundService, Product, Transfer } from 'services/BackendApi';
import { IBasketBuildL4 } from 'services/BackendApi/types/Basket';
import { OtherOptions } from './OtherOptions';

export interface IBookingProduct {
  total: number;
  totalBeforeDiscount: number;
  isOnRequestOrPartiallyOnRequest?: boolean;
  selected: boolean;
  products: Product[];
}

interface IBookingNonAccommodationProductProps {
  products: Transfer[] | GroundService[] | Fine[] | AppliedSuplement[];
  title: string;
  currencyCode: TCurrencyCode;
  className?: string;
  build?: IBasketBuildL4;
}

export const BookingNonAccommodationProduct: React.FC<IBookingNonAccommodationProductProps> = React.memo(
  ({ products, title, currencyCode, className, build }) => {
    const otherOptions = products
      .filter(item => !item.selected)
      .map((p: Transfer) => ({
      title: `${p.products[0].name}${p.meta?.direction ? ` (${p.meta.direction.toUpperCase()})` : ''}`,
      price: p.total,
      currencyCode,
      isOnRequest: p.isOnRequestOrPartiallyOnRequest,
      }));
    const selectedOptions = products.filter(item => item.selected);
    return (
      <div className="booking-non-accommodation-product px-5 py-15px border-solid border-b border-gray-20">
        <div className="nap-data flex items-center justify-between">
          <p className="nap-title font-hurmegeometric-sans text-flint text-13px leading-16px uppercase font-bold m-0">
            {title}
          </p>
        </div>

        <div className="product-lines mt-3px">
          {selectedOptions.length > 0 ? selectedOptions.map((product, index) => {
            const details = {
              description: product.products[0].meta.description,
              title: title === 'Transfers' ? 'Transfer Details' : undefined,
              uploads: build?.uploads.filter(item => item.ownerUuid === product.products[0].uuid) ?? [],
            };
            const includeDetails = title === 'Transfers';
            return (
              <ProductLineWithPrice
                key={index}
                name={product.products[0].name}
                details={includeDetails ? details : undefined}
                total={product.total}
                totalBeforeDiscount={product.totalBeforeDiscount}
                currencyCode={currencyCode}
                isOnRequest={product.isOnRequestOrPartiallyOnRequest}
                className={classnames('non-accommodation-product', className)}
              />
            );
          }) : <div className='text-flint text-[13px] mb-[5px]'>NONE</div>}
        </div>
        {otherOptions.length > 0 && <OtherOptions options={otherOptions} />}
      </div>
    );
  }
);
