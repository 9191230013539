import { keepPreviousData, useQuery } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";
import { AxiosError, AxiosResponse } from "axios";
import { IDirectorySimpleSearchResponse, IFilterSimpleSearch } from "../types/DirectoryEntry";
import { IPage } from "services/BackendApi";

export interface IUseCrmSearchDirectoryEntriesParams {
  filter: IFilterSimpleSearch;
  pagination: IPage;
}

export const useCrmSearchDirectoryEntries = (params: IUseCrmSearchDirectoryEntriesParams) => {
  const crmApi = makeCRMApi();
  
  const crmDirectoryEntries = useQuery<AxiosResponse<IDirectorySimpleSearchResponse>, AxiosError>({
    queryKey: ['crm-directory-entries-simple', params.filter.term, params.filter.directoryEntryType, params.pagination.limit, params.pagination.offset],
    placeholderData: keepPreviousData,
    queryFn: () =>
      crmApi.postDirectorySearchSimple({
        filter: params.filter,
        pagination: {
          limit: params.pagination.limit,
          offset: params.pagination.offset,
        }
      }),
  });

  const list = params.filter.term.length >= 3 ? crmDirectoryEntries.data?.data?.list ?? [] : [];
  
  return { 
    directoryEntries: list,
    crmDirectoryEntries,
   };
}
