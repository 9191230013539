import {
  EPurchaseCostReviewStatus,
  IFinanceRow,
  IBookingComment
} from 'services/BookingManagerApi';
import { ENetworkRequestStatus } from 'services/BackendApi';

export interface IPurchaseCostReviewSubdomain {
  status: EPurchaseCostReviewStatus | null;
  financePurchaseRow: IFinanceRow | null;
  comment: IBookingComment | null;
  requestedStatus: EPurchaseCostReviewStatus | null;
  request: {
    getPurchaseCostReview: ENetworkRequestStatus;
    patchPurchaseCostReview: ENetworkRequestStatus;
    uploadFile: ENetworkRequestStatus;
  };
  vatAmountCents: number | null,
  isTaxInvoiceReceived: boolean,
}

export const initialState: IPurchaseCostReviewSubdomain = {
  status: null,
  financePurchaseRow: null,
  comment: null,
  requestedStatus: null,
  request: {
    getPurchaseCostReview: ENetworkRequestStatus.IDLE,
    patchPurchaseCostReview: ENetworkRequestStatus.IDLE,
    uploadFile: ENetworkRequestStatus.IDLE,
  },
  vatAmountCents: null,
  isTaxInvoiceReceived: false,
};
