import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { differenceInCalendarDays, format } from 'date-fns';
import { DatePickerStateProvider, IDatePickerSateParams } from 'pureUi/providers/DatePickerStateProvider';
import DateRangeInput from 'pureUi/DateRangeInput';
import styled from 'styled-components';
import { pureUiTheme } from 'pureUi/pureUiTheme';
import Checkbox from 'pureUi/Checkbox';
import Label from 'pureUi/Label/index';
import { AvailableProductSets, ICompany } from 'services/BackendApi/types';

import ArrowDown from 'ui/Icons/arrowDown.component.svg';
import ArrowUp from 'ui/Icons/arrowUp.component.svg';
import { SvgIcon } from 'ui/SvgIcon';
import { theme } from '../../../tailwind.config';

// @ts-ignore
import { BookingBuilderAvailableProductSets, LodgingSummary } from 'interfaces';

// @ts-ignore
import { isNilOrEmpty } from 'ramda-adjunct';
import { clone, compose, flatten, uniq, uniqBy } from 'ramda';
import { formatPrice } from 'utils';

import {
  getAppliedOffersForLodging,
  getAppliedSupplementsForLodging,
  getAvailableProductSetAccommodationForUuid,
  getLodgingTotals,
} from '../../utils/bookingBuilder';
import { InstantBookTag } from './InstantBookTag'
// @ts-ignore
import { OccasionsSelect } from 'components';

import { Text } from '../SummaryForm/SummaryForm.styles';

import {
  ButtonSmall,
  CollapseButton,
  CollapseHeader,
  CollapsibleSection,
  LodgingSummaryTitle,
  LodgingTotal,
  LodgingTotalWrapper,
  MealPlanRatePrice,
} from './LodgingSummary.styles';
import connect from './LodgingSummary.state';
import { DateHelper } from 'pureUi/DatePicker';

import { TableCardBox, TableCardRow } from 'pureUi/TableCard';
import { RateTypeTag } from './RateTypeTag';
import { ERoomRateType } from 'store/modules/bookingBuilder/model';
import SingleSelect from 'ui/SingleSelect';
import { bookingRequestedAccommodationsSelector } from 'store/modules/bookingBuilder/selectors';
import { useSelector } from 'react-redux';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { companyDataSelector } from 'store/modules/companyInfo';
import { RadioButtons } from 'components/RadioButtons/RadioButtons';
import { NumberSelect } from 'components/webapp-ui-components/NumberSelect';

const possibleChildAges = [
  { label: '0', value: '0' },
  { label: '1', value: '1' },
  { label: '2', value: '2' },
  { label: '3', value: '3' },
  { label: '4', value: '4' },
  { label: '5', value: '5' },
  { label: '6', value: '6' },
  { label: '7', value: '7' },
  { label: '8', value: '8' },
  { label: '9', value: '9' },
  { label: '10', value: '10' },
  { label: '11', value: '11' },
  { label: '12', value: '12' },
  { label: '13', value: '13' },
  { label: '14', value: '14' },
  { label: '15', value: '15' },
  { label: '16', value: '16' },
  { label: '17', value: '17' },
];

const getAvailableMealPlansForAccommodation = (
  lodging: LodgingSummary,
  availableProductSets: BookingBuilderAvailableProductSets,
  currencyCode: string
) => {
  const availableMealPlans: any[] = [];
  const accommodation = getAvailableProductSetAccommodationForUuid(lodging.uuid, availableProductSets);

  if (!accommodation) {
    return {};
  }

  accommodation.availableSubProductSets['Meal Plan'].forEach((mealPlan: any) => {
    // the radio button value is the UUIDs joined with a slash
    const value = mealPlan.products.map(p => p.uuid).join('/');

    // build the name from all the breakdown names
    const labelNames = mealPlan.products.map(p => p.name).join(' & ');

    const formattedLabelPrice = mealPlan.isOnRequestOrPartiallyOnRequest ? 
      'On Request' :
      `${currencyCode}${formatPrice(mealPlan.total)}`

    const labelOffers = flatten(
      uniq(
        mealPlan.breakdown.map(b => {
          return b.offers.map(o => o.offer.name);
        })
      )
    );

    const label = (
      <React.Fragment>
        <div>
          <span>
            {labelNames}{' '}
            {labelOffers && <MealPlanRatePrice data-discount="true">{labelOffers.join(' & ')}</MealPlanRatePrice>} -{' '}
            {formattedLabelPrice.toUpperCase()}
          </span>
        </div>
      </React.Fragment>
    );

    availableMealPlans.push({
      value,
      label,
    });
  });

  return availableMealPlans;
};

/**
 * handles opening/closing collapsables. makes use of editGuard and onEditGuard
 */
const CollapseToggle = ({ isCollapsed, onClick, type }: { isCollapsed: boolean; onClick: Function; type: string }) => {
  return (
    <CollapseButton
      className={`collapse-toggle ${type}`}
      onClick={() => {
        onClick(!isCollapsed);
      }}
    >
      {isCollapsed ? (
        <SvgIcon width="13" height="19" IconComponent={ArrowDown} defaultFill={theme.colors.black} />
      ) : (
        <SvgIcon width="13" height="19" IconComponent={ArrowUp} defaultFill={theme.colors.black} />
      )}
    </CollapseButton>
  );
};

const MealPlanEditor = ({
  onUpdate,
  lodging,
  availableProductSets,
  currencyCode,
  updateLodgingMealPlanAction,
}: {
  onUpdate: Function;
  lodging: LodgingSummary;
  availableProductSets: AvailableProductSets;
  currencyCode: string;
  updateLodgingMealPlanAction: Function;
}) => {
  const availableMealPlans = getAvailableMealPlansForAccommodation(lodging, availableProductSets, currencyCode);
  const uniqueAvailableMealPlans = uniqBy( ({value}) => value, availableMealPlans as any[]);
  const selectedMealPlanSetUuid = lodging.subProducts['Meal Plan'].map(m => m.uuid).join('/');
  
  const handleMealPlanSetSelection = (mealPlanSetUuid, label) => {
    const dynamicParameters = ParameterService.getParameters();
    const mealPlanUuids = mealPlanSetUuid.split('/');
    updateLodgingMealPlanAction(lodging.hotelUuid, lodging.index, mealPlanUuids);
    onUpdate(false);
    // OWA-5182
    if (dynamicParameters.WOOPRA_DOMAIN) {
      // @ts-ignore
      window.woopra && window.woopra.track('cart-click', {
        type: 'meal-plan',
        lodgingUuid: lodging.uuid,
        itemLabel: label,
        itemUuid: mealPlanSetUuid,
      });
    }
  };

  if (isNilOrEmpty(uniqueAvailableMealPlans)) {
    return <p>There are no available meal plans for the selected dates/occupancy</p>;
  }

  return (
    <div className="mt-4 meal-plan-options">
      <RadioButtons
        id='mealplan' 
        options={uniqueAvailableMealPlans} 
        onChange={handleMealPlanSetSelection} 
        containerClasses="flex flex-col w-full" 
        labelClasses="w-full justify-start" 
        selectedOption={selectedMealPlanSetUuid} 
      />
    </div>
  );
};

const MealPlanCollapsible = ({
  canBeExpanded = true,
  isLiveRate = false,
  lodging,
  currencyCode,
  updateLodgingMealPlanAction,
  availableProductSets,
}: {
  canBeExpanded: boolean;
  isLiveRate: boolean;
  lodging: LodgingSummary;
  currencyCode: string;
  updateLodgingMealPlanAction: Function;
  availableProductSets: AvailableProductSets;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  // OWA-5182
  const handleCollapseClick = () => {
    const dynamicParameters = ParameterService.getParameters();
    if (isCollapsed && dynamicParameters.WOOPRA_DOMAIN) {
      // @ts-ignore
      window.woopra && window.woopra.track('display-click', {
        type: 'meal-plan',
        lodgingUuid: lodging.uuid,
      });
    }
    setIsCollapsed(!isCollapsed);
  };

  if (isLiveRate) {
    return (
      <CollapsibleSection>
        <CollapseHeader>{lodging.liveRate?.externalMealPlanDescription ?? 'Meal Plan Selection'}</CollapseHeader>
      </CollapsibleSection>
    );
  }

  return (
    <CollapsibleSection>
      <CollapseHeader className={!isCollapsed ? 'color-teal' : ''}>
        {lodging.mealPlanBreakdown}
        {canBeExpanded && (
          <CollapseToggle type="meal-plan" isCollapsed={isCollapsed} onClick={handleCollapseClick} />
        )}
      </CollapseHeader>

      {!isCollapsed && (
        <MealPlanEditor
          onUpdate={val => setIsCollapsed(val)}
          currencyCode={currencyCode}
          lodging={lodging}
          updateLodgingMealPlanAction={updateLodgingMealPlanAction}
          availableProductSets={availableProductSets}
        />
      )}
    </CollapsibleSection>
  );
};

const OccasionsCollapsible = ({
  lodging,
  updateLodgingOccasionsAction,
}: {
  lodging: LodgingSummary;
  updateLodgingOccasionsAction: Function;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const requestAccommodations = useSelector(bookingRequestedAccommodationsSelector);

  // OWA-5182
  const handleCollapseClick = () => {
    const dynamicParameters = ParameterService.getParameters();
    if (isCollapsed && dynamicParameters.WOOPRA_DOMAIN) {
      // @ts-ignore
      window.woopra && window.woopra.track('display-click', {
        type: 'occasions',
        lodgingUuid: lodging.uuid,
      });
    }
    setIsCollapsed(!isCollapsed);
  };

  return (
    <CollapsibleSection>
      <CollapseHeader className={!isCollapsed ? 'color-teal' : ''}>
        Occasion(s): {lodging.occasionsBreakdown || 'None'}
        <CollapseToggle type="occasions" isCollapsed={isCollapsed} onClick={handleCollapseClick} />
      </CollapseHeader>

      {!isCollapsed && (
        <div className="mt-4 occasions-options">
          <OccasionsSelect
            onChange={e => {
              const checked = !requestAccommodations[lodging.index][e];
              updateLodgingOccasionsAction(lodging.hotelUuid, lodging.index, { [e]: checked });
            }}
            occasions={undefined} // need to specify undefined because OccassionsSelect is setup badly
            selected={lodging}
          />
        </div>
      )}
    </CollapsibleSection>
  );
};

const DateCollapsible = ({
  lodging,
  updateLodgingDatesAction,
}: {
  lodging: LodgingSummary;
  updateLodgingDatesAction: Function;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  // OWA-5182
  const handleCollapseClick = () => {
    const dynamicParameters = ParameterService.getParameters();
    if (isCollapsed && dynamicParameters.WOOPRA_DOMAIN) {
      // @ts-ignore
      window.woopra && window.woopra.track('date-click', {
        type: 'date-click',
        lodgingUuid: lodging.uuid,
      });
    }
    setIsCollapsed(!isCollapsed);
  };

  const handleDateChange = (dates: [string]) => {
    const dynamicParameters = ParameterService.getParameters();
    const from = new Date(dates[0]);
    const to = new Date(dates[dates.length - 2]);
    updateLodgingDatesAction(lodging.hotelUuid, lodging.index, from, to);
    if (dynamicParameters.WOOPRA_DOMAIN) {
      // @ts-ignore
      window.woopra && window.woopra.track('date-click', {
        type: 'date-selected',
        lodgingUuid: lodging.uuid,
        from: format(from, "yyyy-MM-dd"),
        to: format(to, "yyyy-MM-dd"),
      });
    }
    setIsCollapsed(!isCollapsed);
  };

  // Why +2? Because the difference in non inclusive of the last date, and the end date is a day short
  const length = differenceInCalendarDays(new Date(lodging.endDate), new Date(lodging.startDate)) + 2;
  const firstTimeStamp = new Date(lodging.startDate).getTime();
  const selectedDates = DateHelper.generateDatesFrom(firstTimeStamp, length, 'en-US').map(
    dateObject => dateObject.dateString
  );

  return (
    <React.Fragment>
      <CollapseHeader className={!isCollapsed ? 'color-teal' : ''}>
        {lodging.nightsBreakdown}
        <CollapseToggle type="dates" isCollapsed={isCollapsed} onClick={handleCollapseClick} />
      </CollapseHeader>

      {!isCollapsed && (
        <div className="mt-4">
          <label>Date Picker</label>
          <DatePickerStateProvider
            defaultSelectedDates={selectedDates}
            onDateChange={handleDateChange}
            render={(params: IDatePickerSateParams) => (
              <DateRangeInput
                datePickerLeft
                className="lodgingDateRangeInput"
                displayString={params.displayString}
                currentDate={params.datePickerCurrentDate}
                totalNights={params.totalNights}
                selectedDates={params.selectedDates}
                onDayClick={params.handleDayClick}
                onDayMouseOver={params.handleDateMouseOver}
                showDatePicker={params.showDatePicker}
                onNextClick={params.incrementDate}
                onPrevClick={params.decrementDate}
                onMouseDown={params.toggleDatePicker}
                onClickOutside={params.hideDatePicker}
              />
            )}
          />
        </div>
      )}
    </React.Fragment>
  );
};

const OccupancyEditor = ({
  onUpdate,
  lodging,
  updateLodgingGuestAgesAction,
}: {
  onUpdate: Function;
  lodging: LodgingSummary;
  updateLodgingGuestAgesAction: Function;
}) => {
  const [numberOfAdults, setNumberOfAdults] = useState(lodging.guestAges.numberOfAdults);
  const [agesOfAllChildren, setAgesOfAllChildren] = useState(lodging.guestAges.agesOfAllChildren || []);

  const updateAgeOfChild = (index, value) => {
    const newAgesOfAllChildren = clone(agesOfAllChildren);
    newAgesOfAllChildren[index] = parseInt(value);
    setAgesOfAllChildren(newAgesOfAllChildren);
  };

  const handleUpdate = () => {
    const newGuestAges = {
      numberOfAdults,
      agesOfAllChildren,
    };
    updateLodgingGuestAgesAction(lodging.hotelUuid, lodging.index, newGuestAges);
    onUpdate(true);
  };

  return (
    <div className="mt-4">
      <div className="lodging-summary__occupancy-editor__number-of-adults">
        <div className="number-select-wrapper">
          <label>Number of Adults</label>
          <NumberSelect
            className="numberSelect"
            min={0}
            max={99}
            value={numberOfAdults}
            onAdd={() => setNumberOfAdults(numberOfAdults + 1)}
            onRemove={() => setNumberOfAdults(numberOfAdults - 1)}
          />
        </div>
      </div>

      <div className="lodging-summary__occupancy-editor__number-of-children">
        <div className="number-select-wrapper">
          <label>Number of Children</label>
          <NumberSelect
            className="numberSelect"
            min={0}
            max={99}
            value={agesOfAllChildren.length}
            onAdd={() => {
              setAgesOfAllChildren([...agesOfAllChildren, 0]);
            }}
            onRemove={() => {
              setAgesOfAllChildren(agesOfAllChildren.slice(0, agesOfAllChildren.length - 1));
            }}
          />
        </div>
      </div>

      {agesOfAllChildren.length >= 1 && (
        <div className="className='lodging-summary__occupancy-editor__specify-ages-of-children">
          {agesOfAllChildren.map((ageOfChild, index) => {
            return (
              <div key={`child-age-selector-${index}`} className="child-age-selector mt-5px">
                <label>Child {index + 1}</label>
                <div className="child-age-select-wrapper">
                  <SingleSelect
                    fieldId="child-age-select-wrapper"
                    className="child-age-select-wrapper min-w-200px"
                    value={String(ageOfChild)}
                    options={possibleChildAges}
                    onChange={value => updateAgeOfChild(index, Number(value))}
                    hasLegacyLook
                    maxVisibleItems={10}
                  />
                </div>
              </div>
            );
          })}
        </div>
      )}

      <div className="flex flex-row-reverse mt-4">
        <ButtonSmall onClick={handleUpdate}>Update Occupancy</ButtonSmall>
      </div>
    </div>
  );
};

const OccupancyCollapsible = ({
  canBeExpanded = true,
  lodging,
  updateLodgingGuestAgesAction,
}: {
  canBeExpanded: boolean;
  lodging: LodgingSummary;
  updateLodgingGuestAgesAction: Function;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  // OWA-5182
  const handleCollapseClick = () => {
    const dynamicParameters = ParameterService.getParameters();
    if (isCollapsed && dynamicParameters.WOOPRA_DOMAIN) {
      // @ts-ignore
      window.woopra && window.woopra.track('display-click', {
        type: 'occupancy',
        lodgingUuid: lodging.uuid,
      });
    }
    setIsCollapsed(!isCollapsed);
  };

  return (
    <CollapsibleSection>
      <CollapseHeader className={!isCollapsed ? 'color-teal' : ''}>
        {lodging.occupancyBreakdown}
        {canBeExpanded && (
          <CollapseToggle type="occupancy" isCollapsed={isCollapsed} onClick={handleCollapseClick} />
        )}
      </CollapseHeader>

      {!isCollapsed && (
        <OccupancyEditor
          onUpdate={val => setIsCollapsed(val)}
          lodging={lodging}
          updateLodgingGuestAgesAction={updateLodgingGuestAgesAction}
        />
      )}
    </CollapsibleSection>
  );
};

// the "lodgings" that are rendered here are built in
// src/containers/SummaryForm/SummaryForm.tsx:renderLodgingSummaries
export const LodgingSummaryRender = props => {
  const dynamicParameters = ParameterService.getParameters();
  const lodging: LodgingSummary = props.lodging;
  const availableProductSets: AvailableProductSets = props.availableProductSets;
  const isSr = props.isSr;
  const textOnlyOffersPerLodging: any = props.textOnlyOffersPerLodging;
  const updateLodgingGuestAgesAction: Function = props.updateLodgingGuestAgesAction;
  const updateLodgingMealPlanAction: Function = props.updateLodgingMealPlanAction;
  const updateLodgingDatesAction: Function = props.updateLodgingDatesAction;
  const removeLodgingAction: Function = props.removeLodgingAction;
  const updateLodgingOccasionsAction: Function = props.updateLodgingOccasionsAction;
  const updateLodgingRepeatGuestAction: Function = props.updateLodgingRepeatGuestAction;
  const currencyCode: string = props.currencyCode;

  const appliedSupplements = getAppliedSupplementsForLodging(lodging, availableProductSets, currencyCode);
  const lodgingTotals = getLodgingTotals(lodging, availableProductSets);
  const appliedOffers = getAppliedOffersForLodging(lodging, availableProductSets, textOnlyOffersPerLodging);

  const isTa = useSelector(AuthSelectors.isTA);
  const taCompany = useSelector(companyDataSelector) as ICompany;

  const isLiveRatesEnabled = isTa
  ? (dynamicParameters.ENABLE_V1_LIVE_RATES && taCompany?.enableInstantBooking)
  : (dynamicParameters.ENABLE_V1_LIVE_RATES || (dynamicParameters.ENABLE_V1_LIVE_RATES_SRS && isSr));

  const isLiveRate = !!lodging.liveRate;

  const { t } = useTranslation();
  
  // instant book is available if the rate itself is available to instant book
  // AND (if the user is a TA and the feature is enabled for TAs
  //      OR if the user is an SR and the feature is enabled for SRs)
  const showInstantBookBadge =
    ((dynamicParameters.ENABLE_INSTANT_BOOK_TA_USERS && taCompany?.enableInstantBooking && isTa) ||
      (dynamicParameters.ENABLE_INSTANT_BOOK_INTERNAL_USERS && isSr));

  const PriceBreakdown = ({ total, totalBeforeDiscount, isOnRequest, roomRateType }) => {
    if (isOnRequest) {
      return <label>Price available on request</label>;
    }

    if (total !== totalBeforeDiscount) {
      return (
        <React.Fragment>
          {isLiveRatesEnabled && <RateTypeTag rateType={roomRateType} />}
          {showInstantBookBadge && lodging.availableToInstantBook && <InstantBookTag />}
          <LodgingTotal data-discounted={true}>
            {currencyCode}
            {formatPrice(total)}
          </LodgingTotal>
          <LodgingTotal data-secondary={true}>
            {currencyCode}
            {formatPrice(totalBeforeDiscount)}
          </LodgingTotal>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {isLiveRatesEnabled && <RateTypeTag rateType={roomRateType} />}
          {showInstantBookBadge && lodging.availableToInstantBook && <InstantBookTag />}
          <LodgingTotal>
            {currencyCode}
            {formatPrice(total)}
          </LodgingTotal>
        </React.Fragment>
      );
    }
  };

  // if lodgingTotals.total is 0, the lodging is still being added, so we need to render
  // a loading component
  if (lodgingTotals.total === '0' && lodgingTotals.isOnRequest === false) {
    return (
      <TableCardBox className="lodging-summary">
        <label>
          <strong>Loading new lodging data...</strong>
        </label>
      </TableCardBox>
    );
  }

  return (
    <div className={props.className}>
      <TableCardBox className="table-card-box lodging-summary">
        <TableCardRow className="table-card-row" depth={2}>
          <LodgingSummaryTitle>
            <strong className="room-title">{lodging.title}</strong>
            <LodgingTotalWrapper>
              <PriceBreakdown
                total={lodgingTotals.total}
                totalBeforeDiscount={lodgingTotals.totalBeforeDiscount}
                isOnRequest={lodgingTotals.isOnRequest}
                roomRateType={isLiveRate ? ERoomRateType.LIVE : ERoomRateType.STATIC}
              />
            </LodgingTotalWrapper>
          </LodgingSummaryTitle>
        </TableCardRow>

        <TableCardRow className="table-card-row" depth={3}>
          <DateCollapsible lodging={lodging} updateLodgingDatesAction={updateLodgingDatesAction} />
        </TableCardRow>

        <TableCardRow className="table-card-row" depth={3}>
          <OccupancyCollapsible
            canBeExpanded={!isLiveRate}
            lodging={lodging}
            updateLodgingGuestAgesAction={updateLodgingGuestAgesAction}
          />
        </TableCardRow>

        <TableCardRow className="table-card-row" depth={3}>
          <MealPlanCollapsible
            canBeExpanded={!isLiveRate}
            isLiveRate={isLiveRate}
            lodging={lodging}
            currencyCode={currencyCode}
            availableProductSets={availableProductSets}
            updateLodgingMealPlanAction={updateLodgingMealPlanAction}
          />
        </TableCardRow>

        {!isLiveRate && (
          <TableCardRow className="table-card-row" depth={3}>
            <OccasionsCollapsible lodging={lodging} updateLodgingOccasionsAction={updateLodgingOccasionsAction} />
          </TableCardRow>
        )}

        {isSr && !isLiveRate && (
          <TableCardRow className="table-card-row" depth={3}>
            <Label inline reverse text="Repeat Guest">
              <Checkbox
                className="repeatGuest"
                checked={lodging.repeatCustomer}
                onChange={() => {
                  updateLodgingRepeatGuestAction(lodging.hotelUuid, lodging.index, !lodging.repeatCustomer);
                }}
              />
            </Label>
          </TableCardRow>
        )}

        {appliedSupplements && appliedSupplements.length >= 1 && (
          <TableCardRow className="table-card-row " depth={3}>
            <CollapsibleSection>
              <CollapseHeader>
                <label>Applied Supplements</label>
                {appliedSupplements.map((s, i) => (
                  <Text key={i}>{s}</Text>
                ))}
              </CollapseHeader>
            </CollapsibleSection>
          </TableCardRow>
        )}

        {appliedOffers && appliedOffers.length >= 1 && (
          <TableCardRow className="table-card-row " depth={3}>
            <CollapsibleSection>
              <CollapseHeader>
                <label>Applied Offers</label>
                {appliedOffers.map((s, i) => (
                  <Text key={i} data-discounted="true">
                    {s}
                  </Text>
                ))}
              </CollapseHeader>
            </CollapsibleSection>
          </TableCardRow>
        )}

        <TableCardRow depth={3} className="table-card-row pb-4 flex flex-row-reverse">
          <ButtonSmall
            onClick={() => {
              removeLodgingAction(lodging.hotelUuid, lodging.index);
            }}
          >
            Remove Rooms
          </ButtonSmall>
        </TableCardRow>
      </TableCardBox>
    </div>
  );
};

// @ts-ignore
const ConnectedLodgingSummary = compose(connect)(LodgingSummaryRender);

export default styled(ConnectedLodgingSummary)`
  .table-card-box .table-card-row + .table-card-row {
    border-bottom: 1px solid ${pureUiTheme.colors.grayDark};
  }

  .repeatGuest {
    margin-right: 10px;
  }

  .number-select-wrapper {
    display: flex;
    & > label {
      flex: 1;
    }

    span.material-icons:hover {
      background: ${pureUiTheme.colors.teal};
    }
  }

  .child-age-selector {
    display: flex;

    & > label {
      flex: 1;
    }
  }
`;
