import { IFilter, ITypeCode } from "services/CrmApi/types/DirectoryEntry";
import { ISingleSelectOption } from "ui/SingleSelect";

export const SET_TYPE_FILTER = 'crm/SET_TYPE_FILTER';
export const SET_COUNTRY_FILTER = 'crm/SET_COUNTRY_FILTER';
export const SET_STAGE_FILTER = 'crm/SET_STAGE_FILTER';
export const SET_SCORE_FILTER = 'crm/SET_SCORE_FILTER';
export const SET_MANAGER_TEXT_FILTER = 'crm/SET_MANAGER_TEXT_FILTER';
export const SET_MANAGER_FILTER = 'crm/SET_MANAGER_FILTER';
export const SET_TERM_FILTER = 'crm/SET_TERM_FILTER';
export const RESET_WHOLE_FILTER = 'crm/RESET_WHOLE_FILTER';
export const IS_DIRTY_EDIT_COMPANY_PROFILE = 'crm/IS_DIRTY_EDIT_COMPANY_PROFILE';
export const IS_DIRTY_EDIT_CONTACT_PROFILE = 'crm/IS_DIRTY_EDIT_CONTACT_PROFILE';

export type SetTypeFilterAction = ReturnType<typeof setTypeFilterAction>;
export const setTypeFilterAction = (value: ITypeCode[]) => ({
  type: SET_TYPE_FILTER as typeof SET_TYPE_FILTER,
  value,
});  

export type SetCountryFilterAction = ReturnType<typeof setCountryFilterAction>;
export const setCountryFilterAction = (value: string[]) => ({
  type: SET_COUNTRY_FILTER as typeof SET_COUNTRY_FILTER,
  value,
});  

export type SetStageFilterAction = ReturnType<typeof setStageFilterAction>;
export const setStageFilterAction = (value: string | null) => ({
  type: SET_STAGE_FILTER as typeof SET_STAGE_FILTER,
  value,
});  

export type SetScoreFilterAction = ReturnType<typeof setScoreFilterAction>;
export const setScoreFilterAction = (value: number | null) => ({
  type: SET_SCORE_FILTER as typeof SET_SCORE_FILTER,
  value,
});     

export type SetManagerTextFilterAction = ReturnType<typeof setManagerTextFilterAction>;
export const setManagerTextFilterAction = (value: string) => ({
  type: SET_MANAGER_TEXT_FILTER as typeof SET_MANAGER_TEXT_FILTER,
  value,
});      

export type SetManagerFilterAction = ReturnType<typeof setManagerFilterAction>;
export const setManagerFilterAction = (value: string | null) => ({
  type: SET_MANAGER_FILTER as typeof SET_MANAGER_FILTER,
  value,
});     

export type SetTermFilterAction = ReturnType<typeof setTermFilterAction>;
export const setTermFilterAction = (value: string) => ({
  type: SET_TERM_FILTER as typeof SET_TERM_FILTER,
  value,
});    

export type ResetWholeFilterAction = ReturnType<typeof resetWholeFilterAction>;
export const resetWholeFilterAction = (value: IFilter) => ({
  type: RESET_WHOLE_FILTER as typeof RESET_WHOLE_FILTER,
  value,
});

export type SetIsDirtyEditCompanyProfileFormAction = ReturnType<typeof setIsDirtyEditCompanyProfileFormAction>;
export const setIsDirtyEditCompanyProfileFormAction = (value: boolean) => ({
  type: IS_DIRTY_EDIT_COMPANY_PROFILE as typeof IS_DIRTY_EDIT_COMPANY_PROFILE,
  value,
});

export type SetIsDirtyEditContactProfileFormAction = ReturnType<typeof setIsDirtyEditContactProfileFormAction>;
export const setIsDirtyEditContactProfileFormAction = (value: boolean) => ({
  type: IS_DIRTY_EDIT_CONTACT_PROFILE as typeof IS_DIRTY_EDIT_CONTACT_PROFILE,
  value,
});

export type CrmAction =
  | SetTypeFilterAction
  | SetCountryFilterAction
  | SetStageFilterAction
  | SetScoreFilterAction
  | SetManagerTextFilterAction
  | SetManagerFilterAction
  | SetTermFilterAction
  | ResetWholeFilterAction
  | SetIsDirtyEditCompanyProfileFormAction
  | SetIsDirtyEditContactProfileFormAction;
