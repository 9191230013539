import { useMutation } from "@tanstack/react-query";
import { makeCRMApi } from "..";
import { IUpdateLeadRequest } from "../types/CrmLeadTypes";

export const useUpdateLeadMutation = () => {
  const crmApi = makeCRMApi();
  
  const updateLeadMutation = useMutation({
    mutationKey: ['crm-update-lead'],
    mutationFn: (requestData: IUpdateLeadRequest) => crmApi.updateLead(requestData),
  });
  
  return updateLeadMutation;
}
