import { isNil } from 'lodash-es';
import * as Yup from 'yup';

export const isEmptyOrNil = (value) => isNil(value) || value === '';

export const isNoneSelected = (values: ICompanyCreateFormValues) : boolean => {
  return isEmptyOrNil(values.existingContactId) && (isEmptyOrNil(values.contactFirstName) || isEmptyOrNil(values.contactType));
}

export const isBothSelected = (values: ICompanyCreateFormValues | ILinkedContactFormValues) : boolean => {
  return !isEmptyOrNil(values.existingContactId) && !isEmptyOrNil(values.contactFirstName) && !isEmptyOrNil(values.contactType);
}

export const companyCreateCommon = Yup.object().shape({
  type: Yup.string().required('This field is required'),
  name: Yup.string().required('This field is required'),
}).test({
  test: function(values) {
    // https://github.com/jaredpalmer/formik/issues/2146
    const noneSelected = isNoneSelected(values);
    const bothSelected = isBothSelected(values);
    if (noneSelected) {
      return this.createError({
        path: 'at-least-one',
        message: 'Contact Info is mandatory',
      });
    }
    if (bothSelected) {
      return this.createError({
        path: 'both-not-allowed',
        message: 'Select Existing or create new, but not both options.',
      });
    }
    return true;
  }
});

export const addExistingContactValidator = (
  Yup.object().shape({
    existingContactId: Yup.string().required('This field is required'),
  })
).concat(companyCreateCommon);

export const addNewContactValidator = (
  Yup.object().shape({
    contactType: Yup.string().nullable(true).required('This field is required'),
    contactFirstName: Yup.string().required('This field is required'),
    contactEmail: Yup.string().email('Must be valid email'),
  })
).concat(companyCreateCommon);

export interface ILinkedContactFormValues {
  contactType: string | null;
  contactTitle: string | null;
  contactFirstName: string;
  contactLastName: string;
  contactEmail: string;
  contactMobile: string;
  contactLandline: string;
  existingContactId: string | null;
}

export interface ICompanyCreateFormValues extends ILinkedContactFormValues {
  type: string;
  name: string;
  country: string | null;
  keyNotes: string | null;  
}