import React, { memo } from 'react';
import { compose, pipe, values, when, complement, isNil } from 'ramda';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';

import { useCurrentWidth, useFetchData } from 'effects';
import { mapWithIndex } from 'utils';

import Offer from 'containers/OfferPromo';

import connect from './LatestOffers.state';
import { Title, Offers } from './LatestOffers.styles';
import { Loader } from 'components/Loader/Loader';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';
import styled from 'styled-components';

const renderOffer = (offer, i) => <Offer key={i} id={offer} />;

const renderOffers = when(complement(isNil), pipe(mapWithIndex(renderOffer), values));

const renderOfferContainer = ({ offers, isMobile }) => <Offers>{renderOffers(offers)}</Offers>;

const StyledCarousel = styled(Carousel)`
  .slide {
    background: white !important;
  }
`;
export const LatestOffers = ({ fetchLatestOffers, offers, offersStatus }) => {
  const { t } = useTranslation();
  const offersFetched = useFetchData(offersStatus, fetchLatestOffers, []);

  const { isMobile } = useCurrentWidth();

  console.log('isMobile', isMobile);
  return (
    <div className="container mx-auto max-w-1280px p-0">
      {offers.length ? <Title>{t('sections.latestOffers')}</Title> : null}
      <Loader isLoading={!offersFetched}>
        <div>
          {!isMobile && <div className="flex flex-wrap justify-center">{renderOffers(offers)}</div>}
          {isMobile && (
            <StyledCarousel
              infiniteLoop={true}
              showStatus={false}
              showIndicators={false}
              showThumb={false}
              showArrows={false}
            >
              {renderOffers(offers)}
            </StyledCarousel>
          )}
        </div>
      </Loader>
    </div>
  );
};

export default compose(connect, memo)(LatestOffers);
