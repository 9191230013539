import { useQuery } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { IBootstrapCountry } from 'services/BackendApi/types/country';
import { makeBackendApi } from '../BackendApi';
import { IPage } from '../types';

interface IUseBEUsersOptions {
  sort?: string;
  page?: IPage;
}

export const useBEUsers = (filterTerm: string, options?: IUseBEUsersOptions) => {
  const backendApi = makeBackendApi();

  const beUsers = useQuery<AxiosResponse>({
    queryKey: ['be-users', filterTerm],
    queryFn: () =>
      backendApi.searchUsersSimple(filterTerm, options?.sort ?? 'firstName', options?.page),
  });

  return beUsers;
}
