import { createSelector } from 'reselect';
import { ICrmDomain } from './model';
import { ITypeCode, IFilter } from 'services/CrmApi/types/DirectoryEntry';


export const crmDomain = (state: any): ICrmDomain => state.crm;

export const typesFilterSelector = createSelector(
  crmDomain,
  (domain: ICrmDomain): ITypeCode[] => domain.filters.typeCodes
);

export const countriesFilterSelector = createSelector(
  crmDomain,
  (domain: ICrmDomain): string[] => domain.filters.countryCodes
);

export const stagesFilterSelector = createSelector(
  crmDomain,
  (domain: ICrmDomain): string | null => domain.filters.leadStageCode
);

export const scoresFilterSelector = createSelector(
  crmDomain,
  (domain: ICrmDomain): number | null => domain.filters.leadScoreGte
);

export const managerFilterSelector = createSelector(
  crmDomain,
  (domain: ICrmDomain): string | null => domain.filters.managerId
);

export const managerTextFilterSelector = createSelector(
  crmDomain,
  (domain: ICrmDomain): string => domain.data.managerText
);

export const wholeFilterSelector = createSelector(
  crmDomain,
  (domain: ICrmDomain): IFilter => domain.filters
);

export const termFilterSelector = createSelector(
  crmDomain,
  (domain: ICrmDomain): string => domain.filters.term
);

export const isDirtyEditCompanyProfileSelector = createSelector(
  crmDomain,
  (domain: ICrmDomain): boolean => domain.data.isDirtyEditCompanyProfile
);

export const isDirtyEditContactProfileSelector = createSelector(
  crmDomain,
  (domain: ICrmDomain): boolean => domain.data.isDirtyEditContactProfile
);