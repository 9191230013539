import { debounce } from "lodash-es";
import { useCallback, useEffect, useState } from "react";

/*
Due to inconsistencies in Figma designs,
designs for different features use different breakpoints.
This set of breakpoitns is used by TMS and CRM.
*/
const CRM_BREAKPOINTS = {
  mobile: 768,
  tablet: 1439,
  desktop: 1919,
  desktopHD: Number.MAX_SAFE_INTEGER,
};

const windowExists = typeof window !== 'undefined' ? window : { innerWidth: 0 };

/**
 * useResponsive
 *
 * Custom hook that returns the current width of the window and
 * whether the window isMobile, isTablet, isDesktop, isDesktopHD
 *
 * @returns {object}
 */
export const useResponsive = () => {
  const [currentWidth, setCurrentWidth] = useState(windowExists.innerWidth || 0);
  const updateWidth = useCallback(debounce(() => {
    setCurrentWidth(windowExists.innerWidth || 0)
  }, 500), []);

  useEffect(() => {
    window.addEventListener('resize', updateWidth);

    () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [updateWidth]);
  
  const isMobile = currentWidth <= CRM_BREAKPOINTS.mobile;
  const isTablet = currentWidth > CRM_BREAKPOINTS.mobile && currentWidth <= CRM_BREAKPOINTS.tablet;
  const isDesktop = currentWidth > CRM_BREAKPOINTS.tablet && currentWidth <= CRM_BREAKPOINTS.desktop;
  const isDesktopHD = currentWidth > CRM_BREAKPOINTS.desktop && currentWidth <= CRM_BREAKPOINTS.desktopHD;

  return { currentWidth, isMobile, isTablet, isDesktop, isDesktopHD };
};