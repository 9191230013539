import React, { useCallback, useState } from 'react'
import AnimateHeight from 'react-animate-height';
import classnames from 'classnames';
import { theme } from '../../../../../tailwind.config';
import { ArrowDownComponent } from 'ui/Icons';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';

interface ICollapsibleHeaderProps {
  title: React.ReactNode;
  children: React.ReactNode;
  isOpenInitially?: boolean;
}

export const CollapsibleHeader: React.FC<ICollapsibleHeaderProps> = ({ title, children, isOpenInitially = false }) => {
  const { isDesktop, isDesktopHD } = useResponsive();
  const [isOpen, setOpen] = useState(isOpenInitially);

  const collapsibleHeaderClassname = classnames('collapsible-info-container ', {
    'min-w-[320px]': isDesktop || isDesktopHD,
  })

  const toggle = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  return (
    <div className={collapsibleHeaderClassname}>
      <div className="title-container font-pt-sans flex justify-between items-baseline cursor-pointer py-[10px]" onClick={toggle}>
        <div className="section-title font-pt-sans text-[17px] leading-normal text-black  m-0 select-none">
          {title}
        </div>
        <span
          className={classnames(
            'flex justify-center items-center transform-transition transition-ease-in-out duration-300 cursor-pointer',
            { 'rotate-180': isOpen }
          )}
        >
          <ArrowDownComponent icon={theme.colors['black']} />
        </span>
      </div>
      <AnimateHeight duration={300} height={isOpen ? 'auto' : 0} className="accordion-area">
        {children}
      </AnimateHeight>
    </div>
  )
}