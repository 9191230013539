import React, { useCallback, useState } from 'react';
import { Price } from 'ui/Price';
import { ProductLineWithPrice } from './ProductLineWithPrice';
import { BookingBuilder, EUploadTag, ICompany, ProductSetAccommodation } from 'services/BackendApi';
import { PlainDates } from './PlainDates';
import { ERateType, RateTag } from 'ui/RateTag/RateTag';
import { isNil, uniq } from 'lodash-es';
import { LodgingSummary } from 'interfaces';
import * as AuthSelectors from 'store/modules/auth/selectors';
import { addDays, format } from 'date-fns';
import { useSelector } from 'react-redux';
import { companyDataSelector } from 'store/modules/companyInfo';
import { isRefundable as isRefundableFn } from 'common-lib/cancellation-policy-composer';
import { formatDateDisplay, isAdult, numberOfNights, offsetDate } from 'utils';
import { ERefundabilityTagStatus, RefundabilityTag } from 'ui/RefundabilityTag/RefundabilityTag';
import { IBasketBuildL4 } from 'services/BackendApi/types/Basket';
import { SvgIcon } from 'ui/SvgIcon';
import MapIcon from 'ui/Icons/components/Map.component';
import { theme } from '../../../tailwind.config';
import ArticleIcon from 'ui/Icons/components/Article.component';
import PictureIcon from 'ui/Icons/components/Picture.component';
import VideoIcon from 'ui/Icons/components/Video.component';
import { openLink } from './helpers';
import { EVideoPlatform, VideoModal } from 'components/AccommodationCard/VideoModal';
import { BasketHotelPhotoGalleryModal } from './BasketHotelPhotoGalleryModal';
import { pluralize } from 'utils/string';
import {
  AdultComponent as AdultIcon,
  RoomSizeComponent as RoomSizeIcon,
} from 'ui/Icons';
import { VerticalLine } from 'ui/VerticalLine';
import { HidingTooltip } from 'ui/Tooltip';
import { useTranslation } from 'react-i18next';
import { OtherOptions } from './OtherOptions';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';
import { IOnHoldPerRoomInfo } from './BasketItem';
import { OnHoldInfoSecondary } from './OnHoldInfoSecondary';

interface IBookingAccommodationProps {
  booking: BookingBuilder;
  accommodation: ProductSetAccommodation;
  lodgingSummaries: LodgingSummary[];
  startDate: string;
  endDate: string;
  accommodationIndex: number;
  build: IBasketBuildL4;
  onHoldInfo: IOnHoldPerRoomInfo | undefined;
}

export const BookingAccommodation: React.FC<IBookingAccommodationProps> = React.memo(
  ({ booking, startDate, endDate, lodgingSummaries, accommodation, accommodationIndex, build, onHoldInfo }) => {
    const { t } = useTranslation();
    const company = useSelector(companyDataSelector) as ICompany;
    const isTa: boolean = useSelector(AuthSelectors.isTA);
    const selectedMealPlan = accommodation.availableSubProductSets['Meal Plan'].find(item => item.selected)!;
    const mealPlanOtherOptions = accommodation.availableSubProductSets['Meal Plan']
      .filter(item => !item.selected)!
      .map(mp => ({ title: mp.products[0].name, price: mp.total, currencyCode: booking.response.currency, isOnRequest: mp.isOnRequestOrPartiallyOnRequest }));
    const selectedMealPlanDetails = {
      description: selectedMealPlan.products
        .map(item => {
          if (item.meta.description) {
            return `${item.name}\n${item.meta.description}`;
          }
          return item.name;
        })
        .join('\n'),
      title: 'Meal Plan Details',
      uploads: (build?.uploads || []).filter(item => selectedMealPlan.products.map(item => item.uuid).includes(item.ownerUuid)),
    };

    const extraPersonSupplements = accommodation.availableSubProductSets['Supplement'].filter(item => item.selected);

    const priceClassName = 'font-hurmegeometric-sans text-15px leading-18px text-right mt-5px m-0';
    const isInstantBook =
      lodgingSummaries[accommodationIndex].availableToInstantBook && (!isTa || (isTa && company?.enableInstantBooking));
    const requestedAccommodation = booking.request.Accommodation[accommodationIndex];
    const offers = booking.response.appliedOfferNames;

    const expenseInfo = booking.response.expenseInfosGroupedByRoom?.[accommodationIndex];
    const isRefundable = isNil(expenseInfo) ? false : isRefundableFn(expenseInfo);

    const [isPhotoModalOpen, setPhotoModalOpen] = useState(false);
    const roomPhotos = build.uploads?.filter(
      item =>
        [EUploadTag.PHOTO, EUploadTag.FEATURED_PHOTO].includes(item?.tag as EUploadTag) &&
        item?.ownerUuid === accommodation.products[0].uuid
    );
    const [isVideoModalOpen, setVideoModalOpen] = useState(false);

    const floorPlan = build.uploads?.find(
      item => item?.tag === EUploadTag.FLOOR_PLAN && item?.ownerUuid === accommodation.products[0].uuid
    );
    const handleFloorPlanClick = useCallback(() => {
      floorPlan?.url && openLink(floorPlan.url);
    }, [floorPlan?.url, floorPlan]);
    const roomInfoPdf = build.uploads?.find(
      item => item?.tag === EUploadTag.ACCOMMODATION_INFORMATION && item?.ownerUuid === accommodation.products[0].uuid
    );

    const renderOccupancyTooltip = useCallback(() => {
      return (
        <div
          className="occupancy-tooltip text-13px leading-16px text-black p-10px shadow-pe6"
          style={{ maxWidth: '1600px' }}
        >
          {accommodation.occupancyResult.occupancy.limits.map(limit => {
            const { name, minimum, maximum } = limit;
            const groupName = isAdult(name) ? t('adult_plural') : t(`${name}_plural`) || name;
            const limitText = `${groupName} - ${t('labels.max')} ${maximum} ${t('labels.min')} ${minimum}`;
            return (
              <span className="whitespace-nowrap" key={limit.name}>
                {limitText} <br />
              </span>
            );
          })}
        </div>
      );
    }, [accommodation.occupancyResult.occupancy.limits, t]);

    const handleRoomInfoPdfClick = useCallback(() => {
      roomInfoPdf?.url && openLink(roomInfoPdf?.url);
    }, [roomInfoPdf]);
    const openPhotoModal = useCallback(() => {
      setPhotoModalOpen(true);
    }, []);
    const closePhotoModal = useCallback(() => {
      setPhotoModalOpen(false);
    }, []);
    const openVideoModal = useCallback(() => {
      setVideoModalOpen(true);
    }, []);
    const closeVideoModal = useCallback(() => {
      setVideoModalOpen(false);
    }, []);

    const selectedMealPlanHasDescription = selectedMealPlan.products.some(item => item.meta.description);

    return (
      <div className="confirmation-accommodation px-5 py-15px border-solid border-b border-gray-20">
        <div className="flex justify-between items-center">
          <p className="accommodation-title font-hurmegeometric-sans text-flint text-13px leading-16px uppercase font-bold m-0">
            {accommodation.products[0].name}
          </p>          
          {onHoldInfo && (
            <OnHoldInfoSecondary isValid={onHoldInfo.isValid} systemBookingId={onHoldInfo.systemBookingId}/>
          )}
        </div>
        {(accommodation.products[0].meta.size || accommodation.occupancyResult.occupancy) && (
          <div className="occupancy-and-size flex items-center gap-[10px] h-[24px]">
            {accommodation.products[0].meta.size && (
              <>
                <RoomSizeIcon icon={theme.colors['brown-100']} />
                <span className="text-sm leading-17px text-gold-light">{accommodation.products[0].meta.size} sq. m.</span>
              </>
            )}
            {(accommodation.products[0].meta.size && accommodation.occupancyResult.occupancy) && (<VerticalLine height="15px" color={theme.colors['gray-18']} />)}
            {accommodation.occupancyResult.occupancy && (
              <>
                <HidingTooltip renderTooltipContent={renderOccupancyTooltip} position="bottom-right">
                  <span className="flex">
                    <AdultIcon icon={theme.colors['brown-100']} />
                  </span>
                </HidingTooltip>
                <span className="text-sm leading-17px text-gold-light">
                  x{accommodation.occupancyResult.occupancy.standardOccupancy} (max. {accommodation.occupancyResult.occupancy.maximumPeople})
                </span>
              </>
            )}
          </div>
        )}
        {(floorPlan?.url || roomInfoPdf?.url || roomPhotos.length > 0 || accommodation.products[0].vimeoVideoId) && (
          <div className="extra-details-icons flex justify-start items-center mt-[7px] mb-[12px] gap-[15px] ml-[-5px]">
            {floorPlan?.url ? (
              <div
                className="extra-detail-item flex items-center gap-[3px] cursor-pointer"
                onClick={handleFloorPlanClick}
              >
                <span className="flex justify-center items-center cursor-pointer w-6 h-6 hover:bg-gray-20">
                  <SvgIcon
                    IconComponent={MapIcon}
                    defaultFill={theme.colors['brown-100']}
                    hoverFill={theme.colors['brown-hard']}
                    defaultBackground={theme.colors['transparent']}
                    hoverBackground={theme.colors['gray-20']}
                    width="16px"
                    height="16px"
                  />
                </span>
                <span className="font-hurmegeometric-sans text-[13px] leading-[20px] text-brown-100 underline select-none">
                  Floor Plan
                </span>
              </div>
            ) : null}
            {roomInfoPdf?.url ? (
              <div
                className="extra-detail-item flex items-center gap-[3px] cursor-pointer"
                onClick={handleRoomInfoPdfClick}
              >
                <HidingTextTooltip tooltipContent="Room Info / Bedding" position="bottom-right" tooltipContentClassname="pdf-tooltip">
                  <span className="flex justify-center items-center cursor-pointer w-6 h-6 hover:bg-gray-20">
                    <SvgIcon
                      IconComponent={ArticleIcon}
                      defaultFill={theme.colors['brown-100']}
                      hoverFill={theme.colors['brown-hard']}
                      defaultBackground={theme.colors['transparent']}
                      hoverBackground={theme.colors['gray-20']}
                      width="16px"
                      height="16px"
                    />
                  </span>
                </HidingTextTooltip>
                <span className="font-hurmegeometric-sans text-[13px] leading-[20px] text-brown-100 underline select-none">
                  Amenities
                </span>
              </div>
            ) : null}
            {roomPhotos.length > 0 && (
              <div className="extra-detail-item flex items-center gap-[3px] cursor-pointer" onClick={openPhotoModal}>
                <span className="flex justify-center items-center cursor-pointer w-6 h-6 hover:bg-gray-20">
                  <SvgIcon
                    IconComponent={PictureIcon}
                    defaultFill={theme.colors['brown-100']}
                    hoverFill={theme.colors['brown-hard']}
                    defaultBackground={theme.colors['transparent']}
                    hoverBackground={theme.colors['gray-20']}
                    width="16px"
                    height="16px"
                  />
                </span>
                <span className="font-hurmegeometric-sans text-[13px] leading-[20px] text-brown-100 underline select-none">
                  Pictures
                </span>
              </div>
            )}
            {accommodation.products[0].vimeoVideoId && (
              <div className="extra-detail-item flex items-center gap-[3px] cursor-pointer" onClick={openVideoModal}>
                <span className="flex justify-center items-center cursor-pointer w-6 h-6 hover:bg-gray-20">
                  <SvgIcon
                    IconComponent={VideoIcon}
                    defaultFill={theme.colors['brown-100']}
                    hoverFill={theme.colors['brown-hard']}
                    defaultBackground={theme.colors['transparent']}
                    hoverBackground={theme.colors['gray-20']}
                    width="16px"
                    height="16px"
                  />
                </span>
                <span className="font-hurmegeometric-sans text-[13px] leading-[20px] text-brown-100 underline select-none">
                  Video
                </span>
              </div>
            )}
          </div>
        )}
        <div className="flex justify-between items-center">
          <div className="refundability-tag">
            {isRefundable !== null && (
              <RefundabilityTag
                refundabilityStatus={
                  isRefundable ? ERefundabilityTagStatus.REFUNDABLE : ERefundabilityTagStatus.NON_REFUNDABLE
                }
                className="acc-free-sale-rates-tag flex items-center m-0"
              />
            )}
          </div>
          {isInstantBook ? <RateTag rateType={ERateType.INSTANT_BOOK} /> : null}
        </div>
        <div className="accommodation-data flex items-stretch justify-between">
          <div className="accommodation-data-left">
            <div className="dates-line flex justify-start mt-5px">
              <p className="booking-dates-container booking-adults font-hurmegeometric-sans text-13px leading-16px text-flint uppercase m-0 flex gap-1 lg:items-center flex-col lg:flex-row">
                <span className="booking-dates font-bold">
                  {formatDateDisplay(requestedAccommodation.startDate)} -{' '}
                  {formatDateDisplay(format(addDays(offsetDate(requestedAccommodation.endDate), 1), 'yyyy-MM-dd'))}
                </span>
                <span className="inline-block lg:inline booking-nights bg-teal-20 ml-0 lg:ml-5px p-2px">
                  {numberOfNights(
                    requestedAccommodation.startDate,
                    format(addDays(offsetDate(requestedAccommodation.endDate), 1), 'yyyy-MM-dd')
                  )}{' '}
                  {pluralize(
                    numberOfNights(
                      requestedAccommodation.startDate,
                      format(addDays(offsetDate(requestedAccommodation.endDate), 1), 'yyyy-MM-dd')
                    ),
                    'NIGHT'
                  )}
                </span>
                <span>
                  {requestedAccommodation.guestAges?.numberOfAdults} x{' '}
                  {requestedAccommodation.guestAges?.numberOfAdults === 1 ? 'ADULT' : 'ADULTS'}
                </span>
              </p>
              {requestedAccommodation.guestAges?.agesOfAllChildren?.length ? (
                <p className="booking-children font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-5 m-0">
                  {requestedAccommodation.guestAges.agesOfAllChildren?.length} x{' '}
                  {requestedAccommodation.guestAges.agesOfAllChildren?.length === 1 ? 'CHILD' : 'CHILDREN'}
                </p>
              ) : null}
              {requestedAccommodation.guestAges.agesOfAllChildren?.length >= 1 && (
                <span className="font-hurmegeometric-sans text-13px leading-16px text-flint uppercase ml-2 m-0">
                  ({requestedAccommodation.guestAges.agesOfAllChildren.map(ageNum => `${ageNum}`).join(', ')} YO)
                </span>
              )}
            </div>
            {offers.map((offer, offerIndex) => (
              <div
                key={`offer-${accommodation.products[0].uuid}${offerIndex}`}
                className="offer-item font-hurmegeometric-sans text-13px leading-16px text-red-92 uppercase mt-[5px] mr-[30px]"
              >
                {offer}
              </div>
            ))}
          </div>
          <div className="accommodation-data-right">
            <Price
              total={accommodation.total}
              totalBeforeDiscount={accommodation.totalBeforeDiscount}
              currencyCode={booking.response.currency}
              isOnRequest={accommodation.isOnRequestOrPartiallyOnRequest}
              totalClassName={`${priceClassName} text-flint`}
              totalBeforeDiscountClassName={`${priceClassName} text-gold-light line-through`}
              totalAfterDiscountClassName={`${priceClassName} text-red-15`}
              isOnRequestClassName={`${priceClassName} text-flint`}
              offersAppliedClassName="h-full justify-between"
            />
          </div>
        </div>

        {selectedMealPlan && (
          <div className="meal-plans mt-[10px]">
            <ProductLineWithPrice
              name={selectedMealPlan.products.map(p => p.name).join(', ')}
              total={selectedMealPlan.total}
              totalBeforeDiscount={selectedMealPlan.totalBeforeDiscount}
              currencyCode={booking.response.currency}
              isOnRequest={selectedMealPlan.isOnRequestOrPartiallyOnRequest}
              className="meal-plan"
              details={selectedMealPlanDetails}
              showDescription={selectedMealPlanHasDescription}
            />
          </div>
        )}
        {mealPlanOtherOptions.length > 0 && <OtherOptions options={mealPlanOtherOptions}/>}
        {extraPersonSupplements.length > 0 &&
          extraPersonSupplements.map(supplement => {
            return (
              <div key={supplement.products[0].uuid} className="meal-plans mt-[10px]">
                <ProductLineWithPrice
                  name={supplement.products.map(p => p.name).join(', ')}
                  total={supplement.total}
                  totalBeforeDiscount={supplement.totalBeforeDiscount}
                  currencyCode={booking.response.currency}
                  isOnRequest={supplement.isOnRequestOrPartiallyOnRequest}
                  className="supplement"
                />
              </div>
            );
          })}

        {isPhotoModalOpen && roomPhotos?.length > 0 && (
          <BasketHotelPhotoGalleryModal
            title={accommodation.products[0].name}
            photos={roomPhotos}
            onClose={closePhotoModal}
          />
        )}

        {isVideoModalOpen && accommodation.products[0].vimeoVideoId && (
          <VideoModal
            videoId={accommodation.products[0].vimeoVideoId}
            title={accommodation.products[0].name}
            type={EVideoPlatform.VIMEO}
            onClose={closeVideoModal}
          />
        )}
      </div>
    );
  }
);
