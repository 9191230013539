import { call, takeLatest, put, select } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
  BAM_BOOKING_BUILD_REQUEST,
  bamBookingBuildFailureAction,
  BAMBookingBuildRequestAction,
  bamBookingBuildSuccessAction,
  setBAMLastRequestedBuildAction,
} from '../actions';
import { subDays, format } from 'date-fns';

import { BookingBuilderRequest, BookingBuilderResponse, makeBackendApi } from 'services/BackendApi';
import { IBookingCountryResponse, IOwnershipResponse, makeBookingManagerApi } from 'services/BookingManagerApi';
import * as BreakdownSelectors from '../selectors';
import { IStaticRate } from 'ui/AddAccommodationModal/MealPlanDropdown/types';
import { _subDays, tDateString } from 'utils';

export function* bookingBuildSaga(action: BAMBookingBuildRequestAction) {
  try {
    // we've got a React/Redux race condition due to how we're calling actions
    // and re. this saga. I don't know the exact cause, but the fact we were doing
    // API calls in this saga STOPPED the bug happening. Now I've got rid of them, the
    // race condition manifests. This Promise.resolve() is a hack to get around it.
    // Without this Promise.resolve(), the selectedMealPlan below is stale.
    yield Promise.resolve();
    //
    const selectedBuild = ((yield select(
      BreakdownSelectors.BAMSelectedBuildSelector
    )) as unknown) as BookingBuilderResponse;
    const selectedAccommodation = selectedBuild.availableProductSets.Accommodation[0];
    const ownershipData: IOwnershipResponse = yield select(BreakdownSelectors.BAMOwnershipDataSelector);
    const travelAgentUuid = ownershipData.ownershipData.taInfo.uuid;
    const bookingCountryData: IBookingCountryResponse = yield select(BreakdownSelectors.BAMCountryDataSelector);
    const clientCountryCode = bookingCountryData.countryCode;

    const backendApi = makeBackendApi(travelAgentUuid);
    const startDate = yield select(BreakdownSelectors.BAMStartDateFilterSelector);
    const endDate = yield select(BreakdownSelectors.BAMEndDateFilterSelector);
    const hotelUuid = yield select(BreakdownSelectors.BAMHotelFilterSelector);
    const selectedOccasions = yield select(BreakdownSelectors.BAMSelectedOccasionsSelector);
    const selectedMealPlan = (yield select(BreakdownSelectors.BAMSelectedMealPlansSelector)) as IStaticRate;
    const repeatCustomer = yield select(BreakdownSelectors.BAMSelectedRepeatGuestSelector);
    const guestAges = yield select(BreakdownSelectors.BAMGuestAgesFilterSelector);

    const transfers = yield select(BreakdownSelectors.BAMSelectedTransfersSelector);
    const groundServiceUuids = yield select(BreakdownSelectors.BAMSelectedGroundServicesSelector);
    const finesUuids = yield select(BreakdownSelectors.BAMSelectedFinesSelector);
    const otherItemsUuids = yield select(BreakdownSelectors.BAMSelectedOtherItemsSelector);

    let liveRate: {
      externalRateId: string;
      externalMealPlanCode: string;
      amount: number;
    } | null = null;

    if (selectedBuild.availableProductSets.Accommodation[0].breakdown[0].isLiveRate) {
      liveRate = {
        externalRateId: selectedBuild.availableProductSets.Accommodation[0].breakdown[0].externalRateId!,
        externalMealPlanCode: selectedBuild.availableProductSets.Accommodation[0].breakdown[0].externalMealPlanCode!,
        amount: selectedBuild.totals.totalForPricedItemsCents,
      };
    }
    const bookingBuilderRequest: BookingBuilderRequest = {
      startDate,
      endDate: _subDays(endDate as tDateString, 1),
      guestAges,
      hotelUuid,
      Accommodation: [
        {
          uuid: selectedAccommodation.products[0].uuid,
          guestAges,
          repeatCustomer,
          startDate,
          endDate: _subDays(endDate as tDateString, 1),
          subProducts:
            liveRate === null
              ? {
                  'Meal Plan': selectedMealPlan.mealPlan.uuids.map(item => ({ uuid: item })),
                  Supplement: [],
                }
              : undefined,
          honeymoon: selectedOccasions.includes('honeymoon'),
          anniversary: selectedOccasions.includes('anniversary'),
          wedding: selectedOccasions.includes('wedding'),
          birthday: selectedOccasions.includes('birthday'),
          // @ts-ignore
          liveRate: liveRate ? liveRate : undefined,
        },
      ],
      Transfer: transfers.map(item => {
        if (item.direction === 'return') {
          return { uuid: item.uuid };
        } else {
          return item;
        }
      }),
      customItems: [],
      'Ground Service': groundServiceUuids.map((item: string) => ({ uuid: item })),
      Supplement: otherItemsUuids.map((item: string) => ({ uuid: item })),
      Fine: finesUuids.map((item: string) => ({ uuid: item })),
    };

    const response: AxiosResponse<{ data: BookingBuilderResponse }> = yield call(backendApi.postBookingBuilderRequest, {
      bookingBuilderRequest,
      clientCountryCode,
      travelAgentUuid,
      queryParams: {
        isForBookingBreakdown: true,
      },
    });

    yield put(setBAMLastRequestedBuildAction(bookingBuilderRequest));

    yield put(bamBookingBuildSuccessAction(response.data.data));
  } catch (e) {
    yield put(bamBookingBuildFailureAction(e));
    console.error('Error', e);
  }
}

export function* watchBookingBuildSaga() {
  yield takeLatest([BAM_BOOKING_BUILD_REQUEST], bookingBuildSaga);
}
