import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { pureEscapesLogo, arrowNext as iconArrowNext, arrowPrev as iconArrowPrev } from 'ui/Icons';
import { ImagesCarouselStyled } from './ImagesCarouselStyled';
import classNames from 'classnames';

interface iImagesCarouselProps {
  images: {
    displayName: string;
    url: string;
  }[];
  showThumbnails: boolean;
  showArrows?: boolean;
  showStatus?: boolean;
  wrapperClassName?: string;
  carouselImageClassName?: string;
  thumbnailsCarouselClassName?: string;
}

export const ImagesCarousel: React.FC<iImagesCarouselProps> = ({
  images,
  showArrows = true,
  showStatus = true,
  showThumbnails = false,
  wrapperClassName = '',
  carouselImageClassName = '',
  thumbnailsCarouselClassName = '',
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const thumbnailsContainerRef = useRef<HTMLDivElement>(null);
  const thumbnailRefs = useRef<(HTMLDivElement | null)[]>([]);

  // Reset thumbnail refs array when images change
  useEffect(() => {
    thumbnailRefs.current = images.map(() => null);
  }, [images]);

  const renderPureEscapesLogo = () => {
    return (
      <div className="w-full flex justify-center flex-col items-center bg-brown-20">
        <img
          className="empty-photo block object-cover"
          src={pureEscapesLogo}
          width="82"
          height="82"
          alt="Pure Escapes logo"
        />
      </div>
    );
  };

  const carouselLabels = useMemo(
    () => ({
      leftArrow: '',
      rightArrow: '',
      item: '',
    }),
    []
  );

  const carouselStatusFormatter = useCallback((currentItem: number, total: number) => `${currentItem}/${total}`, []);

  const renderArrowNext = useCallback(
    (clickHandler: () => void) => (
      <div className="carousel-arrow-next select-none" onClick={clickHandler}>
        <img src={iconArrowNext} width="14" height="12" alt="Next picture" />
      </div>
    ),
    []
  );

  const renderArrowPrev = useCallback(
    (clickHandler: () => void) => (
      <div className="carousel-arrow-prev select-none" onClick={clickHandler}>
        <img src={iconArrowPrev} width="14" height="12" alt="Previous picture" />
      </div>
    ),
    []
  );

  const handleThumbnailClick = (index: number) => {
    setCurrentSlide(index);
  };

  // Improved scroll function to ensure the selected thumbnail is fully visible
  // and show a peek of the next thumbnail
  const scrollToThumbnail = (index: number) => {
    const container = thumbnailsContainerRef.current;
    const thumbnail = thumbnailRefs.current[index];

    if (!container || !thumbnail) return;

    const containerWidth = container.offsetWidth;
    const thumbnailWidth = thumbnail.offsetWidth;
    const thumbnailLeft = thumbnail.offsetLeft;

    // Calculate position that places the thumbnail fully in view
    // with a slight offset to show a peek of the next thumbnail
    const peekAmount = 60; // Amount of next thumbnail to show (in pixels)
    let targetScroll = thumbnailLeft - containerWidth / 2 + thumbnailWidth / 2;

    // Adjust to show a peek of the next thumbnail if not the last one
    if (index < images.length - 1) {
      targetScroll = thumbnailLeft - (containerWidth - thumbnailWidth - peekAmount);
    }

    // Ensure we don't scroll beyond the beginning
    targetScroll = Math.max(0, targetScroll);

    // Ensure we don't scroll beyond the end (minus the peek amount if applicable)
    const maxScroll = container.scrollWidth - containerWidth;
    targetScroll = Math.min(targetScroll, maxScroll);

    container.scrollTo({
      left: targetScroll,
      behavior: 'smooth',
    });
  };

  // Ensure the current thumbnail is in view when the slide changes
  useEffect(() => {
    scrollToThumbnail(currentSlide);
  }, [currentSlide]);

  const renderThumbnails = () => {
    if (images.length === 0) return null;

    return (
      <div className="relative w-full mt-2">
        <div
          ref={thumbnailsContainerRef}
          className="flex gap-2 overflow-x-auto scroll-smooth hide-scrollbar"
          style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
          }}
        >
          {images.map((image, index) => (
            <div
              key={`${image.url}-${index}`}
              ref={el => {
                thumbnailRefs.current[index] = el;
              }}
              className={classNames(
                `flex-shrink-0 cursor-pointer ${
                  currentSlide === index ? 'opacity-100 ring-2 ring-blue-500' : 'opacity-70 hover:opacity-100'
                }`,
                thumbnailsCarouselClassName
              )}
              onClick={() => handleThumbnailClick(index)}
            >
              <img src={image.url} alt={`Thumbnail ${index + 1}`} className="w-full h-full object-cover" />
            </div>
          ))}
        </div>
      </div>
    );
  };

  if (images.length === 0) {
    return renderPureEscapesLogo();
  }

  return (
    <ImagesCarouselStyled showStatus={showStatus}>
      <div className={classNames(wrapperClassName)}>
        <Carousel
          className={classNames('carousel')}
          labels={carouselLabels}
          showIndicators={false}
          showThumbs={false}
          showStatus={showStatus}
          statusFormatter={carouselStatusFormatter}
          renderArrowNext={showArrows ? renderArrowNext : undefined}
          renderArrowPrev={showArrows ? renderArrowPrev : undefined}
          selectedItem={currentSlide}
          onChange={setCurrentSlide}
          transitionTime={500}
          showArrows={showArrows}
        >
          {images.map(image => (
            <div key={image.url} className={classNames('image', carouselImageClassName)}>
              <img className="featured-photo block w-full h-full object-cover" src={image.url} alt="Hotel photo" />
            </div>
          ))}
        </Carousel>

        {showThumbnails && renderThumbnails()}
      </div>
    </ImagesCarouselStyled>
  );
};
