import { useNotifications } from 'hooks/useNotifications';
import { IBookingInformation, TCountryCode } from 'interfaces';
import { useState } from 'react';
import { BookingBuilderRequest, HoldBasketBuildArg, ITravelAgent, makeBackendApi } from 'services/BackendApi';
import { iActingOnBehalfOfUser } from 'store/modules/actingOnBehalfOf/model';
import { enqueueNotification } from 'store/modules/ui';

type UseHoldAPI = {
  basketBuildUuid: string;
  bookingInformation: IBookingInformation;
  selectedTa?: ITravelAgent | null;
  actingOnBehalfOfUser?: iActingOnBehalfOfUser | null;
  clientCountryCode?: TCountryCode | null;
  onSuccess?: (basketBuild: { uuid: string; }) => void;
};  

export const useHoldAPI = (props: UseHoldAPI) => {
  const { showErrorNotification } = useNotifications();

  const [data, setData] = useState<{ uuid: string; } | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false);

  const request = async (bookingBuilderRequest: BookingBuilderRequest) => {
    const payload: HoldBasketBuildArg = {
      basketBuildUuid: props.basketBuildUuid,
      bookingBuilderRequest,
      bookingInformation: props.bookingInformation,
      actingOnBehalfOfUser: props.actingOnBehalfOfUser || undefined,
      clientCountryCode: props.clientCountryCode || undefined
    };

    const backendApi = makeBackendApi(props.selectedTa?.uuid);
    setLoading(true);

    try {
      const res = await backendApi.holdBasketBuild(payload);
      setData(res);
      if(props.onSuccess) {
        props.onSuccess(res);
      }
    } catch (e) {
      showErrorNotification('Unable to confirm on-hold with supplier.');
    }

    setLoading(false);
  };

  return {
    request,
    isLoading,
    data,
  };
};
