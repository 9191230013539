import React, { useCallback } from 'react'
import { Field, FormikProps, useFormikContext } from 'formik';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { TextInput } from 'ui/TextInput';
import { theme } from '../../../../../tailwind.config';
import { ArrowDownComponent } from 'ui/Icons';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';
import { useCrmTypeValues } from 'services/CrmApi/queries/useCrmTypeValues';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { useCrmTitleValues } from 'services/CrmApi/queries/useCrmTitleValues';
import { ICompanyCreateFormValues } from 'containers/CRM/newCompany/companyCreateFormValidation';

interface IAddContactNewProps {
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;
}

export const AddContactNew: React.FC<IAddContactNewProps> = ({ isOpen, toggleOpen }) => {
  const form: FormikProps<ICompanyCreateFormValues> = useFormikContext();
  const { isMobile, isTablet, isDesktop, isDesktopHD } = useResponsive();

  const sectionClassname = classnames(
    'section-title font-pt-sans text-[17px] leading-normal text-black m-0 select-none py-0 px-0',
    { 'font-[400]': !isOpen, 'font-[700]': isOpen }
  );
  const firstLineClassname = classnames(
    'type-title-firstname-lastname flex gap-[20px]', {
      'flex-col': isMobile,
      'flex-row': isTablet || isDesktop || isDesktopHD,
    }
  );

  const crmTypeValuesContacts = useCrmTypeValues(ETypeValueType.CONTACT, { enabled: true });
  const typeOptions: ISingleSelectOption[] = crmTypeValuesContacts.data?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];
  const crmTitleValues = useCrmTitleValues();
  const titleOptions: ISingleSelectOption[] = crmTitleValues.data?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];

  const toggleSection = useCallback(() => {
    toggleOpen(!isOpen);
  }, [toggleOpen, isOpen]);

  return (
    <div className="px-[10px]">
      <div className="page-section-title font-pt-sans cursor-pointer flex justify-start items-center gap-[5px]" onClick={toggleSection}>
        <span className={classnames('flex justify-center items-center transform-transition transition-ease-in-out duration-300 cursor-pointer',
            { '-rotate-90': !isOpen, 'rotate-0': isOpen }
          )}
        >
          <ArrowDownComponent icon={theme.colors['black']} />
        </span>
        <p className={sectionClassname}>
          Create New
        </p>
      </div>
      <AnimateHeight duration={300} height={isOpen ? 'auto' : 0} className="accordion-area">
        <>
          <div className="form-container flex flex-col justify-between gap-[20px] mt-[20px] ">
            <div className={firstLineClassname}>
              <div className={classnames('type-title flex gap-[20px]', { 'flex-1': isTablet || isDesktop || isDesktopHD })}>
                <Field name="contactType">
                  {({ field: { name, value }, form: { setFieldValue } }) => (
                    <SingleSelect
                      fieldId="contactType"
                      label="Type *"
                      className={classnames('type flex-1')}
                      value={value}
                      showSelectedOption
                      maxVisibleItems={isMobile ? 4 : 6}
                      options={typeOptions ?? []}
                      labelWhenNothingSelected="None"
                      onChange={value => {
                        setFieldValue(name, value);
                      }}
                      errorMessage={form.touched.contactType && form.errors.contactType ? form.errors.contactType : null}
                      errorClassName="contactType-error"
                    />
                  )}
                </Field>
                <Field name="contactTitle">
                  {({ field: { name, value }, form: { setFieldValue } }) => (
                    <SingleSelect
                      fieldId="contactTitle"
                      label="Title"
                      className={classnames('title flex-1')}
                      value={value}
                      showSelectedOption
                      maxVisibleItems={isMobile ? 4 : 6}
                      options={titleOptions ?? []}
                      labelWhenNothingSelected="None"
                      onChange={value => {
                        setFieldValue(name, value);
                      }}
                      errorMessage={form.touched.contactTitle && form.errors.contactTitle ? form.errors.contactTitle : null}
                      errorClassName="contactTitle-error"
                    />
                  )}
                </Field>
              </div>
              <div className={classnames('firstname-lastname flex  gap-[20px]', { 'flex-1': isMobile || isTablet, 'flex-[2]': isDesktop || isDesktopHD })}>
                <Field
                  as={TextInput}
                  type="text"
                  name="contactFirstName"
                  id="contactFirstName"
                  className={classnames('first-name flex-1')}
                  label="First Name *"
                  errorMessage={form.touched.contactFirstName && form.errors.contactFirstName ? form.errors.contactFirstName : null}
                  errorClassName="contactFirstName-error"
                />
                <Field
                  as={TextInput}
                  type="text"
                  name="contactLastName"
                  id="contactLastName"
                  className={classnames('last-name flex-1')}
                  label="Last Name"
                  errorMessage={form.touched.contactLastName && form.errors.contactLastName ? form.errors.contactLastName : null}
                  errorClassName="contactLastName-error"
                />
              </div>
            </div>
            <div className="email-mobile-landline flex flex-col crm-tablet:flex-row items-start gap-[20px]">
              <div className={classnames('email flex', { 'w-full': isMobile || isTablet, 'flex-1': isTablet || isDesktop || isDesktopHD })}>
                <Field
                  as={TextInput}
                  type="text"
                  name="contactEmail"
                  id="contactEmail"
                  className={classnames('email', { 'flex-1': isMobile || isTablet, 'w-full': isDesktop || isDesktopHD })}
                  label="E-mail"
                  errorMessage={form.touched.contactEmail && form.errors.contactEmail ? form.errors.contactEmail : null}
                  errorClassName="contactEmail-error"
                />
              </div>
              <div className={classnames('mobile-landline flex gap-[20px]', { 'w-full': isMobile || isTablet, 'flex-1': isTablet, 'flex-[2]': isDesktop || isDesktopHD })}>
                <Field
                  as={TextInput}
                  type="text"
                  name="contactMobile"
                  id="contactMobile"
                  className={classnames('mobile', { 'flex-1': isMobile || isTablet })}
                  label="Mobile"
                  errorMessage={form.touched.contactMobile && form.errors.contactMobile ? form.errors.contactMobile : null}
                  errorClassName="contactMobile-error"
                />
                <Field
                  as={TextInput}
                  type="text"
                  name="contactLandline"
                  id="contactLandline"
                  className={classnames('landline', { 'flex-1': isMobile || isTablet })}
                  label="Landline"
                  errorMessage={form.touched.contactLandline && form.errors.contactLandline ? form.errors.contactLandline : null}
                  errorClassName="contactLandline-error"
                />
              </div>
            </div>
          </div>
        </>
      </AnimateHeight>
    </div>
  )
}