import { useMutation } from "@tanstack/react-query";
import { makeCRMApi } from "..";
import { ICreateLeadRequest } from "../types/CrmLeadTypes";

export const useCreateLeadMutation = () => {
  const crmApi = makeCRMApi();
  
  const updateContactMutation = useMutation({
    mutationKey: ['crm-create-lead'],
    mutationFn: (requestData: ICreateLeadRequest) => crmApi.createLead(requestData),
  });
  
  return updateContactMutation;
}
