import { useMutation } from "@tanstack/react-query";
import { makeCRMApi } from "..";
import { IUpdateContactRequest } from "../types/CrmContactTypes";

export const useUpdateContactMutation = () => {
  const crmApi = makeCRMApi();
  
  const updateContactMutation = useMutation({
    mutationKey: ['crm-update-contact'],
    mutationFn: (requestData: IUpdateContactRequest) => crmApi.updateContact(requestData),
  });
  
  return updateContactMutation;
}
