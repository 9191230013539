import classNames from 'classnames';
import React from 'react';
import Flag from 'react-world-flags';
import { IDirectoryEntry } from 'services/CrmApi/types/DirectoryEntry';
import { formatDateTimeDisplay, formatDateTimeDisplayWithoutOffset } from 'utils';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';
import { ContactLogo } from 'ui/ContactLogo';


export interface IContactPageTitleProps {
  uuid: string;
  firstName: string;
  lastName: string | null;
  primaryText: string;
  countryCode: string;
  contactType: string;
  primaryClassName?: string;
  secondaryClassName?: string;
  loadingClassName?: string;
  secondaryCondition?: boolean;
  loadingCondition?: boolean;
  directoryEntry?: IDirectoryEntry;
}

export const ContactPageTitle: React.FC<IContactPageTitleProps> = ({ 
  primaryText, countryCode, contactType, primaryClassName = '', directoryEntry, uuid, firstName, lastName,
}) => {
  const { isDesktop, isDesktopHD } = useResponsive();
  const createdBy = [
    directoryEntry?.createdBy?.firstName,
    directoryEntry?.createdBy?.lastName
  ].filter(Boolean).join(' ')
  const createdAt = directoryEntry?.createdDate ? formatDateTimeDisplayWithoutOffset(directoryEntry?.createdDate) : ''
  const updatedBy = [
    directoryEntry?.updatedBy?.firstName,
    directoryEntry?.updatedBy?.lastName
  ].filter(Boolean).join(' ')
  const updatedAt = directoryEntry?.createdDate ? formatDateTimeDisplayWithoutOffset(directoryEntry?.updatedDate) : ''
  return (
    <div className="contact-page-title flex justify-between items-center">
      <div className="font-noe-display flex items-center gap-[10px]">
        <span className="contact-logo w-[60px] h-auto">
          <ContactLogo type="big" uuid={uuid} firstName={firstName} lastName={lastName}/>
        </span>
        <div className="flex flex-col ">
          <div className="flex gap-[10px] items-baseline">
            <h1 className={classNames('primary-title font-noe-display text-[26px] leading-normal max-w-[70vw] text-black font-normal m-0 text-ellipsis overflow-hidden whitespace-nowrap', primaryClassName)}>
              {primaryText}
            </h1>
            {countryCode && <Flag code={countryCode} className="relative top-[2px] w-[18px] h-[18px]" />}
          </div>
          <p className="font-pt-sans text-[15px] text-black m-0">{contactType}</p>
        </div>
      </div>
      {(isDesktop || isDesktopHD) && (
        <div className="flex flex-col gap-[5px]">
          {createdBy && createdAt && (
            <p className="created-by m-0 font-pt-sans text-[13px] tracking-[0.52px] text-gray-100">
              Created by: <span className="underline">{createdBy}</span>, {createdAt}
            </p>
          )}
          {updatedBy && updatedAt && (
            <p className="created-by m-0 font-pt-sans text-[13px] tracking-[0.52px] text-gray-100">
              Modified by: <span className="underline">{updatedBy}</span>, {updatedAt}
            </p>
          )}
        </div>
      )}
    </div>
  );
}
