import { useMutation } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";
import { IUpdateCompanyRequest } from "../types/CrmCompanyTypes";

export const useUpdateCompanyMutation = () => {
  const crmApi = makeCRMApi();
  
  const updateCompanyMutation = useMutation({
    mutationKey: ['crm-update-company'],
    mutationFn: (requestData: IUpdateCompanyRequest) => crmApi.updateCompany(requestData),
  });
  
  return updateCompanyMutation;
}
