import React, { Dispatch, SetStateAction, useState } from 'react';
import classnames from 'classnames';
import FluidButton from 'ui/FluidButton';
import * as UiActions from 'store/modules/ui/actions';
import * as AuthSelectors from 'store/modules/auth/selectors';
import * as BookingBuilderActions from 'store/modules/bookingBuilder/actions';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import * as AgentsSelectors from 'store/modules/agents/selectors';
import * as FastSearchSelectors from 'store/modules/fastSearch/selectors';
import { useCurrentWidth } from 'effects';
import { useDispatch, useSelector } from 'react-redux';
import { RIGHT_COLUMN_BREAKPOINT } from './const';
import { StandardModal } from 'pureUi/Modal';
import { BookingBuilder, ICompany, makeBackendApi } from 'services/BackendApi';
import { selectedTaSelector } from 'store/modules/agents';
import { useTranslation } from 'react-i18next';
import { isBookingOnRequestV2 } from 'store/modules/bookings';
import { useRequestBooking } from './hooks/useRequestBooking';
import { LodgingSummary } from 'interfaces';
import { LiveRatePricesModal } from 'containers/HotelContainer/LiveRatePricesModal';
import { SaveQuoteProposalButtons } from './SaveQuoteProposalButtons';
import { companyDataSelector } from 'store/modules/companyInfo';

interface IPayLaterSectionProps {
  booking: BookingBuilder;
  lodgingSummaries: LodgingSummary[];
  className?: string;
  canBook: boolean;
  isRequestInProgress: boolean;
  setRequestInProgress: Dispatch<SetStateAction<boolean>>;
  setRedirectLocation: React.Dispatch<React.SetStateAction<string | null>>;
  setShouldShowLeaveAlert: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  isPrimaryButtonDisabled: boolean;
  buttonsDisabled: boolean; 
  isLiveRatePricesModalOpen: boolean;
  setIsLiveRatePricesModalOpen: Dispatch<SetStateAction<boolean>>;
  liveRatePricesModalData: any;
  setLiveRatePricesModalData: Dispatch<SetStateAction<any>>;
}

export const FourBookButtons: React.FC<IPayLaterSectionProps> = ({ 
  booking, lodgingSummaries, isLoading, canBook, isRequestInProgress, setRequestInProgress, setRedirectLocation, setShouldShowLeaveAlert, isPrimaryButtonDisabled, isLiveRatePricesModalOpen, setIsLiveRatePricesModalOpen, liveRatePricesModalData, setLiveRatePricesModalData
 }) => {
  const { currentWidth } = useCurrentWidth();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  
  const selectedTa = useSelector(selectedTaSelector);
  const backendApi = makeBackendApi(selectedTa?.uuid);
  const bookingDomain = useSelector(BookingBuilderSelectors.bookingSelector);
  const selectedCompanyMembership = useSelector(AgentsSelectors.selectedCompanyMembershipSelector);
  const searchQuery = useSelector(FastSearchSelectors.offersQuerySelector);
  const clientCountryCode = searchQuery.clientCountryCode;
  const bbv1ClearBookingErrors = () => dispatch(BookingBuilderActions.bbv1ClearBookingErrorsAction());
  const bbv1AddBookingError = (error: any) => dispatch(BookingBuilderActions.bbv1AddBookingErrorAction(error));
  const enqueueNotification = (notification: any) => dispatch(UiActions.enqueueNotification(notification));

  const containsLiveRates = booking.response.potentialBooking.Accommodation.some(requestedAccommodation => {
    return requestedAccommodation.isLiveRate;
  });
  const isTa: boolean = useSelector(AuthSelectors.isTA);
  const company = useSelector(companyDataSelector) as ICompany;
  const allowBookingLiveRates = (!isTa && containsLiveRates) || (isTa && containsLiveRates && company?.enableInstantBooking);

  const {
    handleRequestToBookButton,
    handleLiveRatePricesModalConfirm,
    closeLiveRatePricesModal,
  } = useRequestBooking({
    booking,
    setRequestInProgress,
    backendApi,
    enqueueNotification,
    selectedCompanyMembership,
    bookingDomain,
    bbv1AddBookingError,
    bbv1ClearBookingErrors,
    containsLiveRates,
    clientCountryCode,
    setShouldShowLeaveAlert,
    setRedirectLocation,
    allowBookingLiveRates,
    setIsLiveRatePricesModalOpen,
    liveRatePricesModalData,
    setLiveRatePricesModalData,
  });
  
  const isOnRequest = useSelector(isBookingOnRequestV2());
  const guestInfo = useSelector(BookingBuilderSelectors.guestInfoSelector);
  const guestInfoFilledIn = guestInfo.guestFirstName?.trim() && guestInfo.guestLastName?.trim();

  const buttonsDisabled = !guestInfoFilledIn || !canBook || isLoading;
  
  const bookLabel = allowBookingLiveRates ? t('buttons.bookLive') : undefined;
  const bookLabelIfOnRequest = isOnRequest ? t('buttons.bookOnRequest') : t('buttons.bookNow');
  
  return (
    <div className="booking-buttons-container">
      <div className="booking-buttons flex flex-col gap-[8px] mt-[14px]">
        <div className={classnames('booking-buttons-primary flex gap-[8px]', { 'flex-col': currentWidth < RIGHT_COLUMN_BREAKPOINT })}>
          <FluidButton
            type="primary"
            className={classnames('flex-1 w-full booking-button-request-to-book font-semibold font-hurmegeometric-sans min-h-[55px] rounded-none uppercase', { 'hover:bg-green-prime hover:border-none': !(buttonsDisabled || isPrimaryButtonDisabled) })}
            onClick={handleRequestToBookButton}
            disabled={buttonsDisabled || isPrimaryButtonDisabled}
          >
            {bookLabel || bookLabelIfOnRequest}
          </FluidButton>
        </div>
        <SaveQuoteProposalButtons
          booking={booking}
          lodgingSummaries={lodgingSummaries}
          isRequestInProgress={isRequestInProgress}
          setShouldShowLeaveAlert={setShouldShowLeaveAlert}
          setRequestInProgress={setRequestInProgress}
          setRedirectLocation={setRedirectLocation}
          canBook={canBook}
          isLoading={isLoading}
        />
      </div>

      {isLiveRatePricesModalOpen && (
        <StandardModal removePadding={true} showCloseButton={false}>
          <LiveRatePricesModal
            confirmButtonLabel="Book now"
            onConfirm={handleLiveRatePricesModalConfirm}
            onClose={closeLiveRatePricesModal}
            isConfirmDisabled={isRequestInProgress}
            liveRates={liveRatePricesModalData}
            currency={booking.response.currency}
            total={booking.response?.totals?.totalForPricedItemsCents ?? 0}
          />
        </StandardModal>
      )}
    </div>
  );
};
