import React, { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames';
import { ILinkedDirectoryEntryArrayItem } from 'services/CrmApi/types/DirectoryEntry';
import { ETableSortColumn } from './types';
import { ESortOrder } from 'store/common/types';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';
import { Link } from 'ui/Link';
import { IScrollableTableColumn, IScrollableTableRow } from 'ui/ScrollableTable/types';
import { ScrollableTable } from 'ui/ScrollableTable/ScrollableTable';
import { useInplaceSorting } from '../../hooks/useInplaceSorting';
import { useNotifications } from 'hooks/useNotifications';
import { useUnlinkDirectoryEntriesMutation } from 'services/CrmApi/mutations/useUnlinkDirectoryEntriesMutation';
import { useDefaultOnErrorHandler } from 'services/CrmApi/mutations/defaultOnErrorHandler';
import ConfirmationModal, { EConfirmationModalType } from 'ui/ConfirmationModal';
import { CompanyIcon } from 'ui/Icons/components/Company.component';
import { theme } from '../../../../../tailwind.config';

interface ILinkedCompaniesListProps {
  companies: ILinkedDirectoryEntryArrayItem[];
  onCompanyUnlink: () => void;
}

export const LinkedCompaniesList: React.FC<ILinkedCompaniesListProps> = React.memo(({ companies, onCompanyUnlink }) => {
  const { isMobile, isTablet, isDesktop, isDesktopHD } = useResponsive();
  const { sortBy, sortOrder, handleSort, sortedItems } = useInplaceSorting<ILinkedDirectoryEntryArrayItem>(ETableSortColumn.CREATED, ESortOrder.DESC, companies);
  const unlinkDirectoryEntriesMutation = useUnlinkDirectoryEntriesMutation();
  const { showSuccessNotification } = useNotifications();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);  
  const [companiesPrevCount, setCompaniesPrevCount] = useState(companies.length);
  
  useEffect(() => {
    if (companies.length > companiesPrevCount) {
      // When we add (and not remove) a link then reset the Sort
      handleSort();
    }
    setCompaniesPrevCount(companies.length);
  }, [companies, companiesPrevCount, handleSort]);  

  const handleDeleteLinkClick = useCallback(() => {
    setConfirmationModalOpen(true);
  }, []);

  const handleDeleteLinkConfirmation = useCallback(async (linkId: string) => {
    try {
      await unlinkDirectoryEntriesMutation.mutateAsync(linkId);
      showSuccessNotification('Link removed');
      onCompanyUnlink();
    } catch (error) {
      defaultOnErrorHandler(error, 'Failed to remove link');
    } finally {
      setConfirmationModalOpen(false);
    }
  }, [defaultOnErrorHandler, onCompanyUnlink, showSuccessNotification, unlinkDirectoryEntriesMutation]);

  
  const generalColumnClassName = 'flex-1 text-gray-100 tracking-2xs text-xs leading-16px uppercase';

  const columnNameClassname = classnames('column-name', {
    'flex-1': isMobile || isTablet,
    'flex-[2]': isDesktop || isDesktopHD,
  });

  const rowNameClassname = classnames('flex row-name overflow-hidden whitespace-nowrap text-ellipsis', {
    'flex-1': isMobile || isTablet,
    'flex-[2]': isDesktop || isDesktopHD,
  });

  const linkClassname = 'font-pt-sans text-[15px]';

  const columns: IScrollableTableColumn[] = [
    {
      id: ETableSortColumn.NAME,
      label: 'NAME',
      className: columnNameClassname,
      fieldToSortBy: ETableSortColumn.NAME,
      isVisible: true,
    },
    {
      id: ETableSortColumn.TYPE,
      label: 'TYPE',
      className: generalColumnClassName,
      fieldToSortBy: ETableSortColumn.TYPE,
      isVisible: true,
    },
    {
      id: 'actions',
      label: '',
      isVisible: true,
      className: 'item-actions flex items-center relative w-[68px] px-[10px]',
    }
  ];

  const rows: IScrollableTableRow[] = sortedItems.map(company => {
  
    const name = (
      <div className={rowNameClassname} title={company.fullName}>
        {(isDesktop || isDesktopHD) && <div className="company-logo inline-flex max-w-[28px] h-auto mr-[10px] my-[2px]">
          <CompanyIcon fill={theme.colors['gray-60']} />
        </div>}
        <Link internalClassName={linkClassname} to={`/crm/company/${company.id}/edit`} target='_blank'>{company.fullName}</Link>
      </div>
    );
    const type = <span className="row-type flex-1">{company.companyType?.value ?? ''}</span>;
    const actions = (
      <div className="item-actions flex items-center relative w-[48px]">
        <div className="w-4px h-34px rounded bg-brown-15 mx-[10px]" />
        <span className="w-6">
          <button
            className="delete rounded-full p-0 w-6 h-6 bg-white hover:bg-brown-40 border border-solid border-brown-80 cursor-pointer font-pt-sans"
            onClick={handleDeleteLinkClick}
          >
            {confirmationModalOpen && (
              <ConfirmationModal
                type={EConfirmationModalType.WARNING}
                className="confirm-import-modal"
                title="You are going to remove a relation. You can add it again later if you need to."
                message="Are you sure?"
                confirmButtonLabel="Yes"
                cancelButtonLabel="No"
                onConfirm={() => handleDeleteLinkConfirmation(company.linkId)}
                onCancel={() => {
                  setConfirmationModalOpen(false);
                }}
                isConfirmLoading={false}
              />
            )}
            <i className="fas fa-times text-brown-80"></i>
          </button>
        </span>
      </div>
    );

    return {
      id: company.id,
      className: 'row text-black text-15px h-30px flex items-center pl-2 pr-6 mt-5px mb-5px',
      cells: [
        { id: 'name', value: name, className: rowNameClassname },
        { id: 'type', value: type, className: 'row-type flex-1' },
        { id: 'actions', value: actions }
      ],
    };
  }); 

  return (
    <ScrollableTable
      rows={rows}
      columns={columns}
      sortBy={sortBy}
      sortOrder={sortOrder}
      messageWhenEmpty='No Results Found'
      onSort={handleSort}
      isLoading={false}
    />
  );
});
