import { isBothSelected } from 'containers/CRM/newCompany/companyCreateFormValidation';
import * as Yup from 'yup';

export const companyMainInfoValidationSchema = Yup.object().shape({
  managerId: Yup.string().nullable(true).required('Field is required'),
  keyNotes: Yup.string(),
});

export interface ICrmCompanyMainInfoFormValues {
  managerId: string | null;
  keyNotes: string;
}

export const companyUpdateCommon = Yup.object().shape({}).test({
  test: function(values) {
    // https://github.com/jaredpalmer/formik/issues/2146
    const bothSelected = isBothSelected(values);
    if (bothSelected) {
      return this.createError({
        path: 'both-not-allowed',
        message: 'Select Existing or create new, but not both options.',
      });
    }
    return true;
  }
});

export const addExistingContactValidator = (
  Yup.object().shape({
    existingContactId: Yup.string().required('This field is required'),
  })
).concat(companyUpdateCommon);

export const addNewContactValidator = (
  Yup.object().shape({
    contactType: Yup.string().nullable(true).required('This field is required'),
    contactFirstName: Yup.string().nullable(true).required('This field is required'),
    contactEmail: Yup.string().email('Must be valid email'),
  })
).concat(companyUpdateCommon);