import React from 'react';
import { ESortOrder } from 'store/common/types';
import { IScrollableTableColumn } from './types';
import { TableHeaderColumn } from './TableHeaderColumn';

export interface ITableHeaderProps {
  columns: IScrollableTableColumn[];
  sortBy: string;
  sortOrder: ESortOrder;
  onSort: (newSortBy: string, newSortOrder: ESortOrder) => void;
}

export const TableHeader: React.FC<ITableHeaderProps> = React.memo(({ 
  sortBy, sortOrder, onSort, columns
}) => {
  const visibleColumns = columns.filter(column => column.isVisible);
  const textClassName = 'text-gray-100 tracking-2xs text-xs leading-16px uppercase';

  return (
    <div className="table-header bg-ivory uppercase h-10 flex items-center pr-3 py-[2px]">
      {visibleColumns.map(column => (
        <TableHeaderColumn
          key={column.id}
          column={column}
          className={column.className}
          textClassName={textClassName}
          currentSortBy={sortBy}
          currentSortOrder={sortOrder}
          columnSortBy={column.fieldToSortBy}
          onSort={onSort}
        />
      ))}
    </div>
  );
});
