import { IAddLeadFormValues } from './types';
import { FormikErrors } from 'formik';

export const validateAddLeadForm = (values: IAddLeadFormValues) => {
  const errors: FormikErrors<IAddLeadFormValues> = {};
  if (!values.leadStageTypeCode?.trim().length) {
    errors.leadStageTypeCode = 'Field is required';
  }

  const countryScoreErrors: {[key: string]: string}[] = [];
  const countryCodeSet = new Set();
  values.countryScore.forEach((item, index) => {
    if (!item.countryCode.length) {
      countryScoreErrors[index] = { countryCode: 'Field is required' };
    }
    if (countryCodeSet.has(item.countryCode)) {
      countryScoreErrors[index] = { countryCode: 'Check duplicated destinations' };
    }
    if (item.countryCode?.trim()?.length > 0) {
      countryCodeSet.add(item.countryCode);
    }
  })
  if (Object.keys(countryScoreErrors).length > 0) {
    errors.countryScore = countryScoreErrors;
  }

  return errors;
}
