import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { BackLink } from 'ui/BackLink';
import { PageTitle } from 'ui/PageTitle';
import { ICompanyCreateFormValues, addExistingContactValidator, addNewContactValidator, companyCreateCommon, isBothSelected, isNoneSelected } from './companyCreateFormValidation';
import classnames from 'classnames';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { TextInput } from 'ui/TextInput';
import Editor from 'pureUi/Editor';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { PageSectionTitle } from 'ui/PageSectionTitle';
import { VerticalSpace } from 'ui/VerticalSpace';
import FluidButton from 'ui/FluidButton';
import { useHistory } from 'react-router';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { useResponsive } from '../hooks/useResponsive';
import { useNotifications } from 'hooks/useNotifications';
import { useCrmCountries } from 'services/CrmApi/queries/useCrmCountries';
import { useCrmTypeValues } from 'services/CrmApi/queries/useCrmTypeValues';
import { useDefaultOnErrorHandler } from 'services/CrmApi/mutations/defaultOnErrorHandler';
import { delay } from 'utils/promise';
import { INewCompanyRequest } from 'services/CrmApi/types/CrmCompanyTypes';
import { useCreateCompanyMutation } from 'services/CrmApi/mutations/useCreateCompanyMutation';
import { AddContact, ELinkItemSection } from '../components/AddContact/AddContact';

export const CrmCompanyCreate = () => {
  const history = useHistory();
  const { isMobile } = useResponsive();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();
  const { showSuccessNotification } = useNotifications();
  const crmCountries = useCrmCountries();
  const crmTypeValuesCompanies = useCrmTypeValues(ETypeValueType.COMPANY, { enabled: true });
  const [sectionExpanded, setSectionExpanded] = useState<ELinkItemSection | null>(null);
 
  const initialValues: ICompanyCreateFormValues = useMemo(() => ({
    type: '',
    name: '',
    country: null,
    keyNotes: null,
    contactType: '',
    contactTitle: '',
    contactFirstName: '',
    contactLastName: '',
    contactEmail: '',
    contactMobile: '',
    contactLandline: '',
    existingContactId: null,
  }), []);

  const createCompanyMutation = useCreateCompanyMutation();

  const handleOnSectionExpanded = useCallback((section: ELinkItemSection | null) => {
    setSectionExpanded(section);
  }, []);

  const handleFormSubmit = useCallback(async (values: ICompanyCreateFormValues, formikHelpers: FormikHelpers<ICompanyCreateFormValues>) => {
    const existingContactUuid = values.existingContactId;
    const newContact = {
      typeCode: values.contactType || undefined,
      titleCode: values.contactTitle || undefined,
      firstName: values.contactFirstName,
      lastName: values.contactLastName,
      email: values.contactEmail || undefined,
      mobile: values.contactMobile,
      landline: values.contactLandline,
    };
    const requestData : INewCompanyRequest = {
      // BE is not accepting null values
      typeCode: values.type,
      name: values.name,
      countryCode: values.country || undefined,
      keyNotes: values.keyNotes || undefined,
    }
    if (existingContactUuid) {
      requestData.existingContactUuid = existingContactUuid;
    } else {
      requestData.newContact = newContact;
    }

    try {
      await createCompanyMutation.mutateAsync(requestData);
      formikHelpers.resetForm({values: initialValues});
      // Formik resetForm is executed asynchronously even though it is not an async function
      await delay(0);
      history.push('/crm');
      showSuccessNotification('Company created.');      
    } catch (error) {
      defaultOnErrorHandler(error, 'Failed to create a Company');
    }
  }, [createCompanyMutation, defaultOnErrorHandler, history, initialValues, showSuccessNotification]);

  const countryOptions: ISingleSelectOption[] = crmCountries.data?.data?.map(
    item => ({ value: item.code, label: item.name })
  ) ?? [];

  const typeOptions: ISingleSelectOption[] = crmTypeValuesCompanies.data?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];

  if (crmCountries.isPending || crmTypeValuesCompanies.isPending) {
    return (
      <div className="mt-[20px]">
        <LoadingBar />
      </div>
    )
  }

  if (crmCountries.isError || crmTypeValuesCompanies.isError) {
    return (
      <div className="mt-[20px]">
        <ErrorBar />
      </div>
    )
  }

  const selectClassname = classnames('min-w-[190px]', {
    'w-full': isMobile,
    'w-[200px]': !isMobile,
  });
  const saveButtonClassname = classnames({
    'w-full': isMobile,
  });

  let companyValidator;
  if (sectionExpanded === ELinkItemSection.CREATE_NEW) {
    companyValidator = addNewContactValidator;
  } else if (sectionExpanded === ELinkItemSection.SELECT_EXISTING) {
    companyValidator = addExistingContactValidator;
  } else {
    companyValidator = companyCreateCommon;
  }
  
  return (
    <div className="crm-directory mx-[20px] crm-desktop:mx-[80px]">
      <BackLink type="internal" href="/crm">
        Back
      </BackLink>
      <PageTitle
        primaryText="New Company"
        secondaryCondition={false}
      />
      <VerticalSpace height="20px" />
      <Formik
          initialValues={initialValues}
          validationSchema={companyValidator}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(form: FormikProps<ICompanyCreateFormValues>) => {
            let wrappingError : string | null = null;
            if (form.submitCount > 0 && isNoneSelected(form.values)) {
              wrappingError = 'Contact info is mandatory';
            }
            if (form.submitCount > 0 && isBothSelected(form.values)) {
              wrappingError = 'Select Existing or create new, but not both options.';
            }
            return (
              <>
                <LeaveWithoutSavingModal 
                  when={form.dirty} 
                  confirmButtonLabel="Yes"
                  cancelButtonLabel="No"
                />

                <PageSectionTitle
                  text="Basic Info"
                />

                <form autoComplete={'off'} onSubmit={form.handleSubmit}>
                  <div className="form-container flex flex-col justify-between gap-[20px] mt-[10px]">
                    <div className="type-name-country flex flex-col crm-tablet:flex-row items-start gap-[20px]">
                      <Field name="type">
                        {({ field: { name, value }, form: { setFieldValue, setFieldTouched } }) => (
                          <SingleSelect
                            fieldId="type"
                            label="Type *"
                            className={classnames('type', selectClassname)}
                            value={value}
                            showSelectedOption
                            maxVisibleItems={isMobile ? 4 : 6}
                            options={typeOptions ?? []}
                            labelWhenNothingSelected="None"
                            onChange={value => {
                              setFieldValue(name, value);
                              setFieldTouched(name, value);
                            }}
                            errorMessage={form.touched.type && form.errors.type ? form.errors.type : null}
                            errorClassName="type-error"
                          />
                        )}
                      </Field>
                      <Field
                        as={TextInput}
                        type="text"
                        name="name"
                        id="name"
                        className="name flex-1 w-full desktop:max-w-[480px]"
                        label="Name *"
                        errorMessage={form.touched.name && form.errors.name ? form.errors.name : null}
                        errorClassName="name-error"
                      />
                      <Field name="country">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <SingleSelect
                            fieldId="country"
                            label="Country"
                            className={classnames('country', selectClassname)}
                            value={value}
                            maxVisibleItems={isMobile ? 4 : 6}
                            showSelectedOption
                            options={countryOptions ?? []}
                            labelWhenNothingSelected="None"
                            onChange={value => {
                              setFieldValue(name, value);
                            }}
                            errorMessage={form.touched.country && form.errors.country ? form.errors.country : null}
                            errorClassName="country-error"
                          />
                        )}
                      </Field>
                    </div>
                        
                    <div className="key-notes flex  gap-[20px]">
                      <Field name="keyNotes">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <div className="flex flex-col w-full">
                            <label className="font-pt-sans mb-5px text-black text-13px leading-17px tracking-2xs">
                              Key Notes
                            </label>
                            <Editor text={value} handleEditorChange={(value) => setFieldValue(name, value)} options={[]} mentionAbility={false} />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>

                  <VerticalSpace height="20px" />
                  <AddContact wrappingError={wrappingError} onSectionExpanded={handleOnSectionExpanded}/>
                  
                  <div className="buttons-container mt-[20px]">
                    <FluidButton submit type="primary" textClassName="flex items-center gap-[10px]" className={saveButtonClassname} isLoading={createCompanyMutation.isPending} disabled={createCompanyMutation.isPending}>
                      Save
                    </FluidButton>
                  </div>
                </form>
              </>
            );
          }}
        </Formik>
    </div>
  )
};
