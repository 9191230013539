import React from 'react';
import styled from 'styled-components';
import { colors } from 'pureUi/pureUiTheme';
import { IImportReportItem, EImportReportItemKey } from 'services/BackendApi';
import ReportErrorList from './ReportErrorList';

export interface ReportItemProps {
  className?: string;
  data: IImportReportItem;
}

const EntityLabels: { [key in EImportReportItemKey]: string } = {
  [EImportReportItemKey.REGIONS]: 'Regions',
  [EImportReportItemKey.SEASONS]: 'Seasons',
  [EImportReportItemKey.ACCOMMODATION_RATES]: 'Accommodation Rates',
  [EImportReportItemKey.MEAL_PLAN_RATES]: 'Meal Plan Rates',
  [EImportReportItemKey.TRANSFER_RATES_PER_PERSON]: 'Transfer Rates (per person)',
  [EImportReportItemKey.TRANSFER_RATES_PER_BOOKING]: 'Transfer Rates (per booking)',
  [EImportReportItemKey.SUPPLEMENT_RATES_PER_PERSON_PER_NIGHT]: 'Supplement Rates (per person per night)',
  [EImportReportItemKey.SUPPLEMENT_RATES_PER_NIGHT]: 'Supplement Rates (per night)',
  [EImportReportItemKey.GROUND_SERVICE_RATES_PER_PERSON]: 'Ground Services Rates (per person)',
  [EImportReportItemKey.GROUND_SERVICE_RATES_PER_BOOKING]: 'Ground Services Rates (per booking)',
  [EImportReportItemKey.FINE_RATES]: 'Fine Rates',
};

export const HeaderInfo = styled.div<{ success: boolean }>`
  padding-left: 10px;
  border-left: 5px solid ${props => (props.success ? colors.warmGreen : colors.redFade)};
`;

export const Totals = styled.span`
  padding-left: 10px;
  color: ${colors.grayDarker};
`;

const ReportItem = (props: ReportItemProps) => {
  const { data, className } = props;
  const { key, total, warnings, errors } = data;

  const insertable = total - errors.length;

  return (
    <div className={className}>
      <HeaderInfo data-role={'header-info'} success={!errors || !errors.length}>
        {EntityLabels[key] || key}
        <Totals data-role={'totals'}>
          {insertable} insertable
          {warnings.length > 0 && ` (${warnings.length} with warnings)`}
          {errors.length > 0 && ` | ${errors.length} errors`}
        </Totals>
      </HeaderInfo>
      {warnings.length > 0 && <ReportErrorList data-role={'error-list'} title="Warnings" items={warnings} />}
      {errors.length > 0 && <ReportErrorList data-role={'error-list'} title="Errors" items={errors} />}
    </div>
  );
};

export default ReportItem;
