import React from 'react';
import UserIcon from 'ui/Icons/person-alt.component.svg';
import { sortBy } from 'lodash-es';

export const GuestDetailsSingleLine = ({
  numberOfAdults,
  agesOfAllChildren,
}: {
  numberOfAdults: number;
  agesOfAllChildren: number[];
}) => {
  return (
    <div className="font-pt-sans flex items-center text-[15px] leading-17px">
      <UserIcon className="w-[16px] h-[16px] stroke-black fill-black" />
      <span className="mx-[5px]">x{numberOfAdults}</span>
      {agesOfAllChildren && agesOfAllChildren.length >= 1 && (
        <>
          <UserIcon className="w-[12px] h-[12px] stroke-black fill-black" />
          <span className="mx-[5px]">x{agesOfAllChildren.length}</span>
          <span>&nbsp;({sortBy(agesOfAllChildren).join(', ')}&nbsp;years)</span>
        </>
      )}
    </div>
  );
};
