import React, { useCallback } from 'react';
import { BackLink } from 'ui/BackLink';
import classnames from 'classnames';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { VerticalSpace } from 'ui/VerticalSpace';
import { useRouteMatch } from 'react-router';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { useResponsive } from '../hooks/useResponsive';
import { useCrmCountries } from 'services/CrmApi/queries/useCrmCountries';
import { useCrmTypeValues } from 'services/CrmApi/queries/useCrmTypeValues';
import { useCrmDirectoryEntry } from 'services/CrmApi/queries/useCrmDirectoryEntry';
import { ECrmDirectoryEntryType } from 'services/CrmApi/types/DirectoryEntry';
import { CrmContactTabs } from './CrmContactTabs/CrmContactTabs';
import { ContactPageTitle } from '../components/ContactPageTitle';
import { CrmContactEditProfile } from './CrmContactProfile/CrmContactEditProfile';

interface ICrmContactEditRouteParams {
  contactId: string;
}

export const CrmContactEdit = () => {
  const match = useRouteMatch<ICrmContactEditRouteParams>();
  const contactId = match.params.contactId;
  const { isMobile, isTablet, isDesktop, isDesktopHD } = useResponsive();

  const crmCountries = useCrmCountries();
  const crmTypeValuesContacts = useCrmTypeValues(ETypeValueType.CONTACT, { enabled: true });
  const { crmDirectoryEntryResponse, directoryEntry } = useCrmDirectoryEntry(contactId);
  
  const contacts = [... (directoryEntry?.linkedDirectoryEntries || []) ].filter( directoryEntry => directoryEntry.type === ECrmDirectoryEntryType.PERSON );
  const companies = [... (directoryEntry?.linkedDirectoryEntries || []) ].filter( directoryEntry => directoryEntry.type === ECrmDirectoryEntryType.COMPANY );
  
  const layoutClassname = classnames('flex gap-[20px]', {
    'flex-col': isMobile || isTablet,
    'flex-row': isDesktop || isDesktopHD,
  });
  
  const onLinkOrUnlink = useCallback( () => {
    crmDirectoryEntryResponse.refetch();
  }, [crmDirectoryEntryResponse]);

  if (crmCountries.isPending || crmTypeValuesContacts.isPending || crmDirectoryEntryResponse.isPending) {
    return (
      <div className="mt-[20px]">
        <LoadingBar />
      </div>
    )
  }

  if (crmCountries.isError || crmTypeValuesContacts.isError || !directoryEntry || !directoryEntry.profile) {
    return (
      <div className="mt-[20px]">
        <ErrorBar />
      </div>
    )
  }
 
  if (directoryEntry.profile.type !== ECrmDirectoryEntryType.PERSON || !('contactType' in directoryEntry.profile)) {
    return (
      <div className="mt-[20px]">
        <ErrorBar message="It seems this is not a contact profile" />
      </div>
    )
  }
  
  return (
    <div className="crm-directory mx-[20px] crm-desktop:mx-[80px]">
      <BackLink type="internal" href="/crm">
        Back
      </BackLink>
      <ContactPageTitle
        primaryText={directoryEntry?.fullName ?? ''}
        countryCode={directoryEntry?.country?.code}
        directoryEntry={directoryEntry}
        contactType={directoryEntry?.profile.contactType.value}
        uuid={directoryEntry?.id}
        firstName={directoryEntry?.profile.firstName}
        lastName={directoryEntry?.profile.lastName}
      />
      <VerticalSpace height="20px" />
      <div className={layoutClassname}>
        <CrmContactEditProfile directoryEntry={directoryEntry} />
        <div className="flex-1 flex flex-col gap-[40px]">
          <CrmContactTabs 
            directoryEntry={directoryEntry} 
            contacts={contacts} 
            companies={companies} 
            onLinkOrUnlink={onLinkOrUnlink} 
          />
        </div>
      </div>
    </div>
  )
};
