import React from 'react';
import { differenceInCalendarDays, addDays } from 'date-fns';
import { flatten, uniqBy } from 'ramda';
import capitalize from 'lodash/capitalize';
import cloneDeep from 'lodash/cloneDeep';

import { formatPrice } from './number';
import { formatDateDisplayWithoutOffset, formatDateRangeDisplay } from './date';

import {
  BookingBuilderAvailableProductSets,
  RequestedBuildAccommodation,
  LodgingSummary,
  BookingBuilderAvailableProductSetsAccommodation,
  IBookingInformation,
} from 'interfaces';

import {
  AvailableProductSets,
  BookingBuilder,
  OfferApplication,
  ProductSetAccommodation,
  SelectedAccommodation,
} from 'services/BackendApi/types';
import { BookingBuilderDomain } from 'store/modules/bookingBuilder';
import { BookingStatusTypes } from 'config/enums';
import { IBookingModel } from 'store/modules/bookingManager/sagas/bookingTransforms/BookingModel';
import { TFunction } from 'i18next';
import { LIVE_RATES_PRICE_CHECK_TOLERANCE_CENTS } from 'config';
import { EPaymentMethod } from 'store/modules/bookingBuilder/types';

export const getAvailableProductSetAccommodationForUuid = (
  uuid: string,
  availableProductSets: BookingBuilderAvailableProductSets
): BookingBuilderAvailableProductSetsAccommodation | undefined => {
  const selectedAccommodation = availableProductSets.Accommodation.filter(a => {
    const productIds = a.products.map(p => p.uuid);
    return productIds.includes(uuid);
  });

  if (selectedAccommodation.length) {
    return selectedAccommodation[0];
  }
};

export const getNightsBreakdownForDates = (startDate: any, endDate: any, translate: Function) => {
  // see @https://pureescapes.atlassian.net/browse/OWA-1031
  const amendedEndDate = addDays(new Date(endDate), 1);

  const nights = differenceInCalendarDays(amendedEndDate, new Date(startDate));
  const dateRangeText = formatDateRangeDisplay(startDate, amendedEndDate);

  const nightSigular = translate ? translate('labels.nightSigular') : 'night';
  const nightPlural = translate ? translate('labels.nightPlural') : 'nights';

  return (
    <span>
      <strong>
        {nights} {nights > 1 ? nightPlural : nightSigular}
      </strong>{' '}
      | {dateRangeText}
    </span>
  );
};

export const getTitleForAccommodationUuid = (
  uuid: string,
  availableProductSets: BookingBuilderAvailableProductSets
): string => {
  const accommodation = getAvailableProductSetAccommodationForUuid(uuid, availableProductSets);

  if (!accommodation) {
    return '';
  }

  let productTitles: string[] = [];

  accommodation.products.forEach(product => {
    if (product.uuid === uuid) {
      productTitles.push(product.name);
    }
  });

  return productTitles.join('&');
};

export const getOccupancyBreakdownForAccommodation = (
  accommodation: RequestedBuildAccommodation | SelectedAccommodation
) => {
  const { numberOfAdults, agesOfAllChildren = [] } = accommodation.guestAges;
  const totalGuests = numberOfAdults + agesOfAllChildren.length;

  const adultsBreakdownString = `${numberOfAdults} ${numberOfAdults > 1 ? 'Adults' : 'Adult'}`;
  const childrenBreakdownString = `${agesOfAllChildren.length} ${agesOfAllChildren.length > 1 ? 'Children' : 'Child'}`;

  if (agesOfAllChildren.length) {
    return `${totalGuests} ${
      totalGuests > 1 ? 'Guests' : 'Guest'
    } (${adultsBreakdownString}, ${childrenBreakdownString})`;
  }

  return `${totalGuests} ${totalGuests > 1 ? 'Guests' : 'Guest'} (${adultsBreakdownString})`;
};

export const getMealPlanBreakdownForLodging = (
  requestedBuildLodging: RequestedBuildAccommodation | SelectedAccommodation,
  index: number,
  availableProductSets: BookingBuilderAvailableProductSets
) => {
  if (!requestedBuildLodging || !requestedBuildLodging.subProducts || !requestedBuildLodging.subProducts['Meal Plan']) {
    return null;
  }

  const availableProductAccommodation = availableProductSets.Accommodation[index];

  if (!availableProductAccommodation) {
    return null;
  }

  const selectedMealPlanSet = availableProductAccommodation.availableSubProductSets['Meal Plan'].filter(mealPlanSet => {
    return mealPlanSet.selected;
  })[0];

  // we have the selected meal plan set
  if (!selectedMealPlanSet) {
    return null;
  }

  const labelNames: any[] = selectedMealPlanSet.products.map(p => p.name);

  return <span>{labelNames.join(' & ')}</span>;
};

export const getLodgingTotals = (lodging: LodgingSummary, availableProductSets: AvailableProductSets) => {
  const selectedLodging = availableProductSets.Accommodation[lodging.index];

  if (!selectedLodging) {
    return {
      isOnRequest: false,
      total: '0',
      totalBeforeDiscount: '0',
    };
  }

  if (selectedLodging.isOnRequestOrPartiallyOnRequest) {
    return {
      isOnRequest: true,
      total: '0',
      totalBeforeDiscount: '0',
    };
  }

  let total = 0;
  let totalBeforeDiscount = 0;

  // get base lodging prices
  total += selectedLodging.total ? parseFloat(selectedLodging.total) : 0;
  totalBeforeDiscount += selectedLodging.totalBeforeDiscount ? parseFloat(selectedLodging.totalBeforeDiscount) : 0;

  // add meal plan prices
  selectedLodging.availableSubProductSets['Meal Plan']
    .filter(asp => asp.selected)
    .forEach(asp => {
      total += asp.total ? parseFloat(asp.total) : 0;
      totalBeforeDiscount += asp.totalBeforeDiscount ? parseFloat(asp.totalBeforeDiscount) : 0;
    });

  // add supplement prices
  selectedLodging.availableSubProductSets['Supplement']
    .filter(asp => asp.selected)
    .forEach(asp => {
      total += asp.total ? parseFloat(asp.total) : 0;
      totalBeforeDiscount += asp.totalBeforeDiscount ? parseFloat(asp.totalBeforeDiscount) : 0;
    });

  return {
    isOnRequest: false,
    total: formatPrice(total),
    totalBeforeDiscount: formatPrice(totalBeforeDiscount),
  };
};

export const getAppliedSupplementsForLodging = (
  lodging: LodgingSummary,
  availableProductSets: BookingBuilderAvailableProductSets,
  currencyCode: string
) => {
  try {
    const supplements = availableProductSets.Accommodation[lodging.index].availableSubProductSets.Supplement.filter(
      s => s.selected
    );

    if (!supplements) {
      return [];
    }

    // Applied Supplements rendering
    return supplements.map((supplement, index) => (
      <span key={`applied-supplement-${index}`}>
        {supplement.products.map(p => p.name).join(' & ')}{' '}
        <label>
          (
          {supplement.isOnRequestOrPartiallyOnRequest
            ? 'Price Available On Request'
            : `${currencyCode} ${formatPrice(supplement.total)}`}
          )
        </label>
      </span>
    ));
  } catch (e) {
    return [];
  }
};

export const getAppliedOffersForLodging = (
  lodging: LodgingSummary,
  availableProductSets: AvailableProductSets,
  textOnlyOffersPerLodging: any
) => {
  try {
    const getOfferName = (offer: OfferApplication) => offer.offer.name;

    const lodgingOffers = flatten(
      availableProductSets.Accommodation[lodging.index].breakdown.map(breakdown => breakdown.offers.map(getOfferName))
    );

    const lodgingSubProductOffers = availableProductSets.Accommodation[lodging.index].availableSubProductSets[
      'Meal Plan'
    ]
      .filter(asp => asp.selected)
      .map(asp => asp.breakdown.map(breakdown => breakdown.offers.map(getOfferName)));

    const textOffers = textOnlyOffersPerLodging[lodging.index].map(getOfferName);

    const flat = flatten([lodgingOffers, lodgingSubProductOffers, textOffers]);

    // needs to be unique, @see owa 1022
    return uniqBy(a => a, flat);
  } catch (e) {
    return [];
  }
};
export const getOccassionsArray = (accommodation: RequestedBuildAccommodation | SelectedAccommodation) => {
  const { honeymoon, birthday, anniversary, wedding } = accommodation;
  const occasions = { honeymoon, birthday, anniversary, wedding };

  return Object.keys(occasions)
    .map(o => (occasions[o] ? o : null))
    .filter(Boolean);
};

export const getOccassionsForAccommodation = (accommodation: SelectedAccommodation) => {
  const appliedOccasions = getOccassionsArray(accommodation);

  if (appliedOccasions.length <= 0) {
    return { length: 0, appliedOccasions: null };
  }

  if (appliedOccasions.length === 1) {
    return { length: 1, appliedOccasions: appliedOccasions[0] };
  }

  return {
    length: appliedOccasions.length,
    appliedOccasions:
      appliedOccasions
        .map(o => capitalize(o ?? ''))
        .slice(0, -1)
        .join(', ') +
      ' & ' +
      capitalize(appliedOccasions[appliedOccasions.length - 1] ?? ''),
  };
};

export const getOccassionsBreakdownForLodging = (
  accommodation: RequestedBuildAccommodation | SelectedAccommodation
) => {
  const appliedOccasions = getOccassionsArray(accommodation);

  if (appliedOccasions.length <= 0) {
    return null;
  }

  if (appliedOccasions.length === 1) {
    return appliedOccasions[0];
  }

  return appliedOccasions.slice(0, -1).join(', ') + ' & ' + appliedOccasions.slice(-1);
};

export const getBookingsEndpointAttributesForBookingDomain = props => {
  const {
    bookingDomain,
    bookingStatus = BookingStatusTypes.POTENTIAL,
    proposalUuid = undefined,
    paymentMethod = null,
  }: {
    bookingDomain: BookingBuilderDomain;
    bookingStatus: string;
    proposalUuid: string | undefined;
    paymentMethod: EPaymentMethod | null;
  } = props;

  const bookingHash = bookingDomain.bookingHash || bookingDomain.currentBookingBuilder?.response.bookingHash;
  const bookingBuild = cloneDeep(bookingDomain.currentBookingBuilder?.request);
  bookingBuild?.Accommodation.forEach(accom => {
    // @ts-ignore
    accom.liveRate && delete accom.liveRate.externalMealPlanDescription;
  });

  const bookingInformation = getBookingInformationForBooking(bookingDomain);
  const result = {
    bookingHash,
    bookingBuild,
    bookingInformation,
    status: bookingStatus,
    proposalUuid,
  };

  if (paymentMethod) {
    // @ts-ignore-line
    result.paymentInformation = {
      method: paymentMethod,
    };
  }

  return result;
};

export const getBookingInformationForBooking = (booking: IBookingModel | BookingBuilderDomain): IBookingInformation => {
  const specialRequests = [booking.comments, ...booking.specialRequests];
  return {
    guestTitle: booking.guestTitle || undefined,
    guestFirstName: booking.guestFirstName || '',
    guestLastName: booking.guestLastName || '',
    isRepeatGuest: booking.isRepeatGuest || false,
    flightArrivalNumber: booking.flightArrivalNumber || undefined,
    flightDepartureNumber: booking.flightDepartureNumber || undefined,
    taMarginType: booking.taMarginType || undefined,
    taMarginAmount: booking.taMarginAmount || undefined,
    comments: booking.comments || undefined,
    flightArrivalDate: booking.flightArrivalDate || undefined,
    flightDepartureDate: booking.flightDepartureDate || undefined,
    specialRequests: specialRequests || undefined,
    proposalUuid: booking.proposalUuid || undefined,
    travelAgentUserUuid: booking.travelAgentUserUuid || undefined,
  };
};

export const generateLodgingSummariesFromBooking = (t: TFunction, booking: BookingBuilder | null): LodgingSummary[] => {
  const lodgingSummaries = booking?.request.Accommodation.map((requestedAccommodation, requestedAccommodationIndex) => {
    const matchingAccommodationInPotentialBookingResponse = booking.response.potentialBooking.Accommodation.find(a => {
      return a.product.uuid === requestedAccommodation.uuid && requestedAccommodationIndex === a.roomIdx;
    });

    const lodging = {
      ...requestedAccommodation,
      index: requestedAccommodationIndex,
      hotelUuid: booking.request.hotelUuid,
      availableToInstantBook: booking.response.availableToInstantBook,
      title: getTitleForAccommodationUuid(requestedAccommodation.uuid, booking.response.availableProductSets),
      nightsBreakdown: getNightsBreakdownForDates(requestedAccommodation.startDate, requestedAccommodation.endDate, t),
      mealPlanBreakdown: getMealPlanBreakdownForLodging(
        requestedAccommodation,
        requestedAccommodationIndex,
        booking.response.availableProductSets
      ),
      occupancyBreakdown: getOccupancyBreakdownForAccommodation(requestedAccommodation),
      occasionsBreakdown: getOccassionsBreakdownForLodging(requestedAccommodation),
    };

    // live rates can't be instant book, no matter what is in the response
    if (lodging.liveRate) {
      lodging.availableToInstantBook = false;
    } else if (!lodging.liveRate && matchingAccommodationInPotentialBookingResponse) {
      // @ts-ignore availableToInstantBook DOES exist
      lodging.availableToInstantBook = matchingAccommodationInPotentialBookingResponse?.availableToInstantBook || false;
    } else if (!matchingAccommodationInPotentialBookingResponse) {
      lodging.availableToInstantBook = false;
    }

    return lodging;
  });

  // @ts-ignore
  return lodgingSummaries;
};

export const isLiveRatePricesAreDifferent = (realAccommodations, priceCheckAccommodations) => {
  return (
    realAccommodations.find((realAccom, index) => {
      if (!priceCheckAccommodations[index]?.priceCheck?.totalCents) {
        return true;
      }
      return (
        Math.abs(realAccom.liveRate.amount - priceCheckAccommodations[index].priceCheck.totalCents) >
        LIVE_RATES_PRICE_CHECK_TOLERANCE_CENTS
      );
    }) !== undefined
  );
};

export const isInstantBookRelatedError = e => {
  const related = [
    'Failed to create hotel confirmation pdf',
    'Failed to send instant book confirmation email to the hotel',
  ];
  return related.includes(e.response?.data?.errors?.[0]?.meta?.message);
};

export const getAccommodationOffers = (accommodation: ProductSetAccommodation) => {
  const accommodationOffers: string[] = [];
  accommodation.breakdown.forEach(item => {
    item.offers.forEach(offer => accommodationOffers.push(offer.offer.name));
  });
  return accommodationOffers;
}

export const getMealPlanOffers = (accommodation: ProductSetAccommodation) => {
  const mealPlanOffers: string[] = [];
  accommodation.availableSubProductSets['Meal Plan']
    .filter(mealPlan => mealPlan.selected)
    .forEach(mealPlan => {
      mealPlan.breakdown.forEach(item => {
        item.offers.forEach(offer => mealPlanOffers.push(offer.offer.name));
      });
    });
  return mealPlanOffers;
}
