import { IHotel, BookingBuilder, BookingBuilderResponse } from 'services/BackendApi';
import { getDefaultSearchAndBookingStartDate, getDefaultSearchAndBookingEndDate } from '../../utils';
import { composeCancellationPolicyFromRoomExpenseInfo } from 'common-lib/cancellation-policy-composer';
import { getCurrencySymbol } from 'utils';
import { BasketRateTypes } from 'interfaces';
import { flatten, uniqBy } from 'ramda';

export const makeBookingBuilderStub = (hotelResponse): BookingBuilder => {
  const hotel: IHotel = hotelResponse.data.data;

  return {
    response: {
      displayTotals: {
        blocks: [],
        appliedOfferNames: [],
        totals: {
          oneOrMoreItemsOnRequest: false,
          totalForPricedItemsCents: 0,
          totalBeforeDiscountForPricedItemsCents: 0,
          totalForPricedItems: '0.00',
          totalBeforeDiscountForPricedItems: '0.00',
          total: null,
          totalBeforeDiscount: null,
        },
      },
      availableToHold: false,
      mealPlanSubProductSelections: [],
      canBeBooked: false,
      mustStop: true,
      errors: [],
      currency: hotel.defaultCurrency,
      bookingHash: undefined,
      globalRoomCancellationPolicyText: null,
      isRefundable: null,
      potentialBooking: {
        Accommodation: [],
        Supplement: [],
        Transfer: [],
        'Ground Service': [],
        Fine: [],
      },
      availableProductSets: {
        Accommodation: [],
        Supplement: [],
        Transfer: [],
        'Ground Service': [],
        Fine: [],
      },
      textOnlyOffersPerLodging: [],
      appliedOfferNames: [],
      uploads: [],
      hotel,
      totals: {
        oneOrMoreItemsOnRequest: false,
        totalForPricedItemsCents: 0,
        totalBeforeDiscountForPricedItemsCents: 0,
        totalForPricedItems: '0.00',
        totalBeforeDiscountForPricedItems: '0.00',
        total: '0.00',
        totalBeforeDiscount: '0.00',
      },
      minimumNightsReview: false,
      availableToInstantBook: false,
      expenseInfosGroupedByRoom: [],
    },
    request: {
      startDate: getDefaultSearchAndBookingStartDate(),
      endDate: getDefaultSearchAndBookingEndDate(),
      guestAges: {
        numberOfAdults: 0,
        agesOfAllChildren: [],
      },
      hotelUuid: hotel.uuid,
      Accommodation: [],
      Transfer: [],
      'Ground Service': [],
      Fine: [],
      Supplement: [],
      customItems: [],
    },
    bookingErrors: [],
  };
};

const makeStubAggregateRecord = (title: string) => ({
  title,
  quantity: 0,
  oneOrMoreItemsOnRequest: false,
  totalForPricedItemsCents: 0,
  totalBeforeDiscountForPricedItemsCents: 0,
  totalForPricedItems: '0.00',
  totalBeforeDiscountForPricedItems: '0.00',
  total: '0.00',
  totalBeforeDiscount: '0.00',
  offers: [],
  drilldown: [],
});

export const totalCentsAsString = (total: number): string => (total / 100).toFixed(2);

export const getCancellationPoliciesFromResponse = (
  response: BookingBuilderResponse | undefined,
  rateType: BasketRateTypes | undefined
) => {
  if (!response) {
    return [];
  }

  let allCancellationPolicies: string[] = [];

  // this slightly odd formatting is to match how src/containers/HotelContainer/HotelContainer.tsx
  // does its formatting. we can simply this at some point
  const roomPolicies = response.expenseInfosGroupedByRoom.map((roomExpenseInfo, index) => {
    const x = composeCancellationPolicyFromRoomExpenseInfo(roomExpenseInfo, {
      currencySymbol: getCurrencySymbol(response.currency),
      appendLines: ['*at 00.00 time at destination'],
    });
    x[0] = `Accommodation ${index + 1}: ${x[0]}`;
    return x.join('\n');
  });

  allCancellationPolicies = [...roomPolicies, ...allCancellationPolicies];

  allCancellationPolicies = allCancellationPolicies.concat(
    response.potentialBooking.Fine.map(product => product.cancellationPolicy)
  );
  allCancellationPolicies = allCancellationPolicies.concat(
    response.potentialBooking['Ground Service'].map(product => product.cancellationPolicy)
  );
  allCancellationPolicies = allCancellationPolicies.concat(
    response.potentialBooking.Supplement.map(product => product.cancellationPolicy)
  );
  allCancellationPolicies = allCancellationPolicies.concat(
    response.potentialBooking.Transfer.map(product => product.cancellationPolicy)
  );

  return uniqBy(a => a, flatten(allCancellationPolicies).filter(Boolean));
};
