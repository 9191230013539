import { useMutation } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";

export const useUnlinkDirectoryEntriesMutation = () => {
  const crmApi = makeCRMApi();
  
  const unlinkDirectoryEntryMutation = useMutation({
    mutationKey: ['crm-unlink-directory-entries'],
    mutationFn: (param: string) => crmApi.unlinkDirectoryEntries(param),
  });
  
  return unlinkDirectoryEntryMutation;
}
