import { reverse, sortBy as sortByFn } from 'lodash-es';
import { useCallback, useEffect, useState } from 'react';
import { ESortOrder } from 'store/common/types';

const getSortedItems = <T>(sortBy: keyof T, sortOrder: ESortOrder, items: T[]): T[] => {
  const sortedItems = sortByFn(items, [sortBy]);
  if (sortOrder === ESortOrder.DESC) {
    reverse(sortedItems);
  }
  return sortedItems;
}

export const useInplaceSorting = <T>(initialSortBy: keyof T, initialSortOrder: ESortOrder, items: T[]) => {
  const [sortBy, setSortBy] = useState<keyof T>(initialSortBy);
  const [sortOrder, setSortOrder] = useState<ESortOrder>(initialSortOrder);
  const [sortedItems, setSortedItems] = useState<T[]>(
    getSortedItems(initialSortBy, initialSortOrder, items)
  );

  useEffect(() => {
    const newSortedItems = getSortedItems(sortBy, sortOrder, items);
    setSortedItems(newSortedItems);
  }, [items, sortBy, sortOrder]);

  const handleSort = useCallback((newSortBy: keyof T = initialSortBy, newSortOrder: ESortOrder = initialSortOrder) => {
    setSortBy(newSortBy);
    setSortOrder(newSortOrder);
  }, [sortedItems]);

  return { sortedItems, sortBy, sortOrder, handleSort };
}