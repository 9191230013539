import { prop } from 'ramda';
import i18n from '../../i18n';
import formConfig from '../';

export default {
  title: {
    label: i18n.t('labels.titleOptional'),
    default: '',
    props: {
      options: prop('titles', formConfig),
    },
  },
  firstName: {
    label: i18n.t('labels.firstName'),
    default: '',
  },
  lastName: {
    label: i18n.t('labels.lastName'),
    default: '',
  },
  email: {
    label: i18n.t('labels.emailAddress'),
    default: '',
  },
};
