import React from 'react';
import classNames from 'classnames';

interface iNumberSelectProps {
  className: string;
  min: number;
  max: number;
  value: number;
  onAdd: () => void;
  onRemove: () => void;
}

export const NumberSelect = (props: iNumberSelectProps) => {
  return (
    <div className={classNames('flex items-center gap-2 h-[40px]', props.className)}>
      <button
        className={classNames(
          'rounded-full bg-brown-prime text-white hover:bg-teal-100 min-w-[28px] min-h-[28px] text-[1.5rem] cursor-pointer',
          {
            'opacity-50 pointer-events-none': props.value <= props.min,
          }
        )}
        onClick={() => {
          if (props.value <= props.min) {
            return;
          }
          return props.onRemove();
        }}
      >
        -
      </button>
      <span className="font-bold min-w-[16px] text-center">{props.value}</span>
      <button
        className={classNames(
          'rounded-full bg-brown-prime text-white hover:bg-teal-100 min-w-[28px] min-h-[28px] text-[1.5rem] cursor-pointer',
          {
            'opacity-50 pointer-events-none': props.value >= props.max,
          }
        )}
        onClick={() => {
          if (props.value >= props.max) {
            return;
          }
          return props.onAdd();
        }}
      >
        +
      </button>
    </div>
  );
};
