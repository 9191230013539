import * as Yup from 'yup';

export const contactProfileFormValidationSchema = Yup.object().shape({
  contactTypeCode: Yup.string().required('Field is required'),
  titleTypeCode: Yup.string().nullable(true),
  firstName: Yup.string().required('Field is required'),
  lastName: Yup.string().nullable(true),
  email: Yup.string().email('Must be valid email'),
  acceptsEmail: Yup.boolean(),
  countryCode: Yup.string().nullable(true),
  region: Yup.string().nullable(true),
  address: Yup.string().nullable(true),
  mobile: Yup.string().nullable(true),
  landline: Yup.string().nullable(true),
  birthday: Yup.string().nullable(true),
  personalDocumentTypeCode: Yup.string().nullable(true),
  personalDocumentNumber: Yup.string().nullable(true),
  personalDocumentCountryCode: Yup.string().nullable(true),
  roleTypeCode: Yup.string().nullable(true),
  roleEnd: Yup.string().nullable(true),
  roleStart: Yup.string().nullable(true),
  linkedin: Yup.string().nullable(true),
  twitter: Yup.string().nullable(true),
  instagram: Yup.string().nullable(true),
});

export interface IContactProfileFormValues {
  contactTypeCode: string;
  titleTypeCode: string | null;
  firstName: string;
  lastName: string | null;
  email: string | null;
  acceptsEmail?: boolean;
  countryCode: string | null;
  region: string | null;
  address: string | null;
  mobile: string | null;
  landline: string | null;
  birthday: string | null;
  personalDocumentTypeCode: string | null;
  personalDocumentNumber: string | null;
  personalDocumentCountryCode: string | null;
  roleTypeCode: string | null;
  roleStart: string | null;
  roleEnd: string | null;
  linkedin: string | null;
  twitter: string | null;
  instagram: string | null;
}
