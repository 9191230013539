import React, { useCallback, useEffect, useMemo } from 'react';
import classnames from 'classnames';

import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { ENetworkRequestStatus } from 'services/BackendApi';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import FluidButton from 'ui/FluidButton';
import { MapStayFormValidationSchema } from './formValidation';
import { GeneralModal } from 'ui/GeneralModal';
import SingleSelect from 'ui/SingleSelect';
import { RadioListBox } from 'ui/RadioListBox';
import { createActions } from 'store/modules/liveRates/actions';
import { mappableStaysSelector, requestMappableStaysGetSelector, requestMappableStaysPatchSelector, unmappedHotelsSelector } from 'store/modules/liveRates/selectors';
import { ELiveRatesExternalSystem } from 'services/BackendApi/types/LiveRatesInternal';
import { sortBy } from 'lodash-es';
import { LoadingBar } from 'ui/NetworkStatusBar';

const externalSupplierOptions = [
  {
    value: ELiveRatesExternalSystem.DERBYSOFT,
    label: 'DerbySoft',
  },
  {
    value: ELiveRatesExternalSystem.ILLUSIONS,
    label: 'Illusions',
  },
  {
    value: ELiveRatesExternalSystem.JUMEIRAH,
    label: 'Jumeirah',
  },
  {
    value: ELiveRatesExternalSystem.MOCK,
    label: 'Mock',
  },
];

export interface IMapStayModalProps {
  onCancel: () => void;
}

export interface IMapStayFormValues {
  stayUuid: string | null;
  externalStayUuid: string | null;
  externalSupplier: ELiveRatesExternalSystem | null;
}

export const MapStayModal = (props: IMapStayModalProps) => {
  const { onCancel } = props;

  const dispatch = useDispatch();
  const { dynamicParameters } = useDynamicParameters();

  const unmappedHotels = useSelector(unmappedHotelsSelector);
  const mappableStays = useSelector(mappableStaysSelector);
  const requestMappableStaysGet = useSelector(requestMappableStaysGetSelector);
  const requestMappableStaysPatch = useSelector(requestMappableStaysPatchSelector);
  const isLoading = requestMappableStaysGet === ENetworkRequestStatus.PENDING;
  const isSaving = requestMappableStaysPatch === ENetworkRequestStatus.PENDING;

  const mapStayModalContentClassname = classnames('map-stay-modal-content flex flex-col flex-1');
  const sortedMappableStays = useMemo(() => sortBy(mappableStays, ['name']), [mappableStays]);

  const sortedUnmappedHotels = useMemo(() => sortBy(unmappedHotels, ['name']), [unmappedHotels]);
  const stayOptions = sortedUnmappedHotels.map(unmappedHotel => ({
    label: unmappedHotel.name,
    value: unmappedHotel.uuid,
  }));

  const validExternalSupplierOptions = useMemo(() =>
    externalSupplierOptions
      .filter(x => dynamicParameters.LIVE_RATE_SUPPLIERS_LIST?.includes(x.value)),
    [dynamicParameters.LIVE_RATE_SUPPLIERS_LIST]
  );

  useEffect(() => {
    dispatch(createActions.getMappableStaysRequestAction())
  }, [dispatch]);

  const initialValues: IMapStayFormValues = {
    stayUuid: null,
    externalStayUuid: null,
    externalSupplier: null,
  };

  const handleFormSubmit = useCallback(
    (mapStayFormValues: IMapStayFormValues, formikHelpers: FormikHelpers<IMapStayFormValues>) => {
      const { stayUuid, externalStayUuid, externalSupplier } = mapStayFormValues;
      if (!stayUuid || !externalStayUuid || !externalSupplier) {
        return;
      }
      dispatch(createActions.patchMappableStayRequestAction(stayUuid, externalStayUuid, externalSupplier));

      formikHelpers.setSubmitting(false);
    },
    [dispatch]
  );

  return (
    <GeneralModal
      onClose={onCancel}
      modalWindowClassName="flex justify-center max-w-[470px] px-35px py-25px rounded"
      shouldCloseByClickingOutside
    >
      <div className={classnames('map-stay-modal flex justify-center w-screen')}>
        <div className={mapStayModalContentClassname}>
          <h3 className="m-0 font-noe-display font-normal text-black text-[21px] leading-[27px]">
            New Mapping
          </h3>
          <p className="font-pt-sans text-15px leading-22px text-black mb-0 mt-25px">
            Select a Stay from the application and the External Supplier to provide Live Rates for it. 
            <br />
            <br />
            When selecting a Supplier you can then select one of their services to map the Stay to. In most cases the name matches, but there might be differences.
          </p>

          {isLoading && (
            <div className="mt-5"><LoadingBar label="Loading..." /></div>
          )}

          {!isLoading && (
            <div>
              <Formik
                initialValues={initialValues}
                validationSchema={MapStayFormValidationSchema}
                enableReinitialize={true}
                validateOnBlur={false}
                onSubmit={handleFormSubmit}
              >
                {(form: FormikProps<IMapStayFormValues>) => {
                  const externalStayOptions = sortedMappableStays
                    .filter(item => item.externalSystem === form.values.externalSupplier)
                    .map(item => ({
                      label: item.name + ' - ID ' + item.externalHotelId,
                      value: item.externalHotelId,
                    }));
                  const someFieldsAreEmpty = form.values.stayUuid === null
                    && form.values.externalStayUuid === null
                    && form.values.externalSupplier === null;

                  return (
                    <form onSubmit={form.handleSubmit}>
                      <div className="flex flex-col gap-[25px] mt-25px">
                        <Field name="stayUuid">
                          {({ field: { name, value }, form: { setFieldValue } }) => (
                            <SingleSelect
                              fieldId="stayUuid"
                              label="Stay"
                              className="stayUuid max-w-[400px]"
                              labelClassName="text-[16px] leading-[21px] font-bold"
                              value={value}
                              options={stayOptions}
                              onChange={value => {
                                setFieldValue(name, value);
                              }}
                              maxVisibleItems={6}
                              scrollToSelectedItem
                              errorMessage={
                                form.touched.stayUuid && form.errors.stayUuid ? form.errors.stayUuid : null
                              }
                              errorClassName="stayUuid-error"
                            />
                          )}
                        </Field>
                        
                        <Field name="externalSupplier">
                          {({ field: { name, value }, form: { setFieldValue } }) => (
                            <SingleSelect
                              fieldId="externalSupplier"
                              label="External Supplier"
                              className="externalSupplier max-w-[400px]"
                              labelClassName="text-[16px] leading-[21px] font-bold"
                              value={value}
                              options={validExternalSupplierOptions}
                              onChange={value => {
                                setFieldValue(name, value);
                              }}
                              maxVisibleItems={4}
                              errorMessage={
                                form.touched.externalSupplier && form.errors.externalSupplier ? form.errors.externalSupplier : null
                              }
                              errorClassName="externalSupplier-error"
                            />
                          )}
                        </Field>

                        <Field name="externalStayUuid">
                          {({ field: { name, value }, form: { setFieldValue } }) => (
                            <RadioListBox
                              id="externalStayUuid"
                              label="External Stay"
                              value={value}
                              options={externalStayOptions}
                              textWhenEmpty={form.values.externalSupplier === null ? 'Select External Supplier.' : 'No External Stay found.'}
                              className="externalStayUuid max-w-[400px] h-[180px]"
                              labelClassName="text-[16px] leading-[21px] font-bold"
                              onChange={value => {
                                setFieldValue(name, value);
                              }}
                              errorMessage={
                                form.touched.externalStayUuid && form.errors.externalStayUuid ? form.errors.externalStayUuid : null
                              }
                              errorClassName="externalStayUuid-error"
                            />
                          )}
                        </Field>

                        <div className="map-stay-buttons flex gap-10px">
                          <FluidButton className="map-stay-cancel" type="secondary" onClick={onCancel}>
                            Cancel
                          </FluidButton>
                          <FluidButton
                            className="map-stay-save"
                            type="primary"
                            onClick={() => form.submitForm()}
                            isLoading={isSaving}
                            disabled={!form.isValid || isLoading || isSaving || someFieldsAreEmpty}
                          >
                            Save
                          </FluidButton>
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </GeneralModal>
  );
};
