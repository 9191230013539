import React from 'react';
import classnames from 'classnames';
import { TCurrencyCode } from 'interfaces';
import { formatPriceCents, getCurrencySymbol } from 'utils';
import { IStaticRate } from './types';
import { isEqual } from 'lodash-es';

export interface IMealPlanOptionPriceProps {
  currency: TCurrencyCode;
  isOnRequest: boolean;
  totalBeforeDiscountCents?: number;
  totalCents?: number;
  isLiveRate: boolean;
}

export const MealPlanOptionPrice: React.FC<IMealPlanOptionPriceProps> = ({
  totalBeforeDiscountCents,
  totalCents,
  isOnRequest,
  currency,
  isLiveRate,
}) => {
  if (isLiveRate) {
    return null;
  }
  if (isOnRequest) {
    return <span className="meal-plan-price-on-request italic">(COST ON REQUEST)</span>;
  }
  if (totalBeforeDiscountCents && totalCents && totalCents < totalBeforeDiscountCents) {
    return (
      <>
        <span className="meal-plan-price-before-discount line-through">
          &nbsp;{getCurrencySymbol(currency)}
          {formatPriceCents(totalBeforeDiscountCents)}
        </span>
        <span className="meal-plan-price-after-discount text-red-92">
          &nbsp;{getCurrencySymbol(currency)}
          {formatPriceCents(totalCents)}
        </span>
      </>
    );
  }
  return (
    <span className="meal-plan-price-no-discount">
      &nbsp;{getCurrencySymbol(currency)}
      {formatPriceCents(totalCents)}
    </span>
  );
};

export interface IMealPlanDropdownOptionsProps {
  currency: TCurrencyCode;
  value?: IStaticRate;
  options: IStaticRate[];
  maxVisibleItems?: number;
  onChoose: (value: IStaticRate) => void;
  isLiveRate: boolean;
}

export const MealPlanDropdownOptions: React.FC<IMealPlanDropdownOptionsProps> = React.memo(
  ({ options, value, maxVisibleItems, onChoose, isLiveRate, currency }) => {
    const optionsHeight = maxVisibleItems ? { style: { maxHeight: `${maxVisibleItems * 35}px` } } : {};

    return (
      <div className="meal-plan-dropdown-options flex flex-col overflow-y-auto select-none bg-white" {...optionsHeight}>
        {options.length === 0
          ? null
          : options.map(option => {
              const key = option.mealPlan.uuids.join('_');
              const label = (
                <p className="font-pt-sans text-black text-[15px] capitalize m-0">
                  {option.mealPlan.names.join(' & ')}
                  {!isLiveRate && (
                    <MealPlanOptionPrice
                      isOnRequest={option.mealPlan.isOnRequest}
                      totalBeforeDiscountCents={option.mealPlan.totalBeforeDiscountCents}
                      currency={currency}
                      totalCents={option.mealPlan.totalCents}
                      isLiveRate={isLiveRate}
                    />
                  )}
                </p>
              );
              return (
                <span
                  key={key}
                  className={classnames(
                    `meal-plan-dropdown-option value-${key} flex items-center hover:bg-green-prime-20 px-[10px]`
                  )}
                  onClick={() => onChoose(option)}
                >
                  <span
                    className={classnames(
                      'font-pt-sans text-black py-[10px] text-[15px] leading-[19px] text-wrap overflow-hidden text-ellipsis uppercase',
                      {
                        'font-bold': isEqual(option, value),
                      }
                    )}
                  >
                    {label}
                  </span>
                </span>
              );
            })}
      </div>
    );
  }
);
