import styled, { css } from 'styled-components';

import { theme } from 'styles';
import { Link } from 'ui/Link';

export const HeaderContainer = styled.div`
  display: flex;
  height: ${theme.sizes.headerSizes.mobile}px;
  border-bottom: 1px solid ${theme.borders.medium};
  align-items: center;

  > div {
    flex: 1 1 50%;
    width: 50%;
    margin: 0 ${theme.spacing.gutter * 2}px;
  }

  ${props => props.theme.breakpoints.desktop`
    height: ${theme.sizes.headerSizes.tablet}px;

    > div {
      width: unset;
      flex: 1 1 auto;
      
      :first-child, :last-child {
        margin: 0;
      }
    }
  `}
`;

export const StyledHeader = styled.div`
  position: relative;
  background: ${theme.backgrounds.default};
  width: 100%;
  position: fixed;
  z-index: 10;
  top: 0;

  ${props => props.theme.breakpoints.desktop`
    position: relative;
    padding: 0;
  `}
`;

export const HeaderLogo = styled(Link)`
  margin: ${theme.spacing.gutter}px ${theme.spacing.gutter * 1.5}px;
  display: block;
  margin: 0 ${theme.spacing.gutter * 2}px;
  padding: 0;
  outline: 0;
  width: 213px !important;
  ${props => props.theme.breakpoints.desktop`
    margin: ${theme.spacing.gutter}px;
  `}

  img {
    display: block;
    margin: 0;
    padding: 0;
    max-width: 100%;
  }
`;

export const HeaderMenuArea = styled.div`
  width: 100%;
  text-align: right;
`;

export const HeaderMenuWrapper = styled.div``;
