import { IBookingManagerHotelDetails } from './types';

export const GET_HOTEL_DETAILS_REQUEST = 'bookingManager_finance/GET_HOTEL_DETAILS_REQUEST';
export const GET_HOTEL_DETAILS_SUCCESS = 'bookingManager_finance/GET_HOTEL_DETAILS_SUCCESS';
export const GET_HOTEL_DETAILS_FAILURE = 'bookingManager_finance/GET_HOTEL_DETAILS_FAILURE';

export const SET_RESORT_CONFIRMATION_NUMBER = 'bookingManager_finance/SET_RESORT_CONFIRMATION_NUMBER';

export const PATCH_HOTEL_DETAILS_REQUEST = 'bookingManager_finance/PATCH_HOTEL_DETAILS_REQUEST';
export const PATCH_HOTEL_DETAILS_SUCCESS = 'bookingManager_finance/PATCH_HOTEL_DETAILS_SUCCESS';
export const PATCH_HOTEL_DETAILS_FAILURE = 'bookingManager_finance/PATCH_HOTEL_DETAILS_FAILURE';

export type GetHotelDetailsRequestAction = ReturnType<typeof getHotelDetailsRequestAction>;
export const getHotelDetailsRequestAction = () => ({
  type: GET_HOTEL_DETAILS_REQUEST as typeof GET_HOTEL_DETAILS_REQUEST,
});

export type GetHotelDetailsSuccessAction = ReturnType<typeof getHotelDetailsSuccessAction>;
export const getHotelDetailsSuccessAction = (hotelDetails: IBookingManagerHotelDetails[]) => ({
  type: GET_HOTEL_DETAILS_SUCCESS as typeof GET_HOTEL_DETAILS_SUCCESS,
  hotelDetails,
});

export type GetHotelDetailsFailureAction = ReturnType<typeof getHotelDetailsFailureAction>;
export const getHotelDetailsFailureAction = (error: string) => ({
  type: GET_HOTEL_DETAILS_FAILURE as typeof GET_HOTEL_DETAILS_FAILURE,
  error,
});

export type SetResortConfirmationNumberAction = ReturnType<typeof setResortConfirmationNumberAction>;
export const setResortConfirmationNumberAction = (resortConfirmationNumber: string) => ({
  type: SET_RESORT_CONFIRMATION_NUMBER as typeof SET_RESORT_CONFIRMATION_NUMBER,
  resortConfirmationNumber,
});

export type PatchHotelDetailsRequestAction = ReturnType<typeof patchHotelDetailsRequestAction>;
export const patchHotelDetailsRequestAction = () => ({
  type: PATCH_HOTEL_DETAILS_REQUEST as typeof PATCH_HOTEL_DETAILS_REQUEST,
});

export type PatchHotelDetailsSuccessAction = ReturnType<typeof patchHotelDetailsSuccessAction>;
export const patchHotelDetailsSuccessAction = (hotelDetails: IBookingManagerHotelDetails[]) => ({
  type: PATCH_HOTEL_DETAILS_SUCCESS as typeof PATCH_HOTEL_DETAILS_SUCCESS,
  hotelDetails,
});

export type PatchHotelDetailsFailureAction = ReturnType<typeof patchHotelDetailsFailureAction>;
export const patchHotelDetailsFailureAction = (error: string) => ({
  type: PATCH_HOTEL_DETAILS_FAILURE as typeof PATCH_HOTEL_DETAILS_FAILURE,
  error,
});

export type HotelDetailsAction =
  | GetHotelDetailsRequestAction
  | GetHotelDetailsSuccessAction
  | GetHotelDetailsFailureAction
  | SetResortConfirmationNumberAction
  | PatchHotelDetailsRequestAction
  | PatchHotelDetailsSuccessAction
  | PatchHotelDetailsFailureAction;
