import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import { Link } from 'ui/Link';

import { SettingsForm } from 'containers';
import { CompanyDetailsFormConnected } from 'containers/CompanyDetailsForm';

import { UserContainer, Main, Aside } from './User.styles';
import * as InvHeaderSelectors from 'store/modules/inventoryHeader/selectors';
import { StandardModal } from 'pureUi/Modal';
import classNames from 'classnames';

const SettingsPagesEnum = {
  password: 'password',
  companyDetails: 'company-details',
  profileDetails: 'profile-details',
};

export const Settings = ({ history, match, ...props }) => {
  const { t } = useTranslation();

  const onClose = useCallback(() => history.push('/settings'), [history]);
  const section = match.params.section;
  let Component;
  let activePage;

  switch (section) {
    case SettingsPagesEnum.companyDetails:
      Component = CompanyDetailsFormConnected;
      activePage = SettingsPagesEnum.companyDetails;
      break;
    case SettingsPagesEnum.profileDetails:
    default:
      Component = SettingsForm;
      activePage = SettingsPagesEnum.profileDetails;
  }

  const invHeaderUrl = useSelector(InvHeaderSelectors.getLastSetURLSelector);

  return (
    <>
      <Link
        type="internal"
        to={invHeaderUrl}
        styledAsButton={true}
        className="ml-20px"
        internalClassName="rounded-none uppercase space-x-2 font-hurmegeometric-sans"
      >
        <span>{`< Go Back`}</span>
      </Link>
      <div className="container mx-auto max-w-1280px">
        <UserContainer>
          <Aside>
            <div>
              <h1 className="font-noe-display tracking-[1px] text-[22px] text-flint">{t('labels.settings')}</h1>
              <div className="flex flex-col divide-solid divide-y-1 divide-gray-50 space-y-4">
                <span className="pt-4 border-t-1 border-solid border-gray-50">
                  <Link
                    to={`/settings/${SettingsPagesEnum.profileDetails}`}
                    className={classNames('uppercase font-hurmegeometric-sans text-11px', {
                      'font-bold': SettingsPagesEnum.profileDetails === activePage,
                    })}
                  >
                    {t('labels.profileDetails')}
                  </Link>
                </span>
                <span className="pt-4">
                  <Link
                    to={`/settings/${SettingsPagesEnum.companyDetails}`}
                    className={classNames('uppercase font-hurmegeometric-sans text-11px', {
                      'font-bold': SettingsPagesEnum.companyDetails === activePage,
                    })}
                  >
                    {t('labels.companyDetails')}
                  </Link>
                </span>
              </div>
            </div>
          </Aside>
          <Main>{Component && <Component {...props} />}</Main>
        </UserContainer>
      </div>
    </>
  );
};

export default Settings;
