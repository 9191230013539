import { useMutation } from '@tanstack/react-query';
import { makeCRMApi } from '..';

export const useRemoveLeadMutation = () => {
  const crmApi = makeCRMApi();
  
  const removeLeadMutation = useMutation({
    mutationKey: ['crm-remove-lead'],
    mutationFn: (leadId: string) => crmApi.removeLead(leadId),
  });
  
  return removeLeadMutation;
}
