import React from 'react'
import classnames from 'classnames';

interface IBorderWithLabelProps {
  label?: string;
  children: React.ReactNode;
  wrappingError?: string;
}

export const BorderWithLabel: React.FC<IBorderWithLabelProps> = ( { label, children, wrappingError }) => {
  const wrapperClassname = classnames('wrapper relative mt-[20px] py-[15px] border border-solid', {
    'border-red-95': wrappingError,
    'border-gray-20': !wrappingError,
  });

  return (
    <>
      <div className={wrapperClassname}>
        <span className="label absolute top-[-9px] left-[10px] font-pt-sans text-[12px] traking-[0.48px] uppercase text-gray-100 bg-white px-[5px]">{label}</span>
        {children}
      </div>
      {wrappingError && (
        <p className="error text-red-95 text-13px leading-[15px] font-pt-sans mt-5px mb-0">
          {wrappingError}
        </p>
      )}
    </>
  )
}
