import { pick } from 'lodash-es';
import { call, takeLatest, select, put, all } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import { saveAs } from 'file-saver';
import * as Actions from './actions';
import * as BootstrapActions from '../bootstrap/actions';
import * as Selectors from './selectors';
import { FormData, HotelComment } from './model';
import {
  makeBookingManagerApi,
  Proposal,
  ProposalRequest,
  IGetHotelDetailsResponse,
  IHeadlineLineItemBreakdownResponse,
  EProposalLogo,
} from 'services/BookingManagerApi';
import { IProposalSelection } from 'services/BookingManagerApi/types/ProposalSelection';
import { enqueueNotification } from '../ui';
import { AxiosResponse } from 'axios';
import * as BootstrapSelectors from 'store/modules/bootstrap/selectors';

export function* initFormSaga(action: Actions.InitFormAction) {
  if (action.proposalUuid) {
    return yield initFromProposal(action.proposalUuid);
  }
  yield initFromProposalSelection(action.proposalSelectionUuid, action.bookingUuids);
  yield put(Actions.saveRequestAction());
}

export function* initFromProposal(proposalUuid: string) {
  const api = makeBookingManagerApi();

  const proposal: Proposal = yield getProposal(proposalUuid);

  const hotelDetailsRequests = proposal.bookingUuids.map(bookingUuid => call(api.getHotelDetails, bookingUuid));
  const hotelDetailsResponses: AxiosResponse<IGetHotelDetailsResponse>[] = yield all(hotelDetailsRequests);

  const hotelComments: HotelComment[] = [];
  hotelDetailsResponses.forEach((hotelDetailsResponse, idx) => {
    hotelDetailsResponse.data.hotelDetails.forEach(hotelDetail => {
      hotelComments.push({
        hotelUuid: hotelDetail.uuid,
        name: hotelDetail.name,
        content: proposal.hotelComments[idx].content,
      });
    });
  });

  yield put(Actions.setAllHotelCommentsAction(hotelComments));
}

export function* initFromProposalSelection(proposalSelectionUuid: string, bookingUUids: string[]) {
  const selection = yield getProposalSelection(proposalSelectionUuid);

  yield put(Actions.setNameAction(selection.name));

  yield put(Actions.setGuestTitleAction(selection.guestTitle || null));
  yield put(Actions.setGuestFirstNameAction(selection.guestFirstName));
  yield put(Actions.setGuestLastNameAction(selection.guestLastName));

  yield initHotelCommentsFromHotelDetails(bookingUUids);
}

export function* initHotelCommentsFromHotelDetails(bookingUuids: string[]) {
  const api = makeBookingManagerApi();

  const hotelDetailsRequests = bookingUuids.map(bookingUuid => call(api.getHotelDetails, bookingUuid));
  const hotelDetailsResponses: AxiosResponse<IGetHotelDetailsResponse>[] = yield all(hotelDetailsRequests);

  const hotelComments: HotelComment[] = [];
  hotelDetailsResponses.forEach((hotelDetailsResponse, idx) => {
    hotelDetailsResponse.data.hotelDetails.forEach(hotelDetail => {
      hotelComments.push({
        hotelUuid: hotelDetail.uuid,
        name: hotelDetail.name,
        content: null,
      });
    });
  });

  yield put(Actions.setAllHotelCommentsAction(hotelComments));
}

export function* getProposal(proposalUuid: string) {
  yield put(Actions.getRequestAction());

  try {
    const api = makeBookingManagerApi();

    const res: AxiosResponse<Proposal> = yield call(api.getProposal, proposalUuid);

    yield put(Actions.getSuccessAction(res.data));
    return res.data;
  } catch (error) {
    yield put(Actions.getFailureAction());
  }
}

export function* getProposalSelection(proposalSelectionUuid: string) {
  yield put(Actions.getSelectionRequestAction());

  try {
    const api = makeBookingManagerApi();

    const res: AxiosResponse<IProposalSelection> = yield call(api.getProposalSelection, proposalSelectionUuid);

    const breakdownRequests = res.data.bookingUuids.map(bookingUuid =>
      call(api.getHeadlineLineItemBreakdown, bookingUuid)
    );
    const breakdownResponses: AxiosResponse<IHeadlineLineItemBreakdownResponse>[] = yield all(breakdownRequests);
    const taMarginIncluded =
      breakdownResponses
        .map(response => response.data?.headlineLineItemBreakdown?.MarginPercentage ?? 0)
        .filter(taMarginPercentage => taMarginPercentage > 0).length > 0;
    if (taMarginIncluded) {
      yield put(Actions.toggleTaMarginIncludedAction());
    }
    res.data.taMarginIncluded = taMarginIncluded;

    yield put(Actions.getSelectionSuccessAction(res.data));
    return res.data;
  } catch (error) {
    yield put(Actions.getSelectionFailureAction());
  }
}

export function* saveProposal() {
  const api = makeBookingManagerApi();

  const proposal: Proposal = yield select(Selectors.proposal);

  const proposalSelection: IProposalSelection = yield select(Selectors.proposalSelection);
  const bookingUuids: string[] = yield select(Selectors.bookingUuids);

  const formData: FormData = yield select(Selectors.formData);

  const payload = {
    proposalSelectionUuid: proposal?.proposalSelectionUuid || proposalSelection.uuid,
    bookingUuids: proposal?.bookingUuids || bookingUuids,
    ...formData,
    hotelComments: formData.hotelComments.map(x => pick(x, ['hotelUuid', 'content'])),
  } as ProposalRequest;

  const res: AxiosResponse<Proposal> = proposal
    ? yield call(api.updateProposal, proposal.uuid, payload)
    : yield call(api.createProposal, payload);

  return res.data;
}

export function* saveRequestSaga() {
  try {
    const proposal: Proposal = yield saveProposal();
    yield put(Actions.saveSuccessAction(proposal));

    enqueueNotification({
      message: 'Proposal PDF has been saved successfully.',
      options: { variant: 'success' },
    });
  } catch (e) {
    yield put(Actions.saveFailureAction());
    yield put(
      enqueueNotification({
        message: 'Error saving proposal PDF.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* downloadRequestSaga() {
  try {
    const proposal: Proposal = yield saveProposal();
    yield put(Actions.saveSuccessAction(proposal));

    saveAs(proposal.pdfUrl);
    yield put(Actions.downloadSuccessAction());
  } catch (e) {
    yield put(Actions.downloadFailureAction());
    yield put(
      enqueueNotification({
        message: 'Error downloading proposal PDF.',
        options: { variant: 'error' },
      })
    );
  }
}

export function* updatePathSaga() {
  const proposal: Proposal = yield select(Selectors.proposal);
  if (proposal) {
    const path = `/proposals-v2/${proposal.proposalSelectionUuid}/generate?proposal=${proposal.uuid}`;
    yield put(replace(path));
  }
}

export function* setLogoOptionsSaga() {
  const mainCompanyInfo = yield select(BootstrapSelectors.getMainCompanySelector);
  const logoOptions = yield select(Selectors.proposalLogoOptionsSelector);
  if (mainCompanyInfo) {
    logoOptions[logoOptions.findIndex(obj => obj.value === EProposalLogo.MAIN_COMPANY)][
      'label'
    ] = `${mainCompanyInfo.name} Logo`;
  }
  yield put(Actions.setLogoOptionsAction(logoOptions));
}

export function* watchProposalGenerateSaga() {
  yield takeLatest(Actions.INIT_FORM, initFormSaga);
  yield takeLatest(Actions.SAVE_REQUEST, saveRequestSaga);
  yield takeLatest(Actions.DOWNLOAD_REQUEST, downloadRequestSaga);
  yield takeLatest([Actions.DOWNLOAD_SUCCESS, Actions.SAVE_SUCCESS], updatePathSaga);
  yield takeLatest(BootstrapActions.SET_MAIN_COMPANY_INFO, setLogoOptionsSaga);
}
