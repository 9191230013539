import { ENetworkRequestStatus } from 'services/BackendApi';
import { BookingStatusTypes } from 'config/enums';
import { TCurrencyCode } from 'interfaces';
import { ITCBookingStatementSortableItems, ITCBookingStatementPageRequest } from 'services/BookingManagerApi/types';
import { IDueDate, IPdf } from '../ledger/model';
import { getProposalsEntities } from '../proposals';
import { IExportCsvModel, exportCsvInitialState } from 'store/modules/exportCsv/model';

export interface IExport {
  request: ENetworkRequestStatus;
  exportUuid: string | null;
}

export interface ITCBookingsTotals {
  currency: TCurrencyCode;
  invoiceAmountTotal: number;
  paidAmountTotal: number;
  balanceToPayTotal: number;
  overdueTotal: number;
}

export interface ITCBookingStatementDomain extends IExportCsvModel {
  // summary
  selectedCompanyUuid: string | null;

  // We should now have some "totals" here as in
  // src/store/modules/ledger/model.ts
  tcBookingTotals: ITCBookingsTotals[];
  tcBookingTotalsLoad: ENetworkRequestStatus;
  tcBookingTotalsError?: Error;

  // booking ledger
  bookingsByCurrency: {
    data: ITCBookingStatementLedger | null;
    tcBookingStatementLoad: ENetworkRequestStatus;
    tcBookingStatementError?: Error;
    sortBy?: ITCBookingStatementSortableItems;
    sortOrder?: 'asc' | 'desc';
    currentPage: number;
    itemsPerPage: number;
    filter: ITCBookingStatementPageRequest['filter'];
    currency: TCurrencyCode | null;
    request: {
      updateFilters: ENetworkRequestStatus;
    };
  };

  // downloadPdf statement
  statementPdf: IPdf;

  // ui stuff
  csvIsLoading: boolean;
  statementFilterDateRange: string[] | null;
  statementFilterDateRangeType: string | null;
  statementFilterStatus: string[] | null;
  statementFilterBookingRefs: string[];
  statementFilterAmountMin: number | null;
  statementFilterAmountMax: number | null;
}

export interface ITCBookingStatementPagination {
  offset: number;
  limit: number;
  totalRows: number;
}

export interface ITCBookingStatementRow {
  createdAt: string;
  version: number;
  bookingUuid: string;
  status: typeof BookingStatusTypes[keyof typeof BookingStatusTypes];
  travelAgentCompanyUuid: string;
  leadGuestTitle: string | null;
  leadGuestFirstName: string;
  leadGuestLastName: string;
  bookingArrival: string;
  bookingDeparture: string;
  invoiceAmount: number;
  paidAmount: number;
  balanceToPay: number;
  overdueAmount: number;
  currency: string;
  currencySymbol: string;
  dueDates: IDueDate[];
  humanReadableId: string;
  canViewBooking: boolean;
  hotelNames: string[];
}

export interface ITCBookingStatementLedger {
  invoiceAmountTotal: number;
  paidAmountTotal: number;
  balanceToPayTotal: number;
  overdueTotal: number;
  rows: ITCBookingStatementRow[];
  pagination: ITCBookingStatementPagination;
}

export const initialState: ITCBookingStatementDomain = {
  // summary
  selectedCompanyUuid: null,

  tcBookingTotals: [],
  tcBookingTotalsLoad: ENetworkRequestStatus.IDLE,

  // booking ledger
  bookingsByCurrency: {
    data: null,
    tcBookingStatementLoad: ENetworkRequestStatus.IDLE,
    currentPage: 0,
    itemsPerPage: 10,
    sortBy: 'createdAt',
    sortOrder: 'desc',
    filter: {},
    currency: null,
    request: {
      updateFilters: ENetworkRequestStatus.IDLE,
    },
  },

  statementPdf: {
    downloadedPdfs: [],
    requests: {
      generate: ENetworkRequestStatus.IDLE,
      downloadHistory: ENetworkRequestStatus.IDLE,
    },
  },

  // ui stuff
  csvIsLoading: false,
  statementFilterDateRange: null,
  statementFilterDateRangeType: 'arrival',
  statementFilterStatus: null,
  statementFilterBookingRefs: [''],
  statementFilterAmountMin: null,
  statementFilterAmountMax: null,

  // Export CSV
  ...exportCsvInitialState,
};
