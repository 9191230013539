import produce from 'immer';
import { zip } from 'lodash-es';
import React, { useCallback, useState } from 'react';
import classnames from 'classnames';
import FluidButton from 'ui/FluidButton';
import { IBasketBuildL4 } from 'services/BackendApi/types/Basket';
import { StandardModal } from 'pureUi/Modal';
import { iActingOnBehalfOfUser } from 'store/modules/actingOnBehalfOf/model';
import { LiveRatePricesModal } from 'containers/HotelContainer/LiveRatePricesModal';
import { useNotifications } from 'hooks/useNotifications';
import { usePriceCheckAPI } from './hooks/usePriceCheckAPI';
import { BookingBuilderRequest, ILiveRatePricesModalItem, IPriceCheckResAccommodation, ITravelAgent, SelectedAccommodation } from 'services/BackendApi';
import { isLiveRatePricesAreDifferent } from 'utils/bookingBuilder';
import { useHoldAPI } from './hooks/useHoldAPI';
import { IBookingInformation } from 'interfaces';
import { useDispatch } from 'react-redux';
import { enqueueNotification } from 'store/modules/ui';


interface IHoldButtonProps {
  basketBuild: IBasketBuildL4;
  bookingInformation: IBookingInformation;
  disabled?: boolean;
  selectedTa: ITravelAgent | null;
  actingOnBehalfOfUser?: iActingOnBehalfOfUser | null;
  onSuccess?: (basketBuild: { uuid: string; }) => void;
}

export const HoldButton: React.FC<IHoldButtonProps> = React.memo((props: IHoldButtonProps) => {
  const { showSuccessNotification } = useNotifications();

  const bbResponse = props.basketBuild.latestBookingBuilderResponse!;

  const holdAPI = useHoldAPI({
    basketBuildUuid: props.basketBuild.uuid,
    bookingInformation: props.bookingInformation,
    selectedTa: props.selectedTa,
    actingOnBehalfOfUser: props.actingOnBehalfOfUser,
    clientCountryCode: props.basketBuild.clientCountryCode, 
    onSuccess: (basketBuild): void => {
      showSuccessNotification('Successful on-hold request to supplier.');
      if(props.onSuccess) {
        props.onSuccess(basketBuild);
      }
    }
  });

  const [isPriceCheckModalOpen, setPriceCheckModalOpen] = useState(false);
  const [priceCheckModalData, setPriceCheckModalData] = useState<ILiveRatePricesModalItem[] | null>(null);

  const priceCheckAPI = usePriceCheckAPI({
    bookingBuilderRequest: props.basketBuild.buildRequest,
    selectedTa: props.selectedTa,
    actingOnBehalfOfUser: props.actingOnBehalfOfUser,
    clientCountryCode: props.basketBuild.clientCountryCode,
    onSuccess: (data: IPriceCheckResAccommodation[]): void => {
      if(isLiveRatePricesAreDifferent(props.basketBuild.buildRequest.Accommodation, data)) {
        setPriceCheckModalData(buildPriceCheckModalData(props.basketBuild, data));
        setPriceCheckModalOpen(true);
        return;
      }
      holdAPI.request(props.basketBuild.buildRequest);
    }
  });

  const handlePriceCheckModalClose = useCallback(() => setPriceCheckModalOpen(false), []);
  
  const handlePriceCheckModalConfirm = useCallback(() => {
    const modifiedBookingBuilderRequest = applyPriceCheck(props.basketBuild.buildRequest, priceCheckAPI.data!);
    holdAPI.request(modifiedBookingBuilderRequest);
  }, [props.basketBuild.buildRequest, priceCheckAPI.data]);
  
  const disabled = props.disabled || priceCheckAPI.isLoading || holdAPI.isLoading;

  return (
    <div className="hold-container">
      <FluidButton
        type="primary"
        className={classnames(
          'hold-button flex-1 w-full font-semibold font-hurmegeometric-sans min-h-[55px] my-[14px] rounded-none uppercase',
          { 'hover:bg-green-prime hover:border-none': !disabled }
        )}
        disabled={disabled}
        onClick={priceCheckAPI.request}
        isLoading={holdAPI.isLoading || priceCheckAPI.isLoading}
      >
        Hold
      </FluidButton>
      {isPriceCheckModalOpen && (
        <StandardModal removePadding={true} showCloseButton={false}>
          <LiveRatePricesModal
            confirmButtonLabel="Confirm"
            onConfirm={handlePriceCheckModalConfirm}
            onClose={handlePriceCheckModalClose}
            isConfirmDisabled={disabled}
            liveRates={priceCheckModalData!}
            currency={bbResponse.currency}
            total={bbResponse.totals?.totalForPricedItemsCents ?? 0}
          />
        </StandardModal>
      )}
    </div>
  );
});

function buildPriceCheckModalData(
  basketBuild: IBasketBuildL4,
  priceCheckAccoms: IPriceCheckResAccommodation[]
): ILiveRatePricesModalItem[] {
  const bbReqAccom = basketBuild.buildRequest.Accommodation;
  const bbResAccom = basketBuild.latestBookingBuilderResponse!.availableProductSets.Accommodation;
  return priceCheckAccoms.map((priceCheckAccom, index) => ({
      title: bbResAccom[index].products[0].name,
      liveRate: priceCheckAccom.liveRate,
      guestAges: priceCheckAccom.guestAges,
      prevPrice: bbReqAccom[index].liveRate!.amount,
      newPrice: priceCheckAccom.priceCheck?.totalCents ?? 0,
      prevMealPlan: bbResAccom[index].availableSubProductSets['Meal Plan'][0].products[0].name,
      newMealPlan: priceCheckAccom.priceCheck?.externalMealPlanDescription ?? '',
  }));
}

function applyPriceCheck(
  bookingBuilderRequest: BookingBuilderRequest,
  priceChecks: IPriceCheckResAccommodation[]
): BookingBuilderRequest {
  return produce(bookingBuilderRequest, (draft: BookingBuilderRequest) => {
    const ts = zip(bookingBuilderRequest.Accommodation, priceChecks);
    const defined = ts.filter(t => t[1]?.priceCheck);

    draft.Accommodation = defined.map(([acc, res]) => {
      acc!.liveRate!.amount = res!.priceCheck!.totalCents;
      acc!.liveRate!.externalMealPlanCode = res!.priceCheck!.externalMealPlanCode;
      return acc;
    }) as SelectedAccommodation[];

    return draft;
  }); 
}