import { createReducer } from 'store/utils';
import { initialState } from './types';
import { produce } from 'immer';

import * as HotelAccommodationProductsActions from './actions';
import { IHotelAccommodationProductsDomain, IHAPStaticRateResponseObject } from './types';
import { ENetworkRequestStatus } from 'services/BackendApi';

const getDefaultMealPlan = mealPlanSubProductSelections => {
  // However 'mealPlanSubProductSelections' is array of objects with just 'uuid' field as for now,
  // we protect ourselves from changes/additional props in it in future.
  return mealPlanSubProductSelections.map(s => ({ uuid: s.uuid }));
};

const formatHotelAccommodationProduct = (obj: IHAPStaticRateResponseObject) => {
  const returnObject = {
    uuid: obj.product.uuid,
    title: obj.product.name,
    description: obj.product.meta.description,
    amenities: obj.product.meta.amenities,
    moreInformation: obj.product.meta.moreInformation,
    size: obj.product.meta.size,
    ages: obj.product.options.ages,
    occupancy: obj.product.options.occupancy,
    categoryType: obj.product.meta.categoryType,
    floorPlans: obj.floorPlan !== null ? [obj.floorPlan] : [],
    vimeoVideoId: obj.product.vimeoVideoId,
    accommodationInformation: obj.accommodationInformation !== null ? [obj.accommodationInformation] : [],
    category: obj.product.category,
    uploads: obj.product.uploads,
    staticRates: obj.results.map(result => {
      return {
        uuid: result.build.potentialBooking.Accommodation[0].product.uuid,
        rateUuid: result.build.potentialBooking.Accommodation[0].rateUuid,
        guestAges: result.ageSet,
        totals: {
          ...result.build.totals,
        },
        totalGuests: result.ageSet.numberOfAdults + result.ageSet.agesOfAllChildren.length,
        isStandardOccupancy: result.isStandardOccupancy,
        isDisabled: false,
        defaultMealPlan: getDefaultMealPlan(result.build.mealPlanSubProductSelections),
        dates: result.build.potentialBooking.Accommodation[0].dates,
        datesCount: result.build.potentialBooking.Accommodation[0].dates.length,
        appliedOfferNames: [...result.build.appliedOfferNames],
        availableProductSets: result.build.availableProductSets,
        globalRoomCancellationPolicyText: result.build.globalRoomCancellationPolicyText,
        isRefundable: result.build.isRefundable,
        potentialBooking: {
          ...result.build.potentialBooking,
        },
        canBeBooked: result.build.canBeBooked,
        availableToInstantBook: result?.build?.availableToInstantBook || false,
        expenseInfosGroupedByRoom: result.build.expenseInfosGroupedByRoom,
      };
    }),
  };

  return returnObject;
};

export default createReducer(
  {
    [HotelAccommodationProductsActions.FETCH_HOTEL_ACCOMMODATION_PRODUCTS_REQUEST]: (
      state: IHotelAccommodationProductsDomain,
      action: ReturnType<typeof HotelAccommodationProductsActions.fetchHotelAccommodationProductsRequestAction>
    ): IHotelAccommodationProductsDomain => {
      return {
        ...state,
        loadStatus: ENetworkRequestStatus.PENDING,
      };
    },

    [HotelAccommodationProductsActions.FETCH_HOTEL_ACCOMMODATION_PRODUCTS_SUCCESS]: (
      state: IHotelAccommodationProductsDomain,
      action: ReturnType<typeof HotelAccommodationProductsActions.fetchHotelAccommodationProductsSuccessAction>
    ): IHotelAccommodationProductsDomain => {
      return produce(state, draftState => {
        // eslint-disable-next-line no-console
        draftState.formattedAccommodationProducts = [...action.hotelAccomData.map(formatHotelAccommodationProduct)];
        draftState.bookingComRatesData = [...action.bookingComRatesData];
        draftState.loadStatus = ENetworkRequestStatus.SUCCESS;
        if (action.liveRateData) {
          draftState.liveRatesData = [...(action.liveRateData.Accommodation || [])];
          draftState.liveRatesCurrency = action.liveRateData.currency;
          draftState.isLiveRateLoadingError = false;
        } else {
          draftState.isLiveRateLoadingError = true;
        }
        return draftState;
      });
    },

    [HotelAccommodationProductsActions.FETCH_HOTEL_ACCOMMODATION_PRODUCTS_FAILURE]: (
      state: IHotelAccommodationProductsDomain,
      action: ReturnType<typeof HotelAccommodationProductsActions.fetchHotelAccommodationProductsFailureAction>
    ): IHotelAccommodationProductsDomain => {
      return {
        ...state,
        loadStatus: ENetworkRequestStatus.ERROR,
        error: action.error,
      };
    },

    [HotelAccommodationProductsActions.RESET_HOTEL_ACCOMMODATION_PRODUCTS_STATE]: (
      state: IHotelAccommodationProductsDomain
    ): IHotelAccommodationProductsDomain => {
      return {
        ...state,
        loadStatus: ENetworkRequestStatus.IDLE,
        formattedAccommodationProducts: [],
        liveRatesData: [],
        liveRatesCurrency: null,
        error: null,
        bookingComRatesData: [],
        isLiveRateLoadingError: false,
      };
    },
  },
  initialState
);
