import { useMutation } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";
import { IUpsertLink } from "../types/DirectoryEntry";

export const useLinkDirectoryEntriesMutation = () => {
  const crmApi = makeCRMApi();
  
  const createCompanyMutation = useMutation({
    mutationKey: ['crm-link-directory-entries'],
    mutationFn: (requestData: IUpsertLink) => crmApi.linkDirectoryEntries(requestData),
  });
  
  return createCompanyMutation;
}
