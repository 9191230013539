import React from 'react';
import classnames from 'classnames';
import { SortIcon } from 'ui/Table/SortIcon';
import { ESortOrder } from 'store/common/types';
import { IScrollableTableColumn } from './types';

const getOppositeSortOrder = (order: ESortOrder) => {
  return order === ESortOrder.DESC ? ESortOrder.ASC : ESortOrder.DESC;
}

interface ITableHeaderColumnProps {
  column: IScrollableTableColumn;
  className?: string;
  textClassName?: string;
  currentSortBy: string;
  currentSortOrder: ESortOrder;
  columnSortBy?: string;
  onSort: (newSortBy: string, newSortOrder: string) => void;
}
export const TableHeaderColumn: React.FC<ITableHeaderColumnProps> = React.memo((
  { className, textClassName, column, currentSortBy, currentSortOrder, columnSortBy, onSort }
) => {
  const handleClick = () => {
    if (!columnSortBy) {
      return;
    }
    const newSortOrder = currentSortBy === columnSortBy ? getOppositeSortOrder(currentSortOrder) : ESortOrder.ASC;
    onSort(columnSortBy, newSortOrder);
  }

  return (
    <div className={classnames(className, 'px-[10px] flex items-center justify-between cursor-pointer hover:bg-gray-10 rounded')} onClick={handleClick}>
      <span className={classnames(textClassName, 'select-none')}>{column.label}</span>
      <div className="inline-block">
        {!!columnSortBy && <SortIcon sortBy={currentSortBy} sortOrder={currentSortOrder} newSortBy={columnSortBy} />}
      </div>
    </div>
  );
});
