import { isBothSelected } from 'containers/CRM/newContact/contactCreateFormValidation';
import * as Yup from 'yup';

export const contactMainInfoValidationSchema = Yup.object().shape({
  managerId: Yup.string().nullable(true).required('Field is required'),
  keyNotes: Yup.string(),
});

export const contactUpdateCommon = Yup.object().shape({}).test({
  test: function(values) {
    // https://github.com/jaredpalmer/formik/issues/2146
    const bothSelected = isBothSelected(values);
    if (bothSelected) {
      return this.createError({
        path: 'both-not-allowed',
        message: 'Select Existing or create new, but not both options.',
      });
    }
    return true;
  }
});

export const addExistingCompanyValidator = (
  Yup.object().shape({
    existingCompanyId: Yup.string().required('This field is required'),
  })
).concat(contactUpdateCommon);

export const addNewCompanyValidator = (
  Yup.object().shape({
    companyName: Yup.string().nullable(true).required('This field is required'),
    companyType: Yup.string().nullable(true).required('This field is required'),
  })
).concat(contactUpdateCommon);

export interface ICrmContactMainInfoFormValues {
  managerId: string | null;
  keyNotes: string;
}

export interface ICrmContactLinkedCompanyFormValues {
  managerId: string | null;
  keyNotes: string;
}
