import classNames from 'classnames';
import React from 'react';
import { OnHoldIconSecondary } from 'ui/Icons/components/BasketOnHoldSecondary.component';
import { theme } from '../../../tailwind.config';
import { IOnHoldPerRoomInfo } from './BasketItem';

interface IOnHoldInfoSecondaryProps extends Pick<IOnHoldPerRoomInfo, 'isValid' | 'systemBookingId'> {}

export const OnHoldInfoSecondary: React.FC<IOnHoldInfoSecondaryProps> = React.memo(({ isValid, systemBookingId }) => {
  const color = isValid ? 'green-message' : 'status-cancelled';
  const colorsClassName = `text-${color}`;
  return (
    <React.Fragment>
      <div className="on-hold-info-primary flex items-center justify-start pt-[15px] pb-[5px]">
        <span className="text-gray-80 ml-1">{`ID. ${systemBookingId}`}</span>
        <div className="flex items-center justify-start rounded-full bg-brown-prime-10 ml-2 pl-2 pr-2">
          <span className={ classNames('uppercase text-[17px] font-normal', colorsClassName)}>{isValid ? 'On Hold' : 'Expired' }</span>
          <OnHoldIconSecondary fill={theme.colors[color]} width={30} height={30}/>
        </div>
      </div>
    </React.Fragment>
  );

});