import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { AxiosWrapper } from 'services/AxiosWrapper';
import { injectJwtTokenIntoHeaders } from 'services/tokenLocalStorage';
import { IDirectoryEntry, IDirectorySearchParams, IDirectorySearchResponse, IDirectorySimpleSearchParams, IDirectorySimpleSearchResponse, IUpsertLink, IUpsertLinkResponse } from './types/DirectoryEntry';
import { IBootstrapCountry } from 'services/BackendApi/types/country';
import { ETypeValueType, ITypeValue } from './types/types';
import { INewCompanyRequest, INewCompanyResponse, IUpdateCompanyRequest, IUpdateCompanyResponse } from './types/CrmCompanyTypes';
import { INewContactRequest, INewContactResponse, IUpdateContactRequest, IUpdateContactResponse } from './types/CrmContactTypes';
import { ICreateLeadRequest, ICreateLeadResponse, IUpdateLeadRequest } from './types/CrmLeadTypes';
import { ICrmErrorCollection } from './types/CrmErrorTypes';

export enum CrmEndpoints {
  EXAMPLES = 'v1/examples',
  COUNTRIES = 'v1/countries',
  TYPE_VALUES = 'v1/type-values',
  DIRECTORY = 'v1/directory',
  COMPANY = 'v1/company',
  CONTACT = 'v1/contact',
  LINK = 'v1/link',
  LEAD = 'v1/leads',
}

export class CRMApiService<T extends AxiosWrapper<AxiosInstance>> {
  client: T;

  constructor(client: T) {
    this.client = client;
  }

  getExamples = async (): Promise<AxiosResponse> => {
    const endpoint = `${CrmEndpoints.EXAMPLES}`;
    return this.client.get(endpoint);
  };

  postTypeValues = async (type: ETypeValueType, { enabled }: { enabled: boolean }): Promise<AxiosResponse<ITypeValue[]>> => {
    const endpoint = `${CrmEndpoints.TYPE_VALUES}/${type}`;
    return this.client.post(endpoint, { enabled });
  };

  postTitleValues = async (type: ETypeValueType, { enabled }: { enabled: boolean }): Promise<AxiosResponse<ITypeValue[]>> => {
    const endpoint = `${CrmEndpoints.TYPE_VALUES}/${type}`;
    return this.client.post(endpoint, { enabled });
  };
  
  postCountries = async ({ isDestination }: { isDestination?: boolean } = { isDestination: undefined }): Promise<AxiosResponse<IBootstrapCountry[]>> => {
    const endpoint = `${CrmEndpoints.COUNTRIES}`;
    const params = isDestination ? { isDestination } : {};
    return this.client.post(endpoint, params);
  };

  createCompany = async (data: INewCompanyRequest): Promise<AxiosResponse<INewCompanyResponse>> => {
    const endpoint = `${CrmEndpoints.COMPANY}`;
    return this.client.post(endpoint, { ...data });
  };

  updateCompany = async (data: IUpdateCompanyRequest): Promise<AxiosResponse<IUpdateCompanyResponse>> => {
    const { directoryId, ...rest } = data;
    const endpoint = `${CrmEndpoints.DIRECTORY}/${directoryId}`;
    return this.client.patch(endpoint, { ...rest });
  };

  getDirectory = async (directoryId: string): Promise<AxiosResponse<IDirectoryEntry>> => {
    const endpoint = `${CrmEndpoints.DIRECTORY}/${directoryId}`;
    return this.client.get(endpoint);
  };

  createContact = async (data: INewContactRequest): Promise<AxiosResponse<INewContactResponse>> => {
    const endpoint = `${CrmEndpoints.CONTACT}`;
    return this.client.post(endpoint, { ...data });
  };

  updateContact = async (data: IUpdateContactRequest): Promise<AxiosResponse<IUpdateContactResponse>> => {
    const { directoryId, ...rest } = data;
    const endpoint = `${CrmEndpoints.DIRECTORY}/${directoryId}`;
    return this.client.patch(endpoint, { ...rest });
  };
  
  postDirectorySearch = async (params: IDirectorySearchParams): Promise<AxiosResponse<IDirectorySearchResponse>> => {
    const endpoint = `${CrmEndpoints.DIRECTORY}/search`
    return this.client.post(endpoint, params);
  }

  postDirectorySearchSimple = async (params: IDirectorySimpleSearchParams): Promise<AxiosResponse<IDirectorySimpleSearchResponse>> => {
    const endpoint = `${CrmEndpoints.DIRECTORY}/search/simple`
    return this.client.post(endpoint, params);
  }

  linkDirectoryEntries = async (data: IUpsertLink): Promise<AxiosResponse<IUpsertLinkResponse>> => {
    const endpoint = `${CrmEndpoints.LINK}`
    return this.client.post(endpoint, { ...data });
  }

  unlinkDirectoryEntries = async (linkId: string): Promise<AxiosResponse> => {
    const endpoint = `${CrmEndpoints.LINK}`
    return this.client.delete(`${endpoint}/${linkId}`);
  }

  createLead = async (data: ICreateLeadRequest): Promise<AxiosResponse<ICreateLeadResponse | ICrmErrorCollection>> => {
    const { directoryEntryId, ...rest } = data;
    const endpoint = `${CrmEndpoints.DIRECTORY}/${directoryEntryId}/leads`;
    return this.client.post(endpoint, { ...rest });
  }

  updateLead = async (data: IUpdateLeadRequest): Promise<AxiosResponse<ICreateLeadResponse | ICrmErrorCollection>> => {
    const { leadId, ...rest } = data;
    const endpoint = `${CrmEndpoints.LEAD}/${leadId}`;
    return this.client.put(endpoint, { ...rest });
  }

  removeLead = async (leadId: string): Promise<AxiosResponse<void | ICrmErrorCollection>> => {
    const endpoint = `${CrmEndpoints.LEAD}/${leadId}`;
    return this.client.delete(endpoint);
  }
}

export const makeCRMApi = (): CRMApiService<AxiosWrapper<AxiosInstance>> => {
  const dynamicParameters = ParameterService.getParameters();
  const headers: any = {};

  const client = axios.create({
    baseURL: dynamicParameters.CRM_BASE_URL,
    headers: injectJwtTokenIntoHeaders(headers),
    withCredentials: true,
  });

  const clientWrapper = new AxiosWrapper(client);

  return new CRMApiService<AxiosWrapper<AxiosInstance>>(clientWrapper);
};
