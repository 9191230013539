import { ECompanyType } from "../types";

export const travelCompanyTypeOptions = [
  ECompanyType.TOUR_OPERATOR,
  ECompanyType.TRAVEL_AGENT,
  ECompanyType.CONCIERGE,
  ECompanyType.PERSONAL_ASSISTANT,
  ECompanyType.CONSORTIA,
  ECompanyType.OTHER,
].map(item => (
  {
    label: item,
    value: item,
  }
));
