import { TCountryCode } from 'interfaces';
import { pick } from 'lodash-es';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { BookingBuilderRequest, IPriceCheckReq, IPriceCheckResAccommodation, ITravelAgent, makeBackendApi } from 'services/BackendApi';
import { iActingOnBehalfOfUser } from 'store/modules/actingOnBehalfOf/model';
import { enqueueNotification } from 'store/modules/ui';

type UsePriceCheckAPI = {
  bookingBuilderRequest: BookingBuilderRequest;
  selectedTa?: ITravelAgent | null,
  actingOnBehalfOfUser?: iActingOnBehalfOfUser | null;
  clientCountryCode?: TCountryCode | null;
  onSuccess?: (xs: IPriceCheckResAccommodation[]) => void;
};  

export const usePriceCheckAPI = (props: UsePriceCheckAPI) => {
  const dispatch = useDispatch();

  const [data, setData] = useState<IPriceCheckResAccommodation[] | null>(null)
  const [isLoading, setLoading] = useState<boolean>(false);

  const request = async () => {
    const payload: IPriceCheckReq = {
      Accommodation: props.bookingBuilderRequest.Accommodation.map(x => ({
        ...pick(x, 'uuid', 'startDate', 'endDate', 'guestAges'),
        liveRate: pick(x.liveRate!, 'externalRateId', 'externalMealPlanCode'),
      }))
    };

    const backendApi = makeBackendApi(props.selectedTa?.uuid);
    setLoading(true);

    try {
      const res = await backendApi.liveRatesPriceCheck(
        props.bookingBuilderRequest.hotelUuid,
        payload,
        props.clientCountryCode,
        props.actingOnBehalfOfUser
      );
      setData(res.data.data.Accommodation);
      if(props.onSuccess) {
        props?.onSuccess(res.data.data.Accommodation);
      }
    } catch (e) {
      dispatch(enqueueNotification({
        message: 'Error verifying Live Rate price. Please, try later to complete.',
        options: { variant: 'error' },
      }));
    }
    setLoading(false);
  };

  return {
    request,
    isLoading,
    data
  };
};
