import { createSelector } from 'reselect';
import { IBookingManagerHotelDetailsSubdomain, IBookingManagerHotelDetails } from './types';
import { hotelDetailsSubdomainSelector } from '../../selectors';

export const hotelDetailsSelector = createSelector(
  hotelDetailsSubdomainSelector,
  (subdomain: IBookingManagerHotelDetailsSubdomain) => subdomain.hotelDetails
);

export const resortConfirmationNumbersSelector = createSelector(
  hotelDetailsSelector,
  (hotelDetails: IBookingManagerHotelDetails[]) => hotelDetails.map(hd => hd.resortConfirmationNumber).join(', ')
);

export const networkRequestsSelector = createSelector(
  hotelDetailsSubdomainSelector,
  (subdomain: IBookingManagerHotelDetailsSubdomain) => subdomain.networkRequests
);
