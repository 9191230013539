import {
  BookingBuilderRequest,
  BookingBuilderResponse,
  ENetworkRequestStatus,
  IBookingError,
  IGuestAges,
} from 'services/BackendApi';
import {
  IHeadlineLineItemBreakdown,
  IHeadlineBreakdownVersionListVersion,
  IOfferTerms,
  ICancellationPolicies,
  IPaymentTerms,
  IInvoiceAddressee,
  IPaymentMethod,
  IHeadlineLineItemBreakdownLineItem,
  IBAMState,
  IPaymentMethodPerCurrency,
  IOwnershipResponse,
  IBookingCountryResponse,
  IHeadlineLineItemBreakdownAccommodationLineItem,
} from 'services/BookingManagerApi';

import { EBankAccount, TCurrencyCode } from 'interfaces';
import { ICompanyMembership } from 'services/BookingManagerApi/types/CompanyMemberships';
import { SuppliersSearchItem } from 'services/BookingManagerApi/types/AncillaryService';
import { IStaticRate } from 'ui/AddAccommodationModal/MealPlanDropdown/types';

export enum EPdfLogo {
  MAIN_COMPANY_LOGO = 'main_company_logo',
  TA_LOGO = 'ta_logo',
  NO_LOGO = 'no_logo',
}

//FIXME remove from here as it needs to be defined as BMS response interface
export enum EInvoiceType {
  PROFORMA = 'proforma',
  FINAL = 'final',
  CANCELLATION = 'cancellation',
}

export enum EInvoiceAddresseeType {
  TRAVEL_PARTNER = 'travel_partner',
  FINAL_CLIENT = 'final_client',
}

export enum EInvoiceMutationMode {
  CREATE = 'create',
  UPDATE = 'update',
}

export enum EInvoiceLang {
  EN = 'en',
  AR = 'ar',
}

export enum EBAMPage {
  PAGE_ACCOMMODATIONS = 'page_accomodations',
  PAGE_ADDONS = 'page_addons',
}

export interface ILiveCancellationModalDataAccom {
  bookingRef: string;
  date: string;
  cancellationFeeCents: number;
  rateCents: number;
  cancellationPolicies: string[];
}

export interface ILiveAccommodationData {
  bookingRef: string;
  rateCents: number;
  cancellationFeeCents: number;
}

export interface ILiveAccommodationCancellationPolicy {
  bookingRef: string;
  cancellationPolicies: string[];
}

export interface ILiveCancellationModalData {
  isLiveCancellationModalOpen: boolean;
  accoms: ILiveCancellationModalDataAccom[];
  cancellationFeeCentsTotal: number;
  cancellationError: string | null;
}

export interface IDateRange {
  start: string;
  end: string | null;
}

export interface IAccommodationDetails {
  hotelUuids: string[];
  hotelNames: string[];
  roomName: string;
  headlineLineItemBreakdownAccommodationLineItem: IHeadlineLineItemBreakdownAccommodationLineItem;
}

export type customeErrorType = (IBookingError & { message: string })[];

export enum ErrorType {
  WARN = 'WARN',
  STOP = 'STOP',
}

export interface IEditAccommodationError {
  checkMessage: string;
  type: ErrorType;
  exists: boolean;
  showMessage: string;
}

export interface IEditAccommodationErrors {
  warnings: string[];
  stops: string[];
}

export const editAccommodationStringChecks: IEditAccommodationErrors = {
  warnings: [
    'has an impermissible occupancy',
    'Too many people for this accommodation',
    'Too many Infant guests',
    'Too many Adult guests',
  ],
  stops: ['No Accommodation Product rates'],
};

export interface IEditAccommodationForm {
  startDate: string;
  endDate: string;
  guestAges: IGuestAges;
  selectedMealPlan: IStaticRate | undefined;
  selectedOccasions: string[];
  isRepeatGuest: boolean;
  accommodationDetails: IAccommodationDetails;
  ownershipData: IOwnershipResponse | null;
  countryData: IBookingCountryResponse | null;
  selectedBuild: BookingBuilderResponse | null;
  lastRequestedBuild: BookingBuilderRequest | null;
  accomIndex: number;
  errors: IEditAccommodationErrors;
  hasEdited: boolean;
  requests: {
    bookingBuildRequest: ENetworkRequestStatus;
    initiateBookingBuildRequest: ENetworkRequestStatus;
    simulateBreakdownRequest: ENetworkRequestStatus;
  };
}

export interface IBookingManagerBreakdownSubdomain {
  defaultHotelCurrencyCode: TCurrencyCode | null;
  headlineLineItemBreakdownCommited: IHeadlineLineItemBreakdown | null;
  headlineLineItemBreakdown: IHeadlineLineItemBreakdown | null;
  headlineBreakdownVersionList: IHeadlineBreakdownVersionListVersion[];
  headlineBreakdownSelectedVersion: IHeadlineBreakdownVersionListVersion | null;
  headlineBreakdownRealLatestVersion: IHeadlineBreakdownVersionListVersion | null;
  headlineLineItemBreakdownUpdatedAt: string | null;
  isHeadlineBreakdownEditedWithoutSaving: boolean;
  breakdownLogo: EPdfLogo | null;
  isCreateQuoteModalOpen: boolean;
  accommodation: IBAMState;
  activity: {
    isAddActivityModalOpen: boolean;
  };
  isAddAncillaryTransfersModalOpen: boolean;
  isAddAncillaryGroundServiceModalOpen: boolean;
  isAddAncillaryAccommodationModalOpen: boolean;
  createInvoice: {
    isCreateInvoiceModalOpen: boolean;
    isAddresseeModalOpen: boolean;
    bankAccount?: EBankAccount;
    invoiceAddresseeType: EInvoiceAddresseeType;
    invoiceAddressee?: IInvoiceAddressee;
    mode: EInvoiceMutationMode | null;
    lang: EInvoiceLang;
  };
  createManualInvoice: {
    isCreateManualInvoiceModalOpen: boolean;
    isAddresseeModalOpen: boolean;
    bankAccount?: EBankAccount;
    invoiceAddresseeType: EInvoiceAddresseeType;
    invoiceAddressee?: IInvoiceAddressee;
    mode: EInvoiceMutationMode | null;
    lang: EInvoiceLang;
  };
  paymentMethods: {
    list: IPaymentMethod[] | null;
    defaultPaymentMethodPerCurrency: IPaymentMethodPerCurrency[];
  };
  isSaveToPerformActionModalOpen: boolean;
  isPcrDetailsModalOpen: boolean;
  paymentTerms: IPaymentTerms | null;
  offerTerms: IOfferTerms | null;
  cancellationPolicies: ICancellationPolicies | null;
  policiesAndRestrictions: string | null;
  membership: ICompanyMembership | null;
  networkRequests: IBookingManagerBreakdownSubdomainNetworkRequests;

  barCommissionPercentage: number | null;
  barCommissionAmount: number | null;
  barCommissionErrors: {
    field: 'barCommissionPercentage' | 'barCommissionAmount';
    message: string;
  }[];

  // live cancellations
  liveCancellationModalData: ILiveCancellationModalData;

  isLiveCancellationWarningModalOpen: boolean;

  liveCancellationAccommodationData: ILiveAccommodationData[];
  liveAccommodationCancellationPolicies: ILiveAccommodationCancellationPolicy[];

  transfersSuppliers: SuppliersSearchItem[];
  groundServicesSuppliers: SuppliersSearchItem[];
  activitiesSuppliers: SuppliersSearchItem[];
  shouldUserNeedToConfirmCPsAndPTs: boolean;

  currentlyEditingLineItem: IHeadlineLineItemBreakdownLineItem | null;
  currentlyEditingLineItemIndex: number | null;
  editAccommodationForm: IEditAccommodationForm | null;
}

export interface IBookingManagerBreakdownSubdomainNetworkRequests {
  headlineLineItemBreakdownLoad: ENetworkRequestStatus;
  barCommission: ENetworkRequestStatus;
  saveAndUpdateHeadlineBreakdownLoad: ENetworkRequestStatus;
  downloadBreakdown: ENetworkRequestStatus;
  headlineBreakdownVersionListLoad: ENetworkRequestStatus;
  downloadBreakdownForClient: ENetworkRequestStatus;
  createInvoice: ENetworkRequestStatus;
  createManualInvoice: ENetworkRequestStatus;
  getPaymentMethods: ENetworkRequestStatus;
  paymentTermsLoad: ENetworkRequestStatus;
  offerTermsLoad: ENetworkRequestStatus;
  membershipLoad: ENetworkRequestStatus;
  membershipUpdate: ENetworkRequestStatus;
  cancellationPolicyLoad: ENetworkRequestStatus;
  policiesAndRestrictionsLoad: ENetworkRequestStatus;
  policiesAndTermsUpdate: ENetworkRequestStatus;
  invoiceAddresseeLoad: ENetworkRequestStatus;
  invoiceAddresseeSave: ENetworkRequestStatus;
  postLiveAccommodationCancellationLoad: ENetworkRequestStatus;
  getLiveAccommodationCancellationDataLoad: ENetworkRequestStatus;
  headlineLineItemBreakdownViaVersionReload: ENetworkRequestStatus;
  getSuppliers: ENetworkRequestStatus;
  exchangeCurrencyRequest: ENetworkRequestStatus;
}

export const initialBamState: IBAMState = {
  filters: {
    hotelUuid: '',
    startDate: '',
    endDate: '',
    guestAges: {
      numberOfAdults: 2,
      agesOfAllChildren: [],
    },
  },
  data: {
    accommodationResults: [],
    selectedBuild: null,
    selectedBuildIndex: -1,
    filterHotels: [],
    selectedMealPlan: null,
    selectedOccasions: [],
    isRepeatGuest: false,
    lastRequest: null,
    lastRequestedBuild: null,
    ownershipData: null,
    countryData: null,
  },
  pageAddons: {
    selectedTransfers: [],
    selectedGroundServices: [],
    selectedOtherItems: [],
    selectedFines: [],
  },
  requests: {
    filterHotelsRequest: ENetworkRequestStatus.IDLE,
    searchAccommodationsRequest: ENetworkRequestStatus.IDLE,
    bookingBuildRequest: ENetworkRequestStatus.IDLE,
  },
  page: EBAMPage.PAGE_ACCOMMODATIONS,
  error: null,
};

export const initialState: IBookingManagerBreakdownSubdomain = {
  defaultHotelCurrencyCode: null,
  headlineLineItemBreakdownCommited: null,
  headlineLineItemBreakdown: null,
  headlineBreakdownVersionList: [],
  headlineBreakdownSelectedVersion: null,
  headlineBreakdownRealLatestVersion: null,
  headlineLineItemBreakdownUpdatedAt: null,
  isHeadlineBreakdownEditedWithoutSaving: false,
  barCommissionPercentage: null,
  barCommissionAmount: null,

  barCommissionErrors: [],

  breakdownLogo: EPdfLogo.MAIN_COMPANY_LOGO,
  accommodation: initialBamState,
  activity: {
    isAddActivityModalOpen: false,
  },
  isAddAncillaryTransfersModalOpen: false,
  isAddAncillaryGroundServiceModalOpen: false,
  isAddAncillaryAccommodationModalOpen: false,
  createInvoice: {
    isCreateInvoiceModalOpen: false,
    isAddresseeModalOpen: false,
    bankAccount: undefined,
    invoiceAddresseeType: EInvoiceAddresseeType.TRAVEL_PARTNER,
    invoiceAddressee: undefined,
    mode: null,
    lang: EInvoiceLang.EN,
  },
  createManualInvoice: {
    isCreateManualInvoiceModalOpen: false,
    isAddresseeModalOpen: false,
    bankAccount: undefined,
    invoiceAddresseeType: EInvoiceAddresseeType.TRAVEL_PARTNER,
    invoiceAddressee: undefined,
    mode: null,
    lang: EInvoiceLang.EN,
  },
  paymentMethods: {
    list: null,
    defaultPaymentMethodPerCurrency: [],
  },
  isPcrDetailsModalOpen: false,
  isCreateQuoteModalOpen: false,
  isSaveToPerformActionModalOpen: false,
  paymentTerms: null,
  cancellationPolicies: null,
  policiesAndRestrictions: null,
  offerTerms: null,
  membership: null,
  transfersSuppliers: [],
  groundServicesSuppliers: [],
  activitiesSuppliers: [],
  networkRequests: {
    headlineLineItemBreakdownLoad: ENetworkRequestStatus.IDLE,
    barCommission: ENetworkRequestStatus.IDLE,
    saveAndUpdateHeadlineBreakdownLoad: ENetworkRequestStatus.IDLE,
    downloadBreakdown: ENetworkRequestStatus.IDLE,
    headlineBreakdownVersionListLoad: ENetworkRequestStatus.IDLE,
    downloadBreakdownForClient: ENetworkRequestStatus.IDLE,
    createInvoice: ENetworkRequestStatus.IDLE,
    createManualInvoice: ENetworkRequestStatus.IDLE,
    getPaymentMethods: ENetworkRequestStatus.IDLE,
    paymentTermsLoad: ENetworkRequestStatus.IDLE,
    cancellationPolicyLoad: ENetworkRequestStatus.IDLE,
    offerTermsLoad: ENetworkRequestStatus.IDLE,
    membershipLoad: ENetworkRequestStatus.IDLE,
    membershipUpdate: ENetworkRequestStatus.IDLE,
    policiesAndRestrictionsLoad: ENetworkRequestStatus.IDLE,
    policiesAndTermsUpdate: ENetworkRequestStatus.IDLE,
    invoiceAddresseeLoad: ENetworkRequestStatus.IDLE,
    invoiceAddresseeSave: ENetworkRequestStatus.IDLE,
    postLiveAccommodationCancellationLoad: ENetworkRequestStatus.IDLE,
    getLiveAccommodationCancellationDataLoad: ENetworkRequestStatus.IDLE,

    // we keep a separate load variable for when the headline is specifically changed
    // by re-loading all the versions. it allows a more fine grained styling and state-layout
    headlineLineItemBreakdownViaVersionReload: ENetworkRequestStatus.IDLE,

    getSuppliers: ENetworkRequestStatus.IDLE,
    exchangeCurrencyRequest: ENetworkRequestStatus.IDLE,
  },

  liveCancellationModalData: {
    isLiveCancellationModalOpen: false,
    accoms: [],
    cancellationFeeCentsTotal: 0,
    cancellationError: null,
  },

  isLiveCancellationWarningModalOpen: false,

  liveCancellationAccommodationData: [],
  liveAccommodationCancellationPolicies: [],

  shouldUserNeedToConfirmCPsAndPTs: false,

  currentlyEditingLineItem: null,
  currentlyEditingLineItemIndex: null,
  editAccommodationForm: null,
};
