import React, { useEffect, useRef } from 'react';

import { useDispatch } from 'react-redux';

import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { StandardModal } from 'pureUi/Modal';
import SingleDateInput from 'pureUi/SingleDateInput';
import classNames from 'classnames';
import { ILiveCancellationModalData } from 'store/modules/bookingManager/subdomains/breakdown/model';
import FluidButton from 'ui/FluidButton';
import styled from 'styled-components';
import { formatDate, formatPriceCents } from 'utils';
import { PriceAsCentsInput } from 'ui/stateful/PriceAsCentsInput';
import { ENetworkRequestStatus } from 'services/BackendApi';

export interface ILiveCancellationModalProps {
  liveCancellationModalData: ILiveCancellationModalData;
  currencySymbol: string;
  requestCancellationLoad: ENetworkRequestStatus;
}

const StyledSingleDateInput = styled(SingleDateInput)`
  .pseudoSelect {
    outline: none;
    width: 110px;
    height: 35px;
  }
`;

export const LiveCancellationModal = (props: ILiveCancellationModalProps) => {
  const { accoms, cancellationError } = props.liveCancellationModalData;
  const dispatch = useDispatch();

  return (
    <StandardModal
      className="items-start bg-black-true-transparent-60 z-11"
      frameClassName="w-570px h-[650px] py-20px px-9 font-pt-sans justify-between"
      showCloseButton={props.requestCancellationLoad !== ENetworkRequestStatus.PENDING}
      removePadding={true}
      overflowY={'scroll'}
      onClose={() => {
        dispatch(BreakdownActions.setLiveAccommodationCancellationModalIsOpenAction(false));
      }}
    >
      <div
        className={classNames({
          'opacity-60 pointer-events-none': props.requestCancellationLoad === ENetworkRequestStatus.PENDING,
        })}
      >
        <h1 className="font-noe-display text-21px mt-4">Request Cancellation</h1>
        <p>Please review the Cancellation Fee, select the Due Date and insert the Amount and click Continue:</p>

        <div className="space-y-25px">
          {accoms.map(accom => {
            return (
              <div key={accom.bookingRef}>
                <div className="flex flex-row items-center justify-between bg-brown-15 py-3 px-15px mb-10px">
                  <span className="live-rate-label block text-xs bg-teal-40 border border-solid border-teal-80 px-5px py-2px uppercase font-hurmegeometric-sans rounded-1px text-black">
                    Live Rate
                  </span>
                  <span className="live-rate-price block font-bold leading-21px text-base">
                    {props.currencySymbol}
                    {formatPriceCents(accom.rateCents)}
                  </span>
                </div>
                <div className="flex flex-row items-center justify-between">
                  <span className="text-base font-bold leading-21px">Cancellation Fee:</span>
                  <div className="flex flex-row items-center">
                    <span className="text-13px leading-14px mr-5px text-black">Due date</span>

                    <div className="relative">
                      <StyledSingleDateInput
                        value={accom.date && accom.date !== '' ? new Date(accom.date) : null}
                        placeholder="Select..."
                        onChange={value => {
                          dispatch(
                            BreakdownActions.setLiveAccommodationCancellationModalAccomDataAction(accom.bookingRef, {
                              date: formatDate(value),
                            })
                          );
                        }}
                        calendarCount={1}
                        enablePastDates={true}
                        className={classNames('due-date-input', {
                          dateInputField: true,
                          dateNotSelected: accom.date === null,
                        })}
                      />
                      <span
                        style={{
                          top: '7px',
                          right: '9px',
                        }}
                        className="pointer-events-none absolute text-gray-100 ml-2"
                      >
                        <i className="fas fa-calendar" />
                      </span>
                    </div>
                  </div>
                  <div className="flex flex-row items-center">
                    <span className="text-13px leading-14px mr-5px text-black">{props.currencySymbol}</span>
                    <PriceAsCentsInput
                      className="cancellation-fee-price text-15px leading-19px py-2 px-10px text-right border border-solid border-gray-40 w-110px h-35px outline-none"
                      isAllowUndefined={false}
                      disabled={false}
                      onBlurUpdate={val => {
                        if (val !== accom.cancellationFeeCents) {
                          dispatch(
                            BreakdownActions.setLiveAccommodationCancellationModalAccomDataAction(accom.bookingRef, {
                              cancellationFeeCents: val,
                            })
                          );
                        }
                      }}
                      cents={accom.cancellationFeeCents}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        {accoms.length >= 2 && (
          <div className="flex flex-row items-center bg-green-25 justify-between py-10px px-15px mt-25px">
            <span className="text-xs uppercase">Total Cancellation Fee</span>

            <span className="text-base font-bold">
              {props.currencySymbol}
              {formatPriceCents(props.liveCancellationModalData.cancellationFeeCentsTotal)}
            </span>
          </div>
        )}
      </div>
      {cancellationError && (
        <div className="text-red-95 text-15px leading-21px">
          <p>
            Failed to cancel the booking in the external system. Confirm with the hotel if the booking is cancelled
            already and perform a manual cancellation so that accommodation is marked as cancelled in our system.
          </p>
          <p>
            External error message: <i>"{cancellationError}"</i>
          </p>
        </div>
      )}
      <div className="flex flex-row items-center mt-3">
        <FluidButton
          className="request-cancellation-discard mr-10px"
          disabled={props.requestCancellationLoad === ENetworkRequestStatus.PENDING}
          onClick={() => {
            dispatch(BreakdownActions.setLiveAccommodationCancellationModalIsOpenAction(false));
          }}
          type="secondary"
        >
          Discard
        </FluidButton>
        <FluidButton
          className="request-cancellation-continue"
          isLoading={props.requestCancellationLoad === ENetworkRequestStatus.PENDING}
          disabled={accoms.some(a => a.date === '')}
          onClick={() => {
            dispatch(BreakdownActions.postLiveAccommodationCancellationRequestAction());
          }}
          type="primary"
        >
          {cancellationError ? 'Manual cancellation' : 'Continue with Cancellation'}
        </FluidButton>
      </div>
    </StandardModal>
  );
};
