import React from 'react';
import { GeneralModal } from 'ui/GeneralModal';
import { enqueueNotification } from 'store/modules/ui';
import { useDispatch } from 'react-redux';
import {
  IAccommodationProduct,
  IFineProduct,
  IGroundServiceProduct,
  IHotel,
  IProduct,
  ISeasonalProductRate,
} from 'services/BackendApi';
import { produce } from 'immer';
import * as _ from 'lodash-es';
import { FineRateDetails } from '../FineRateDetails';
import { IBootstrapExtraPersonSupplementProduct } from 'store/modules/bootstrap/model';
import { TransferRateDetails } from '../TransferRateDetails';
import { GroundServiceRateDetails } from '../GroundServiceRateDetails';
import { RoomRateDetails } from '../RoomRateDetails';
import { SupplementRateDetails } from '../SupplementRateDetails';
import { validateSeasonalProductRate } from 'containers/HotelAdmin/validation';
import { IBootstrapCountry } from 'services/BackendApi/types/country';

export const CreateRateModal = ({
  onConfirm,
  onClose,
  hotel,
  product,
  bootstrapCountries,
  epsProduct,
}: {
  onConfirm: (data: any) => void;
  onClose: () => void;
  hotel: IHotel;
  product: IProduct<any>;
  bootstrapCountries: IBootstrapCountry[];
  epsProduct: IBootstrapExtraPersonSupplementProduct;
}) => {
  const dispatch = useDispatch();

  const [seasonalProductRate, setSeasonalProductRate] = React.useState<Partial<ISeasonalProductRate>>({
    seasonUuid: hotel.seasons ? hotel.seasons[0]?.uuid : undefined,
    isOnRequest: false,
    productUuid: product.uuid,
    markupType: 'flat',

    // if we're making an accommodation rate, it needs to have
    // seasonalProductAddonRates for the EPS stuff
    // otherwise, seasonalProductAddonRates is undefined

    // @ts-ignore
    seasonalProductAddonRates:
      product.type === 'Accommodation'
        ? [
            {
              productUuid: epsProduct.uuid,
              isOnRequest: false,
              rate: '0',
              markupType: 'flat',
              markupAmount: '0',
              alternateRates: product.options.ages.reduce((accum, age) => {
                return {
                  ...accum,
                  [age.name]: {
                    markupAmount: '0.00',
                    rate: '0.00',
                    markupType: 'flat',
                  },
                };
              }, {}),
            },
          ]
        : undefined,
  });

  return (
    <GeneralModal
      onClose={() => onClose()}
      modalWindowClassName="w-[1100px] px-[34px] py-25px rounded h-[80vh] overflow-y-scroll font-pt-sans"
      shouldCloseByClickingOutside={false}
    >
      <h1 className="font-normal font-noe-display text-[36px] leading-46px">Create new {product.type} Rate</h1>

      {product.type === 'Accommodation' && (
        <RoomRateDetails
          hotel={hotel}
          room={product as IAccommodationProduct}
          seasonalProductRate={seasonalProductRate}
          bootstrapCountries={bootstrapCountries}
          onCta={() => {
            const validation = validateSeasonalProductRate(seasonalProductRate, product, epsProduct);
            if (validation.isValid === false) {
              dispatch(
                enqueueNotification({
                  message: `Accommodation rate is not valid: ${validation.message}`,
                  options: { variant: 'warning' },
                })
              );
              return;
            }
            onConfirm(seasonalProductRate);
          }}
          epsProduct={epsProduct}
          ctaLabel="Create Rate"
          onUpdateSeasonalProductRate={(field, value) => {
            setSeasonalProductRate({
              ...produce(seasonalProductRate, _draft => {
                _.set(_draft, field, value);
              }),
            });
          }}
        />
      )}

      {product.type === 'Transfer' && (
        <TransferRateDetails
          hotel={hotel}
          transfer={product as IFineProduct}
          seasonalProductRate={seasonalProductRate}
          bootstrapCountries={bootstrapCountries}
          onCta={() => {
            const validation = validateSeasonalProductRate(seasonalProductRate, product);
            if (validation.isValid === false) {
              dispatch(
                enqueueNotification({
                  message: `Transfer rate is not valid: ${validation.message}`,
                  options: { variant: 'warning' },
                })
              );
              return;
            }
            onConfirm(seasonalProductRate);
          }}
          ctaLabel="Create Rate"
          onUpdateSeasonalProductRate={(field, value) => {
            setSeasonalProductRate({
              ...produce(seasonalProductRate, _draft => {
                _.set(_draft, field, value);
              }),
            });
          }}
        />
      )}

      {product.type === 'Supplement' && (
        <SupplementRateDetails
          hotel={hotel}
          supplement={product as IFineProduct}
          seasonalProductRate={seasonalProductRate}
          bootstrapCountries={bootstrapCountries}
          onCta={() => {
            const validation = validateSeasonalProductRate(seasonalProductRate, product);
            if (validation.isValid === false) {
              dispatch(
                enqueueNotification({
                  message: `Supplement rate is not valid: ${validation.message}`,
                  options: { variant: 'warning' },
                })
              );
              return;
            }
            onConfirm(seasonalProductRate);
          }}
          ctaLabel="Create Rate"
          onUpdateSeasonalProductRate={(field, value) => {
            setSeasonalProductRate({
              ...produce(seasonalProductRate, _draft => {
                _.set(_draft, field, value);
              }),
            });
          }}
        />
      )}
      {product.type === 'Ground Service' && (
        <GroundServiceRateDetails
          hotel={hotel}
          groundService={product as IGroundServiceProduct}
          seasonalProductRate={seasonalProductRate}
          bootstrapCountries={bootstrapCountries}
          onCta={() => {
            const validation = validateSeasonalProductRate(seasonalProductRate, product);
            if (validation.isValid === false) {
              dispatch(
                enqueueNotification({
                  message: `Ground service rate is not valid: ${validation.message}`,
                  options: { variant: 'warning' },
                })
              );
              return;
            }
            onConfirm(seasonalProductRate);
          }}
          ctaLabel="Create Rate"
          onUpdateSeasonalProductRate={(field, value) => {
            setSeasonalProductRate({
              ...produce(seasonalProductRate, _draft => {
                _.set(_draft, field, value);
              }),
            });
          }}
        />
      )}
      {product.type === 'Fine' && (
        <FineRateDetails
          hotel={hotel}
          fineProduct={product as IFineProduct}
          seasonalProductRate={seasonalProductRate}
          bootstrapCountries={bootstrapCountries}
          onCta={() => {
            const validation = validateSeasonalProductRate(seasonalProductRate, product);
            if (validation.isValid === false) {
              dispatch(
                enqueueNotification({
                  message: `Fine rate is not valid: ${validation.message}`,
                  options: { variant: 'warning' },
                })
              );
              return;
            }
            onConfirm(seasonalProductRate);
          }}
          ctaLabel="Create Rate"
          onUpdateSeasonalProductRate={(field, value) => {
            setSeasonalProductRate({
              ...produce(seasonalProductRate, _draft => {
                _.set(_draft, field, value);
              }),
            });
          }}
        />
      )}
    </GeneralModal>
  );
};
