import styled from 'styled-components';

export const ImagesCarouselStyled = styled.div<{
  showStatus: boolean;
}>`
  .carousel .carousel-status {
    display: ${props => (props.showStatus ? 'flex' : 'none')};
    background: #ffffff;
    opacity: 0.8;
    width: 97px;
    height: 40px;
    position: absolute;
    bottom: 0;
    left: 0;
    top: auto;
    font-family: 'PT Sans', Arial;
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.06em;
    text-shadow: none;
    margin: 0;
    color: #413e3b;
    justify-content: center;
    align-items: center;
    user-select: none;
  }

  .carousel-arrow-next {
    position: absolute;
    z-index: 1;
    left: 69px;
    bottom: 0px;
    cursor: pointer;
    width: 27px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s linear;
  }

  .carousel-arrow-prev {
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    cursor: pointer;
    width: 27px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s linear;
  }
`;
