import React from 'react';

import { IIconProps } from 'ui/CircleIconButton';
import { theme } from '../../../../tailwind.config';
export const OnHoldIconSecondary: React.FC<IIconProps> = React.memo(
  ({ fill = theme.colors['white'], width = '18', height = '19' }) => (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      className={`min-w-[${width}px] min-h-[${height}px]`}
      preserveAspectRatio="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path 
        fillRule="evenodd"
        clipRule="evenodd" 
        d="M17.2083 14.6146L16.9375 12.0605H17.8333C18.1042 12.0605 18.3333 11.8459 18.3333 11.5454V10.5151C18.3333 10.2361 18.1042 10 17.8333 10H12.1667C11.875 10 11.6667 10.2361 11.6667 10.5151V11.5454C11.6667 11.8459 11.875 12.0605 12.1667 12.0605H13.0417L12.7708 14.6146C11.7708 15.0868 11 15.9668 11 17.04C11 17.3405 11.2083 17.5551 11.5 17.5551H14.3333V19.8088C14.3333 19.8302 14.3333 19.8517 14.3333 19.8732L14.8333 20.9034C14.8958 21.0322 15.0833 21.0322 15.1458 20.9034L15.6458 19.8732C15.6458 19.8517 15.6667 19.8302 15.6667 19.8088V17.5551H18.5C18.7708 17.5551 19 17.3405 19 17.04C19 15.9454 18.2083 15.0868 17.2083 14.6146Z" 
        fill={fill}
      />
    </svg>
  )
);
