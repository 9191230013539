import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames';
import Select from 'pureUi/Select';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder';
import { guestTitles } from 'utils/guestInformation';
import { IValueLabelPair } from 'interfaces';
import { UITextArea } from 'ui/UITextArea';
import { useDispatch, useSelector } from 'react-redux';
import { updateBookingGuestInformationAction } from 'store/modules/bookingBuilder';
import { TextInput } from 'ui/TextInput';
import { useCurrentWidth } from 'effects';

const titles: IValueLabelPair[] = [
  {
    value: '',
    label: guestTitles[0],
  },
  ...guestTitles.slice(1).map(x => ({
    value: x.toLowerCase(),
    label: x,
  })),
];

interface ILabelProps {
  children: React.ReactNode;
}

const Label: React.FC<ILabelProps> = ({ children }) => (
  <div className="self-stretch text-gray-100 text-xs font-normal font-hurmegeometric-sans uppercase">{children}</div>
);
interface IBookingLeadGuestInfoProps {
  disabled: boolean;
  className?: string;
  setShouldShowLeaveAlert: Dispatch<SetStateAction<boolean>>;
}

export const BasketLeadGuestInfo: React.FC<IBookingLeadGuestInfoProps> = React.memo(
  ({ disabled, className, setShouldShowLeaveAlert }) => {
    const dispatch = useDispatch();
    const { currentWidth } = useCurrentWidth();
    const guestInfo = useSelector(BookingBuilderSelectors.guestInfoSelector);
    const [guestFirstNameError, setGuestFirstNameError] = useState<string | null>(null);
    const [guestLastNameError, setGuestLastNameError] = useState<string | null>(null);
    const wrapperClassName = classnames('flex  gap-[20px]', {
      'flex-col items-stretch': currentWidth < 1280,
    });

    useEffect(() => {
      return () => {
        // on unmount, reset the guest info
        dispatch(
          updateBookingGuestInformationAction({
            guestTitle: null,
            guestFirstName: null,
            guestLastName: null,
            isRepeatGuest: false,
            comments: '',
          })
        );
      };
    }, []);

    const handleValueChange = useCallback(
      (valueName, newValue) => {
        const newGuestInfo = {
          ...guestInfo,
          [valueName]: newValue,
        };
        return newGuestInfo;
      },
      [guestInfo]
    );

    const handleSetGuestTitle = useCallback(
      e => {
        dispatch(updateBookingGuestInformationAction(handleValueChange('guestTitle', e.target.value)));
        setShouldShowLeaveAlert(true);
      },
      [dispatch, handleValueChange, setShouldShowLeaveAlert]
    );

    const handleSetGuestFirstName = useCallback(
      e => {
        dispatch(updateBookingGuestInformationAction(handleValueChange('guestFirstName', e.target.value)));
        if (!guestInfo.guestFirstName) {
          setGuestFirstNameError(null);
        }
        setShouldShowLeaveAlert(true);
      },
      [dispatch, guestInfo.guestFirstName, handleValueChange, setShouldShowLeaveAlert]
    );

    const handleSetGuestLastName = useCallback(
      e => {
        dispatch(updateBookingGuestInformationAction(handleValueChange('guestLastName', e.target.value)));
        if (!guestInfo.guestLastName) {
          setGuestLastNameError(null);
        }
        setShouldShowLeaveAlert(true);
      },
      [dispatch, guestInfo.guestLastName, handleValueChange, setShouldShowLeaveAlert]
    );

    const handleSetComments = useCallback(
      value => {
        dispatch(updateBookingGuestInformationAction(handleValueChange('comments', value)));
        setShouldShowLeaveAlert(true);
      },
      [dispatch, handleValueChange, setShouldShowLeaveAlert]
    );

    const handleGuestFirstNameBlur = useCallback(() => {
      setGuestFirstNameError(!guestInfo.guestFirstName ? 'REQUIRED FIELD' : null);
    }, [guestInfo.guestFirstName]);

    const handleGuestLastNameBlur = useCallback(() => {
      setGuestLastNameError(!guestInfo.guestLastName ? 'REQUIRED FIELD' : null);
    }, [guestInfo.guestLastName]);

    return (
      <div className="flex flex-col">
        <div className="booking-lead-guest-heading font-hurmegeometric-sans text-[12px] leading-[14px] font-bold text-flint pb-[10px] border-b border-gray-20">
          LEAD GUEST INFO
        </div>
        <div className="booking-lead-guest-form flex flex-col gap-[20px] overflow-auto mt-[20px]">
          <div className={wrapperClassName}>
            <div className="booking-lead-guest-title flex-col justify-start items-start gap-[15px] inline-flex min-w-[80px]">
              <Label>TITLE</Label>
              <Select
                disabled={disabled}
                value={guestInfo.guestTitle || ''}
                options={titles}
                onChange={handleSetGuestTitle}
                className="max-h-[36px]"
              ></Select>
            </div>
            <div
              className={classnames(
                'booking-lead-guest-firstname flex-col justify-start items-start gap-[10px] inline-flex',
                { 'max-w-[125px]': currentWidth >= 1280 }
              )}
            >
              <Label>FIRST NAME</Label>
              <TextInput
                disabled={disabled}
                data-lpignore="true"
                id="guest-first-name"
                value={guestInfo.guestFirstName ?? ''}
                onChange={handleSetGuestFirstName}
                onBlur={handleGuestFirstNameBlur}
                className="w-full"
                inputClassName="border-gray-17 bg-white-true focus:border-teal-80 focus:border-2 focus:py-[6px]"
                errorMessage={guestFirstNameError}
              />
            </div>
            <div
              className={classnames(
                'booking-lead-guest-lastname grow shrink basis-0 flex-col justify-start items-start gap-[10px] inline-flex',
                { 'max-w-[125px]': currentWidth >= 1280 }
              )}
            >
              <Label>LAST NAME</Label>
              <TextInput
                id="guest-last-name"
                disabled={disabled}
                value={guestInfo.guestLastName ?? ''}
                onChange={handleSetGuestLastName}
                onBlur={handleGuestLastNameBlur}
                className="w-full"
                inputClassName="border-gray-17 bg-white-true focus:border-teal-80 focus:border-2 focus:py-[6px]"
                errorMessage={guestLastNameError}
              />
            </div>
          </div>
          <div className="shrink basis-0 flex-col justify-start items-start gap-[10px] inline-flex">
            <Label>SPECIAL REQUESTS</Label>
            <UITextArea
              rows={4}
              disabled={disabled}
              value={guestInfo.comments ?? ''}
              onChange={handleSetComments}
              className="bg-white-true w-full"
              textAreaClassName="border-gray-17 bg-white-true focus:border-teal-80 focus:border-2 focus:py-[9px]"
            />
          </div>
        </div>
      </div>
    );
  }
);
