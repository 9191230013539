import { AxiosError } from "axios";
import { useNotifications } from "hooks/useNotifications";
import { IApiError } from "services/types/commonApiTypes";

export const useDefaultOnErrorHandler = () => {
    const { showErrorNotification } = useNotifications();
  
  const defaultOnErrorHandler = ( error: AxiosError, defaultMessage: string ) => {
    if (error.response && error.response.status >= 500) {
      showErrorNotification('Something went wrong');
      return;
    }

    const messages = error.response?.data?.errors ?? [{ message: defaultMessage }];
    messages.forEach((error: IApiError) => showErrorNotification(error.message)); 
  }

  return { defaultOnErrorHandler };
}
