import { Formik, FormikHelpers, FormikProps } from 'formik';
import * as AuthSelectors from 'store/modules/auth/selectors';
import React, { useCallback, useState } from 'react';
import FluidButton from 'ui/FluidButton';
import { useSelector } from 'react-redux';
import { addExistingContactValidator, addNewContactValidator } from '../CrmCompanyTabs/crmCompanyMainInfoForm';
import { useNotifications } from 'hooks/useNotifications';
import { useDefaultOnErrorHandler } from 'services/CrmApi/mutations/defaultOnErrorHandler';
import { useQueryClient } from '@tanstack/react-query';
import { VerticalSpace } from 'ui/VerticalSpace';
import { ELinkItemSection } from 'containers/CRM/components/AddCompany/AddCompany';
import { isNil } from 'lodash-es';
import { IUpsertLink } from 'services/CrmApi/types/DirectoryEntry';
import { useLinkDirectoryEntriesMutation } from 'services/CrmApi/mutations/useLinkDirectoryEntriesMutation';
import { ILinkedContactFormValues, isBothSelected, isEmptyOrNil } from 'containers/CRM/newCompany/companyCreateFormValidation';
import { INewContactRequest } from 'services/CrmApi/types/CrmContactTypes';
import { useCreateContactMutation } from 'services/CrmApi/mutations/useCreateContactMutation';
import { AddContact } from 'containers/CRM/components/AddContact/AddContact';
import { isNilOrEmpty } from 'ramda-adjunct';

interface ILinkedContactsAddProps {
  directoryEntryId: string;
  onContactLink: () => void;
}

export const LinkedContactsAdd: React.FC<ILinkedContactsAddProps> = React.memo(({ directoryEntryId, onContactLink }) => {
  const queryClient = useQueryClient();
  const createContactMutation = useCreateContactMutation();
  const linkDirectoryEntriesMutation = useLinkDirectoryEntriesMutation();
  const [sectionExpanded, setSectionExpanded] = useState<ELinkItemSection | null>(null);
  const { showSuccessNotification } = useNotifications();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();
  const isLoading = queryClient.isFetching({ queryKey: ['crm-directory-entry', directoryEntryId] }) > 0;
  const queryState = queryClient.getQueryState(['crm-directory-entry', directoryEntryId]);
  const isSaving = queryState?.status === 'pending';
  const isSr = useSelector(AuthSelectors.isSr);
  const canEdit = !isSr;
    
  const handleOnSectionExpanded = useCallback((section: ELinkItemSection | null) => {
    setSectionExpanded(section);
  }, []);

  const handleLinkContact = useCallback(async (values: ILinkedContactFormValues, formikHelpers: FormikHelpers<ILinkedContactFormValues>) => {
    // If existing: then use the /link endpoint to bind both directory_entries
    // If new: create /contact, then use the /link endpoint
    let existingContactId = values.existingContactId;
    if (isNil(existingContactId) || existingContactId === '') {
      const contactCreateRequestData : INewContactRequest = {
      typeCode: values.contactType as string, 
      firstName: values.contactFirstName as string,
      titleCode: isNil(values.contactTitle) ? undefined : values.contactTitle,
      lastName: isEmptyOrNil(values.contactLastName) ? undefined : values.contactLastName,
      email: isEmptyOrNil(values.contactEmail) ? undefined : values.contactEmail,
      mobile: isEmptyOrNil(values.contactMobile) ? undefined : values.contactMobile,
      landline: isEmptyOrNil(values.contactLandline) ? undefined : values.contactLandline,
      };
      try {
        const response = await createContactMutation.mutateAsync(contactCreateRequestData);
        showSuccessNotification('Contact created.');      
        await queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntryId] });
        existingContactId = response.data.id;
      } catch (error) {
        defaultOnErrorHandler(error, 'Failed to create a Contact');
        await queryClient.invalidateQueries({ queryKey: ['crm-directory-entry', directoryEntryId] });
      }
    }
    if (!(isNilOrEmpty(existingContactId))) {
      const linkDirectoryEntriesRequestData : IUpsertLink = {
      sourceId: directoryEntryId,
      destinationId: existingContactId as string,
      linkTypeCode: 'MEMBER', // In the future this value will be selected somewhere in FE
      reference: null, // not being used yet
      };
      try {
        await linkDirectoryEntriesMutation.mutateAsync(linkDirectoryEntriesRequestData);
        showSuccessNotification('Company linked to contact.');      
        await queryClient.invalidateQueries({ queryKey: ['crm-link-directory-entries', directoryEntryId] });
        formikHelpers.resetForm();
        onContactLink();
      } catch (error) {
        defaultOnErrorHandler(error, 'Failed to link Contact to Company');
        await queryClient.invalidateQueries({ queryKey: ['crm-link-directory-entries', directoryEntryId] });
      }
    }
  }, []);

  const initialAddLinkContactValues: ILinkedContactFormValues = {
    contactType: null,
    contactFirstName: '',
    contactTitle: null,
    contactLastName: '',
    contactEmail: '',
    contactMobile: '',
    contactLandline: '',

    existingContactId: null,
  };
  
  let contactValidator;
  if (sectionExpanded === ELinkItemSection.CREATE_NEW) {
    contactValidator = addNewContactValidator;
  }
  if (sectionExpanded === ELinkItemSection.SELECT_EXISTING) {
    contactValidator = addExistingContactValidator;
  }
  
  return (
    <>
      <Formik 
        initialValues={initialAddLinkContactValues}
        enableReinitialize={true}
        validationSchema={contactValidator}
        onSubmit={handleLinkContact}
      >
        {(form: FormikProps<ILinkedContactFormValues>) => {
          let wrappingError : string | null = null;
          if (form.submitCount > 0 && isBothSelected(form.values)) {
              wrappingError = 'Select Existing or create new, but not both options.';
          }
          const isEmpty = Object.values(form.values).every(value => isNilOrEmpty(value));
          return (
            <>
              <AddContact 
                wrappingError={wrappingError}
                showPageSectionTitle={false}
                onSectionExpanded={handleOnSectionExpanded}
                excludeId={directoryEntryId}
              />
              <VerticalSpace height="20px" />
              <div className="buttons-container ">
                <FluidButton 
                type="secondary" 
                textClassName="flex items-center gap-[10px]" 
                isLoading={isLoading || isSaving}
                disabled={!canEdit || isEmpty || sectionExpanded === null} 
                onClick={form.submitForm}
                >
                  Add
                </FluidButton>
              </div>
            </>
          );
        }}
      </Formik>
    </>
  );
})