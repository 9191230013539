import classNames from 'classnames';
import React from 'react';

interface IEmptyTableRowProps {
  className?: string;
  text: string;
}

export const EmptyTableRow: React.FC<IEmptyTableRowProps> = React.memo(({ className, text }) => {
  return (
    <div>
      <span className={classNames(className, "font-pt-sans text-15px leading-19px text-black flex h-50px items-center pl-5 border-solid border-b border-gray-10 italic")}>
        {text}
      </span>
    </div>
  );
});
