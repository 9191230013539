import React, { useCallback, useEffect, useState } from 'react';
import { useDynamicParameters } from 'hooks/useDynamicParameters';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { IHeadlineLineItemBreakdownComponent } from 'ui/HeadlineLineItemBreakdown';
import { ITableColumn, ITableRow } from 'ui/Table/types';
import { Table } from 'ui/Table';
import { ESortOrder } from 'store/common/types';
import { Radio } from 'ui/Radio';
import { ERefundabilityTagStatus, RefundabilityTag } from 'ui/RefundabilityTag/RefundabilityTag';
import { AvailabilityTag, EAvailabilityTagStatus } from 'ui/AvailabilityTag/AvailabilityTag';
import { RateTypeTag } from 'containers/LodgingSummary/RateTypeTag';
import { ERoomRateType } from 'store/modules/bookingBuilder';
import { Price } from 'ui/Price';
import { currencyCodeToSymbol, getCurrencyBySymbol } from 'utils';
import { TCurrencyCode } from 'interfaces';
import { HidingTooltip } from 'ui/Tooltip';
import { SvgIcon } from 'ui/SvgIcon';
import { theme } from '../../../tailwind.config';
import { InfoTransparentIcon } from 'ui/Icons/components/InfoTransparent.component';
import { AccommodationModalPrice } from './AccommodationModalPrice';
import { BookingBuilderResponse, ENetworkRequestStatus, ICompany, ProductSetAccommodation } from 'services/BackendApi';
import * as BookingBuilderSelectors from 'store/modules/bookingBuilder/selectors';
import {
  composeCancellationPolicyFromRoomExpenseInfo,
  isRefundable as isRefundableFn,
} from 'common-lib/cancellation-policy-composer';
import { AddAccommodationModalAvailability } from './AddAccommodationModalAvailability';
import { flatten, uniqBy } from 'ramda';

const noop = () => {};

interface IAccommodationRowLeftPartProps {
  selected: boolean;
  build: BookingBuilderResponse;
  availability: string;
  accommodation: ProductSetAccommodation;
  accommodationIndex: number;
  onChoose: (build: BookingBuilderResponse, index: number) => void;
  index: number;
}

const AccommodationRowLeftPart: React.FC<IAccommodationRowLeftPartProps> = ({
  build,
  accommodation,
  selected,
  availability,
  onChoose,
  accommodationIndex,
  index,
}) => {
  const isLiveRate = !!build.potentialBooking.Accommodation[0]?.isLiveRate;
  const selectedMealPlan = accommodation.availableSubProductSets['Meal Plan'].find(item => item.selected);
  const mealPlan = selectedMealPlan?.products.map(p => p.name).join(', ') ?? '';
  const expenseInfo = build.expenseInfosGroupedByRoom[0];
  const isRefundable = isRefundableFn(expenseInfo);

  // OWA-8688 if a build is live, then the availability is "Available"
  if (isLiveRate) {
    availability = 'Available';
  }
  const handleChoose = useCallback(() => {
    onChoose(build, index);
  }, [build, onChoose, accommodation]);

  return (
    <div className={classnames('flex items-center justify-between h-full')}>
      <div className="left-part flex items-center pl-[20px]">
        <div className="inline-flex" onClick={handleChoose}>
          <Radio checked={selected} onClick={handleChoose} />
        </div>
        <div className="cursor-pointer ml-[5px] min-w-[85px]" onClick={handleChoose}>
          <RateTypeTag
            wrapperClassName="ml-2 min-w-[85px]"
            rateType={isLiveRate ? ERoomRateType.LIVE : ERoomRateType.STATIC}
          />
        </div>
        <span className="ml-[20px] cursor-pointer text-[13px]" onClick={handleChoose}>
          {mealPlan}
        </span>
      </div>
      <div className="right-part flex items-center min-w-[225px]">
        <RefundabilityTag
          refundabilityStatus={
            isRefundable ? ERefundabilityTagStatus.REFUNDABLE : ERefundabilityTagStatus.NON_REFUNDABLE
          }
          className="refundability-tag flex items-center o:font-pt-sans o:text-[13px] capitalize"
        />
        <AddAccommodationModalAvailability availability={availability} />
      </div>
    </div>
  );
};

interface IAccommodationRowRightPartProps {
  build: BookingBuilderResponse;
  currencyCode: TCurrencyCode;
}

const AccommodationRowRightPart: React.FC<IAccommodationRowRightPartProps> = ({ currencyCode, build }) => {
  const expenseInfo = build.expenseInfosGroupedByRoom[0];
  const isRefundable = isRefundableFn(expenseInfo);
  const cancellationPolicyLines: string[] = [];
  build.expenseInfosGroupedByRoom.forEach(item => {
    const accommodation = [
      composeCancellationPolicyFromRoomExpenseInfo(item, {
        currencySymbol: currencyCodeToSymbol(currencyCode),
        appendLines: [' (at 00.00 time at destination)'],
      }).join('. '),
    ].join(': ');
    cancellationPolicyLines.push(accommodation);
  });
  const paymentTermsList = build.potentialBooking.Accommodation.map(product => product.paymentTerms);
  const paymentTerms = uniqBy(a => a, flatten(paymentTermsList).filter(Boolean));

  const renderTooltip = useCallback(() => {
    return (
      <div className="bam-tooltip text-13px leading-[17px] text-black px-[8px] py-[5px] shadow-pe6 normal-case text-left w-[300px]">
        {cancellationPolicyLines.length >= 1 && (
          <>
            <p className="font-bold m-0">Cancellation Policy:</p>
            {cancellationPolicyLines.map(item => (
              <p key={item} className="font-normal m-0">
                {item}
              </p>
            ))}
          </>
        )}
        {paymentTerms.length >= 1 && (
          <>
            <p className="font-bold m-0">Payment Terms:</p>
            {paymentTerms.map(item => (
              <p key={item} className="font-normal m-0">
                {item}
              </p>
            ))}
          </>
        )}
      </div>
    );
  }, [cancellationPolicyLines, isRefundable, paymentTerms]);

  return (
    <div className={classnames('flex items-center justify-end gap-[20px] h-full ml-[-16px] pr-[5px] pl-[16px]')}>
      <AccommodationModalPrice totals={build.totals} currencyCode={build.currency} />
      <HidingTooltip renderTooltipContent={renderTooltip} position="left-top">
        <span className="cursor-pointer flex">
          <SvgIcon
            IconComponent={InfoTransparentIcon}
            defaultFill={theme.colors['gray-40']}
            hoverFill={theme.colors['gray-80']}
            activeFill={theme.colors['gray-40']}
            width="18px"
            height="18px"
          />
        </span>
      </HidingTooltip>
    </div>
  );
};

export interface IAddAccommodationModalTableProps {
  onChooseAccommodation: (build: BookingBuilderResponse, index: number) => void;
  bookingCurrencySymbol: string;
}

export const AddAccommodationModalTable = ({
  onChooseAccommodation,
  bookingCurrencySymbol,
}: IAddAccommodationModalTableProps) => {
  const accommodations = useSelector(BreakdownSelectors.BAMAccommodationsSelector);
  const selectedBuildData = useSelector(BreakdownSelectors.BAMSelectedBuildDataSelector);
  const searchAccommodationsRequest = useSelector(BreakdownSelectors.BAMSearchAccommodationsRequestSelector);
  const isLoading = searchAccommodationsRequest === ENetworkRequestStatus.PENDING;

  const columns: ITableColumn[] = [
    {
      id: 'room',
      label: 'Room',
      width: '100%',
      className: '',
    },
    {
      id: 'tpCostFrom',
      label: 'TP Cost From',
      width: '150px',
      className: 'pr-[42px] text-right',
    },
  ];

  const rows: ITableRow[] = accommodations.map((item, accommodationIndex) => {
    const accommodationUuid = item.builds[0].availableProductSets.Accommodation[0].products[0].uuid;
    const accommodationName = item.builds[0].availableProductSets.Accommodation[0].products[0].name;

    const renderTpCostFrom = (isExpanded: boolean) => (
      <div className="text-right pr-[45px]">
        {!isExpanded && (
          <AccommodationModalPrice totals={item.builds[0].totals} currencyCode={item.builds[0].currency} />
        )}
      </div>
    );

    const renderUpperPart = (isExpanded: boolean) => {
      // OWA-8688 if any of the builds are live, then the availability is "Available"
      const isAnyBuildLive = item.builds.some(b => !!b.potentialBooking.Accommodation[0]?.isLiveRate);
      if (isAnyBuildLive) {
        item.availability = 'Available';
      }
      return (
        <div className="flex w-full justify-between items-center">
          <span className="">{accommodationName}</span>
          {!isExpanded ? <AddAccommodationModalAvailability availability={item.availability} /> : null}
        </div>
      );
    };

    const subRows: Omit<ITableRow, 'subRows'>[] = item.builds.map((build, buildIndex) => {
      const accom = build.availableProductSets.Accommodation[0];

      const selected =
        selectedBuildData.selectedBuild?.availableProductSets.Accommodation[0].products[0].uuid ===
          build.availableProductSets.Accommodation[0].products[0].uuid &&
        selectedBuildData.selectedBuildIndex === buildIndex;

      const leftPart = (
        <AccommodationRowLeftPart
          accommodation={accom}
          accommodationIndex={accommodationIndex}
          build={build}
          availability={item.availability}
          selected={selected}
          onChoose={onChooseAccommodation}
          index={buildIndex}
        />
      );
      const rightPart = <AccommodationRowRightPart currencyCode={build.currency} build={build} />;
      return {
        id: `${accom.products[0].uuid}-${buildIndex}`,
        className: classnames('search-result-row', {
          'o:bg-green-25 font-normal': selected,
        }),
        cells: [
          {
            id: 'room',
            value: leftPart,
            contentClassName: 'h-full',
          },
          {
            id: 'tpCostFrom',
            value: rightPart,
            contentClassName: 'text-right block',
          },
        ],
      };
    });

    return {
      id: accommodationUuid,
      cells: [
        {
          id: 'room',
          renderValue: renderUpperPart,
          title: accommodationName,
          contentClassName: 'inline-flex w-full justify-between',
        },
        { id: 'tpCostFrom', renderValue: renderTpCostFrom, contentClassName: 'text-right block' },
      ],
      subRows,
    };
  });

  return (
    <Table
      columns={columns}
      rows={isLoading ? [] : rows}
      sortBy={'id'}
      sortOrder={ESortOrder.ASC}
      onSort={noop} // no sorting is possible
      messageWhenEmpty={isLoading ? '' : 'No Results'}
      evenColor="white"
      loading={isLoading}
    />
  );
};
