import { Field, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { BackLink } from 'ui/BackLink';
import { PageTitle } from 'ui/PageTitle';
import { addExistingCompanyValidator, addNewCompanyValidator, contactCreateCommon, IContactCreateFormValues, isBothSelected } from './contactCreateFormValidation';
import classnames from 'classnames';
import { LeaveWithoutSavingModal } from 'ui/LeaveWithoutSavingModal';
import { TextInput } from 'ui/TextInput';
import Editor from 'pureUi/Editor';
import SingleSelect, { ISingleSelectOption } from 'ui/SingleSelect';
import { ETypeValueType } from 'services/CrmApi/types/types';
import { PageSectionTitle } from 'ui/PageSectionTitle';
import { VerticalSpace } from 'ui/VerticalSpace';
import FluidButton from 'ui/FluidButton';
import { useHistory } from 'react-router';
import { ErrorBar, LoadingBar } from 'ui/NetworkStatusBar';
import { useResponsive } from '../hooks/useResponsive';
import { INewContactRequest } from 'services/CrmApi/types/CrmContactTypes';
import { useCrmCountries } from 'services/CrmApi/queries/useCrmCountries';
import { useCrmTypeValues } from 'services/CrmApi/queries/useCrmTypeValues';
import { useCreateContactMutation } from 'services/CrmApi/mutations/useCreateContactMutation';
import { useNotifications } from 'hooks/useNotifications';
import { delay } from 'utils/promise';
import { useDefaultOnErrorHandler } from 'services/CrmApi/mutations/defaultOnErrorHandler';
import { useCrmTitleValues } from 'services/CrmApi/queries/useCrmTitleValues';
import { AddCompany, ELinkItemSection } from '../components/AddCompany/AddCompany';
import { isNil } from 'lodash-es';

export const CrmContactCreate = () => {
  const history = useHistory();
  const { isMobile } = useResponsive();
  const { showSuccessNotification } = useNotifications();
  const { defaultOnErrorHandler } = useDefaultOnErrorHandler();

  const crmCountries = useCrmCountries();
  const crmTypeValuesContacts = useCrmTypeValues(ETypeValueType.CONTACT, { enabled: true });
  const { isPending: isTitleValuesPending, isError: isTitleValuesError, data: titleValues } = useCrmTitleValues();
  const createContactMutation = useCreateContactMutation();
  const [sectionExpanded, setSectionExpanded] = useState<ELinkItemSection | null>(null);

  const initialValues: IContactCreateFormValues = useMemo(() => ({
    type: '',
    title: null,
    firstName: '',
    lastName: null,
    country: null,
    email: null,
    mobile: null,
    landline: null,
    keyNotes: null,
    existingCompanyId: null,
    companyType: null,
    companyName: null,
  }), []);
  
  const handleOnSectionExpanded = useCallback((section: ELinkItemSection | null) => {
    setSectionExpanded(section);
  }, []);

  const handleFormSubmit = useCallback(async (values: IContactCreateFormValues, formikHelpers: FormikHelpers<IContactCreateFormValues>) => {
    const existingCompanyUuid = values.existingCompanyId;
    const newCompany = {
      typeCode: values.companyType || undefined,
      name: values.companyName || undefined,
    };
    const requestData : INewContactRequest = {
      // BE is not accepting null values
      typeCode: values.type,
      titleCode: values.title || undefined,
      firstName: values.firstName,
      lastName: values.lastName || undefined,
      countryCode: values.country || undefined,
      email: values.email || undefined,
      mobile: values.mobile || undefined,
      landline: values.landline || undefined,
      keyNotes: values.keyNotes || undefined,
    }
    if (existingCompanyUuid) {
      requestData.existingCompanyUuid = existingCompanyUuid;
    } 
    if (!isNil(newCompany) && Object.values(newCompany).some(o => !isNil(o))) {
      requestData.newCompany = newCompany;
    }
    
    try {
      await createContactMutation.mutateAsync(requestData);
      formikHelpers.resetForm({values: initialValues});
      // Formik resetForm is executed asynchronously even though it is not an async function
      await delay(0);
      history.push('/crm');
      showSuccessNotification('Contact created.');      
    } catch (error) {
      defaultOnErrorHandler(error, 'Failed to create a Contact');
    }
  }, [createContactMutation, defaultOnErrorHandler, history, initialValues, showSuccessNotification]);

  const countryOptions: ISingleSelectOption[] = crmCountries.data?.data?.map(
    item => ({ value: item.code, label: item.name })
  ) ?? [];

  const typeOptions: ISingleSelectOption[] = crmTypeValuesContacts.data?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];

  const titleOptions: ISingleSelectOption[] = titleValues?.data?.map(
    item => ({ value: item.code, label: item.value })
  ) ?? [];

  if (crmCountries.isPending || crmTypeValuesContacts.isPending || isTitleValuesPending) {
    return (
      <div className="mt-[20px]">
        <LoadingBar />
      </div>
    )
  }

  if (crmCountries.isError || crmTypeValuesContacts.isError || isTitleValuesError) {
    return (
      <div className="mt-[20px]">
        <ErrorBar />
      </div>
    )
  }

  const selectClassname = classnames('min-w-[200px]', {
    'w-full': isMobile,
    'w-[200px]': !isMobile,
  });
  const namesClassname = classnames('min-w-[300px]', {
    'w-full': isMobile,
    'w-[300px]': !isMobile,
  });
  const emailsClassname = classnames('min-w-[420px]', {
    'w-full': isMobile,
    'w-[420px]': !isMobile,
  });
  const phonesClassname = classnames('min-w-[200px]', {
    'w-full': isMobile,
    'w-[200px]': !isMobile,
  });
  const saveButtonClassname = classnames({
    'w-full': isMobile,
  });

  let contactValidator;
  if (sectionExpanded === ELinkItemSection.CREATE_NEW) {
    contactValidator = addNewCompanyValidator;
  } else if (sectionExpanded === ELinkItemSection.SELECT_EXISTING) {
    contactValidator = addExistingCompanyValidator;
  } else {
    contactValidator = contactCreateCommon;
  }
  
  return (
    <div className="crm-directory mx-[20px] crm-desktop:mx-[80px]">
      <BackLink type="internal" href="/crm">
        Back
      </BackLink>
      <PageTitle
        primaryText="New Contact"
        secondaryCondition={false}
      />
      <VerticalSpace height="20px" />
      <PageSectionTitle
        text="Basic Info"
      />
      <Formik
          initialValues={initialValues}
          validationSchema={contactValidator}
          enableReinitialize={true}
          onSubmit={handleFormSubmit}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(form: FormikProps<IContactCreateFormValues>) => {  
            let wrappingError : string | null = null;
            if (form.submitCount > 0 && isBothSelected(form.values)) {
              wrappingError = 'Select Existing or create new, but not both options.';
            }
            return (
              <>
                <LeaveWithoutSavingModal 
                  when={form.dirty} 
                  confirmButtonLabel="Yes"
                  cancelButtonLabel="No"
                />

                <form autoComplete={'off'} onSubmit={form.handleSubmit}>
                  <div className="form-container flex flex-col justify-between gap-[20px] mt-[10px]">
                    <div className="type-title-firstname-lastname-country flex flex-col crm-tablet:flex-row items-start gap-[20px]">
                      <Field name="type">
                        {({ field: { name, value }, form: { setFieldValue, setFieldTouched } }) => (
                          <SingleSelect
                            fieldId="type"
                            label="Type *"
                            className={classnames('type', selectClassname)}
                            value={value}
                            showSelectedOption
                            maxVisibleItems={isMobile ? 4 : 6}
                            options={typeOptions ?? []}
                            labelWhenNothingSelected="None"
                            onChange={value => {
                              setFieldValue(name, value);
                              setFieldTouched(name, value);
                            }}
                            errorMessage={form.touched.type && form.errors.type ? form.errors.type : null}
                            errorClassName="type-error"
                          />
                        )}
                      </Field>
                      <Field name="title">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <SingleSelect
                            fieldId="title"
                            label="Title"
                            className={classnames('type', selectClassname)}
                            value={value}
                            showSelectedOption
                            maxVisibleItems={isMobile ? 4 : 6}
                            options={titleOptions ?? []}
                            labelWhenNothingSelected="None"
                            onChange={value => {
                              setFieldValue(name, value);
                            }}
                            errorMessage={form.touched.title && form.errors.title ? form.errors.title : null}
                            errorClassName="title-error"
                          />
                        )}
                      </Field>
                      <Field
                        as={TextInput}
                        type="text"
                        name="firstName"
                        id="firstName"
                        className={classnames('first-name', namesClassname)}
                        label="First Name *"
                        errorMessage={form.touched.firstName && form.errors.firstName ? form.errors.firstName : null}
                        errorClassName="first-name-error"
                      />
                      <Field
                        as={TextInput}
                        type="text"
                        name="lastName"
                        id="lastName"
                        className={classnames('last-name', namesClassname)}
                        label="Last Name"
                        errorMessage={form.touched.lastName && form.errors.lastName ? form.errors.lastName : null}
                        errorClassName="last-name-error"
                      />
                      <Field name="country">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <SingleSelect
                            fieldId="country"
                            label="Country"
                            className={classnames('country', selectClassname)}
                            value={value}
                            maxVisibleItems={isMobile ? 4 : 6}
                            showSelectedOption
                            options={countryOptions ?? []}
                            labelWhenNothingSelected="None"
                            onChange={value => {
                              setFieldValue(name, value);
                            }}
                            errorMessage={form.touched.country && form.errors.country ? form.errors.country : null}
                            errorClassName="country-error"
                          />
                        )}
                      </Field>
                    </div>
                    <div className="email-mobile-landline flex flex-col crm-tablet:flex-row items-start gap-[20px]">
                      <Field
                        as={TextInput}
                        type="text"
                        name="email"
                        id="email"
                        className={classnames('email', emailsClassname)}
                        label="E-mail"
                        errorMessage={form.touched.email && form.errors.email ? form.errors.email : null}
                        errorClassName="email-error"
                      />
                      <Field
                        as={TextInput}
                        type="text"
                        name="mobile"
                        id="mobile"
                        className={classnames('mobile', phonesClassname)}
                        label="Mobile"
                        errorMessage={form.touched.mobile && form.errors.mobile ? form.errors.mobile : null}
                        errorClassName="mobile-error"
                      />
                      <Field
                        as={TextInput}
                        type="text"
                        name="landline"
                        id="landline"
                        className={classnames('landline', phonesClassname)}
                        label="Landline"
                        errorMessage={form.touched.landline && form.errors.landline ? form.errors.landline : null}
                        errorClassName="landline-error"
                      />
                    </div>
                    <div className="key-notes flex  gap-[20px]">
                      <Field name="keyNotes">
                        {({ field: { name, value }, form: { setFieldValue } }) => (
                          <div className="flex flex-col w-full">
                            <label className="font-pt-sans mb-5px text-black text-13px leading-17px tracking-2xs">
                              Key Notes
                            </label>
                            <Editor text={value} handleEditorChange={(value) => setFieldValue(name, value)} options={[]} mentionAbility={false} />
                          </div>
                        )}
                      </Field>
                    </div>
                  </div>
                  
                <VerticalSpace height="20px" />
                <AddCompany wrappingError={wrappingError} onSectionExpanded={handleOnSectionExpanded}/>

                <div className="buttons-container mt-[20px]">
                  <FluidButton submit type="primary" textClassName="flex items-center gap-[10px]" className={saveButtonClassname}>
                     Save
                  </FluidButton>
                </div>
                </form>
              </>
            );
          }}
        </Formik>
    </div>
  )
};
