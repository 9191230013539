import React, { useCallback, useEffect, useState } from 'react'
import { AddContactNew } from './AddContactNew';
import classnames from 'classnames';
import { PageSectionTitle } from 'ui/PageSectionTitle';
import { BorderWithLabel } from 'ui/BorderWithLabel/BorderWithLabel';
import { AddContactExisting } from './AddContactExisting';
import { isNil } from 'lodash-es';

export enum ELinkItemSection {
  SELECT_EXISTING = 'SELECT_EXISTING',
  CREATE_NEW = 'CREATE_NEW',
}

interface IAddContactProps {
  wrappingError: string | null;
  showPageSectionTitle?: boolean;
  onSectionExpanded?: (ELinkItemSection) => void;
  excludeId?: string;
}

export const AddContact: React.FC<IAddContactProps> = ({ wrappingError = null, showPageSectionTitle = true, onSectionExpanded = () => {}, excludeId }) => {
  const [openSection, setOpenSection] = useState<ELinkItemSection | null>(null);
  
  const contactWrapperClassname = classnames('relative mt-[20px] py-[15px] border border-solid', {
    'border-red-95': !isNil(wrappingError),
    'border-gray-20': isNil(wrappingError),
  });

  const toggleSelectExistingSection = useCallback((isOpen: boolean) => {
    const value = isOpen ? ELinkItemSection.SELECT_EXISTING : null;
    setOpenSection(value);
    onSectionExpanded(value);
  }, []);

  const toggleCreateNewSection = useCallback((isOpen: boolean) => {
    const value = isOpen ? ELinkItemSection.CREATE_NEW : null;
    setOpenSection(value);
    onSectionExpanded(value);
  }, []);

  return (
    <>
      {showPageSectionTitle && (<PageSectionTitle
        text="Contact *"
      />)}
      <BorderWithLabel label="Add Contact" wrappingError={wrappingError ?? undefined}>
        <AddContactExisting isOpen={openSection === ELinkItemSection.SELECT_EXISTING} toggleOpen={toggleSelectExistingSection} excludeId={excludeId} />
        <div className="w-full border-b-1 border-solid border-gray-20 my-[12px]"></div>
        <AddContactNew isOpen={openSection === ELinkItemSection.CREATE_NEW} toggleOpen={toggleCreateNewSection} /> 
      </BorderWithLabel>
    </>
  )
}