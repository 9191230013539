import { initialState, IBookingManagerDomain } from './model';
import * as Actions from './actions';
import { produce } from 'immer';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';

import { commentsSubdomainReducer } from './subdomains/comments/reducer';
import checklistReducer from './subdomains/checklist/reducer';
import { ChecklistAction } from './subdomains/checklist/actions';

import downloadsReducer from './subdomains/downloads/reducer';
import { DownloadsAction } from './subdomains/downloads/actions';

import uploadsReducer from './subdomains/uploads/reducer';
import { UploadsAction } from './subdomains/uploads/actions';

import guestInformationReducer from './subdomains/guestInformation/reducer';
import { GuestInformationAction } from './subdomains/guestInformation/actions';

import breakdownReducer from './subdomains/breakdown/reducer';
import { BreakdownAction } from './subdomains/breakdown/actions';

import dashboardReducer from './subdomains/dashboard/reducer';
import { DashboardAction } from './subdomains/dashboard/actions';

import voucherReducer from './subdomains/voucher/reducer';
import { VoucherAction } from './subdomains/voucher/actions';

import { bookingStatusOptionsSubdomainReducer } from './subdomains/bookingStatusOptions/reducer';
import { BookingStatusOptionsSubdomainAction } from './subdomains/bookingStatusOptions/actions';
import taLogoReducer from './subdomains/taLogo/reducer';
import { TaLogoAction } from './subdomains/taLogo/actions';

import financeReducer from './subdomains/finance/reducer';
import { FinanceAction } from './subdomains/finance/actions';

import hotelDetailsReducer from './subdomains/hotelDetails/reducer';
import { HotelDetailsAction } from './subdomains/hotelDetails/actions';

import ownershipReducer from './subdomains/ownership/reducer';
import { OwnershipAction } from './subdomains/ownership/actions';

import purchaseCostReviewReducer from './subdomains/purchaseCostReview/reducer';
import { PurchaseCostReviewAction } from './subdomains/purchaseCostReview/actions';

import settingsReducer from './subdomains/bookingSettings/reducer';
import { SettingsAction } from './subdomains/bookingSettings/actions';

import { bespokeNegotiationReducer } from './subdomains/bespokeNegotiation/reducer';
import { Any as BespokeNegotiationAction } from './subdomains/bespokeNegotiation/actions';

import { externalIdsReducer } from './subdomains/externalIds/reducer';
import { Any as ExtneralIdsAction } from './subdomains/externalIds/actions';

export const bookingManagerReducer = (
  state: IBookingManagerDomain = initialState,
  action: Actions.BookingManagerAction
) => {
  return {
    booking: bookingReducer(state.booking, action),
    commentsSubdomain: commentsSubdomainReducer(state.commentsSubdomain, action),
    checklistSubdomain: checklistReducer(state.checklistSubdomain, action as ChecklistAction),
    downloadsSubdomain: downloadsReducer(state.downloadsSubdomain, action as DownloadsAction),
    uploadsSubdomain: uploadsReducer(state.uploadsSubdomain, action as UploadsAction),
    guestInformation: guestInformationReducer(state.guestInformation, action as GuestInformationAction),
    availableProposals: availableProposalsReducer(state.availableProposals, action),
    networkRequests: networkRequestsReducer(state.networkRequests, action),
    uiState: uiStateReducer(state.uiState, action),
    breakdownSubdomain: breakdownReducer(state.breakdownSubdomain, action as BreakdownAction),
    dashboardSubdomain: dashboardReducer(state.dashboardSubdomain, action as DashboardAction),
    bookingStatusOptionsSubdomain: bookingStatusOptionsSubdomainReducer(
      state.bookingStatusOptionsSubdomain,
      action as BookingStatusOptionsSubdomainAction
    ),
    financeSubdomain: financeReducer(state.financeSubdomain, action as FinanceAction),
    voucherSubdomain: voucherReducer(state.voucherSubdomain, action as VoucherAction),
    taLogoSubdomain: taLogoReducer(state.taLogoSubdomain, action as TaLogoAction),
    hotelDetailsSubdomain: hotelDetailsReducer(state.hotelDetailsSubdomain, action as HotelDetailsAction),
    ownershipSubdomain: ownershipReducer(state.ownershipSubdomain, action as OwnershipAction),
    settingsSubdomain: settingsReducer(state.settingsSubdomain, action as SettingsAction),
    purchaseCostReviewSubdomain: purchaseCostReviewReducer(
      state.purchaseCostReviewSubdomain,
      action as PurchaseCostReviewAction
    ),
    bespokeNegotiationSubdomain: bespokeNegotiationReducer(
      state.bespokeNegotiationSubdomain,
      action as BespokeNegotiationAction
    ),
    externalIdsSubdomain: externalIdsReducer(state.externalIdsSubdomain, action as ExtneralIdsAction),
  };
};

const bookingReducer = (bookingState: IBookingManagerDomain['booking'], action: Actions.BookingManagerAction) => {
  switch (action.type) {
    case Actions.GET_BOOKING_SUCCESS:
      return action.booking;

    case Actions.RESET_BOOKING:
      return null;

    default:
      return bookingState;
  }
};

const availableProposalsReducer = (
  proposalState: IBookingManagerDomain['availableProposals'],
  action: Actions.BookingManagerAction
) => {
  switch (action.type) {
    case Actions.GET_AVAILABLE_PROPOSALS_SUCCESS:
      return action.availableProposals;

    default:
      return proposalState;
  }
};

const networkRequestsReducer = (
  networkState: IBookingManagerDomain['networkRequests'],
  action: Actions.BookingManagerAction
) => {
  switch (action.type) {
    case Actions.GET_BOOKING_REQUEST:
      return produce(networkState, draftState => {
        draftState.bookingLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_BOOKING_FAILURE:
      return produce(networkState, draftState => {
        draftState.bookingLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.GET_BOOKING_SUCCESS:
      return produce(networkState, draftState => {
        draftState.bookingLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.REQUEST_TO_BOOK_REQUEST:
      return produce(networkState, draftState => {
        draftState.requestToBook = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.REQUEST_TO_BOOK_SUCCESS:
      return produce(networkState, draftState => {
        draftState.requestToBook = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.REQUEST_TO_BOOK_FAILURE:
      return produce(networkState, draftState => {
        draftState.requestToBook = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.CONFIRM_REQUEST:
      return produce(networkState, draftState => {
        draftState.confirm = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.CONFIRM_SUCCESS:
      return produce(networkState, draftState => {
        draftState.confirm = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.CONFIRM_FAILURE:
      return produce(networkState, draftState => {
        draftState.confirm = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.CANCEL_REQUEST:
      return produce(networkState, draftState => {
        draftState.cancel = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.CANCEL_SUCCESS:
      return produce(networkState, draftState => {
        draftState.cancel = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.CANCEL_FAILURE:
      return produce(networkState, draftState => {
        draftState.cancel = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    case Actions.GET_AVAILABLE_PROPOSALS_REQUEST:
      return produce(networkState, draftState => {
        draftState.availableProposalsLoad = ENetworkRequestStatus.PENDING;
        return draftState;
      });

    case Actions.GET_AVAILABLE_PROPOSALS_SUCCESS:
      return produce(networkState, draftState => {
        draftState.availableProposalsLoad = ENetworkRequestStatus.SUCCESS;
        return draftState;
      });

    case Actions.GET_AVAILABLE_PROPOSALS_FAILURE:
      return produce(networkState, draftState => {
        draftState.availableProposalsLoad = ENetworkRequestStatus.ERROR;
        return draftState;
      });

    default:
      return networkState;
  }
};

const uiStateReducer = (uiState: IBookingManagerDomain['uiState'], action: Actions.BookingManagerAction) => {
  switch (action.type) {
    case Actions.SET_PRODUCT_BREAKDOWN_TABLE_EXPANDED_UUID:
      return produce(uiState, draftState => {
        draftState.productTableBreakdownExpandedUuid[action.productType].push(action.uuid);
        return draftState;
      });

    case Actions.REMOVE_PRODUCT_BREAKDOWN_TABLE_EXPANDED_UUID:
      return produce(uiState, draftState => {
        draftState.productTableBreakdownExpandedUuid[action.productType] = draftState.productTableBreakdownExpandedUuid[
          action.productType
        ].filter(uuid => uuid !== action.uuid);
        return draftState;
      });

    default:
      return uiState;
  }
};

export default bookingManagerReducer;
