import { useDispatch } from 'react-redux';
import { enqueueNotification, enqueueNotificationMultiple } from 'store/modules/ui';

export const useNotifications = () => {
  const dispatch = useDispatch();

  const showSuccessNotification = (message: string) => {
    dispatch(
      enqueueNotification({
        message: message,
        options: { variant: 'success' },
      })
    );
  };

  const showErrorNotification = (message: string) => {
    dispatch(
      enqueueNotification({
        message: message,
        options: { variant: 'error' },
      })
    );
  };

  const showInfoNotification = (message: string) => {
    dispatch(
      enqueueNotification({
        message: message,
        options: { variant: 'info' },
      })
    );
  };

  const showWarningNotification = (message: string) => {
    dispatch(
      enqueueNotification({
        message: message,
        options: { variant: 'warning' },
      })
    );
  };

  const showMultipleNotifications = (notifs: { message: string; variant: string }[]) => {
    dispatch(enqueueNotificationMultiple(notifs.map(n => ({ message: n.message, options: { variant: n.variant } }))));
  };

  return {
    showSuccessNotification,
    showErrorNotification,
    showInfoNotification,
    showWarningNotification,
    showMultipleNotifications,
  };
};
