import { EBookingStatus } from './BookingStatusResponse';

export type ISpecialRequests = string;
export interface ISpecialRequestsResponse {
  bookingUuid: string;
  version: string;
  specialRequests: ISpecialRequests;
}

export interface IBookingStateHistoryItem {
  status: EBookingStatus;
  timestamp: string;
}

export enum ERateSource {
  STATIC = 'static',
  SUPPLIER_UNCONFIRMED = 'supplierUnconfirmed',
  SUPPLIER_CONFIRMED = 'supplierConfirmed',
  INSTANT_BOOK = 'instantBook',
}

export interface IDepositAccountBalance {
  USD: number;
  EUR: number;
}

export interface ITopNavigationData {
  bookingReferenceNo: string;
  leadGuestName?: string;
  hotelNames?: string[];
  travelAgentName: string;
  travelAgentCompanyName?: string;
  travelAgentEmail?: string;
  travelAgentTelephone?: string;
  arrivalDate?: string;
  departureDate?: string;
  guestCount: number;
  bookingStatus: EBookingStatus;
  bookingStateHistory: IBookingStateHistoryItem[];
  rateSource?: ERateSource;
  depositAccountBalancesCents?: IDepositAccountBalance;
  travelAgentCompanyUuid?: string;
  travelCompanyMembershipName?: string;
  travelAgentCompanyComment?: string;
  childrenAges: number[];
}

export interface ITopNavigationDataResponse {
  bookingUuid: string;
  version: string;
  topNavigationData: ITopNavigationData;
}

type BookingInformationReservationTeamDataGuest = {
  title?: string | null;
  isLeadGuest?: boolean;
  name?: string | null;
  dateOfBirth?: string | null;
  arrivalCIPBookingNumber?: string | null;
  departureCIPBookingNumber?: string | null;
  arrivalFlightNo?: string | null;
  departureFlightNo?: string | null;
  arrivalTime?: string | null;
  departureTime?: string | null;
  arrivalDate?: string | null;
  departureDate?: string | null;
};

type BookingInformationReservationTeamDataAccommodation = {
  title?: string;
  mealPlan?: string;
};

type BookingInformationReservationTeamDataTransfer = {
  title?: string;
  tertiaryText?: string;
};

export interface IBookingInformationReservationTeamData {
  bookingReferenceNo: string;
  travelAgentName: string;
  travelAgentCountry: string;
  travelAgentCompanyUuid: string;
  travelAgentCompanyName: string;
  travelAgentCompanyId: string;
  hotelNames: string[];
  resortConfirmationNumbers: string[];
  arrivalDate: string | null;
  departureDate: string | null;
  guests: BookingInformationReservationTeamDataGuest[];
  accommodations: BookingInformationReservationTeamDataAccommodation[];
  transfers: BookingInformationReservationTeamDataTransfer[];
}

export interface IBookingInformationReservationTeamDataResponse {
  bookingUuid: string;
  bookingInformationReservationTeamData: IBookingInformationReservationTeamData;
}
