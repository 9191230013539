import classNames from 'classnames';
import React from 'react';
import { OnHoldIconPrimary } from 'ui/Icons/components/BasketOnHoldPrimary.component';
import { formatDateTimeDisplay } from 'utils';
import { theme } from '../../../tailwind.config';
import { IOnHoldInfo } from './BasketItem';

interface IOnHoldInfoPrimaryProps extends Pick<IOnHoldInfo, 'isSetValid' | 'setDeadline'> {}

export const OnHoldInfoPrimary: React.FC<IOnHoldInfoPrimaryProps> = React.memo(({ isSetValid, setDeadline }) => {
  const color = isSetValid ? 'green-message' : 'status-cancelled';
  const colorsClassName = `text-${color}`;
  return (
    <React.Fragment>
      <div className="on-hold-info-primary flex items-center justify-start pt-[15px] pb-[5px]">
        <OnHoldIconPrimary background={theme.colors[color]} width={30} height={30}/>
        <span className={ classNames('uppercase text-[17px] font-normal ml-1', colorsClassName)}>{isSetValid ? 'On Hold' : 'On Hold Expired' }</span>
        <span className="text-gray-80 ml-1">{`(${isSetValid ? 'Until' : ''} ${formatDateTimeDisplay(setDeadline)})`}</span>
      </div>
    </React.Fragment>
  );

});