import axios, { CancelTokenSource } from 'axios';
import { useCallback, useRef, useState } from 'react';

/**
 * This lets you use a modal like a confirmation dialog.
 */
export const useModal = <T>({ onReset }: { onReset?: () => void } = {}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<T | null>(null);
  const [resolveCallback, setResolveCallback] = useState<((value: any | null) => void) | null>(null);
  const cancelTokenRef = useRef<CancelTokenSource | null>(null);

  /**
   * Opens the modal via a promise that doesn't resolve until `handleConfirm` is called, allowing
   * you to block the flow of a function until an action is confirmed.
   *
   * Returns a promise that resolves when `handleConfirm` is called, with the value
   * of whatever was given to the `handleConfirm`
   */
  const requestConfirmation = (newModalData?: T) => {
    return new Promise<any>(resolve => {
      setIsOpen(true);
      if (newModalData) {
        setModalData(newModalData || null);
      }
      setResolveCallback(() => resolve);
    });
  };

  /**
   * Opens the modal but DOESN'T block the flow of the function, allowing the flow to continue.
   *
   * Returns an AbortController signal that can be used in fetch or other cancellable APIs.
   */
  const showModal = (newModalData?: T) => {
    setIsOpen(true);
    if (newModalData) {
      setModalData(newModalData || null);
    }

    // Create a new CancelTokenSource for this modal session
    cancelTokenRef.current = axios.CancelToken.source();

    // Return the CancelTokenSource token for use in axios requests
    return cancelTokenRef.current.token;
  };

  const handleSetRenderData = (data: T) => {
    setModalData(data);
  };

  const handleConfirm = (data?: any) => {
    if (resolveCallback) resolveCallback(data);
    setIsOpen(false);
  };

  const handleClose = () => {
    if (resolveCallback) resolveCallback(null);
    setIsOpen(false);

    // Cancel any ongoing operations when modal is closed
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel('Operation cancelled');
      cancelTokenRef.current = null;
    }
  };

  const cancelOperation = useCallback(() => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current.cancel('Operation cancelled');
      cancelTokenRef.current = null;
    }
  }, []);

  const reset = (callback?: () => void) => {
    setIsOpen(false);
    setModalData(null);
    setResolveCallback(null);
    if (callback) callback();
    if (onReset) onReset();
  };

  return {
    isOpen,
    requestConfirmation,
    showModal,
    handleConfirm,
    handleClose,
    handleSetRenderData,
    modalData,
    cancelOperation,
    reset,
  };
};
