import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { ITCBookingStatementSortableItems } from 'services/BookingManagerApi';

import { ITCBookingStatementRow } from 'store/modules/tcBookingStatement/model';
import { IDueDate } from 'store/modules/ledger/model';
import {
  tcBookingStatementSetSortAction,
  tcBookingStatementSetPageNumberAction,
  tcBookingStatementSetItemsPerPageAction,
} from 'store/modules/tcBookingStatement/actions';
import {
  tcBookingStatementItemsPerPageSelector,
  tcBookingStatementItemsPageCountSelector,
  tcBookingStatementPageSelector,
  tcBookingStatementSortSelector,
} from 'store/modules/tcBookingStatement/selectors';

import { HidingTooltip } from 'ui/Tooltip';

import { format } from '../Ledger/utils';
import { TCLedgerTable } from './TCLedgerTable';
import { TransactionDateColumn } from '../Ledger/TransactionDateColumn';
import { TransactionBookingColumn } from '../Ledger/TransactionBookingColumn';
import { TransactionAmountColumn } from '../Ledger/TransactionAmountColumn';
import { Pagination } from 'pureUi/Pagination';
import { TCurrencyCode } from 'interfaces';

interface ICurrency {
  currency: TCurrencyCode;
}

interface ITCBookingLedgerTableProps extends ICurrency {
  tcBookingStatementRows: ITCBookingStatementRow[];
  tableLoading: boolean;
}

interface IFormatters {
  formatAmount: (amount: number) => string;
  formatDate: (date: string) => string;
}

interface ITCBookingStatementLedgerTableRowProps extends IFormatters {
  row: ITCBookingStatementRow;
  rowIndex: number;
}

interface IRenderHeaderSpan {
  label: string;
  sortKey?: ITCBookingStatementSortableItems;
  labelClasses?: string;
  headerClasses?: string;
}

interface IDueDatesTooltipProps extends IFormatters {
  amountCents: number;
  dueDates: IDueDate[];
  humanReadableId: string;
}

const TCBookingLedgerTableHeader: React.FC = React.memo(() => {
  const dispatch = useDispatch();
  const sort = useSelector(tcBookingStatementSortSelector);

  const Classes = useMemo(
    () => ({
      container: 'bg-ivory border-solid border-b border-gray-20 uppercase text-xs text-gray-120 border-l-0 border-r-0',
      header: 'group flex font-normal items-center justify-between h-8',
      bordered: 'border-r border-solid border-r-gray-20',
    }),
    []
  );

  const handleSort = useCallback(
    (sortBy: ITCBookingStatementSortableItems) => () => {
      if (sort) {
        const newSortOrder = sortBy === sort.field && sort.order === 'asc' ? 'desc' : 'asc';
        dispatch(tcBookingStatementSetSortAction(sortBy, newSortOrder));
      }
    },
    [dispatch, sort]
  );

  const renderSortIcon = useCallback(
    (sortBy: ITCBookingStatementSortableItems) => {
      if (sortBy !== sort.field) {
        return (
          <span className="group-hover:text-gray-40 text-ivory fa-stack fa-lg w-20px ml-5px">
            <i className="fas fa-stack-1x fa-sort-up"></i>
            <i className="fas fa-stack-1x fa-sort-down"></i>
          </span>
        );
      }
      if (sort.order === 'asc') {
        return (
          <span className="fa-stack fa-lg w-20px ml-5px">
            <i className="fas fa-stack-1x fa-sort-up text-gray-100"></i>
            <i className="fas fa-stack-1x fa-sort-down text-gray-40"></i>
          </span>
        );
      } else if (sort.order === 'desc') {
        return (
          <span className="fa-stack fa-lg w-20px ml-5px">
            <i className="fas fa-stack-1x fa-sort-up text-gray-40"></i>
            <i className="fas fa-stack-1x fa-sort-down text-gray-100 "></i>
          </span>
        );
      }
    },
    [sort]
  );

  const renderHeaderSpan = useCallback(
    ({ label, labelClasses, headerClasses, sortKey }: IRenderHeaderSpan) => {
      if (sortKey === undefined) {
        return (
          <span className={Classes.header}>
            <span className={classNames('w-full', labelClasses ?? 'pl-2')}>{label}</span>
          </span>
        );
      }

      return (
        <span
          className={classNames(`${Classes.header} hover:bg-gray-10 cursor-pointer rounded ${headerClasses}`, {
            'bg-gray-10': sort.field === sortKey,
          })}
        >
          <span className={classNames('w-full', labelClasses ?? 'pl-2')}>{label}</span>
          {renderSortIcon(sortKey)}
        </span>
      );
    },
    [Classes.header, renderSortIcon, sort.field]
  );

  return (
    <thead className={Classes.container}>
      <tr className="h-10">
        <th id="dates-header" style={{ width: '100px' }} onClick={handleSort('createdAt')}>
          {renderHeaderSpan({ label: 'Date', sortKey: 'createdAt', headerClasses: 'ml-7px' })}
        </th>

        <th id="booking-header" style={{ width: '160px' }}>
          {renderHeaderSpan({ label: 'Booking' })}
        </th>

        <th id="guest-header" style={{ width: '120px' }}>
          {renderHeaderSpan({ label: 'Guest' })}
        </th>

        <th id="hotel-header" style={{ width: '120px' }}>
          {renderHeaderSpan({ label: 'Hotel' })}
        </th>

        <th id="arrival-dates-header" style={{ width: '80px' }}>
          {renderHeaderSpan({ label: 'Arrival', labelClasses: 'text-left pl-2' })}
        </th>

        <th id="departure-dates-header" style={{ width: '80px' }}>
          {renderHeaderSpan({ label: 'Departure', labelClasses: 'text-left pl-2' })}
        </th>

        <th id="due-dates-header" className={Classes.bordered} style={{ width: '50px' }}>
          {renderHeaderSpan({ label: 'Due Dates', labelClasses: 'text-center pl-0' })}
        </th>

        <th id="invoice-amount-header" className={Classes.bordered} style={{ width: '110px' }}>
          {renderHeaderSpan({ label: 'Invoice Amount', labelClasses: 'text-center pl-0' })}
        </th>

        <th id="paid-amount-header" className={Classes.bordered} style={{ width: '110px' }}>
          {renderHeaderSpan({ label: 'Paid Amount', labelClasses: 'text-center pl-0' })}
        </th>

        <th id="balance-to-pay-header" className={Classes.bordered} style={{ width: '110px' }}>
          {renderHeaderSpan({ label: 'Balance to pay', labelClasses: 'text-center pl-0' })}
        </th>

        <th id="overdue-to-pay-header" style={{ width: '110px' }}>
          {renderHeaderSpan({ label: 'Over Due', labelClasses: 'text-center pl-0' })}
        </th>
      </tr>
    </thead>
  );
});

const TCBookingLedgerTableRow: React.FC<ITCBookingStatementLedgerTableRowProps> = React.memo(
  ({ row, rowIndex, formatAmount, formatDate }) => {
    const guestName = [row.leadGuestTitle, row.leadGuestFirstName, row.leadGuestLastName]
      .filter(item => !!item)
      .join(' ');

    const Classes = useMemo(
      () => ({
        row: 'h-58px text-black text-15px border-t border-solid border-gray-20 even:bg-ivory',
        guest: 'px-2 overflow-hidden whitespace-nowrap text-ellipsis',
        hotel: 'px-2 overflow-hidden whitespace-nowrap text-ellipsis',
        bordered: 'border-r border-solid border-r-gray-20',
        amount: 'text-right pl-2 pr-15px',
        amountsBg: (rowIndex + 1) % 2 === 0 ? 'bg-teal-20' : 'bg-green-25',
        bookingBalanceBg: (rowIndex + 1) % 2 === 0 ? 'bg-gray-10' : 'bg-ivory',
        red: 'text-red-100',
      }),
      [rowIndex]
    );

    const renderDueDatesTooltip = useCallback(() => {
      if (!row.dueDates) {
        return null;
      }

      return (
        <DueDatesTooltip
          amountCents={row.invoiceAmount}
          dueDates={row.dueDates}
          formatAmount={formatAmount}
          formatDate={formatDate}
          humanReadableId={row.humanReadableId}
        />
      );
    }, [row, formatDate, formatAmount]);

    return (
      <tr className={Classes.row}>
        <TransactionDateColumn
          className={`tc-booking-ledger-table-column-date-${rowIndex} pl-15px`}
          date={row.createdAt}
          formatter={formatDate}
        />

        <TransactionBookingColumn
          className={`tc-booking-ledger-table-column-booking-reference-${rowIndex}`}
          bookingUuid={row.bookingUuid}
          bookingReference={row.humanReadableId}
          bookingStatus={row.status}
          canViewBooking={row.canViewBooking}
        />

        <td className={`tc-booking-ledger-table-column-guest-${rowIndex} ${Classes.guest}`}>
          <span>{guestName}</span>
        </td>

        <td className={`tc-booking-ledger-table-column-hotel-${rowIndex} ${Classes.hotel}`}>
          <span>{row.hotelNames.join(', ')}</span>
        </td>

        <td className={`tc-booking-ledger-table-column-arrival-dates-${rowIndex} px-2 ${Classes.bordered} border-r-0`}>
          {row.bookingArrival && (
            <TransactionDateColumn
              className={`tc-booking-ledger-table-column-date-${rowIndex}`}
              date={row.bookingArrival}
              formatter={formatDate}
            />
          )}
        </td>

        <td
          className={`tc-booking-ledger-table-column-departure-dates-${rowIndex} px-2 ${Classes.bordered} border-r-0`}
        >
          {row.bookingDeparture && (
            <TransactionDateColumn
              className={`tc-booking-ledger-table-column-date-${rowIndex}`}
              date={row.bookingDeparture}
              formatter={formatDate}
            />
          )}
        </td>

        <td className={`tc-booking-ledger-table-column-departure-dates-${rowIndex} px-2 ${Classes.bordered}`}>
          {row.dueDates && row.dueDates.length > 0 && (
            <span className="relative">
              <HidingTooltip renderTooltipContent={renderDueDatesTooltip} position="left" tooltipClassname="mt-15">
                <span className="w-full text-center">
                  <i className="cursor-pointer fas fa-info-circle text-gray-60 hover:text-gray-90" />
                </span>
              </HidingTooltip>
            </span>
          )}
        </td>

        <TransactionAmountColumn
          amount={row.invoiceAmount}
          formatter={formatAmount}
          className={`tc-booking-ledger-table-column-transaction-amount booking-ledger-table-column-transaction-amount-${rowIndex} ${
            Classes.amount
          } ${Classes.bordered} ${Classes.amountsBg} ${row.invoiceAmount < 0 ? Classes.red : ``}`}
        />

        <TransactionAmountColumn
          amount={row.paidAmount}
          formatter={formatAmount}
          className={`tc-booking-ledger-table-column-total-balance booking-ledger-table-column-total-balance-${rowIndex} ${
            Classes.amount
          } ${Classes.bordered} ${Classes.amountsBg} ${row.paidAmount < 0 ? Classes.red : ``}`}
        />

        <TransactionAmountColumn
          amount={row.balanceToPay}
          formatter={formatAmount}
          className={`tc-booking-ledger-table-column-total-balance booking-ledger-table-column-total-balance-${rowIndex} ${
            Classes.amount
          } ${Classes.bookingBalanceBg} ${Classes.bordered} ${Classes.amountsBg} ${
            row.balanceToPay < 0 ? Classes.red : ``
          }`}
        />

        <TransactionAmountColumn
          amount={row.overdueAmount}
          formatter={formatAmount}
          className={`tc-booking-ledger-table-column-total-balance booking-ledger-table-column-total-balance-${rowIndex} ${
            Classes.amount
          } ${Classes.bookingBalanceBg} ${Classes.bordered} ${Classes.amountsBg} ${
            row.overdueAmount < 0 ? Classes.red : ``
          } border-r-0`}
        />
      </tr>
    );
  }
);

const TCBookingLedgerTableBody: React.FC<ITCBookingLedgerTableProps> = React.memo(
  ({ tcBookingStatementRows, currency }) => {
    const formatDate = useMemo(() => format.date, []);
    const formatAmount = useMemo(() => format.amount(currency), [currency]);

    return (
      <tbody>
        {tcBookingStatementRows.map((tcBookingStatementRow, rowIndex) => (
          <TCBookingLedgerTableRow
            formatAmount={formatAmount}
            formatDate={formatDate}
            row={tcBookingStatementRow}
            rowIndex={rowIndex}
            key={`booking-ledger-table-row-${rowIndex}`}
          />
        ))}
      </tbody>
    );
  }
);

export const DueDatesTooltip: React.FC<IDueDatesTooltipProps> = React.memo(
  ({ amountCents, humanReadableId, dueDates, formatAmount, formatDate }) => (
    <div className="due-dates-tooltip px-10px py-20px font-pt-sans text-black w-310px">
      <div className="flex justify-between items-center">
        <div className="title font-bold">Proforma Invoice</div>
        <div>{humanReadableId}</div>
      </div>
      <div className="flex justify-between items-center px-15px py-10px bg-teal-20 mt-20px">
        <div className="label text-xs uppercase">Invoice Total</div>
        <div className="value font-bold text-15px">{formatAmount(amountCents)}</div>
      </div>
      <div className="due-dates bg-green-25 text-13px py-5px px-15px">
        <div className="title text-gray-100 pb-1px">Due dates:</div>
        <div className="items">
          {dueDates.map(item => (
            <div key={item.date} className="flex justify-between items-center py-1">
              <div className="label">{formatDate(item.date)}</div>
              <div className="value">{formatAmount(item.amountCents)}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
);

export const TCBookingLedgerTable: React.FC<ITCBookingLedgerTableProps> = React.memo(props => {
  const dispatch = useDispatch();
  const currentPage = useSelector(tcBookingStatementPageSelector);
  const itemsPerPage = useSelector(tcBookingStatementItemsPerPageSelector);
  const pageCount = useSelector(tcBookingStatementItemsPageCountSelector);
  const isLastPage = currentPage + 1 >= pageCount;
  const bodyProps = { ...props, isLastPage };

  const handlePageChange = useCallback(
    (pageNumber: number) => {
      dispatch(tcBookingStatementSetPageNumberAction(pageNumber - 1));
    },
    [dispatch]
  );

  const handleItemsPerPageChange = useCallback(
    (items: number) => {
      dispatch(tcBookingStatementSetItemsPerPageAction(items));
    },
    [dispatch]
  );

  return (
    <>
      <TCLedgerTable
        className={classNames('tc-booking-ledger-table-by-currency', { 'opacity-30': props.tableLoading })}
      >
        <TCBookingLedgerTableHeader />
        <TCBookingLedgerTableBody {...bodyProps} />
      </TCLedgerTable>

      <Pagination
        className="pagination mt-8 mr-15px"
        onPageSelect={handlePageChange}
        pageCount={pageCount}
        currentPage={currentPage + 1}
        itemsPerPage={itemsPerPage}
        onItemsPerPageChange={handleItemsPerPageChange}
      />
    </>
  );
});
