import React from 'react';
import { ChildItem } from './ChildItem';
import FluidButton from 'ui/FluidButton';

interface iChildrenListProps {
  hotel: any;
  singularNoun: string;
  childList: any[];
  onOpen: (childItem: any) => void;
  contentRenderer: (childItem: any) => React.ReactElement;
  renderChildSubProducts: any[];
  onCreateTrigger?: () => void;
  titleRenderer?: (childItem: any) => React.ReactElement;
  editedUnsavedDetailsItemUuids: string[];
  onDeleteTrigger?: (childItem: any) => void;
}

export const ChildrenList = (props: iChildrenListProps) => {
  return (
    <div className="w-full">
      <div className="flex flex-col space-y-2 w-full">
        {props.childList?.map(childItem => {
          const draftChildItem = {
            ...childItem,
          };
          return (
            <div key={childItem.uuid} className="relative">
              <ChildItem
                key={childItem.uuid}
                onOpen={props.onOpen}
                title={props.titleRenderer ? props.titleRenderer(childItem) : childItem.name}
                childItem={draftChildItem}
                contentRenderer={props.contentRenderer}
                renderChildSubProducts={props.renderChildSubProducts}
                editedUnsavedDetailsItemUuids={props.editedUnsavedDetailsItemUuids}
              />

              {props.onDeleteTrigger && (
                <button
                  className="delete-button absolute top-[6px] right-[6px] rounded-full w-6 h-6 bg-brown-60 hover:bg-brown-80 text-white text-xs cursor-pointer"
                  onClick={() => {
                    props.onDeleteTrigger!(childItem);
                  }}
                >
                  &#x2715;
                </button>
              )}
            </div>
          );
        })}

        <hr />
      </div>
      <FluidButton
        onClick={() => {
          props.onCreateTrigger ? props.onCreateTrigger() : null;
        }}
        type="secondary"
        className="w-[250px] !mt-4 self-start"
      >
        Create new {props.singularNoun}
      </FluidButton>
    </div>
  );
};
