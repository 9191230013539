import React from 'react'
import classnames from 'classnames';
import { ESortOrder } from 'store/common/types';
import { TableHeader } from './TableHeader';
import { EmptyTableRow } from './EmptyTableRow';
import { BlockingOverlay } from 'ui/BlockingOverlay';
import { IScrollableTableColumn, IScrollableTableRow } from './types';
import { TableRow } from './TableRow';

interface IScrollableTableProps {
  columns: IScrollableTableColumn[];
  rows: IScrollableTableRow[];
  sortBy: string;
  sortOrder: ESortOrder;
  onSort: (newSortBy?: string, newSortOrder?: ESortOrder) => void;
  isLoading?: boolean;
  className?: string;
  messageWhenEmpty: string;
  emptyTableRowClassName?: string;
  evenColor?: string;
}

export const ScrollableTable: React.FC<IScrollableTableProps> = React.memo(({ 
  columns, rows, className, messageWhenEmpty, isLoading = false, emptyTableRowClassName = '', evenColor = 'white', sortBy, sortOrder, onSort
}) => {
  const tableClassName = classnames(
    className,
    'mt-25px border border-solid border-gray-20 shadow-pe2',
    {
      'opacity-30': isLoading,
    }
  );

  return (
    <BlockingOverlay showWhen={isLoading}>
      <div className={tableClassName}>
          <TableHeader columns={columns} sortBy={sortBy} sortOrder={sortOrder} onSort={onSort} />
          <div className="rows max-h-250px min-h-250px overflow-y-auto">
            {rows.length > 0 ? rows.map((row: IScrollableTableRow, rowIndex: number) => (
              <TableRow
                key={row.id}
                row={row}
                rowIndex={rowIndex}
                evenColor={evenColor}
              />
            )) : (
              <EmptyTableRow text={messageWhenEmpty} className={emptyTableRowClassName} />
            )}
          </div>
        </div>
    </BlockingOverlay>
  );
});
