import { ECrmCompanyType } from 'services/CrmApi/types/DirectoryEntry';
import * as Yup from 'yup';

export const companyProfileFormValidationSchema = Yup.object().shape({
  companyTypeCode: Yup.string().required('Field is required'),
  name: Yup.string().required('Field is required'),
  tcType: Yup.string().nullable(true),
  industry: Yup.string(),
  employeeCount: Yup.number(),
  country: Yup.string().nullable(true),
  address: Yup.string(),
  mobile: Yup.string(),
  email: Yup.string().email('Must be valid email'),
  website: Yup.string(),
  linkedin: Yup.string(),
  twitter: Yup.string(),
  instagram: Yup.string(),
  acceptsEmail: Yup.boolean(),
});

export interface ICompanyProfileFormValues {
  companyTypeCode: string;
  name: string;
  tcType: ECrmCompanyType | null;
  industry: string;
  countryCode: string | null;
  employeeCount: string;
  address: string;
  mobile: string;
  email: string;
  acceptsEmail?: boolean;
  website: string;
  linkedin: string;
  twitter: string;
  instagram: string;
  manager?: string;
}
