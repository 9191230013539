import * as React from 'react';
import classnames from 'classnames';
import sha256 from 'sha.js';

const DEFAULT_COLORS = ['bright-sky-blue', 'status-confirmed', 'status-cancelled', 'status-requested', 'brown-100'];

interface IContactLogoProps {
  uuid: string;
  firstName: string;
  lastName?: string | null;
  sizePx?: string;
  customColors?: string[];
  type: 'big' | 'small';
}

export const ContactLogo: React.FC<IContactLogoProps> = ({ uuid, firstName, lastName, sizePx = '60px', customColors, type }) => {
  
  function getColorFromUUID(uuid) {
    const hash = new sha256('sha256').update(uuid).digest('hex');
    const index = parseInt(hash.substring(0, 8), 16) % colors.length;
    return colors[index];
  }
  
  const colors = customColors || DEFAULT_COLORS;
  const bgColor = getColorFromUUID(uuid);
  const initials = `${firstName.charAt(0).toUpperCase()}${lastName?.charAt(0).toUpperCase() || ''}`;
  const logoStyle: React.CSSProperties = {
    minWidth: sizePx,
    maxWidth: sizePx,
    minHeight: sizePx,
    maxHeight: sizePx,
  }
  const logoClassname = `logo flex items-center justify-center rounded-full bg-${bgColor} bg-opacity-50 text-white text-2xl font-bold`;
  const textClassName = classnames('text-white tracking-[5%] font-[400] font-pt-sans text-center', {
    'text-[21px]': type === 'big',
    'text-[12px]': type === 'small',
  });

  return (
    <div className={logoClassname} style={logoStyle}>
      <span className={textClassName}>
        {initials}
      </span>
    </div>
  );
};
