import axios, { AxiosInstance, AxiosResponse, CancelToken, CancelTokenSource } from 'axios';
import { omitDeep } from 'deepdash-es/standalone';
import { stringify } from 'qs';
import { IFinanceDocument } from 'store/modules/bookingManager/subdomains/finance/types';
import { EInvoiceAddresseeType, EInvoiceLang, EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { IBookingManagerHotelDetails } from 'store/modules/bookingManager/subdomains/hotelDetails/types';
import { ExportType } from 'store/modules/bookingsList/model';
import { IGuest, IVoucherDownloadPayload, Upload } from 'services/BackendApi/types';
import { isNil, omit } from 'lodash-es';

import {
  ITCBookingStatementTotalsResponse,
  ITCBookingStatementPageResponse,
  ITCBookingStatementPageRequest,
  ICancellationPolicies,
  IChecklistItem,
  IDownloadsResponse,
  IPaymentTerms,
  ISpecialRequests,
  IHeadlineLineItemBreakdownResponse,
  ITopNavigationData,
  IBookingSearchResponse,
  IHeadlineBreakdownVersionListVersion,
  IOwnershipResponse,
  ISalesRepresentativesResponse,
  IInvoiceDueDate,
  BookingSearchFilter,
  IPurchaseCostReviewResponse,
  IPurchaseCostReviewPatchRequest,
  IOwnershipTravelAgentMovements,
  IOwnershipPatchBody,
  IBookingLedgerRequestData,
  IBookingLedgerResponse,
  ILedgerTotalsResponse,
  IPostExportResponse,
  IGetExportResponse,
  IBookingComment,
  IBookingComments,
  IDepositAccountRequestData,
  IDepositAccountResponse,
  IHeadlineLineItemBreakdown,
  IInvoiceAddressee,
  IManualBookingLedgerRowResponse,
  ISaveDepositAccountRowResponse,
  IStatementPdf,
  IBespokeNegotiationResponse,
  IBespokeNegotiationItem,
  ICompanyIncentiveResponse,
  ICompanyIncentiveUpsertBody,
  IGetHotelDetailsResponse,
  Proposal,
  IProposalHistory,
  ProposalRequest,
  ProposalDocDraftResponse,
  IBarCommissionResponse,
  IManualInvoiceDueDate,
  ProcessResponse,
  ISimulateBreakdownRequest,
  ISimulateBreakdownResponse,
  IBookingCountryResponse,
} from './types';
import {
  EBookingStatus,
  IBookingStatusCancellationInvoiceRequestData,
  IBookingStatusOptionsResponse,
  IBookingStatusResponse,
} from './types/BookingStatusResponse';
import { IGuestInformationResponse } from './types/GuestInformationResponse';
import { injectJwtTokenIntoHeaders } from '../tokenLocalStorage';
import { TCountryCode, TCurrencyCode } from 'interfaces';
import { IDepositAccountRowForSave, IManualBookingLedgerRowForSave } from 'store/modules/ledger/model';
import {
  IGlobalGrandTotalsFilter,
  IGlobalStatementQuery,
  IGlobalStatementQueryFilter,
  IGlobalStatementTableDataResponse,
} from 'store/modules/globalStatement/types';
import { IGetExternalCancellationDataResponse } from './types/ExternalCancellationData';
import { CancelBookingProcessResponse, ICancelBookingRequest, ICancelBookingResponse } from './types/PostBookingCancel';
import { EBookingType, IGetBookingTypeResponse, ISetBookingTypeResponse } from './types/BookingTypeResponse';
import {
  IProposalSelection,
  IProposalSelectionListFilter,
  IProposalSelectionListResponse,
  IProposalSelectionListSort,
} from './types/ProposalSelection';
import { IPagination } from 'store/common/types';
import { IExternalIds, IExternalIdsResponse } from './types/ExternalIdsResponse';
import { ICompanyMembershipMicrodocPatch, ICompanyMembershipMicrodocResponse } from './types/CompanyMemberships';
import { ParameterService } from 'services/ParametersProviderApi/ParametersService';
import { EPaymentContextCode, IPaymentMethodsGetInContextResponse } from './types/PaymentMethods';
import { AxiosWrapper } from 'services/AxiosWrapper';
import {
  AncillaryProductWithRates,
  AncillaryRatesProductsSearchResponse,
  AncillaryRegionsSearchResponse,
  EAncillaryFilterCode,
  EAncillaryLocationType,
  EAncillaryProductType,
  LocationDirectionWithCountry,
} from './types/AncillaryService';

export enum BookingManagerEndpoints {
  TCBOOKINGSTATEMENT = 'v1/tc-booking-statement',
  BOOKINGS = 'v1/bookings',
  SR_LIST = 'v1/sr-list',
  BOOKING_LEDGER = 'v1/booking-ledger',
  DEPOSIT_ACCOUNT = 'v1/deposit-account-row',
  FINANCE_SUMMARY = 'v1/finance-summary',
  GLOBAL_STATEMENT = 'v1/global-statements',
  INCENTIVES = 'v1/incentives',
  PROPOSAL_SELECTIONS = 'v1/proposal-selection',
  PROPOSAL = 'v1/proposal',
  PAYMENT_METHODS = 'v1/payment-methods',
  ANCILLARY_SERVICE_BRIDGE = 'v1/ancillary-bridge',
  ANCILLARY = 'v1/ancillary',
}

const cleanHeadlineLineItemBreakdown = (headlineLineItemBreakdown: IHeadlineLineItemBreakdown) => {
  return omitDeep(headlineLineItemBreakdown, [
    'PreDiscountSubtotalCents',
    'PurchaseCostSubtotalCents',
    'ProfitPercentage',
    'ProfitCalculatedValueCents',
    'SubtotalCents',
    'preDiscountSubtotalCents',
    'subtotalCents',
    'purchaseCostSubtotalCents',
    'isOnRequest',
    'TotalCostToClientCents',
    'MarginCalculatedValueCents',
    'expires', // for the breakdown holds
    'saleCostCentsUnreactive', // @see https://pureescapes.atlassian.net/browse/OWA-2252
    'CompanyExternalMarkupAmountSubtotalCents',
    'InternalMarkupAmountSubtotalCents',
    'companyExternalMarkupAmountSubtotalCents',
    'internalMarkupAmountSubtotalCents',
    'internalMarkupAmountCents',
    'companyExternalMarkupAmountCents',
    'IsInstantBook',
    'compositeId', // for the compositeIds needed for locations OWA-7763
    'ExternalProfitPercentage', // 7823
  ]);
};

export class BookingManagerApiService<T extends AxiosWrapper<AxiosInstance>> {
  client: T;

  constructor(client: T) {
    this.client = client;
  }

  getChecklist = async (bookingUuid: string): Promise<AxiosResponse<IChecklistItem[]>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/checklist`;
    return this.client.get(endpoint);
  };

  updateChecklist = async (
    bookingUuid: string,
    checklistItems: IChecklistItem[]
  ): Promise<AxiosResponse<IChecklistItem[]>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/checklist`;
    return this.client.patch(endpoint, { checklistItems });
  };

  getCommentsForBooking = async (bookingUuid: string): Promise<AxiosResponse<IBookingComments>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/comments`;
    return this.client.get(endpoint);
  };

  addCommentToBooking = async (
    bookingUuid: string,
    commentText: string,
    commentType: string
  ): Promise<AxiosResponse<IBookingComment>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/comments`;
    return this.client.patch(endpoint, {
      type: commentType,
      text: commentText,
    });
  };

  getDownloads = async (bookingUuid: string): Promise<AxiosResponse<IDownloadsResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/downloads`;
    return this.client.get(endpoint);
  };

  updateCommentOnBooking = async (
    bookingUuid: string,
    uuid: string,
    commentText: string
  ): Promise<AxiosResponse<IBookingComment>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/comments`;
    return this.client.patch(endpoint, {
      uuid,
      text: commentText,
    });
  };

  deleteCommentOnBooking = async (bookingUuid: string, uuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/comments`;
    return this.client.patch(endpoint, {
      uuid,
    });
  };

  getGuestInformation = async (bookingUuid: string): Promise<AxiosResponse<IGuestInformationResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/guest-details`;
    return this.client.get(endpoint);
  };

  getSalesRepresentatives = async (): Promise<AxiosResponse<ISalesRepresentativesResponse>> => {
    const endpoint = `${BookingManagerEndpoints.SR_LIST}`;
    return this.client.get(endpoint);
  };

  saveGuestInformation = async (
    bookingUuid: string,
    guests: IGuest[]
  ): Promise<AxiosResponse<IGuestInformationResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/guest-details`;
    return this.client.patch(endpoint, {
      guests,
    });
  };

  getHeadlineLineItemBreakdown = async (
    bookingUuid: string,
    version?: IHeadlineBreakdownVersionListVersion
  ): Promise<AxiosResponse<IHeadlineLineItemBreakdownResponse>> => {
    let endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/headline-line-item-breakdown`;

    if (version !== undefined) {
      endpoint += `?versionTimestamp=${version.timestamp}`;
    }

    return this.client.get(endpoint);
  };

  saveAndUpdateBarCommission = async (
    bookingUuid: string,
    commissionPercentage: number | null,
    commissionAmount: number | null
  ): Promise<AxiosResponse<IBarCommissionResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/bar-commission`;

    return this.client.patch(endpoint, {
      commission: {
        percentage: commissionPercentage,
        amount: commissionAmount,
      },
    });
  };

  saveAndUpdateHeadlineBreakdown = async (
    bookingUuid: string,
    headlineLineItemBreakdown: IHeadlineLineItemBreakdown
  ): Promise<AxiosResponse<IHeadlineLineItemBreakdownResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/headline-line-item-breakdown`;

    // need to strip all the subtotals out
    const cleanedHeadlineLineIteamBreakdown = omitDeep(headlineLineItemBreakdown, [
      'PreDiscountSubtotalCents',
      'PurchaseCostSubtotalCents',
      'ProfitPercentage',
      'ProfitCalculatedValueCents',
      'SubtotalCents',
      'preDiscountSubtotalCents',
      'subtotalCents',
      'purchaseCostSubtotalCents',
      'isOnRequest',
      'TotalCostToClientCents',
      'MarginCalculatedValueCents',
      'expires', // for the breakdown holds
      'saleCostCentsUnreactive', // @see https://pureescapes.atlassian.net/browse/OWA-2252
      'CompanyExternalMarkupAmountSubtotalCents',
      'InternalMarkupAmountSubtotalCents',
      'companyExternalMarkupAmountSubtotalCents',
      'internalMarkupAmountSubtotalCents',
      'internalMarkupAmountCents',
      'companyExternalMarkupAmountCents',
      'IsInstantBook',
      'compositeId', // for the compositeIds needed for locations OWA-7763
      'ExternalProfitPercentage', // 7823
    ]);

    return this.client.patch(endpoint, {
      headlineLineItemBreakdown: cleanedHeadlineLineIteamBreakdown,
    });
  };

  getPaymentTerms = async (bookingUuid: string, timestamp?: string): Promise<AxiosResponse<IPaymentTerms>> => {
    let endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/payment-terms`;
    if (timestamp !== undefined) {
      endpoint += `?versionTimestamp=${timestamp}`;
    }
    return this.client.get(endpoint);
  };

  updatePaymentTerms = async (bookingUuid: string, paymentTerms: string): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/payment-terms`;
    return this.client.patch(endpoint, {
      paymentTerms,
    });
  };

  getCancellationPolicy = async (
    bookingUuid: string,
    timestamp?: string
  ): Promise<AxiosResponse<ICancellationPolicies>> => {
    let endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/cancellation-policy`;
    if (timestamp !== undefined) {
      endpoint += `?versionTimestamp=${timestamp}`;
    }
    return this.client.get(endpoint);
  };

  updateCancellationPolicy = async (bookingUuid: string, cancellationPolicies: string): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/cancellation-policy`;
    return this.client.patch(endpoint, { cancellationPolicies });
  };

  getOfferTerms = async (bookingUuid: string, timestamp?: string): Promise<AxiosResponse<IPaymentTerms>> => {
    let endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/offer-terms`;
    if (timestamp !== undefined) {
      endpoint += `?versionTimestamp=${timestamp}`;
    }
    return this.client.get(endpoint);
  };

  getSpecialRequests = async (bookingUuid: string): Promise<AxiosResponse<ISpecialRequests>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/special-requests`;
    return this.client.get(endpoint);
  };

  updateSpecialRequests = async (bookingUuid: string, specialRequests: string): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/special-requests`;
    return this.client.patch(endpoint, { specialRequests });
  };

  getTopNavigationData = async (bookingUuid: string): Promise<AxiosResponse<ITopNavigationData>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/top-navigation-data`;
    return this.client.get(endpoint);
  };

  getBookingInformationReservationTeamData = async (
    bookingUuid: string
  ): Promise<AxiosResponse<ITopNavigationData>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/booking-information-reservation-team`;
    return this.client.get(endpoint);
  };

  getBookingStatusOptionsData = async (bookingUuid: string): Promise<AxiosResponse<IBookingStatusOptionsResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/booking-status-options`;
    return this.client.get(endpoint);
  };

  setBookingStatus = async (
    bookingUuid: string,
    status: EBookingStatus
  ): Promise<AxiosResponse<IBookingStatusResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/booking-status`;
    return this.client.patch(endpoint, { status });
  };

  bookingSearch = async (query): Promise<AxiosResponse<IBookingSearchResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/search`;
    return this.client.post(endpoint, query);
  };

  getBarCommission = async (bookingUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/bar-commission`;
    return this.client.get(endpoint);
  };

  getHeadlineBreakdownVersionList = async (bookingUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/headline-line-item-breakdown/versions`;
    return this.client.get(endpoint);
  };

  generateBreakdownPdfForTa = async (
    bookingUuid: string,
    includeMealPlanDetails: boolean = false
  ): Promise<AxiosResponse<IHeadlineLineItemBreakdownResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/breakdown-pdf/generate-ta`;

    return this.client.post(endpoint, {
      includeMealPlanDetails,
    });
  };

  generateBreakdownPdfForClient = async (
    bookingUuid: string,
    breakdownLogo: EPdfLogo,
    includeMealPlanDetails: boolean = false
  ): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/breakdown-pdf/generate-client`;

    return this.client.post(endpoint, {
      breakdownLogo,
      includeMealPlanDetails,
    });
  };

  generateProformaInvoice = async (
    bookingUuid: string,
    bankAccount: string,
    invoiceDueDates: IInvoiceDueDate[],
    invoiceAddresseeType: EInvoiceAddresseeType,
    lang: EInvoiceLang
  ): Promise<AxiosResponse<Upload>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/invoice`;

    return this.client.post(endpoint, {
      bankAccount,
      invoiceDueDates,
      invoiceAddresseeType,
      lang,
    });
  };

  generateManualProformaInvoice = async (
    bookingUuid: string,
    bankAccount: string,
    invoiceDueDate: IManualInvoiceDueDate,
    invoiceAddresseeType: EInvoiceAddresseeType,
    lang: EInvoiceLang,
    cancellationPolicyText: string,
    description?: string
  ): Promise<AxiosResponse<Upload>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/invoice/manual`;

    return this.client.post(endpoint, {
      bankAccount,
      invoiceDueDate,
      invoiceAddresseeType,
      lang,
      cancellationPolicies: cancellationPolicyText,
      description,
    });
  };

  generateFinalInvoice = async (
    bookingUuid: string,
    bankAccount: string,
    invoiceAddresseeType: EInvoiceAddresseeType,
    lang: EInvoiceLang
  ): Promise<AxiosResponse<Upload>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/invoice/final`;

    return this.client.post(endpoint, {
      bankAccount,
      invoiceAddresseeType,
      lang,
    });
  };

  generateCancellationInvoice = async (
    bookingUuid: string,
    bankAccount: string,
    invoiceDueDates: IInvoiceDueDate[],
    invoiceAddresseeType: EInvoiceAddresseeType,
    lang: EInvoiceLang
  ): Promise<AxiosResponse<Upload>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/invoice/final`;

    return this.client.post(endpoint, {
      bankAccount,
      cancellationDueDate: invoiceDueDates[0],
      invoiceAddresseeType,
      lang,
    });
  };

  getVoucherFormView = async (bookingUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/voucher-form`;

    return this.client.get(endpoint);
  };

  downloadVoucher = async (bookingUuid: string, payload: IVoucherDownloadPayload): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/voucher-pdf`;

    return this.client.post(endpoint, payload);
  };

  getFinanceSales = async (bookingUuid: string, timestamp?: string): Promise<AxiosResponse> => {
    let endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/finance-sales`;
    if (timestamp !== undefined) {
      endpoint += `?versionTimestamp=${timestamp}`;
    }
    return this.client.get(endpoint);
  };

  patchFinanceSales = async (bookingUuid: string, financeDocument: IFinanceDocument): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/finance-sales`;
    return this.client.patch(endpoint, {
      rows: financeDocument.rows,
    });
  };

  getFinanceSalesBalance = async (bookingUuid: string, timestamp?: string): Promise<AxiosResponse> => {
    let endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/finance-sales-balance`;
    if (timestamp !== undefined) {
      endpoint += `?versionTimestamp=${timestamp}`;
    }
    return this.client.get(endpoint);
  };

  getFinancePurchase = async (bookingUuid: string, timestamp?: string): Promise<AxiosResponse> => {
    let endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/finance-purchases`;
    if (timestamp !== undefined) {
      endpoint += `?versionTimestamp=${timestamp}`;
    }
    return this.client.get(endpoint);
  };

  patchFinancePurchase = async (bookingUuid: string, financeDocument: IFinanceDocument): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/finance-purchases`;
    return this.client.patch(endpoint, {
      rows: financeDocument.rows,
    });
  };

  getFinancePurchaseBalance = async (bookingUuid: string, timestamp?: string): Promise<AxiosResponse> => {
    let endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/finance-purchases-balance`;
    if (timestamp !== undefined) {
      endpoint += `?versionTimestamp=${timestamp}`;
    }
    return this.client.get(endpoint);
  };

  updatePoliciesAndTerms = async (
    bookingUuid: string,
    cancellationPolicies: string,
    paymentTerms: string,
    policiesAndRestrictions: string,
    offerTerms: string
  ): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/policies-and-terms`;
    return this.client.patch(endpoint, {
      cancellationPolicies,
      paymentTerms,
      policiesAndRestrictions,
      offerTerms,
    });
  };

  getPoliciesAndRestrictions = async (bookingUuid: string, timestamp?: string): Promise<AxiosResponse> => {
    let endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/policies-and-restrictions`;
    if (timestamp !== undefined) {
      endpoint += `?versionTimestamp=${timestamp}`;
    }
    return this.client.get(endpoint);
  };

  getAirlines = async (bookingUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/airline-list`;

    return this.client.get(endpoint);
  };

  getHotelDetails = async (bookingUuid: string): Promise<AxiosResponse<IGetHotelDetailsResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/hotel-details`;
    return this.client.get(endpoint);
  };

  patchHotelDetails = async (
    bookingUuid: string,
    hotelDetails: IBookingManagerHotelDetails[]
  ): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/hotel-details`;

    const hotelDetailsParsed = hotelDetails.map(hd => {
      return omit(hd, ['uploads']);
    });

    return this.client.patch(endpoint, {
      hotelDetails: hotelDetailsParsed,
    });
  };

  getOwnership = async (bookingUuid: string): Promise<AxiosResponse<IOwnershipResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/ownership`;
    return this.client.get(endpoint);
  };

  updateOwnership = async (
    bookingUuid: string,
    ownershipData: IOwnershipPatchBody
  ): Promise<AxiosResponse<IOwnershipResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/ownership`;
    return this.client.patch(endpoint, { ownershipData });
  };

  getOwnershipVersions = async (bookingUuid: string): Promise<AxiosResponse<IOwnershipTravelAgentMovements[]>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/ownership-versions`;
    return this.client.get(endpoint);
  };

  postBookingListExport = async (filter: BookingSearchFilter, exportType: ExportType): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/export`;

    return this.client.post(endpoint, {
      filter: { ...filter },
      exportType,
    });
  };

  postBookingListSrExport = async (filter: BookingSearchFilter): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/export/sr`;

    return this.client.post(endpoint, {
      filter: { ...filter },
    });
  };

  getBookingListExport = async (exportUuid: string): Promise<AxiosResponse<IGetExportResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/export/${exportUuid}`;
    return this.client.get(endpoint);
  };

  postDeletionCheck = async (bookingUuid: string, fileUuids: string[]): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/uploads/deletion-check`;
    return this.client.post(endpoint, {
      uuidList: [...fileUuids],
    });
  };

  deleteUpload = async (bookingUuid: string, uuid: string): Promise<AxiosResponse> => {
    return this.client.delete(`${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/uploads/delete/${uuid}`);
  };

  getPurchaseCostReview = async (bookingUuid: string): Promise<AxiosResponse<IPurchaseCostReviewResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/purchase-cost-review-data`;
    return this.client.get(endpoint);
  };

  patchPurchaseCostReview = async (
    bookingUuid: string,
    payload: IPurchaseCostReviewPatchRequest
  ): Promise<AxiosResponse<IPurchaseCostReviewResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/purchase-cost-review`;
    return this.client.patch(endpoint, payload);
  };

  getTravelPartnerInvoiceAddressee = async (bookingUuid: string): Promise<AxiosResponse<IInvoiceAddressee>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/travel-partner-invoice-addressee`;
    return this.client.get(endpoint);
  };

  getFinalClientInvoiceAddressee = async (bookingUuid: string): Promise<AxiosResponse<IInvoiceAddressee>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/final-client-invoice-addressee`;
    return this.client.get(endpoint);
  };

  saveTravelPartnerInvoiceAddressee = async (
    bookingUuid: string,
    invoiceAddressee: IInvoiceAddressee
  ): Promise<AxiosResponse<IPaymentTerms>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/travel-partner-invoice-addressee`;
    return this.client.patch(endpoint, { invoiceAddressee });
  };

  saveFinalClientInvoiceAddressee = async (
    bookingUuid: string,
    invoiceAddressee: IInvoiceAddressee
  ): Promise<AxiosResponse<IInvoiceAddressee>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/final-client-invoice-addressee`;
    return this.client.patch(endpoint, { invoiceAddressee });
  };

  getBookingAssignableTravelAgentList = async (bookingUuid: string): Promise<AxiosResponse<unknown>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/new-travel-agent`;
    return this.client.get(endpoint);
  };

  postCancellationInvoice = async (
    bookingUuid: string,
    requestData: IBookingStatusCancellationInvoiceRequestData
  ): Promise<AxiosResponse<IBookingStatusResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/booking-status`;
    return this.client.patch(endpoint, { ...requestData });
  };

  bookingStatementTotals = async (companyUuid: string): Promise<AxiosResponse<ILedgerTotalsResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKING_LEDGER}/${companyUuid}/totals`;
    return this.client.get(endpoint);
  };

  depositStatementTotals = async (companyUuid: string): Promise<AxiosResponse<ILedgerTotalsResponse>> => {
    const endpoint = `${BookingManagerEndpoints.DEPOSIT_ACCOUNT}/${companyUuid}/totals`;
    return this.client.get(endpoint);
  };

  bookingLedgerByCurrency = async (
    companyUuid: string,
    currency: TCurrencyCode,
    requestData: IBookingLedgerRequestData
  ): Promise<AxiosResponse<IBookingLedgerResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKING_LEDGER}/${companyUuid}/${currency}/page`;
    return this.client.post(endpoint, { ...requestData });
  };

  tcBookingStatementTotals = async (companyUuid: string): Promise<AxiosResponse<ITCBookingStatementTotalsResponse>> => {
    const endpoint = `${BookingManagerEndpoints.TCBOOKINGSTATEMENT}/${companyUuid}/totals`;
    return this.client.get(endpoint);
  };

  // OWA-4222
  tcBookingStatementByCurrency = async (
    companyUuid: string,
    currency: TCurrencyCode,
    requestData: ITCBookingStatementPageRequest
  ): Promise<AxiosResponse<ITCBookingStatementPageResponse>> => {
    const endpoint = `${BookingManagerEndpoints.TCBOOKINGSTATEMENT}/${companyUuid}/${currency}/page`;
    return this.client.post(endpoint, { ...requestData });
  };

  // OWA-4391
  postTCBookingStatementExportCsv = async (
    companyUuid: string,
    currency: TCurrencyCode,
    filter: ITCBookingStatementPageRequest['filter']
  ): Promise<AxiosResponse<IPostExportResponse>> => {
    const endpoint = `${BookingManagerEndpoints.TCBOOKINGSTATEMENT}/${companyUuid}/${currency}/export`;
    return this.client.post(endpoint, filter);
  };

  // OWA-4391
  getTCBookingStatementExportCsv = async (exportUuid: string): Promise<AxiosResponse<IGetExportResponse>> => {
    const endpoint = `${BookingManagerEndpoints.TCBOOKINGSTATEMENT}/export/${exportUuid}`;
    return this.client.get(endpoint);
  };

  tcBookingStatementDownloadPdf = async (
    companyUuid: string,
    currency: TCurrencyCode,
    requestData: ITCBookingStatementPageRequest
  ): Promise<AxiosResponse<IStatementPdf>> => {
    const endpoint = `${BookingManagerEndpoints.TCBOOKINGSTATEMENT}/${companyUuid}/${currency}/generate-pdf`;
    return this.client.post(endpoint, { ...requestData });
  };

  downloadHistoryForTcBookingStatement = async (
    companyUuid: string,
    currency: TCurrencyCode
  ): Promise<AxiosResponse<IStatementPdf>> => {
    const endpoint = `${BookingManagerEndpoints.TCBOOKINGSTATEMENT}/${companyUuid}/${currency}/pdf-download-history`;
    return this.client.get(endpoint);
  };

  getDepositAccountData = async (
    travelCompanyUuid: string,
    currency: TCurrencyCode,
    requestData: IDepositAccountRequestData
  ): Promise<AxiosResponse<IDepositAccountResponse>> => {
    const endpoint = `${BookingManagerEndpoints.DEPOSIT_ACCOUNT}/${travelCompanyUuid}/${currency}/page`;
    return this.client.post(endpoint, { ...requestData });
  };

  postBookingLedgerExport = async (
    companyUuid: string,
    currency: TCurrencyCode,
    filter: IBookingLedgerRequestData['filter']
  ): Promise<AxiosResponse<IPostExportResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKING_LEDGER}/${companyUuid}/${currency}/export`;
    return this.client.post(endpoint, { ...filter });
  };

  postBookingLedgerAggregateExport = async (companyUuid: string): Promise<AxiosResponse<IPostExportResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKING_LEDGER}/${companyUuid}/export`;
    return this.client.post(endpoint);
  };

  getBookingLedgerExport = async (exportUuid: string): Promise<AxiosResponse<IGetExportResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKING_LEDGER}/export/${exportUuid}`;
    return this.client.get(endpoint);
  };

  postDepositAccountExport = async (
    companyUuid: string,
    currency: TCurrencyCode,
    requestData: IDepositAccountRequestData
  ): Promise<AxiosResponse<IPostExportResponse>> => {
    const endpoint = `${BookingManagerEndpoints.DEPOSIT_ACCOUNT}/${companyUuid}/${currency}/export`;
    return this.client.post(endpoint, { ...requestData });
  };

  postDepositAccountAggregateExport = async (companyUuid: string): Promise<AxiosResponse<IPostExportResponse>> => {
    const endpoint = `${BookingManagerEndpoints.DEPOSIT_ACCOUNT}/${companyUuid}/export`;
    return this.client.post(endpoint);
  };

  getDepositAccountExport = async (exportUuid: string): Promise<AxiosResponse<IGetExportResponse>> => {
    const endpoint = `${BookingManagerEndpoints.DEPOSIT_ACCOUNT}/export/${exportUuid}`;
    return this.client.get(endpoint);
  };

  createManualBookingLedgerRow = async (
    companyUuid: string,
    currency: TCurrencyCode,
    manualBookingLedgerRow: IManualBookingLedgerRowForSave
  ): Promise<AxiosResponse<IManualBookingLedgerRowResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKING_LEDGER}/${companyUuid}/${currency}/row`;
    return this.client.post(endpoint, {
      date: manualBookingLedgerRow.date,
      type: manualBookingLedgerRow.type,
      amountCents: manualBookingLedgerRow.amountCents,
      uploadUuid: manualBookingLedgerRow.uploadUuid,
      uploadUrl: manualBookingLedgerRow.uploadUrl,
      uploadName: manualBookingLedgerRow.uploadName,
    });
  };

  updateManualBookingLedgerRow = async (
    companyUuid: string,
    currency: TCurrencyCode,
    manualBookingLedgerRow: IManualBookingLedgerRowForSave
  ): Promise<AxiosResponse<IManualBookingLedgerRowResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKING_LEDGER}/${companyUuid}/${currency}/row`;
    return this.client.patch(endpoint, {
      uuid: manualBookingLedgerRow.uuid,
      date: manualBookingLedgerRow.date,
      type: manualBookingLedgerRow.type,
      amountCents: manualBookingLedgerRow.amountCents,
      uploadUuid: manualBookingLedgerRow.uploadUuid,
      uploadUrl: manualBookingLedgerRow.uploadUrl,
      uploadName: manualBookingLedgerRow.uploadName,
    });
  };

  generateBookingLedgerPdf = async (
    companyUuid: string,
    currency: TCurrencyCode,
    requestData: IBookingLedgerRequestData
  ): Promise<AxiosResponse<IStatementPdf>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKING_LEDGER}/${companyUuid}/${currency}/generate-pdf`;
    return this.client.post(endpoint, { ...requestData });
  };

  downloadHistoryForBookingLedger = async (
    companyUuid: string,
    currency: TCurrencyCode
  ): Promise<AxiosResponse<IStatementPdf>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKING_LEDGER}/${companyUuid}/${currency}/pdf-download-history`;
    return this.client.get(endpoint);
  };

  createDepositAccountRow = async (
    companyUuid: string,
    currency: TCurrencyCode,
    depositAccountRow: IDepositAccountRowForSave
  ): Promise<AxiosResponse<ISaveDepositAccountRowResponse>> => {
    const endpoint = `${BookingManagerEndpoints.DEPOSIT_ACCOUNT}/${companyUuid}/${currency}`;
    return this.client.post(endpoint, {
      date: depositAccountRow.date,
      type: depositAccountRow.type,
      amountCents: depositAccountRow.amountCents,
      notes: depositAccountRow.notes,
      uploadUuid: depositAccountRow.uploadUuid,
      uploadUrl: depositAccountRow.uploadUrl,
      uploadName: depositAccountRow.uploadName,
      bankAccount: depositAccountRow.bankAccount,
      paymentReceiverCompanyUuid: depositAccountRow.paymentReceiverCompanyUuid, // OWA-5134
    });
  };

  updateDepositAccountRow = async (
    companyUuid: string,
    currency: TCurrencyCode,
    depositAccountRow: IDepositAccountRowForSave
  ): Promise<AxiosResponse<ISaveDepositAccountRowResponse>> => {
    const endpoint = `${BookingManagerEndpoints.DEPOSIT_ACCOUNT}/${companyUuid}/${currency}`;
    return this.client.patch(endpoint, {
      uuid: depositAccountRow.uuid,
      type: depositAccountRow.type,
      date: depositAccountRow.date,
      amountCents: depositAccountRow.amountCents,
      notes: depositAccountRow.notes,
      uploadUuid: depositAccountRow.uploadUuid,
      uploadUrl: depositAccountRow.uploadUrl,
      uploadName: depositAccountRow.uploadName,
      bankAccount: depositAccountRow.bankAccount,
    });
  };

  deleteDepositAccountRow = async (
    companyUuid: string,
    currency: TCurrencyCode,
    depositAccountRowUuid: string
  ): Promise<AxiosResponse<ISaveDepositAccountRowResponse>> => {
    return this.client.delete(
      `${BookingManagerEndpoints.DEPOSIT_ACCOUNT}/${companyUuid}/${currency}/${depositAccountRowUuid}`
    );
  };

  generateDepositAccountPdf = async (
    companyUuid: string,
    currency: TCurrencyCode,
    requestData: IDepositAccountRequestData
  ): Promise<AxiosResponse<IStatementPdf>> => {
    const endpoint = `${BookingManagerEndpoints.DEPOSIT_ACCOUNT}/${companyUuid}/${currency}/generate-pdf`;
    return this.client.post(endpoint, { ...requestData });
  };

  downloadHistoryForDepositAccount = async (
    companyUuid: string,
    currency: TCurrencyCode
  ): Promise<AxiosResponse<IStatementPdf>> => {
    const endpoint = `${BookingManagerEndpoints.DEPOSIT_ACCOUNT}/${companyUuid}/${currency}/pdf-download-history`;
    return this.client.get(endpoint);
  };

  generateFinanceSummaryPdf = async (companyUuid: string): Promise<AxiosResponse<IStatementPdf>> => {
    const endpoint = `${BookingManagerEndpoints.FINANCE_SUMMARY}/${companyUuid}/generate-pdf`;
    return this.client.post(endpoint);
  };

  downloadHistoryForFinanceSummary = async (companyUuid: string): Promise<AxiosResponse<IStatementPdf>> => {
    const endpoint = `${BookingManagerEndpoints.FINANCE_SUMMARY}/${companyUuid}/pdf-download-history`;
    return this.client.get(endpoint);
  };

  globalStatementTableData = async (
    query: IGlobalStatementQuery
  ): Promise<AxiosResponse<IGlobalStatementTableDataResponse>> => {
    const endpoint = `${BookingManagerEndpoints.GLOBAL_STATEMENT}/top-page`;
    return this.client.post(endpoint, query);
  };

  globalStatementTotalsData = async (
    filter: IGlobalGrandTotalsFilter
  ): Promise<AxiosResponse<IGlobalStatementTableDataResponse>> => {
    const endpoint = `${BookingManagerEndpoints.GLOBAL_STATEMENT}/grand-totals`;
    if (Object.keys(filter).length >= 1) {
      return this.client.post(endpoint, { filter });
    }
    return this.client.post(endpoint);
  };

  postGlobalStatementExportCsv = async (
    filter: IGlobalStatementQueryFilter
  ): Promise<AxiosResponse<IGlobalStatementTableDataResponse>> => {
    const endpoint = `${BookingManagerEndpoints.GLOBAL_STATEMENT}/export`;
    return this.client.post(endpoint, filter);
  };

  getGlobalStatementExportCsv = async (exportUuid: string): Promise<AxiosResponse<IGetExportResponse>> => {
    const endpoint = `${BookingManagerEndpoints.GLOBAL_STATEMENT}/export/${exportUuid}`;
    return this.client.get(endpoint);
  };

  getExternalCancellationData = async (
    bookingUuid: string
  ): Promise<AxiosResponse<IGetExternalCancellationDataResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/external-cancellation-policy`;
    return this.client.get(endpoint);
  };

  postBookingCancelProcess = async (
    bookingUuid: string,
    cancelRequestData: ICancelBookingRequest
  ): Promise<AxiosResponse<ProcessResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/cancel/process`;
    return this.client.post(endpoint, cancelRequestData);
  };

  getBookingCancelProcess = async (
    bookingUuid: string,
    processUuid: string
  ): Promise<AxiosResponse<CancelBookingProcessResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/cancel/process/${processUuid}`;
    return this.client.get(endpoint);
  };

  postBookingCancel = async (
    bookingUuid: string,
    cancelRequestData: ICancelBookingRequest
  ): Promise<ICancelBookingResponse> => {
    const {
      data: { processUuid },
    } = await this.postBookingCancelProcess(bookingUuid, cancelRequestData);

    let res: AxiosResponse<CancelBookingProcessResponse>;

    do {
      res = await this.getBookingCancelProcess(bookingUuid, processUuid);
      await new Promise(resolve => setTimeout(resolve, 2000));
    } while (res.data.status === 202);

    if (res.data.status !== 200) {
      throw new Error(res.data.message);
    }
    return res.data.data as ICancelBookingResponse;
  };

  getBookingType = async (bookingUuid: string): Promise<AxiosResponse<IGetBookingTypeResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/booking-type`;
    return this.client.get(endpoint);
  };

  setBookingType = async (
    bookingUuid: string,
    bookingType: EBookingType
  ): Promise<AxiosResponse<ISetBookingTypeResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/booking-type`;
    return this.client.patch(endpoint, { bookingType });
  };

  getBespokeNegotiation = async (bookingUuid: string): Promise<AxiosResponse<IBespokeNegotiationResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/bespoke-negotiation`;
    return this.client.get(endpoint);
  };

  updateBespokeNegotiation = async (
    bookingUuid: string,
    payload: IBespokeNegotiationItem
  ): Promise<AxiosResponse<IBespokeNegotiationResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/bespoke-negotiation`;
    return this.client.patch(endpoint, payload);
  };

  getCompanyIncentives = async (travelCompanyUuid: string): Promise<AxiosResponse<ICompanyIncentiveResponse>> => {
    const endpoint = `${BookingManagerEndpoints.INCENTIVES}/${travelCompanyUuid}`;
    return this.client.get(endpoint);
  };

  upsertCompanyIncentives = async (
    travelCompanyUuid: string,
    incentive: ICompanyIncentiveUpsertBody
  ): Promise<AxiosResponse<void>> => {
    const endpoint = `${BookingManagerEndpoints.INCENTIVES}/${travelCompanyUuid}`;
    return this.client.post(endpoint, incentive);
  };

  deleteCompanyIncentives = async (travelCompanyUuid: string): Promise<AxiosResponse<void>> => {
    const endpoint = `${BookingManagerEndpoints.INCENTIVES}/${travelCompanyUuid}`;
    return this.client.delete(endpoint);
  };

  updateBookingArrivalDeparture = async (
    bookingUuid: string,
    arrival: string,
    departure: string
  ): Promise<AxiosResponse<void>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/booking-arrival-departure`;
    return this.client.patch(endpoint, {
      bookingArrivalDeparture: {
        arrival: new Date(arrival).toISOString().split('T')[0],
        departure: new Date(departure).toISOString().split('T')[0],
      },
    });
  };

  getProposalSelectionList = async ({
    pagination,
    sort,
    filter,
  }: {
    sort: IProposalSelectionListSort;
    filter?: IProposalSelectionListFilter;
    pagination?: IPagination;
  }): Promise<AxiosResponse<IProposalSelectionListResponse>> => {
    const endpoint = `${BookingManagerEndpoints.PROPOSAL_SELECTIONS}/list`;
    return this.client.post(endpoint, {
      sort,
      ...(filter ? { filter } : {}),
      ...(pagination ? { pagination } : {}),
    });
  };

  getProposalSelection = async (proposalSelectionUuid: string): Promise<AxiosResponse<IProposalSelection>> => {
    const endpoint = `${BookingManagerEndpoints.PROPOSAL_SELECTIONS}/${proposalSelectionUuid}`;
    return this.client.get(endpoint);
  };

  createProposal = async (payload: ProposalRequest): Promise<AxiosResponse<Proposal>> => {
    const endpoint = BookingManagerEndpoints.PROPOSAL;
    return this.client.post(endpoint, payload);
  };

  updateProposal = async (proposalUuid: string, payload: ProposalRequest): Promise<AxiosResponse<Proposal>> => {
    const endpoint = `${BookingManagerEndpoints.PROPOSAL}/${proposalUuid}`;
    return this.client.put(endpoint, payload);
  };

  getProposal = async (proposalUuid: string): Promise<AxiosResponse<Proposal>> => {
    const endpoint = `${BookingManagerEndpoints.PROPOSAL}/${proposalUuid}`;
    return this.client.get(endpoint);
  };

  getProposalDocDraft = async (
    proposalUuid: string,
    googleDocId?: string
  ): Promise<AxiosResponse<ProposalDocDraftResponse>> => {
    const endpoint = `${BookingManagerEndpoints.PROPOSAL}/${proposalUuid}/doc/draft`;
    return this.client.post(endpoint, { googleDocId });
  };

  commitProposalDoc = async (proposalUuid: string, googleDocId: string): Promise<AxiosResponse<Proposal>> => {
    const endpoint = `${BookingManagerEndpoints.PROPOSAL}/${proposalUuid}/doc/commit`;
    return this.client.post(endpoint, { googleDocId });
  };

  getFinanceSalesVersionList = async (bookingUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/finance-sales/versions`;
    return this.client.get(endpoint);
  };

  getFinancePurchasesVersionList = async (bookingUuid: string): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/finance-purchases/versions`;
    return this.client.get(endpoint);
  };

  getProposalHistory = async (proposalUuid: string): Promise<AxiosResponse<IProposalHistory[]>> => {
    const endpoint = `${BookingManagerEndpoints.PROPOSAL}/history/${proposalUuid}`;
    return this.client.get(endpoint);
  };

  getExternalIds = async (bookingUuid: string): Promise<AxiosResponse<IExternalIdsResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/external-ids`;
    return this.client.get(endpoint);
  };

  updateExternalIds = async (
    bookingUuid: string,
    externalIds: IExternalIds
  ): Promise<AxiosResponse<IExternalIdsResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/external-ids`;
    return this.client.patch(endpoint, { externalIds });
  };

  getCompanyMembership = async (bookingUuid: string): Promise<AxiosResponse<ICompanyMembershipMicrodocResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/membership`;
    return this.client.get(endpoint);
  };

  updateCompanyMembership = async (
    bookingUuid: string,
    membershipData: ICompanyMembershipMicrodocPatch
  ): Promise<AxiosResponse<ICompanyMembershipMicrodocResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/membership`;
    return this.client.patch(endpoint, { membershipData });
  };

  getBookingPaymentMethods = async (
    bookingUuid: string
  ): Promise<AxiosResponse<IPaymentMethodsGetInContextResponse>> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/payment-methods/get-in-context`;
    return this.client.post(endpoint, { paymentContextCode: EPaymentContextCode.INVOICE });
  };

  getPaymentMethods = async (): Promise<AxiosResponse<IPaymentMethodsGetInContextResponse>> => {
    const endpoint = `${BookingManagerEndpoints.PAYMENT_METHODS}/get-in-context`;
    return this.client.post(endpoint, { paymentContextCode: EPaymentContextCode.INVOICE });
  };

  postImportAncillarySuppliers = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/suppliers/import`;
    return this.client.post(endpoint);
  };

  getImportAncillarySuppliersStatus = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/suppliers/import/latest`;
    return this.client.get(endpoint);
  };

  postImportAncillaryActivities = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/activities/import`;
    return this.client.post(endpoint);
  };

  getImportAncillaryActivitiesStatus = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/activities/import/latest`;
    return this.client.get(endpoint);
  };

  getAncillaryActivitiesCountries = async (currencyCode: TCurrencyCode): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/activities/countries/search`;
    return this.client.get(endpoint, {
      params: {
        currencyCode,
      },
    });
  };

  // ancillary activities
  getAncillaryActivitiesRegions = async ({
    currencyCode,
    countryCode,
  }: {
    currencyCode: TCurrencyCode;
    countryCode: string;
  }): Promise<AxiosResponse<AncillaryRegionsSearchResponse[]>> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/activities/regions/search`;
    return this.client.get(endpoint, {
      params: {
        currencyCode,
        countryCode,
      },
    });
  };

  getAncillaryActivitiesFilterIds = async ({
    currencyCode,
    countryCode,
    startDate,
    endDate,
    regionId,
  }: {
    currencyCode: TCurrencyCode;
    countryCode: string;
    startDate: string;
    endDate: string;
    regionId: string;
  }): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/filter-values/search`;
    const body = {
      productType: 'activity',
      filterCode: 'TYPE',
      currencyCode,
      countryCodes: [countryCode],
      startDate,
      endDate,
    };
    if (regionId !== '-1') {
      body['regionId'] = parseInt(regionId);
    }
    return this.client.post(endpoint, body);
  };

  getAncillaryActivitiesActivityOrSupplier = async ({
    currencyCode,
    countryCode,
    startDate,
    endDate,
    regionId,
    filterValueIds,
    searchTerm,
    signal,
    cancelTokenSource,
  }: {
    currencyCode: TCurrencyCode;
    countryCode: string;
    startDate: string;
    endDate: string;
    regionId: string;
    filterValueIds: string[];
    searchTerm: string;
    signal?: AbortSignal; // for use with lookup searches when users are typing and hitting this endpoint
    cancelTokenSource: CancelTokenSource;
  }): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/suppliers-and-products/search`;
    const body = {
      productType: 'activity',
      filterCode: 'TYPE',
      currencyCode,
      countryCodes: [countryCode],
      startDate,
      endDate,
      search: searchTerm,
    };
    if (regionId !== '-1') {
      body['regionId'] = parseInt(regionId);
    }
    if (!filterValueIds.includes('-1')) {
      body['filterValueIds'] = filterValueIds.map(f => parseInt(f));
    }
    return this.client.post(endpoint, body, {
      cancelToken: cancelTokenSource.token,
    });
  };

  getAncillaryActivitiesSearchResults = async ({
    bookingUuid,
    startDate,
    endDate,
    numberOfAdults,
    regionId,
    baseProductId,
    supplierId,
    filterValueIds,
    agesOfAllChildren,
    countryCode,
  }: {
    bookingUuid: string;
    startDate: string;
    endDate: string;
    numberOfAdults: number;
    countryCode: TCountryCode;
    regionId?: number;
    baseProductId?: number;
    supplierId?: number;
    filterValueIds?: number[];
    agesOfAllChildren?: number[];
  }): Promise<AxiosResponse<AncillaryRatesProductsSearchResponse>> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY}/${bookingUuid}/rates/activity/search`;

    const params = {
      startDate,
      endDate,
      numberOfAdults,
      countryISOCode: countryCode,
      ...(supplierId ? { supplierId } : {}),
      ...(regionId && regionId !== -1 ? { regionId } : {}),
      ...(baseProductId ? { baseProductId } : {}),
      ...(agesOfAllChildren ? { agesOfAllChildren } : {}),
    };
    if (!isNil(filterValueIds) && !filterValueIds.includes(-1)) {
      params['filterValueIds'] = filterValueIds;
    }
    return this.client.post(endpoint, params);
  };

  // ancillary transfers
  postAncillaryLocationsSearch = async (
    productType: EAncillaryProductType,
    locationTypes: EAncillaryLocationType[] = [
      EAncillaryLocationType.AIRPORT,
      EAncillaryLocationType.HOTEL,
      EAncillaryLocationType.PLACE,
    ]
  ) => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/locations/search`;
    return this.client.post(endpoint, {
      locationTypes,
      productType,
    });
  };

  getAncillaryTransfersFilterIds = async ({
    currencyCode,
    countryCodes,
    startDate,
    endDate,
    regionId,
  }: {
    currencyCode: TCurrencyCode;
    countryCodes: TCountryCode[];
    startDate?: string;
    endDate?: string;
    regionId?: string;
  }): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/filter-values/search`;
    const body = {
      productType: EAncillaryProductType.TRANSFER,
      filterCode: EAncillaryFilterCode.TYPE,
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
      ...(regionId ? { regionId } : {}),
      currencyCode,
      countryCodes,
    };

    return this.client.post(endpoint, body);
  };

  getAncillaryTransfersTransferOrSupplier = async ({
    currencyCode,
    searchTerm,
    date,
    transferLocations,
  }: {
    currencyCode: TCurrencyCode;
    searchTerm: string;
    date: string;
    signal?: AbortSignal; // for use with lookup searches when users are typing and hitting this endpoint
    transferLocations: {
      from: LocationDirectionWithCountry;
      to: LocationDirectionWithCountry;
    };
  }): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/suppliers-and-products/search`;
    const body = {
      productType: 'transfer',
      filterCode: 'TYPE',
      currencyCode,
      search: searchTerm,
      startDate: date,
      endDate: date,
      countryCodes: [transferLocations?.from.countryCode, transferLocations?.to.countryCode],
    };
    return this.client.post(endpoint, body);
  };

  getAncillaryGroundServicesFilterIds = async ({
    currencyCode,
    countryCodes,
    startDate,
    endDate,
    regionId,
  }: {
    currencyCode: TCurrencyCode;
    countryCodes: TCountryCode[];
    startDate?: string;
    endDate?: string;
    regionId?: string;
  }): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/filter-values/search`;
    const body = {
      productType: EAncillaryProductType.GROUND_SERVICE,
      filterCode: EAncillaryFilterCode.TYPE,
      ...(startDate ? { startDate } : {}),
      ...(endDate ? { endDate } : {}),
      ...(regionId ? { regionId } : {}),
      currencyCode,
      countryCodes,
    };

    return this.client.post(endpoint, body);
  };

  getAncillaryGroundServiceOrSupplier = async ({
    currencyCode,
    searchTerm,
    date,
    countryCodes,
  }: {
    currencyCode: TCurrencyCode;
    searchTerm: string;
    date: string;
    countryCodes: TCountryCode[];
    signal?: AbortSignal; // for use with lookup searches when users are typing and hitting this endpoint
  }): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/suppliers-and-products/search`;
    const body = {
      productType: 'ground-service',
      filterCode: 'TYPE',
      currencyCode,
      search: searchTerm,
      startDate: date,
      endDate: date,
      countryCodes,
    };
    return this.client.post(endpoint, body);
  };

  getAncillaryTransfersSearchResults = async ({
    bookingUuid,
    dates,
    numberOfAdults,
    baseProductId,
    supplierId,
    filterValueIds,
    agesOfAllChildren,
    transferLocations,
    isReturn,
    countryISOCode,
  }: {
    bookingUuid: string;
    dates: string[];
    numberOfAdults: number;
    baseProductId?: number;
    supplierId?: number;
    filterValueIds?: number[];
    agesOfAllChildren?: number[];
    transferLocations: {
      from: LocationDirectionWithCountry;
      to: LocationDirectionWithCountry;
    };
    isReturn?: boolean;
    countryISOCode?: string;
  }): Promise<AxiosResponse<AncillaryRatesProductsSearchResponse>> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY}/${bookingUuid}/rates/transfer/search`;

    const body = {
      startDate: dates[0],
      endDate: dates.length === 1 ? dates[0] : dates[dates.length - 1],
      numberOfAdults,
      transferLocations: {
        from: {
          id: transferLocations?.from.id,
          type: transferLocations?.from.type,
        },
        to: {
          id: transferLocations?.to.id,
          type: transferLocations?.to.type,
        },
      },
      ...(supplierId ? { supplierId } : {}),
      ...(baseProductId ? { baseProductId } : {}),
      ...(agesOfAllChildren ? { agesOfAllChildren } : {}),
      ...(countryISOCode ? { countryISOCode } : {}),
    };
    if (!isNil(filterValueIds) && !filterValueIds.includes(-1)) {
      body['filterValueIds'] = filterValueIds;
    }
    return this.client.post(endpoint, body);
  };

  getAncillaryGroundServicesSearchResults = async ({
    bookingUuid,
    date,
    numberOfAdults,
    baseProductId,
    supplierId,
    filterValueIds,
    agesOfAllChildren,
    airportId,
  }: {
    bookingUuid: string;
    date: string;
    numberOfAdults: number;
    baseProductId?: number;
    supplierId?: number;
    filterValueIds?: number[];
    agesOfAllChildren?: number[];
    airportId?: number;
  }): Promise<AxiosResponse<AncillaryRatesProductsSearchResponse>> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY}/${bookingUuid}/rates/ground-service/search`;

    const body = {
      startDate: date,
      endDate: date,
      numberOfAdults,
      ...(airportId ? { airportId } : {}),
      ...(supplierId ? { supplierId } : {}),
      ...(baseProductId ? { baseProductId } : {}),
      ...(agesOfAllChildren ? { agesOfAllChildren } : {}),
    };
    if (!isNil(filterValueIds) && !filterValueIds.includes(-1)) {
      body['filterValueIds'] = filterValueIds;
    }
    return this.client.post(endpoint, body);
  };

  getAncillaryRatesProduct = async ({
    bookingUuid,
    dates,
    numberOfAdults,
    agesOfAllChildren,
    perUnitQuantityOverride,
    baseProductId,
    variantId,
    transferLocations,
    airportId,
  }: {
    bookingUuid: string;
    dates: string[];
    numberOfAdults: number;
    baseProductId: number;
    agesOfAllChildren?: number[];
    perUnitQuantityOverride?: number;
    variantId?: number;
    transferLocations?: {
      from: LocationDirectionWithCountry;
      to: LocationDirectionWithCountry;
    };
    airportId?: number;
  }): Promise<AxiosResponse<AncillaryProductWithRates>> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY}/${bookingUuid}/rates/product`;

    const params = {
      startDate: dates[0],
      endDate: dates.length === 1 ? dates[0] : dates[dates.length - 1],
      numberOfAdults,
      ...(baseProductId ? { baseProductId } : {}),
      ...(perUnitQuantityOverride ? { perUnitQuantityOverride } : {}),
      ...(variantId ? { variantId } : {}),
      ...(airportId ? { airportId } : {}),
      ...(agesOfAllChildren ? { agesOfAllChildren } : {}),
      ...(transferLocations
        ? {
            transferLocations: {
              from: {
                id: transferLocations?.from.id,
                type: transferLocations?.from.type,
              },
              to: {
                id: transferLocations?.to.id,
                type: transferLocations?.to.type,
              },
            },
          }
        : {}),
    };

    return this.client.post(endpoint, params);
  };

  postImportAncillaryActivitiesRates = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/activities-rates/import`;
    return this.client.post(endpoint);
  };

  getImportAncillaryActivitiesRatesStatus = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/activities-rates/import/latest`;
    return this.client.get(endpoint);
  };

  // transfers imports
  postImportAncillaryTransfers = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/transfers/import`;
    return this.client.post(endpoint);
  };

  getImportAncillaryTransfersStatus = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/transfers/import/latest`;
    return this.client.get(endpoint);
  };

  postImportAncillaryTransfersRates = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/transfers-rates/import`;
    return this.client.post(endpoint);
  };

  getImportAncillaryTransfersRatesStatus = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/transfers-rates/import/latest`;
    return this.client.get(endpoint);
  };

  // ground services imports
  postImportAncillaryGroundServices = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/ground-services/import`;
    return this.client.post(endpoint);
  };

  getImportAncillaryGroundServicesStatus = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/ground-services/import/latest`;
    return this.client.get(endpoint);
  };

  postImportAncillaryGroundServicesRates = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/ground-services-rates/import`;
    return this.client.post(endpoint);
  };

  getImportAncillaryGroundServicesRatesStatus = async (): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/ground-services-rates/import/latest`;
    return this.client.get(endpoint);
  };

  ancillarySuppliersSimpleSearch = async (
    productType: EAncillaryProductType,
    currencyCode: TCurrencyCode,
    search?: string
  ): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/suppliers/search`;
    return this.client.post(endpoint, {
      productType,
      currencyCode,
    });
  };

  getBookingCountryCode = async (bookingUuid: string, cancelToken?: CancelToken): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/view/booking-country-code`;
    return this.client.get(endpoint, {
      cancelToken,
    });
  };

  // ancillary airports with IATA codes
  getAncillaryAirportsWithIATA = async (
    search: string,
    cancelTokenSource: CancelTokenSource
  ): Promise<AxiosResponse> => {
    const endpoint = `${BookingManagerEndpoints.ANCILLARY_SERVICE_BRIDGE}/airports`;
    return this.client.post(
      endpoint,
      {
        search,
      },
      {
        cancelToken: cancelTokenSource.token,
      }
    );
  };

  postSimulateBreakdown = async (
    bookingUuid: string,
    data: ISimulateBreakdownRequest
  ): Promise<AxiosResponse<ISimulateBreakdownResponse>> => {
    const cleanedHeadlineLineIteamBreakdown = omitDeep(data.headlineLineItemBreakdown, [
      'PreDiscountSubtotalCents',
      'PurchaseCostSubtotalCents',
      'ProfitPercentage',
      'ProfitCalculatedValueCents',
      'SubtotalCents',
      'preDiscountSubtotalCents',
      'subtotalCents',
      'purchaseCostSubtotalCents',
      'isOnRequest',
      'TotalCostToClientCents',
      'MarginCalculatedValueCents',
      'expires', // for the breakdown holds
      'saleCostCentsUnreactive', // @see https://pureescapes.atlassian.net/browse/OWA-2252
      'CompanyExternalMarkupAmountSubtotalCents',
      'InternalMarkupAmountSubtotalCents',
      'companyExternalMarkupAmountSubtotalCents',
      'internalMarkupAmountSubtotalCents',
      'internalMarkupAmountCents',
      'companyExternalMarkupAmountCents',
      'IsInstantBook',
      'compositeId', // for the compositeIds needed for locations OWA-7763
      'ExternalProfitPercentage', // 7823
    ]);

    return this.client.post(`${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/simulate-breakdown`, {
      ...data,
      headlineLineItemBreakdown: cleanedHeadlineLineIteamBreakdown,
    });
  };

  postBookLiveRateWithBreakdown = async (
    bookingUuid: string,
    data: {
      userCountryCode: IBookingCountryResponse;
      headlineLineItemBreakdown: IHeadlineLineItemBreakdown;
      cancellationPolicies: string | null;
      paymentTerms: string | null;
      policiesAndRestrictions: string | null;
      offerTerms: string | null;
      accommodationIdx: number;
      expectedPriceCents: number;
    }
  ): Promise<AxiosResponse<{ processUuid: string }>> => {
    return this.client.post(`${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/live-rate/book`, {
      ...data,
      headlineLineItemBreakdown: cleanHeadlineLineItemBreakdown(data.headlineLineItemBreakdown),
    });
  };

  getBookLiveRateWithBreakdownStatus = async (
    bookingUuid: string,
    processUuid: string
  ): Promise<AxiosResponse<{ data: any; message: string; status: number }>> => {
    return this.client.get(`${BookingManagerEndpoints.BOOKINGS}/${bookingUuid}/live-rate/book/status/${processUuid}`);
  };
}

export const makeBookingManagerApi = (): BookingManagerApiService<AxiosWrapper<AxiosInstance>> => {
  const dynamicParameters = ParameterService.getParameters();
  const headers: any = {};

  const client = axios.create({
    baseURL: dynamicParameters.BOOKING_MANAGER_BASE_URL,
    headers: injectJwtTokenIntoHeaders(headers),
    withCredentials: true,
    paramsSerializer: stringify,
  });

  const clientWrapper = new AxiosWrapper(client);

  return new BookingManagerApiService<AxiosWrapper<AxiosInstance>>(clientWrapper);
};
