import { LIVE_RATES_PRICE_CHECK_TOLERANCE_CENTS } from 'config';
import { useModal } from 'hooks/useModal';
import { pick } from 'lodash-es';
import { IPriceCheckReq, makeBackendApi } from 'services/BackendApi';
import {
  IFinanceRow,
  IHeadlineLineItemBreakdownAccommodationLineItem,
  IInvoiceDueDate,
  IPaymentMethodsGetInContextResponse,
  makeBookingManagerApi,
} from 'services/BookingManagerApi';
import { useDispatch, useSelector } from 'react-redux';
import { _isDateInPast, _subDays, getCurrencyBySymbol, tDateString } from 'utils';
import { tryCatch } from 'utils/tryCatch';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { automaticProformaInvoiceToTaSelector } from 'store/modules/bookingManager/subdomains/finance/selectors';
import { useNotifications } from 'hooks/useNotifications';
import { saveAs } from 'file-saver';
import axios from 'axios';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { TCurrencyCode } from 'interfaces';

interface iUseBookLiveAccommodationProps {
  accom: IHeadlineLineItemBreakdownAccommodationLineItem;
  bookingUuid: string;
  travelAgentUuid?: string;
  currencySymbol?: string;
  accommodationIndex: number;
  headlineLineItemBreakdown: ReturnType<typeof BreakdownSelectors.headlineLineItemBreakdownSelector>;
  paymentTerms: ReturnType<typeof BreakdownSelectors.paymentTermsSelector>;
  cancellationPolicies: ReturnType<typeof BreakdownSelectors.cancellationPoliciesSelector>;
  policiesAndRestrictions: ReturnType<typeof BreakdownSelectors.policiesAndRestrictionsSelector>;
  offerTerms: ReturnType<typeof BreakdownSelectors.offerTermsSelector>;
}

export interface iDataForPriceCheckModal {
  liveRate: {
    externalMealPlanCode: string;
    externalRateId: string;
  };
  guestAges: {
    numberOfAdults: number;
    agesOfAllChildren: number[];
  };
  prevPrice: number;
  newPrice: number;
  title: string;
  prevMealPlanCode: string;
  prevMealPlanName: string;
  newMealPlanCode: string;
  newMealPlanName: string;
  arrival: tDateString | undefined;
  departure: tDateString | undefined;
}

/**
 * A custom hook that orchestrates booking a live accommodation
 */
export const useBookLiveAccommodation = (props: iUseBookLiveAccommodationProps) => {
  const notificationsOps = useNotifications();
  if (!props.accom) {
    notificationsOps.showErrorNotification(
      'No accommodation object passed to useBookLiveAccommodation - this should not happen! Please refresh the page and try again.'
    );
  }
  const backendApi = makeBackendApi(props.travelAgentUuid);
  const bookingManagerApi = makeBookingManagerApi();

  // this is a confusing bit of logic;
  // - After running everything, we want to reload the page and so we need to
  // mark the breakdown as NOT edited (so we don't get the warning modal)
  // - However, if they cancel or error out halfway through the process, we want to reset
  // whether or not the breakdown is edited, so we can show the warning modal again
  const initialIsBreakdownEdited = useSelector(BreakdownSelectors.isHeadlineBreakdownEditedWithoutSavingSelector);

  const dispatch = useDispatch();

  const modalOps = useModal<{
    type:
      | 'booking-pre-confirm'
      | 'price-check-no-diff'
      | 'price-check-diff'
      | 'price-check-not-available'
      | 'booking-in-progress'
      | 'successful-no-proforma-invoice'
      | 'successful-with-proforma-invoice';
    dataForPriceCheckModal?: iDataForPriceCheckModal;
  }>({
    onReset: () => {
      dispatch(BreakdownActions.setIsBreakdownEditedWithoutSaving(initialIsBreakdownEdited));
    },
  });

  const currency = getCurrencyBySymbol(props.currencySymbol!);

  const [pollId, setPollId] = useState<string | null>(null);
  const [pollingStatus, setPollingStatus] = useState<'success' | 'pending' | 'error' | null>(null);

  const proformaInvoice: IFinanceRow | undefined = useSelector(automaticProformaInvoiceToTaSelector);
  const isBreakdownSalesCostModified = useSelector(BreakdownSelectors.isBreakdownSalesCostModifiedSelector);
  const createInvoice = useSelector(BreakdownSelectors.breakdownCreateInvoiceSelector);
  const [priceCheckIsModified, setPriceCheckIsModified] = useState(false);

  // polling query
  useQuery({
    queryKey: ['pollStatus', 'bookLiveAccommodation', props.accommodationIndex],
    queryFn: async () => {
      return bookingManagerApi.getBookLiveRateWithBreakdownStatus(props.bookingUuid, pollId!);
    },
    enabled: pollId !== null,
    refetchOnMount: false,
    refetchInterval: data => {
      if (pollId === null) {
        return false;
      }

      if (data.state.data?.data.status === 200) {
        setPollingStatus('success');
        return false;
      }

      // still pending
      if (data.state.data?.data.status === 202) {
        setPollingStatus('pending');
        return 2000;
      }

      if (data.state.data?.data.status === 422) {
        setPollingStatus('error');
        return false;
      }

      return false;
    },
  });

  // when the polling is done, we need to call the onSuccessfulBooking function
  useEffect(() => {
    if (pollingStatus === 'error') {
      modalOps.reset();
      notificationsOps.showErrorNotification('Failed to confirm booking - please refresh the page and try again');
      return;
    }
    if (pollingStatus !== 'success') {
      return;
    }
    onSuccessfulBooking();
  }, [pollingStatus]);

  const onBookNow = async () => {
    if (!props.accom.saleCostCents) {
      modalOps.reset();
      notificationsOps.showErrorNotification(
        'This accommondation does not have a cost, and therefore cannot be booked'
      );
      return;
    }
    dispatch(BreakdownActions.setIsBreakdownEditedWithoutSaving(false));

    if (!props.headlineLineItemBreakdown) {
      // this should be impossible, but, ya know
      modalOps.reset();
      notificationsOps.showErrorNotification('No breakdown object - cannot book');
      return;
    }

    // show the pre confirm modal, and get a cancelToken so we can cancel the countrycode and pricecheck
    // if the user wants
    const cancelToken = modalOps.showModal({
      type: 'booking-pre-confirm',
    });

    // get the country code - this is needed for the price check
    const { data: getCountryCodeResponse, error: getCountryCodeError } = await tryCatch(
      bookingManagerApi.getBookingCountryCode(props.bookingUuid, cancelToken)
    );
    if (getCountryCodeError) {
      modalOps.reset();
      if (axios.isCancel(getCountryCodeError)) {
        notificationsOps.showWarningNotification('Accommodation confirmation cancelled');
      } else {
        notificationsOps.showErrorNotification(
          'Failed to retrieve prerequisite information to book - please refresh the page and try again'
        );
      }
      return;
    }

    // do the price check
    const hotelUuid = props.accom.requestMeta?.requestSegment.data.attributes.hotelUuid;
    const { data: priceCheckRes, error: priceCheckError } = await tryCatch<any, Error>(
      backendApi.liveRatesPriceCheck(
        hotelUuid,
        ({
          Accommodation: [
            {
              ...pick(props.accom.requestMeta?.requestSegment.data.attributes.Accommodation[0], [
                'uuid',
                'startDate',
                'endDate',
                'guestAges',
                'liveRate.externalMealPlanCode',
                'liveRate.externalRateId',
              ]),
            },
          ],
        } as unknown) as IPriceCheckReq,
        getCountryCodeResponse.data.countryCode,
        null,
        cancelToken
      )
    );
    if (priceCheckError) {
      modalOps.reset();
      if (axios.isCancel(priceCheckError)) {
        notificationsOps.showWarningNotification('Accommodation confirmation cancelled');
      } else {
        notificationsOps.showErrorNotification('Failed to perform price check - please refresh the page and try again');
      }
      return;
    }

    const priceCheckAccom = priceCheckRes.data.data.Accommodation[0];
    const priceCheckData = priceCheckAccom.priceCheck as {
      currency: TCurrencyCode;
      externalMealPlanCode: string;
      externalMealPlanDescription: string;
      totalCents: number;
    };

    // if we don't have price check data, thats a specific scenario
    if (!priceCheckData) {
      modalOps.reset();
      await modalOps.requestConfirmation({ type: 'price-check-not-available' });
      return;
    }

    // otherwise, we compare the old and the new and create a dataset to render

    const originalLiveRatePrice = props.accom.saleCostCents!;
    const originalMealPlanCode =
      props.accom.requestMeta?.requestSegment.data.attributes.Accommodation[0].liveRate.externalMealPlanCode;

    const isPriceCheckDifference =
      Math.abs(originalLiveRatePrice - priceCheckData.totalCents) > LIVE_RATES_PRICE_CHECK_TOLERANCE_CENTS ||
      originalMealPlanCode !== priceCheckData.externalMealPlanCode;

    setPriceCheckIsModified(isPriceCheckDifference);

    const dataForPriceCheckModal: iDataForPriceCheckModal = {
      liveRate: priceCheckAccom.liveRate,
      guestAges: priceCheckAccom.guestAges,
      prevPrice: originalLiveRatePrice,
      newPrice: priceCheckAccom.priceCheck.totalCents ?? null,
      title: props.accom.title,
      prevMealPlanCode: props.accom.requestMeta!.requestSegment.data.attributes.Accommodation[0].liveRate
        .externalMealPlanCode,
      prevMealPlanName: props.accom['Meal Plan'].items[0].title,
      newMealPlanCode: priceCheckAccom.priceCheck.externalMealPlanCode,
      newMealPlanName: priceCheckAccom.priceCheck.externalMealPlanDescription,
      arrival: props.accom.arrival as tDateString,
      departure: props.accom.departure as tDateString,
    };

    // either they're confirming they're OK with the new price, or there is
    // no difference in price, in which case they still need to confirm it
    const userOkWithNewPrice = isPriceCheckDifference
      ? await (modalOps.requestConfirmation({ type: 'price-check-diff', dataForPriceCheckModal }) as Promise<boolean>)
      : await (modalOps.requestConfirmation({ type: 'price-check-no-diff', dataForPriceCheckModal }) as Promise<
          boolean
        >);

    // they're NOT ok with the new price; just exit out
    if (!userOkWithNewPrice) {
      modalOps.reset();
      notificationsOps.showWarningNotification('Accommodation booking cancelled');
      return;
    }

    // otherwise, show the booking in progress modal...
    modalOps.showModal({
      type: 'booking-in-progress',
    });

    // ...and book it. no cancel token here: if they've come this far, they cant cancel out
    const priceToBookAt = priceCheckData?.totalCents ? priceCheckData.totalCents : originalLiveRatePrice;
    const {
      data: bookLiveRateWithBreakdownResponseData,
      error: bookLiveRateWithBreakdownResponseError,
    } = await tryCatch(
      bookingManagerApi.postBookLiveRateWithBreakdown(props.bookingUuid, {
        userCountryCode: {
          countryCode: getCountryCodeResponse.data.countryCode,
          countryCodeSource: getCountryCodeResponse.data.countryCodeSource,
        },
        accommodationIdx: props.accommodationIndex,
        expectedPriceCents: priceToBookAt,
        headlineLineItemBreakdown: props.headlineLineItemBreakdown,
        cancellationPolicies: props.cancellationPolicies,
        offerTerms: props.offerTerms,
        paymentTerms: props.paymentTerms,
        policiesAndRestrictions: props.policiesAndRestrictions,
      })
    );
    if (bookLiveRateWithBreakdownResponseError) {
      modalOps.reset();
      notificationsOps.showErrorNotification('Failed to confirm booking - please refresh the page and try again');
      return;
    }
    setPollId(bookLiveRateWithBreakdownResponseData.data.processUuid);
  };

  /**
   * This function is called after we've POST'd to webapp-backend and we've been polling
   * and everything has returned 200 OK.
   */
  const onSuccessfulBooking = async () => {
    const shouldGenerateNewProforma = proformaInvoice && (isBreakdownSalesCostModified || priceCheckIsModified);

    // if we don't need to generate a new proforma invoice, just reload the page
    if (!shouldGenerateNewProforma) {
      await modalOps.requestConfirmation({
        type: 'successful-no-proforma-invoice',
      });
      location.reload();
      return;
    }

    notificationsOps.showSuccessNotification(
      'Booking has existing proforma invoice, and prices have changed - generating new proforma invoice...'
    );

    const { data: initialResponses, error: initialResponsesError } = await tryCatch(
      Promise.all([
        bookingManagerApi.getExternalCancellationData(props.bookingUuid),
        bookingManagerApi.getBookingPaymentMethods(props.bookingUuid),
      ])
    );
    if (initialResponsesError) {
      modalOps.reset();
      notificationsOps.showErrorNotification('Failed to generate invoice, but your booking has been confirmed.');
      return;
    }
    const [externalCancellationDataResponse, getBookingPaymentMethodsResponse] = initialResponses;

    const defaultPaymentMethod = (getBookingPaymentMethodsResponse.data
      .defaultPaymentMethodPerCurrency as IPaymentMethodsGetInContextResponse['defaultPaymentMethodPerCurrency']).find(
      x => x.currency === currency.code
    );

    if (!defaultPaymentMethod) {
      throw new Error('No default payment method found');
    }

    const defaultPaymentMethodName = (getBookingPaymentMethodsResponse.data
      .paymentMethods as IPaymentMethodsGetInContextResponse['paymentMethods']).find(
      x => x.code === defaultPaymentMethod.paymentMethodCode
    )?.name;

    if (!defaultPaymentMethodName) {
      throw new Error('No default payment method found');
    }

    const deadline = externalCancellationDataResponse.data[0].deadlines[0].deadline.split(' ')[0] as tDateString;

    const isDeadlineInPast = _isDateInPast(deadline);

    const dueDate = isDeadlineInPast ? deadline : _subDays(deadline, 2);

    const dueDates: IInvoiceDueDate[] = [
      {
        date: dueDate,
        percentage: 100,
        amountCents: 0,
        isConfirmed: true,
      },
    ];

    const { data: generateInvoiceResponse, error: generateInvoiceResponseError } = await tryCatch(
      bookingManagerApi.generateProformaInvoice(
        props.bookingUuid,
        defaultPaymentMethodName,
        dueDates,
        createInvoice.invoiceAddresseeType,
        createInvoice.lang
      )
    );
    if (generateInvoiceResponseError) {
      modalOps.reset();
      notificationsOps.showErrorNotification('Failed to generate invoice, but your booking has been confirmed.');
      return;
    }

    await modalOps.requestConfirmation({
      type: 'successful-with-proforma-invoice',
    });

    saveAs(generateInvoiceResponse.data.url, generateInvoiceResponse.data.filename);
    location.reload();
  };

  return {
    onBookNow,
    modalOps,
  };
};
