import React, { useCallback, useEffect } from "react";
import CircleCloseIcon from 'ui/Icons/circleClose.component.svg';
import MagnifyingIcon from 'ui/Icons/magnifying-glass.component.svg';
import Loading from 'ui/AsyncSearchDropdown/Loading';
import TextInput from 'pureUi/TextInput';
import classNames from "classnames";
import { IUseCrmSearchDirectoryEntriesParams, useCrmSearchDirectoryEntries } from "services/CrmApi/queries/useCrmSearchDirectoryEntries";
import { ECrmDirectoryEntryType } from "services/CrmApi/types/DirectoryEntry";
import { useDebounce } from "hooks/useDebounce";

interface IProps {
  value: string;
  onChange: (value: string | null) => void;
  directoryEntryType: ECrmDirectoryEntryType;
  excludeIds?: string[];
}

export const ExistingDirectoryEntriesSearch: React.FC<IProps> = ({ value, onChange, directoryEntryType, excludeIds = [] }) => {
  const [searchTerm, setSearchTerm, debouncedSearchTerm] = useDebounce(value, 500);
  
  const circleCloseIconClassname = classNames('block cursor-pointer fill-brown-80 hover:fill-brown-120 absolute w-[18px] top-[10px] right-[10px]', { "hidden": !value });
  const searchOptionClassname = classNames("p-[5px] my-[5px] flex flex-col justify-center font-pt-sans rounded-[3px] hover:bg-gray-10 cursor-pointer");
  
  const params: IUseCrmSearchDirectoryEntriesParams = {
    filter: {
      term: debouncedSearchTerm ?? '',
      directoryEntryType: directoryEntryType,
    },
    pagination: {
      limit: 100,
      offset: 0,
    },
  }
  let { crmDirectoryEntries, directoryEntries } = useCrmSearchDirectoryEntries(params);
  directoryEntries = directoryEntries.filter( directoryEntry => !excludeIds.some(id => id === directoryEntry.id) );
  const selectedDirectoryEntry = directoryEntries.find(item => item.id === value) ?? null;

  // Reset the searchTerm when Formik resets the value
  useEffect(() => {
    if (value === '') {
      setSearchTerm('');
    }
  }, [value]);

  const handleRemoveSearch = useCallback(() => {
    setSearchTerm('');
    onChange(null);
  }, [onChange]);

  const handleTextChange: React.FormEventHandler<HTMLInputElement> = useCallback((e) => {
    setSearchTerm(e.currentTarget.value);
    onChange(null);
  }, [onChange, setSearchTerm]);

  return (
    <div className="w-full p-[10px] bg-white-hard flex flex-col">
      <TextInput
        onChange={handleTextChange}
        placeholder="Search from directory ..."
        className="flex flex-col relative"
        value={searchTerm}
        inputClassName="!py-[10px] !px-[30px] !text-black !bg-white-hard"
      >
        <CircleCloseIcon onClick={handleRemoveSearch} className={circleCloseIconClassname} />
        <MagnifyingIcon className='absolute w-[18px] top-[10px] left-[10px] fill-light-gray rotate-90' />
      </TextInput>
      {
        crmDirectoryEntries.isFetching && searchTerm?.length >= 3 ?
          (
            <div className="py-2">
              <Loading />
            </div>
          ) : (
            directoryEntries.length && searchTerm?.length >= 3 && !value ? (
              <div className="flex flex-col flex-1 max-h-[295px] overflow-auto">
                <div className='search-options'>
                  {directoryEntries.slice(0, 10).map((option, index, arr) => {
                    const key = `search-option-${option.id}`
                    const label = option.name;
                    const handleSelectItem = () => {
                      setSearchTerm(label);
                      onChange(option.id);
                    };
                    return (
                      <>
                        <div key={key} className={searchOptionClassname} onClick={handleSelectItem}>
                          <span className='h-[19px]'>{label}</span>
                        </div>
                        {index + 1 < arr.length && <div className="h-[0px] border border-gray-10" />}
                      </>
                    )
                  })}
                </div>
              </div>
            ) : (
              !selectedDirectoryEntry && searchTerm?.length > 3 && crmDirectoryEntries.isFetched  && <div className="text-black text-[15px] font-normal mt-[10px] italic font-pt-sans">No results found.</div>
            )
          )
      }
    </div>
  );
};