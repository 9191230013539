import React, { useCallback } from 'react';
import classnames from 'classnames';
import { BookingBuilderResponse, ENetworkRequestStatus } from 'services/BackendApi';
import * as BreakdownSelectors from 'store/modules/bookingManager/subdomains/breakdown/selectors';
import * as DashboardSelectors from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import * as BreakdownActions from 'store/modules/bookingManager/subdomains/breakdown/actions';
import { AddAccommodationModalFilters } from '../AddAccommodationModalFilters';
import { useDispatch, useSelector } from 'react-redux';
import { RHPSubtitle } from '../RightHandPanel/RHPSubtitle';
import { AddAccommodationModalTable } from '../AddAccommodationModalTable';
import { convertMealPlanToStaticRate } from '../helpers';

export interface ILHPPageAccommodationsProps {
  currencySymbol: string;
  hotelUuid: string;
  bookingUuid: string;
  isVisible: boolean;
}

export const LHPPageAccommodations: React.FC<ILHPPageAccommodationsProps> = ({
  hotelUuid,
  bookingUuid,
  currencySymbol,
  isVisible,
}) => {
  const dispatch = useDispatch();
  const searchAccommodationsRequest = useSelector(BreakdownSelectors.BAMSearchAccommodationsRequestSelector);
  const accommodations = useSelector(BreakdownSelectors.BAMAccommodationsSelector);
  const searchResultsCount =
    searchAccommodationsRequest === ENetworkRequestStatus.PENDING
      ? '(Searching...)'
      : `(${accommodations.length || 'There are no products for the searched criteria'})`;
  const error = useSelector(BreakdownSelectors.BAMErrorSelector);
  const topNavigationData = useSelector(DashboardSelectors.topNavigationDataDashboardSelector);
  const filterHotelsRequest = useSelector(BreakdownSelectors.BAMFilterHotelsRequestSelector);

  const handleChooseAccommodation = useCallback(
    (build: BookingBuilderResponse, index: number) => {
      dispatch(BreakdownActions.setBAMSelectedAccommodationAction(build, index));
      const selectedAccommodation = build.availableProductSets.Accommodation[0];
      const selectedMealPlan = selectedAccommodation.availableSubProductSets['Meal Plan'].find(item => item.selected);
      dispatch(BreakdownActions.bamBookingBuildRequestAction(bookingUuid));
      dispatch(BreakdownActions.setBAMSelectedMealPlanAction(convertMealPlanToStaticRate(selectedMealPlan)));
      dispatch(BreakdownActions.setBAMSelectedRepeatGuestAction(false));
      dispatch(BreakdownActions.setBAMSelectedOccasionsAction([]));
    },
    [bookingUuid, dispatch]
  );

  return (
    <div className={classnames({ hidden: !isVisible })}>
      <AddAccommodationModalFilters
        hotelUuid={hotelUuid}
        bookingCurrencySymbol={currencySymbol}
        bookingUuid={bookingUuid}
        startAndEndDate={{
          startDate: topNavigationData?.arrivalDate ?? '',
          endDate: topNavigationData?.departureDate ?? '',
        }}
        error={error}
      />

      {filterHotelsRequest !== ENetworkRequestStatus.PENDING && (
        <div
          className={classnames('font-pt-sans flex gap-[5px] mt-[25px] mb-[10px]', {
            'opacity-50': searchAccommodationsRequest === ENetworkRequestStatus.PENDING,
          })}
        >
          <RHPSubtitle>Search Results</RHPSubtitle>
          <span className="font-normal">{searchResultsCount}</span>
        </div>
      )}

      {filterHotelsRequest !== ENetworkRequestStatus.PENDING && (
        <AddAccommodationModalTable
          onChooseAccommodation={handleChooseAccommodation}
          bookingCurrencySymbol={currencySymbol}
        />
      )}
    </div>
  );
};
