import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import { IBookingManagerHotelDetailsSubdomain } from './types';

export const initialState: IBookingManagerHotelDetailsSubdomain = {
  hotelDetails: [
    {
      uuid: '',
      name: '',
      phoneNumber: null,
      address: null,
      countryCode: null,
      region: null,
      policiesAndRestrictions: [],
      resortConfirmationNumber: null,
      defaultCurrency: null,
    },
  ],
  networkRequests: {
    hotelDetailsLoad: ENetworkRequestStatus.IDLE,
    hotelDetailsPatchLoad: ENetworkRequestStatus.IDLE,
  },
};
