import React, { useCallback } from 'react'
import { Field } from 'formik';
import classnames from 'classnames';
import AnimateHeight from 'react-animate-height';
import { theme } from '../../../../../tailwind.config';
import { ArrowDownComponent } from 'ui/Icons';
import { useResponsive } from 'containers/CRM/hooks/useResponsive';
import { ExistingDirectoryEntriesSearch } from '../ExistingDirectoryEntriesSearch/ExistingDirectoryEntriesSearch';
import { ECrmDirectoryEntryType } from 'services/CrmApi/types/DirectoryEntry';

interface IAddCompanyExistingProps {
  isOpen: boolean;
  toggleOpen: (isOpen: boolean) => void;
}

export const AddCompanyExisting: React.FC<IAddCompanyExistingProps> = ({ isOpen, toggleOpen }) => {
  const { isMobile, isTablet, isDesktop, isDesktopHD } = useResponsive();

  const sectionClassname = classnames(
    'section-title font-pt-sans text-[17px] leading-normal text-black m-0 select-none py-0 px-0',
    { 'font-[400]': !isOpen, 'font-[700]': isOpen }
  );
  const existingSearchClassname = classnames('form-container flex flex-col justify-between gap-[20px] mt-[20px]', {
    'w-full': isMobile,
    'w-[480px]': isDesktop || isDesktopHD || isTablet
  });

  const toggleSection = useCallback(() => {
    toggleOpen(!isOpen);
  }, [isOpen, toggleOpen]);

  return (
    <div className="px-[10px]">
      <div className="page-section-title font-pt-sans cursor-pointer flex justify-start items-center gap-[5px]" onClick={toggleSection}>
        <span className={classnames('flex justify-center items-center transform-transition transition-ease-in-out duration-300 cursor-pointer',
            { '-rotate-90': !isOpen, 'rotate-0': isOpen }
          )}
        >
          <ArrowDownComponent icon={theme.colors['black']} />
        </span>
        <p className={sectionClassname}>
          Select Existing
        </p>
      </div>
      <AnimateHeight duration={300} height={isOpen ? 'auto' : 0} className="accordion-area">
        <>
          <div className={existingSearchClassname}>
            <Field name="existingCompanyId">
              {({ field: { name, value }, form: { setFieldValue, setFieldTouched } }) => (
                <ExistingDirectoryEntriesSearch value={value} onChange={value => {
                  setFieldValue(name, value)
                }} directoryEntryType={ECrmDirectoryEntryType.COMPANY} />
              )}
            </Field>
          </div>
        </>
      </AnimateHeight>
    </div>
  )
}