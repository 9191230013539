import { useQuery } from "@tanstack/react-query";
import { makeCRMApi } from "../CrmApi";
import { AxiosResponse } from "axios";
import { IDirectoryEntry } from "../types/DirectoryEntry";
import { injectDirectoryEntryTypeIntoProfile } from "../helpers/directoryEntry";

export const useCrmDirectoryEntry = (directoryId: string) => {
  const crmApi = makeCRMApi();

  const crmDirectoryEntry = useQuery<AxiosResponse<IDirectoryEntry>>({
    queryKey: ['crm-directory-entry', directoryId],
    queryFn: () => crmApi.getDirectory(directoryId),
  });

  const directoryEntry = !crmDirectoryEntry.data?.data ? undefined : {
    ...crmDirectoryEntry.data?.data,
    profile: injectDirectoryEntryTypeIntoProfile(crmDirectoryEntry.data?.data),
  };

  return {
    crmDirectoryEntryResponse: crmDirectoryEntry,
    directoryEntry,
  };
}
