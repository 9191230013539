import { IBookingManagerHotelDetailsSubdomain } from './types';
import { initialState } from './model';
import { ENetworkRequestStatus } from 'services/BackendApi/types/Generic';
import * as Actions from './actions';
import { produce } from 'immer';

const financeReducer = (
  state: IBookingManagerHotelDetailsSubdomain = initialState,
  action: Actions.HotelDetailsAction
) => {
  switch (action.type) {
    case Actions.GET_HOTEL_DETAILS_REQUEST:
      return produce(state, draft => {
        draft.networkRequests.hotelDetailsLoad = ENetworkRequestStatus.PENDING;
        return draft;
      });
    case Actions.GET_HOTEL_DETAILS_SUCCESS:
      return produce(state, draft => {
        draft.networkRequests.hotelDetailsLoad = ENetworkRequestStatus.SUCCESS;
        draft.hotelDetails = action.hotelDetails;
        return draft;
      });
    case Actions.GET_HOTEL_DETAILS_FAILURE:
      return produce(state, draft => {
        draft.networkRequests.hotelDetailsLoad = ENetworkRequestStatus.ERROR;
        return draft;
      });

    case Actions.SET_RESORT_CONFIRMATION_NUMBER:
      return produce(state, draft => {
        draft.hotelDetails[0] = {
          ...draft.hotelDetails[0],
          resortConfirmationNumber: action.resortConfirmationNumber,
        };
        return draft;
      });

    case Actions.PATCH_HOTEL_DETAILS_REQUEST:
      return produce(state, draft => {
        draft.networkRequests.hotelDetailsPatchLoad = ENetworkRequestStatus.PENDING;
        return draft;
      });
    case Actions.PATCH_HOTEL_DETAILS_SUCCESS:
      return produce(state, draft => {
        draft.networkRequests.hotelDetailsPatchLoad = ENetworkRequestStatus.SUCCESS;
        draft.hotelDetails = action.hotelDetails;
        return draft;
      });
    case Actions.PATCH_HOTEL_DETAILS_FAILURE:
      return produce(state, draft => {
        draft.networkRequests.hotelDetailsPatchLoad = ENetworkRequestStatus.ERROR;
        return draft;
      });

    default:
      return state;
  }
};

export default financeReducer;
