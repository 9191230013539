import React from 'react';
import { SimpleTabs } from 'ui/SimpleTabs';
import { CrmContactDocuments } from './CrmContactDocuments';
import { CrmContactMainInfo } from './CrmContactMainInfo';
import { IDirectoryEntry, ILinkedDirectoryEntryArrayItem } from 'services/CrmApi/types/DirectoryEntry';

interface ICrmContactTabsProps {
  directoryEntry: IDirectoryEntry;
  contacts: ILinkedDirectoryEntryArrayItem[];
  companies: ILinkedDirectoryEntryArrayItem[];
  onLinkOrUnlink: () => void;
}

export const CrmContactTabs: React.FC<ICrmContactTabsProps> = ({ directoryEntry, contacts, companies, onLinkOrUnlink }) => {
    const crmContactTabs = [
      {
        title: 'Overview',
        name: 'overview',
        styles: 'min-w-[50%]',
        renderContent: () => <CrmContactMainInfo directoryEntryId={directoryEntry.id} contacts={contacts} companies={companies} onLinkOrUnlink={onLinkOrUnlink} />,
      },
      {
        title: 'Documents',
        name: 'documents',
        styles: 'min-w-[50%]',
        renderContent: () => <CrmContactDocuments />,
      },
    ];
  
    return (
      <SimpleTabs tabConfig={crmContactTabs} className="o:w-full o:p-0" tabSelectorClassName="o:m-0" />
    );
}