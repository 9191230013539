import { EUserType, IPage, ISort, IUser } from 'services/BackendApi';
import { IBootstrapCountry } from 'services/BackendApi/types/country';
import { ESortOrder, IPagination } from 'store/common/types';
import { ETypeValueType, ICodeValue, ICrmLeadStage } from './types';

export enum ECrmDirectoryEntryType {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON',
}

export enum ECrmDirectoryListSortableKey {
  NAME = 'name',
  PROFILE_TYPE = 'profileType',
  MANAGER = 'manager',
  COUNTRY = 'country',
  STAGE = 'leadStage',
  SCORE = 'leadScore',
  CREATED_AT = 'createdAt',
}

export interface ILeadCountryScoreItem {
  country: IBootstrapCountry;
  annualValueBookings: number | null;
  annualNumberBookings: number | null;
}

export enum ECrmCompanyType {
  TOUR_OPERATOR = "Tour Operator",
  TRAVEL_AGENT = "Travel Agent",
  CONCIERGE = "Concierge",
  PERSONAL_ASSISTANT = "Personal Assistant",
  CONSORTIA = "Consortia",
  OTHER = "Other",
}

export interface ICrmPerson {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  type: EUserType;
}

export interface ICrmLead {
  /** Format: uuid */
  id: string;
  leadStageType: ICrmLeadStage;
  channelType: ICodeValue | null;
  sourceType: ICodeValue | null;
  scoring: number | null;
  countryScore: ILeadCountryScoreItem[];
}

export interface ILinkedDirectoryEntryArrayItem {
  /** Format: uuid */
  id: string;
  linkId: string;
  type: ECrmDirectoryEntryType;
  fullName: string;
  country: IBootstrapCountry | null;
  /** Format: date-time */
  createdDate: string;
  email: string | null;
  role: ICodeValue | null;
  contactType: ICodeValue | null;
  companyType: ICodeValue | null;
}

export interface ITypeCode {
  value: string;
  profileType: ETypeValueType;
}

export interface IFilter {
  term: string;
  typeCodes: ITypeCode[];    
  managerId: string | null;
  countryCodes: string[];
  leadStageCode: string | null;
  leadScoreGte: number | null;
}

export interface IFilterSearch {
  term?: string;
  companyTypeCodes?: string[];    
  contactTypeCodes?: string[];  
  managerId?: string;
  countryCodes?: string[];
  leadStageCode?: string;
  leadScoreGte?: number | null;
}

export interface IDirectorySearchParams {
  filter: IFilterSearch;
  pagination: IPage;
  sort: ISort<ECrmDirectoryListSortableKey>;
}

export interface IFilterSimpleSearch {
  directoryEntryType: ECrmDirectoryEntryType;
  term: string;
}

export interface IDirectorySimpleSearchParams {
  filter: IFilterSimpleSearch;
  pagination: IPage;
}

export interface ICrmCompanyProfile {
  /** Format: uuid */
  id: string;
  type: ECrmDirectoryEntryType;
  companyType: ICodeValue;
  tcType: ECrmCompanyType;
  name: string;
  industry: string | null;
  description: string | null;
  employeeCount: number | null;
  website: string | null;
}

export interface ICrmContactProfile {
  id: string;
  type: ECrmDirectoryEntryType;
  contactType: ICodeValue;
  firstName: string;
  lastName: string | null;
  titleType: ICodeValue | null;
  landline: string | null;
  region: string | null;
  /** Format: date */
  birthday: string | null;
  personalDocumentType: ICodeValue | null;
  personalDocumentNumber: string | null;
  personalDocumentCountry: ICodeValue | null;
  role: ICodeValue | null;
  /** Format: date */
  roleStart: string | null;
  /** Format: date */
  roleEnd: string | null;
}

export interface IDirectoryEntrySearchResult {
  id: string;
  directoryEntryType: ECrmDirectoryEntryType;
  profileId: string;
  profileType: ICodeValue;
  name: string;
  country: IBootstrapCountry | null;
  leadStage: ICrmLeadStage | null;
  leadScore: number | null;
  manager: ICrmPerson;
  createdAt: string;
}

export interface IDirectoryEntry {
    /** Format: uuid */
    id: string;
    manager: ICrmPerson;
    type: ECrmDirectoryEntryType;
    fullName: string;
    /** Format: email */
    email: string | null;
    acceptsEmail: boolean;
    country: IBootstrapCountry;
    mobile: string | null;
    address: string | null;
    linkedin: string | null;
    twitter: string | null;
    instagram: string | null;
    /** Format: date-time */
    createdDate: string;
    createdBy: ICrmPerson;
    /** Format: date-time */
    updatedDate: string | null;
    updatedBy: ICrmPerson;
    keyNotes: string;
    profile: ICrmCompanyProfile | ICrmContactProfile;
    lead: ICrmLead;
    linkedDirectoryEntries: ILinkedDirectoryEntryArrayItem[];
}

export interface IDirectoryEntryLinked {
    /** Format: uuid */
    id: string;
    type: ECrmDirectoryEntryType;
    fullName: string;
    /** Format: email */
    email: string | null;
    companyType: string | null;
    contactType: string | null;
    roleType: string | null;
}

export interface IDirectorySearchResponse {
  list: IDirectoryEntrySearchResult[];
  pagination: IPagination;
  count: number;
}

export interface IDirectoryEntrySimpleSearchResult {
  /** Format: uuid */
  id: string;
  directoryEntryType: ECrmDirectoryEntryType;
  name: string;
  email: string | null;
}

export interface IDirectorySimpleSearchResponse {
  list: IDirectoryEntrySimpleSearchResult[];
  pagination: IPagination;
}

export interface IUpsertLink {
  sourceId: string;
  destinationId: string;
  linkTypeCode: string;
  reference: string | null;
}

export interface IUpsertLinkResponse {
  id: string;
  source: IDirectoryEntryLinked;
  destination: IDirectoryEntryLinked;
  linkType: ICodeValue;
  user: ICrmPerson;
  /** Format: date-time */
  date: string | null;
  reference: string | null;
}
