import * as BookingBuilderUtils from 'utils/bookingBuilder';
import { saveAs } from 'file-saver';
import { enquiryCreatedNotificationMessage } from './common';
import { EPdfLogo } from 'store/modules/bookingManager/subdomains/breakdown/model';
import { BookingStatusTypes } from 'config/enums';
import { AxiosInstance } from 'axios';
import { AxiosWrapper } from 'services/AxiosWrapper';
import { BackendApiService, BookingBuilderRequest } from 'services/BackendApi';
import { iActingOnBehalfOfUser } from 'store/modules/actingOnBehalfOf/model';
import { IBookingInformation } from 'interfaces';

interface IBookingEndpointAttributes {
  bookingHash: string | null | undefined;
  bookingBuild: BookingBuilderRequest | undefined;
  bookingInformation: IBookingInformation;
  status: string;
  proposalUuid: string | undefined;
  basketBuildUuid?: string | undefined;
}

export const handleSaveBooking = async ({
  bookingBuild,
  bookingDomain,
  backendApi,
  clientCountryCode,
  selectedCompanyMembership,
  setShouldShowLeaveAlert,
  setRedirectLocation,
  taMarginAmountOverride,
  actingOnBehalfOfUser,
  selectedTa,
  basketBuildUuid,
}: {
  bookingBuild;
  backendApi: BackendApiService<AxiosWrapper<AxiosInstance>>;
  actingOnBehalfOfUser: iActingOnBehalfOfUser;
  [key: string]: any; // for all other arguments
}) => {
  const attr: IBookingEndpointAttributes = BookingBuilderUtils.getBookingsEndpointAttributesForBookingDomain({
    bookingDomain,
  });
  attr.basketBuildUuid = basketBuildUuid;
  attr.bookingInformation.travelAgentUserUuid = selectedTa?.uuid;
  if (bookingBuild) {
    attr.bookingBuild = bookingBuild.request;
    attr.bookingHash = bookingBuild.response.bookingHash;
  }
  if (taMarginAmountOverride && taMarginAmountOverride !== '' && taMarginAmountOverride !== '0') {
    attr.bookingInformation.taMarginAmount = taMarginAmountOverride;
  }
  try {
    const booking = await backendApi.createBooking({
      // @ts-ignore legacy
      bookingAttributes: attr,
      clientCountryCode,
      selectedCompanyMembership,
      actingOnBehalfOfUser,
    });
    setShouldShowLeaveAlert(false);
    setRedirectLocation(`/booking-manager/${booking.uuid}`);
  } catch (e) {
    throw new Error(e);
  }
};

export const handleDownloadQuote = async ({
  bookingBuild,
  bookingDomain,
  backendApi,
  bookingManagerApi,
  enqueueNotification,
  clientCountryCode,
  quoteMrginLogoOption,
  selectedCompanyMembership,
  taMarginAmountOverride,
  actingOnBehalfOfUser,
  selectedTa,
  basketBuildUuid,
  onPostRemove,
}: {
  backendApi: BackendApiService<AxiosWrapper<AxiosInstance>>;
  actingOnBehalfOfUser: iActingOnBehalfOfUser;
  basketBuildUuid?: string;
  [key: string]: any; // for all other arguments
}) => {
  try {
    const attr: IBookingEndpointAttributes = BookingBuilderUtils.getBookingsEndpointAttributesForBookingDomain({
      bookingDomain,
    });
    if (basketBuildUuid) {
      attr.basketBuildUuid = basketBuildUuid;
    }
    attr.bookingInformation.travelAgentUserUuid = selectedTa?.uuid;
    if (bookingBuild) {
      attr.bookingBuild = bookingBuild.request;
      attr.bookingHash = bookingBuild.response.bookingHash;
    }
    if (taMarginAmountOverride && taMarginAmountOverride !== '' && taMarginAmountOverride !== '0') {
      attr.bookingInformation.taMarginAmount = taMarginAmountOverride;
    }
    const booking = await backendApi.createBooking({
      // @ts-ignore legacy
      bookingAttributes: attr,
      clientCountryCode,
      selectedCompanyMembership,
      actingOnBehalfOfUser,
    });

    enqueueNotification({
      message: enquiryCreatedNotificationMessage(booking),
      options: { variant: 'success' },
    });

    let upload = { url: '', filename: '' };
    if (quoteMrginLogoOption === EPdfLogo.MAIN_COMPANY_LOGO || quoteMrginLogoOption === null) {
      const { data } = await bookingManagerApi.generateBreakdownPdfForTa(booking.uuid, true);
      upload = data;
    } else {
      const { data } = await bookingManagerApi.generateBreakdownPdfForClient(booking.uuid, quoteMrginLogoOption, true);
      upload = data;
    }

    if (basketBuildUuid) {
      onPostRemove(basketBuildUuid);
    }

    saveAs(upload.url, upload.filename);
  } catch (err) {
    enqueueNotification({
      message: 'Failed to generate a quote to download',
      options: { variant: 'error' },
    });

    throw err;
  }
};

export const handleRequestBooking = async ({
  bookingBuild,
  bookingDomain,
  backendApi,
  liveRatePricesModalData = false,
  clientCountryCode,
  selectedCompanyMembership,
  isInstantBook = false,
  bbv1AddBookingError,
  bbv1ClearBookingErrors,
  setShouldShowLeaveAlert,
  setRedirectLocation,
  paymentMethod,
  taMarginAmountOverride,
  actingOnBehalfOfUser,
  selectedTa,
  basketBuildUuid,
}: {
  backendApi: BackendApiService<AxiosWrapper<AxiosInstance>>;
  actingOnBehalfOfUser: iActingOnBehalfOfUser | null;
  [key: string]: any; // for all other arguments
}) => {
  let attr = {
    ...BookingBuilderUtils.getBookingsEndpointAttributesForBookingDomain({
      bookingDomain,
      bookingStatus: BookingStatusTypes.REQUESTED,
      paymentMethod,
    }),
    isInstantBook,
    basketBuildUuid,
  };
  attr.basketBuildUuid = basketBuildUuid;
  attr.bookingInformation.travelAgentUserUuid = selectedTa?.uuid;
  if (bookingBuild) {
    attr.bookingBuild = bookingBuild.request;
    attr.bookingHash = bookingBuild.response.bookingHash;
  }

  if (taMarginAmountOverride && taMarginAmountOverride !== '' && taMarginAmountOverride !== '0') {
    attr.bookingInformation.taMarginAmount = taMarginAmountOverride;
  }

  // merge new prices coming from priceCheck endpoint
  const bookingAttributes = !liveRatePricesModalData
    ? attr
    : {
        ...attr,
        bookingBuild: {
          ...attr.bookingBuild,
          Accommodation: attr.bookingBuild!.Accommodation.map((acc, index) => ({
            ...acc,
            liveRate: {
              ...acc.liveRate,
              amount: liveRatePricesModalData[index].newPrice ?? liveRatePricesModalData[index].prevPrice,
            },
          })),
        },
      };

  try {
    bbv1ClearBookingErrors();
    const booking = await backendApi.createBooking({
      // @ts-ignore legacy
      bookingAttributes,
      clientCountryCode,
      selectedCompanyMembership,
      actingOnBehalfOfUser,
    });

    setShouldShowLeaveAlert(false);
    setRedirectLocation(`/booking-manager/${booking.uuid}`);
  } catch (e) {
    e.response.data.errors?.map(error => bbv1AddBookingError(error));
    throw e;
  }
};

export const openLink = (url: string) => window.open(url, '_blank');
