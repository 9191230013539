import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { tail } from 'ramda';
import classnames from 'classnames';
import { formatDateDisplay } from 'utils';
import {
  IBookingStateHistoryItem,
  ITopNavigationData,
  EBookingStatus,
  IBookingInformationReservationTeamData,
} from 'services/BookingManagerApi/types';
import { HidingTooltip } from 'ui/Tooltip';
import SectionTitle from 'ui/SectionTitle';
import { SvgIcon } from '../../ui/SvgIcon';
import CalendarInIcon from 'ui/Icons/calendar-in.component.svg';
import CalendarOutIcon from 'ui/Icons/calendar-out.component.svg';
import { format } from 'ui/Ledger/utils';
import { getAge } from 'pureUi/GuestInformationForm/helpers';

import InfoIcon from '../../ui/Icons/Info.component.svg';
import { theme } from '../../../tailwind.config';
import { ERateSource } from 'services/BookingManagerApi';
import { DateHelper } from 'pureUi/DatePicker';
import { EUserType, IGuest } from 'services/BackendApi';
import StarIcon from 'ui/Icons/star-sharp.component.svg';
import { HidingTextTooltip } from 'ui/Tooltip/HidingTextTooltip';
import CopyIcon from 'ui/Icons/copy.component.svg';
import { bookingInformationReservationTeamDashboardSelector } from 'store/modules/bookingManager/subdomains/dashboard/selectors';
import * as InventoryHeaderSelectors from 'store/modules/inventoryHeader/selectors';
interface ITopNavigationBarProps {
  userRole: EUserType;
  data: ITopNavigationData | null;
  bookingInformationReservationTeamData: IBookingInformationReservationTeamData | null;
  isLoading?: boolean;
  isError: boolean;
}
import { enqueueNotification } from 'store/modules/ui';

const renderDate = (date: string | undefined): string => {
  return date ? formatDateDisplay(date) : 'unknown';
};

interface IDepositAccountSummaryProps {
  balances: ITopNavigationData['depositAccountBalancesCents'];
  travelAgentCompanyUuid: ITopNavigationData['travelAgentCompanyUuid'];
}

interface TooltipComponentProps {
  balance: number;
  children: React.ReactNode;
}

const TooltipComponent = (props: TooltipComponentProps) => {
  const creditTooltip = React.useCallback(() => {
    return (
      <div className="text-13px leading-17px text-black p-10px" style={{ maxWidth: '170px' }}>
        {props.balance > 0 ? (
          <p>
            The TA <span className="text-brown-100">owes money</span>.
          </p>
        ) : (
          <p>
            The TA has a <span className="text-brown-100">credit</span>.
          </p>
        )}
      </div>
    );
  }, [props.balance]);

  if (!props.balance) {
    return <>{props.children}</>;
  }

  return (
    <HidingTooltip renderTooltipContent={creditTooltip} position="bottom">
      {props.children}
    </HidingTooltip>
  );
};

const DEFAULT_BALANCES = { USD: 0, EUR: 0 };
const DepositAccountSummary = ({
  balances = DEFAULT_BALANCES,
  travelAgentCompanyUuid,
}: IDepositAccountSummaryProps) => {
  const { USD, EUR } = balances;
  return (
    <>
      <div className={`capitalize font-bold`} data-testid="deposit-account-summary">
        Deposit account
      </div>
      <TooltipComponent balance={EUR}>
        <a href={travelAgentCompanyUuid ? `/statement/${travelAgentCompanyUuid}/deposits/eur` : '#'}>
          <u className="text-black" data-testid="deposit-account-eur">
            {format.amount('EUR')(EUR)}
          </u>
        </a>
      </TooltipComponent>
      <TooltipComponent balance={USD}>
        <a href={travelAgentCompanyUuid ? `/statement/${travelAgentCompanyUuid}/deposits/usd` : '#'}>
          <u className="text-black" data-testid="deposit-account-usd">
            {format.amount('USD')(USD)}
          </u>
        </a>
      </TooltipComponent>
    </>
  );
};

const StatusHistory = ({ statusHistory }: { statusHistory: IBookingStateHistoryItem[] }) => {
  return (
    <>
      <div className="indicators flex flex-col items-center pt-1">
        <div className="indicator rounded-full w-11px h-11px bg-teal-100"></div>
        {tail(statusHistory).map((item: IBookingStateHistoryItem) => {
          return (
            <React.Fragment key={item.status}>
              <div className="connector h-3 border-r border-solid border-gray-40"></div>
              <div className="indicator rounded-full w-11px h-11px bg-teal-100"></div>
            </React.Fragment>
          );
        })}
      </div>
      <div className="list text-sm leading-sm font-bold ml-3">
        {statusHistory.map((item: IBookingStateHistoryItem) => {
          // Potential should be Enquiry
          return (
            <div className={`item ${item.status} capitalize`} key={item.status}>
              {item.status === EBookingStatus.ENQUIRY ? 'Enquiry' : item.status}
            </div>
          );
        })}
      </div>
      <div className="dates text-sm leading-sm text-right text-gray-100 ml-4">
        {statusHistory.map((item: IBookingStateHistoryItem) => {
          return (
            <div key={item.status} className={`item ${item.status}`}>
              {renderDate(item.timestamp)}
            </div>
          );
        })}
      </div>
    </>
  );
};

export const TopNavigationBar: React.FC<ITopNavigationBarProps> = ({
  userRole,
  data,
  bookingInformationReservationTeamData,
  isLoading = false,
  isError,
}) => {
  const semanticClassName = 'top-navigation';
  const containerClassName = `
    flex items-center justify-center min-h-150px font-pt-sans
    border border-solid border-gray-40 shadow-pe1 mb-7
  `;

  const isSrOrAdminOrFinance = [EUserType.ADMIN, EUserType.FINANCE, EUserType.SR].includes(userRole);

  if (isLoading || data === null) {
    return (
      <div className={`${semanticClassName} loading ${containerClassName} text-lg text-black`}>
        <span>Loading...</span>
      </div>
    );
  }

  if (isError) {
    return (
      <div className={`${semanticClassName} error ${containerClassName} text-sm text-red-100`}>
        <span>Failed to load Top Navigation Data</span>
      </div>
    );
  }

  const borderStyles = 'border-r border-solid border-r-gray-40';

  const isInventoryHeaderOpen = useSelector(InventoryHeaderSelectors.isMenuOpenSelector);

  const bookingInformationTooltipContent = (
    <div className="space-y-5">
      <span className="block space-x-2">
        <span className="font-bold">Ref:</span>
        <span>{bookingInformationReservationTeamData?.bookingReferenceNo}</span>
      </span>
      <span className="block space-x-2">
        <span className="font-bold">Agent:</span>
        <span>{`${bookingInformationReservationTeamData?.travelAgentCompanyName} (${bookingInformationReservationTeamData?.travelAgentCountry}) - Ref: ${bookingInformationReservationTeamData?.travelAgentCompanyId}, ${bookingInformationReservationTeamData?.travelAgentCompanyName}`}</span>
      </span>
      <span className="block space-x-2">
        <span className="font-bold">Hotel:</span>
        <span>{bookingInformationReservationTeamData?.hotelNames.join(', ')}</span>
      </span>
      <span className="block space-x-2">
        <span className="font-bold">Resort Confirmation:</span>
        <span>{bookingInformationReservationTeamData?.resortConfirmationNumbers.join(', ')}</span>
      </span>
      <span className="block space-x-2">
        <span className="font-bold">Dates:</span>
        <span>
          {formatDateDisplay(bookingInformationReservationTeamData?.arrivalDate)} -{' '}
          {formatDateDisplay(bookingInformationReservationTeamData?.departureDate)}
        </span>
      </span>
      <span className="block">
        <span className="block font-bold">Guests:</span>
        <span className="space-y-2">
          {bookingInformationReservationTeamData?.guests.map((guest, guestIndex) => (
            <span key={`${guestIndex}${guest.title}${guest.name}`} className="block">
              <span>
                {guest.name} {guest.dateOfBirth ? `- Age: ${getAge(guest as IGuest)}` : ''}{' '}
                {guest.isLeadGuest && ' (LEAD)'}
              </span>
              <span className="block">
                From {guest.arrivalDate ? formatDateDisplay(guest.arrivalDate) : ''} - {guest.arrivalFlightNo} at{' '}
                {guest.arrivalTime} - {guest.arrivalCIPBookingNumber}
              </span>
              <span className="block">
                To {guest.departureDate ? formatDateDisplay(guest.departureDate) : ''} - {guest.departureFlightNo} at{' '}
                {guest.departureTime} - {guest.departureCIPBookingNumber}
              </span>
            </span>
          ))}
        </span>
      </span>
      <span className="block">
        <span className="block font-bold">Accommodations:</span>
        {bookingInformationReservationTeamData?.accommodations.map(accommodation => (
          <span key={`${accommodation.title}${accommodation.title}`} className="block">
            <span>
              {accommodation.title} - Meal basis: {accommodation.mealPlan}
            </span>
          </span>
        ))}
      </span>
      <span className="block">
        <span className="block font-bold">Transfers:</span>
        {bookingInformationReservationTeamData?.transfers.map((transfer, transferIndex) => (
          <span key={`${transfer.title}${transferIndex}`} className="block">
            <span>
              {transfer.title} - {transfer.tertiaryText}
            </span>
          </span>
        ))}
      </span>
    </div>
  );

  const isElementOverflown = element => {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  };

  // this looks insane but please don't format it! This code is formatted like this
  // so that the string it returns is formatted correctly when copied to the clipboard
  const getBookingInformationReservationTeamClipboardFormat = () => {
    return `Ref: ${bookingInformationReservationTeamData?.bookingReferenceNo || ''}
Agent: ${bookingInformationReservationTeamData?.travelAgentCompanyName ||
      ''} (${bookingInformationReservationTeamData?.travelAgentCountry ||
      ''}) - Ref: ${bookingInformationReservationTeamData?.travelAgentCompanyId ||
      ''}, ${bookingInformationReservationTeamData?.travelAgentCompanyName || ''}
Hotel: ${bookingInformationReservationTeamData?.hotelNames || ''}
Resort Confirmation: ${bookingInformationReservationTeamData?.resortConfirmationNumbers || ''}
Dates: ${
      bookingInformationReservationTeamData?.arrivalDate
        ? formatDateDisplay(bookingInformationReservationTeamData?.arrivalDate)
        : ''
    } - ${
      bookingInformationReservationTeamData?.departureDate
        ? formatDateDisplay(bookingInformationReservationTeamData?.departureDate)
        : ''
    }

Guests:
${bookingInformationReservationTeamData?.guests
  .map(
    guest =>
      `${guest.name || ''} ${guest.dateOfBirth ? `- Age: ${getAge(guest as IGuest)}` : ``} ${
        guest.isLeadGuest ? ' (LEAD)' : ''
      }
From ${guest.arrivalDate ? formatDateDisplay(guest.arrivalDate) : ''} - ${guest.arrivalFlightNo ||
        ''} at ${guest.arrivalTime || ''} - ${guest.arrivalCIPBookingNumber || ''}
To ${guest.departureDate ? formatDateDisplay(guest.departureDate) : ''} - ${guest.departureFlightNo ||
        ''} at ${guest.departureTime || ''} - ${guest.departureCIPBookingNumber || ''}
`
  )
  .join('')}
Accommodations:
${bookingInformationReservationTeamData?.accommodations
  .map(
    accommodation => `${accommodation.title || ''} - Meal basis: ${accommodation.mealPlan || ''}
`
  )
  .join('')}
Transfers:
${bookingInformationReservationTeamData?.transfers
  .map(
    transfer => `${transfer.title || ''} - ${transfer.tertiaryText || ''}
`
  )
  .join('')}`;
  };

  // if the booking tooltip content overflows, give it a fade style
  const [shouldApplyFadeStyle, setShouldApplyFadeStyle] = React.useState(false);

  const dispatch = useDispatch();

  return (
    <div
      className={`${semanticClassName} p-5 border border-solid border-gray-40 shadow-pe1 font-pt-sans text-black mb-7`}
    >
      <div
        style={{
          minHeight: '132px', // so the rate source badge doesn't overlap the booking ref
        }}
        className="flex flex-row"
      >
        {/* details */}
        <div style={{ minWidth: '201px' }} className={`section ${borderStyles} pr-4 relative`}>
          <div className="flex justify-between">
            <SectionTitle testId="section-title">Details</SectionTitle>
            <HidingTooltip
              tooltipClassname={classnames('booking-information-tooltip p-4 overflow-y-hidden', {
                'max-h-[calc(100vh-300px)]': isInventoryHeaderOpen,
                'max-h-[calc(100vh-320px)]': !isInventoryHeaderOpen,
                'fade-out-to-white': shouldApplyFadeStyle,
              })}
              onRenderCallback={() => {
                const bookingInformationTooltipElement = document.getElementsByClassName(
                  'booking-information-tooltip'
                )[0];
                setShouldApplyFadeStyle(isElementOverflown(bookingInformationTooltipElement));
              }}
              renderTooltipContent={() => bookingInformationTooltipContent}
              position="bottom-right"
              width="600px"
            >
              <button
                className="text-brown-prime h-20px cursor-pointer bg-white p-0 outline-none"
                onClick={async () => {
                  try {
                    await navigator.clipboard.writeText(getBookingInformationReservationTeamClipboardFormat());
                    dispatch(
                      enqueueNotification({
                        message: `Copied booking information to clipboard`,
                        options: { variant: 'success' },
                      })
                    );
                  } catch (e) {
                    dispatch(
                      enqueueNotification({
                        message: `Failed to copy booking information to clipboard`,
                        options: { variant: 'error' },
                      })
                    );
                  }
                }}
              >
                <CopyIcon className="w-[20px] fill-brown-prime" />
              </button>
            </HidingTooltip>
          </div>
          <div className="booking-reference mt-4">
            {/* booking reference number */}
            <div className="flex flex-row items-center">
              <span className="text-gray-100 font-bold text-xl leading-md">#</span>
              <span data-testid="topNavigationReferenceNo" className="reference-number text-sm leading-sm ml-2">
                {data.bookingReferenceNo}
              </span>
            </div>
          </div>
          <div style={{ minWidth: '160px' }} className="flex flex-row items-center absolute bottom-0 space-x-8px">
            {/* static rate or live rate badges */}
            {(data.rateSource === undefined ||
              data.rateSource === ERateSource.STATIC ||
              data.rateSource === ERateSource.INSTANT_BOOK) && (
              <span className="block text-xs rate-source-static border border-solid border-gray-50 px-5px py-2px uppercase font-hurmegeometric-sans rounded-1px text-black">
                Static Rate
              </span>
            )}
            {data.rateSource === ERateSource.SUPPLIER_UNCONFIRMED && (
              <div className="rate-source-supplier-unconfirmed flex flex-row items-center">
                <span className="text-xs bg-yellow-20 border border-solid border-yellow-30 px-5px py-2px uppercase font-hurmegeometric-sans rounded-1px text-black mr-2">
                  Live Rate
                </span>

                <HidingTextTooltip
                  tooltipContent="The rate shown has not been checked and may have changed."
                  position="right"
                  width="136px"
                >
                  <InfoIcon width="17px" height="17px" fill={theme.colors['gray-80']} />
                </HidingTextTooltip>
              </div>
            )}
            {data.rateSource === ERateSource.SUPPLIER_CONFIRMED && (
              <span className="block text-xs rate-source-supplier-confirmed bg-teal-40 border border-solid border-teal-80 px-5px py-2px uppercase font-hurmegeometric-sans rounded-1px text-black">
                Live Rate
              </span>
            )}

            {/* travel company membership name */}
            {data.travelCompanyMembershipName && (
              <HidingTextTooltip
                tooltipContent="Membership associated to this booking."
                position="bottom"
                width="236px"
              >
                <span className="travel-company-membership flex flex-row py-2px px-1 items-center font-hurmegeometric-sans text-13px border border-solid border-gray-20 max-w-[92px]">
                  <SvgIcon
                    IconComponent={StarIcon}
                    defaultFill={theme.colors['teal-100']}
                    hoverFill={theme.colors['teal-100']}
                    activeFill={theme.colors['teal-100']}
                  />
                  <span className="travel-company-membership-name ml-1 line-clamp-2 overflow-hidden text-ellipsis break-words">
                    {data.travelCompanyMembershipName}
                  </span>
                </span>
              </HidingTextTooltip>
            )}
          </div>
        </div>

        {/* guest name, hotel name, number of guests */}
        <div style={{ minWidth: '160px' }} className={`section ${borderStyles} pl-4 pr-4 text-sm leading-xl`}>
          <div className="lead-guest flex items-center" data-testid="topNavigationLeadGuest">
            <svg
              width="20"
              height="20"
              className="text-gray-100"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path
                fill="currentColor"
                d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
              ></path>
            </svg>
            <span className="name font-bold ml-3">{data.leadGuestName}</span>
          </div>
          <div className="hotel flex items-center">
            <svg
              width="20"
              height="20"
              className="text-gray-100"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path
                fill="currentColor"
                d="M20 4H4v2h16V4zm1 10v-2l-1-5H4l-1 5v2h1v6h10v-6h4v6h2v-6h1zm-9 4H6v-4h6v4z"
              ></path>
            </svg>
            <span className="name ml-3">{data.hotelNames?.join(', ')}</span>
          </div>
          <div className="guests flex items-center" data-testid="topNavigationGuestCount">
            <svg
              width="20"
              height="20"
              className="text-gray-100"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path
                fill="currentColor"
                d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"
              ></path>
            </svg>

            <span className="count ml-3">
              {data.guestCount} {data.guestCount === 1 ? 'guest' : 'guests'}{' '}
              {data.childrenAges.length >= 1 ? `(Children ages: ${data.childrenAges.join(', ')})` : ''}
            </span>
          </div>
        </div>

        {/* dates and nights */}
        <div
          style={{ minWidth: '130px' }}
          className={`section ${borderStyles} pl-4 pr-4 text-sm leading-xl flex flex-col justify-between`}
          data-testid="topNavigationDates"
        >
          <div className="booking-arrival-departure">
            <div className="arrival flex items-center">
              <span
                style={{
                  marginTop: '-12px',
                }}
              >
                <SvgIcon
                  IconComponent={CalendarInIcon}
                  defaultFill={theme.colors['gray-100']}
                  hoverFill={theme.colors['gray-100']}
                  activeFill={theme.colors['gray-100']}
                  width="26px"
                  height="14px"
                />
              </span>
              <span className="date ml-3">{renderDate(data.arrivalDate)}</span>
            </div>
            <div className="departure flex items-center">
              <span
                style={{
                  marginTop: '-12px',
                }}
              >
                <SvgIcon
                  IconComponent={CalendarOutIcon}
                  defaultFill={theme.colors['gray-100']}
                  hoverFill={theme.colors['gray-100']}
                  activeFill={theme.colors['gray-100']}
                  width="26px"
                  height="14px"
                />
              </span>
              <span className="date ml-3">{renderDate(data.departureDate)}</span>
            </div>
          </div>

          <span className="uppercase bg-teal-20 rounded text-black text-14px px-2 self-start">
            {DateHelper.daysBetween(data.arrivalDate!, data.departureDate!)} nights
          </span>
        </div>

        {/* travel agent info */}
        <div className={`section ${borderStyles} flex pl-4 pr-4 leading-sm text-sm`}>
          <div>
            <svg
              width="20"
              height="20"
              className=" text-gray-100"
              focusable="false"
              viewBox="0 0 24 24"
              aria-hidden="true"
              role="presentation"
            >
              <path
                fill="currentColor"
                d="M21 8V7l-3 2-3-2v1l3 2 3-2zm1-5H2C.9 3 0 3.9 0 5v14c0 1.1.9 2 2 2h20c1.1 0 1.99-.9 1.99-2L24 5c0-1.1-.9-2-2-2zM8 6c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm6 12H2v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1zm8-6h-8V6h8v6z"
              ></path>
              <path fill="none" d="M0 0h24v24H0z"></path>
            </svg>
            {/* <ContactMail className="text-gray-100" fontSize="small" /> */}
          </div>
          <div className="travel-agent ml-3">
            <div className="name">{data.travelAgentName}</div>
            <div className="company-name flex flex-row items-center justify-between">
              {isSrOrAdminOrFinance && (
                <u className="text-black">
                  <a href={`/travel-companies/${data.travelAgentCompanyUuid}/settings`} target="_blank">
                    {data.travelAgentCompanyName}
                  </a>
                </u>
              )}
              {!isSrOrAdminOrFinance && <span>{data.travelAgentCompanyName}</span>}
              {data.travelAgentCompanyComment && isSrOrAdminOrFinance && (
                <HidingTextTooltip tooltipContent="TC has comments" position="bottom">
                  <InfoIcon width="17px" height="17px" fill={theme.colors['status-enquiry']} />
                </HidingTextTooltip>
              )}
            </div>
            <div className="email">{data.travelAgentEmail}</div>
            <div className="phone">Phone: {data.travelAgentTelephone}</div>
          </div>
        </div>

        {/* state history */}
        {data.bookingStateHistory && data.bookingStateHistory.length > 0 && (
          <div
            style={{ minWidth: '195px' }}
            className={classnames('section status-history flex items-start bg-white-true pl-4', {
              [borderStyles]: isSrOrAdminOrFinance,
            })}
            data-testid="topNavigationStatusHistory"
          >
            <StatusHistory statusHistory={data.bookingStateHistory} />
          </div>
        )}

        {isSrOrAdminOrFinance && (
          <div style={{ minWidth: '113px' }} className={`section text-black flex flex-col pl-4 leading-sm text-sm`}>
            <DepositAccountSummary
              travelAgentCompanyUuid={data.travelAgentCompanyUuid}
              balances={data.depositAccountBalancesCents}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TopNavigationBar;
